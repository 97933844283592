import { reducerConst } from "../constants/storeConstants"

export const getUserDetails = payload => {
    return {
        type: reducerConst.USER_DETAILS,
        payload,
    }
}

export const getUserBonusDetails = payload => {
    return {
        type: reducerConst.USER_BONUS_DETAIL,
        payload,
    }
}


export const isFavLoadingAction = payload => {
    return {
        type: reducerConst.Fav_LOADING,
        payload,
    }
}


export const getFavFiatAction = payload => {
    return {
        type: reducerConst.FAV_FIAT,
        payload,
    }
}


export const getFavCryptoAction = payload => {
    return {
        type: reducerConst.FAV_CRYPTO,
        payload,
    }
}

export const getNewsCat = payload => {
    return {
        type: reducerConst.NEWS_CATEGORIES,
        payload,
    }
}

export const getCryptoListAction = payload => {
    return {
        type: reducerConst.CRYPTO_LIST,
        payload,
    }
}

export const ipAddressAction = payload => {
    return {
        type: reducerConst.IP_ADDRESS,
        payload,
    }
}


export const countryAction = payload => {
    return {
        type: reducerConst.COUNTRY_NAME,
        payload,
    }
}

export const btRendererAction = payload => {
    return {
        type: reducerConst.BT_RENDERER,
        payload,
    }
}


export const btUrlAction = payload => {
    return {
        type: reducerConst.BT_URL,
        payload,
    }
}

export const buyCurrencyAction = payload => {
    return {
        type: reducerConst.BUY_CURRENCY,
        payload,
    }
}

export const buyNetworkAction = payload => {
    // alert("heeelo--->>>>>")
    return {
        type: reducerConst.BUY_NETWORK,
        payload,
    }
}

export const buyAssetAction = payload => {
    // alert("heeelo--->>>>>")
    return {
        type: reducerConst.BUY_ASSET,
        payload,
    }
}



export const sellCurrencyAction = payload => {
    // alert("heeelo--->>>>>")
    return {
        type: reducerConst.SELL_CURRENCY,
        payload,
    }
}

export const sellNetworkAction = payload => {
    // alert("heeelo--->>>>>")
    return {
        type: reducerConst.SELL_NETWORK,
        payload,
    }
}

export const sellAssetAction = payload => {
    // alert("heeelo--->>>>>")
    return {
        type: reducerConst.SELL_ASSET,
        payload,
    }
}


export const swapNetworkFromAction = payload => {
    return {
        type: reducerConst.SWAP_NETWORK_FROM,
        payload,
    }
}

export const swapAssetFromAction = payload => {
    return {
        type: reducerConst.SWAP_ASSET_FROM,
        payload,
    }
}

export const swapNetworkToAction = payload => {
    return {
        type: reducerConst.SWAP_NETWORK_TO,
        payload,
    }
}

export const swapAssetToAction = payload => {
    return {
        type: reducerConst.SWAP_ASSET_TO,
        payload,
    }
}


export const currencyAction = payload => {
    return {
        type: reducerConst.CURRENCY_DATA,
        payload,
    }
}


export const networkAction = payload => {
    return {
        type: reducerConst.NETWORK_DATA,
        payload,
    }
}


export const assetAction = payload => {
    return {
        type: reducerConst.ASSET_DATA,
        payload,
    }
}


// export const metamaskAccAction = payload => {
//     return {
//         type: reducerConst.METAMASK_ACC,
//         payload,
//     }
// }


export const metamaskBuyAction = payload => {
    return {
        type: reducerConst.METAMASK_ACC_BUY,
        payload,
    }
}


export const metamaskSellAction = payload => {
    return {
        type: reducerConst.METAMASK_ACC_SELL,
        payload,
    }
}



export const walletBuyAction = payload => {
    return {
        type: reducerConst.WALLET_ACC_BUY,
        payload,
    }
}


export const walletSellAction = payload => {
    return {
        type: reducerConst.WALLET_ACC_SELL,
        payload,
    }
}


export const userBalanceAction = payload => {
    return {
        type: reducerConst.USER_BALANCE,
        payload,
    }
}

export const sportsBookAction = payload => {
    return {
        type: reducerConst.SPORT_BOOK,
        payload,
    }
}

export const topRatedGamesAction = payload => {
    return {
        type: reducerConst.TOP_RATED_GAMES,
        payload,
    }
}

export const categoryPillOptions = payload => {
    return {
        type: reducerConst.CATEGORY_PILL_OPTIONS,
        payload,
    }
}

export const currencyExchangeData = payload => {
    return {
        type: reducerConst.CURRENCY_EXCHANGE,
        payload,
    }
}


export const selectCrypto = payload => {
    return {
        type: reducerConst.SELECTED_CRYPTO,
        payload,
    }
}

export const selectFiat = payload => {
    return {
        type: reducerConst.SELECTED_FIAT,
        payload,
    }
}

export const setRubleAmountAction = payload => {
    console.log({payload})
    return {
        type: reducerConst.RUBLE_AMOUNT,
        payload,
    }
}