import React, { useEffect, useState } from 'react'
import { getAPIAuth } from '../../../service/apiInstance';
import moment from 'moment';
import Pagination from './Pagination';


const filterBetsOptions = [
  {
    label: "Sport",
    value: "sport",
  },
  {
    label: "Casino",
    value: "casino",
  },
];


const BetHistory = () => {
  const [selectedBet, setSelectedBet] = useState('sport')
  const [listLoader, setListLoader] = useState(true)
  const [loader, setLoader] = useState(true)
  const [cryptoList, setCryptoList] = useState([])
  const [activeCrypto, setActiveCrypto] = useState('ALL')
  const [data, setData] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [pageNo, setPageNo] = useState(1)


  console.log('totalPages', totalPages)

      // getting all crypto id
      const getCryptoList = async () => {
        setListLoader(true)
        try {
          const res = await getAPIAuth(`crypto/get-crypt-favorite-by-admin`)
          if (res?.data?.success) {
            setCryptoList(res?.data?.data)
            setActiveCrypto("ALL")
          }
        } catch (error) {
    
        } finally {
          setListLoader(false)
        }
      }

      const getBetHistory = async () => {
        setLoader(true)
        try {
          const res = await getAPIAuth(`user/betHistory?type=${selectedBet}&start_date&end_date&limit=10&skip=${(pageNo - 1) * 10}&betStatus&provider`)
          if (res?.data?.success) {
            setData(res?.data?.data)
            setTotalPages(Math.ceil(res?.data?.totalRecord?.[0]?.total / 10))
          }
        } catch (error) {
    
        } finally {
          setLoader(false)
        }
      }
    
      useEffect(() => {
        getCryptoList()
      }, [])

      useEffect(() => {
        getBetHistory()
      }, [selectedBet, pageNo])


  return (
    <>
    <div className="row">
      <div className="col-6 col-md-4">
        <div className="dropdown reportsDropdown">
          <button className="btn btn-secondary marketBtn dropdown-toggle dropdownToggle text-capitalize" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {selectedBet ? selectedBet : 'Sports'}
          </button>
          <ul className="dropdown-menu dropdownMenu w-100" >
            {
              filterBetsOptions?.map((item) => (
                <li className={`menuLinkDrop ${item?.label === selectedBet ? 'active' : ''}`}
                  onClick={() => setSelectedBet(item?.label?.toLowerCase() === "all" ? '' : item?.label?.toLowerCase())}
                  key={item?.label}
                >{item?.label}
                </li>
              ))
            }
          </ul>
        </div>
      </div>
      <div className="col-6 col-md-4">
        <div className="dropdown reportsDropdown">
          <button className="btn btn-secondary marketBtn dropdown-toggle dropdownToggle text-uppercase" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {activeCrypto ? activeCrypto : 'All'}
          </button>
          <ul className="dropdown-menu dropdownMenu w-100" >
              <li className={`menuLinkDrop ${'ALL' === activeCrypto ? 'active' : ''}`}
                  onClick={() => setActiveCrypto('ALL')}
                >ALL
                </li>
            {
              cryptoList?.map((item) => (
                <li className={`menuLinkDrop text-uppercase ${item?.code === activeCrypto ? 'active' : ''}`}
                  onClick={() => setActiveCrypto(item?.code?.toLowerCase() === "All" ? '' : item?.code?.toLowerCase())}
                  key={item?.code}
                >{item?.code}
                </li>
              ))
            }
          </ul>
        </div>
      </div>
      <div className="col-12 pt-3">
        
        <div className="table-responsive">
                      <table className="table affiliateTable mb-2 align-middle">
                        <thead>
                          <tr className="tableHead text-nowrap">
                            <th scope="col" className="border-0 rounded-start-2 px-4">
                              TYPE
                            </th>
                            <th scope="col" className="border-0 px-4">
                              TIME
                            </th>
                            <th scope="col" className="border-0 px-4 texter">
                              AMOUNT
                            </th>
                            <th
                              scope="col"
                              className="border-0 px-4 text-er rounded-end-2"
                            >
                              PROFIT
                            </th>
                            <th
                              scope="col"
                              className="border-0 px-4 text-er rounded-end-2"
                            >
                              BET STATUS
                            </th>
                          </tr>
                        </thead>
                        <tbody className="tablebody">
                          {data?.length ? data?.map((item) => (
                            <tr className="text-nowrap" key={item?._id}>
                              <td className="border-0  rounded-start-2 px-4 text-uppercase">
                                {item?.betType}
                              </td>
                              <td className="border-0 px-4">
                              {moment(item.createdAt).format("MMM DD YYYY , HH:mm")}
                              </td>
                              <td className="border-0 px-4">
                                {item?.amount ? Number(item.amount).toFixed(4) : '0.00'}
                              </td>
                              <td className="border-0 px-4 text-decoration-underlin cursor-pointer">
                                {item?.profitLoss ? Number(item.profitLoss).toFixed(4) : '0.00'}
                              </td>
                              <td className="border-0 px-4 text-decoration-underlin cursor-pointer text-capitalize">
                                {item?.betStatus}
                              </td>
                            </tr>
                          )) : <>
                            <div className="py-5 text-center text-white">No data found</div>
                          </>}
                        </tbody>
                      </table>
                    </div>

        {
          totalPages > 1 ? 
            <>
              <Pagination 
                pageNo={pageNo} 
                setPageNo={setPageNo} 
                totalPages={totalPages}
              />
            </>
          : ''
        }
      </div>
    </div>
    </>
  )
}

export default BetHistory