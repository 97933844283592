import React from 'react'
import Modal from 'react-bootstrap/Modal';


const Hunthub = ({
    isOpen,
    setIsOpen
}) => {
    return (
        <>
            <Modal show={isOpen} centered onHide={() => setIsOpen(false)} className='modalSection' >
                <Modal.Header closeButton>
                    <Modal.Title>Hunts Hub</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modalBody'>
                    <div className='modalheading-green modalheading my-3'>
                        THURSDAY
                    </div>
                    <div className='d-flex align-items-start justify-content-between'>
                        <div>
                            <div className='modalheading text-white'>Accumulated rewards:</div>
                            <div className='d-flex align-items-center'>
                                <div className='dblimg'>
                                    <img src="/assets/img/bouns/DBL6.png" alt="" className='h-100 w-100 object-cover' />
                                </div>
                                <div className='fat ms-3'>0 DBL</div>
                            </div>

                        </div>
                        <button className='commonbtn'>Previous Hunts</button>
                    </div>

                    <div className='d-flex justify-content-around btnholder'>
                        <div className='greenbtn active'>Daily Hunts</div>
                        <div className='greenbtn'>Weekly Hunts</div>
                    </div>
                    <div className='d-flex align-items-center my-3'>
                        <div className='expire'>Expires in</div>
                        <div className='time ms-4'>13 : 59 : 31</div>
                    </div>
                    <div className='d-flex align-items-center slots'>
                        <div className="circle flex-shrink">
                            <div className="inner-circle ">
                                1/1000
                            </div>

                        </div>
                        <div className='details d-flex flex-column align-item-start ms-4'>
                            <div className='d-flex align-items-center my-2'>
                                <div className="time">
                                    Big Win
                                </div>
                                <div className='ms-3 quection'>
                                    <img src="/assets/img/bouns/quection.png" alt="" className='h-100 w-100' />

                                </div>
                            </div>
                            <div className="expire">
                                Win a payout of 50x or above in any game with a bet of greater than $ 0.40.
                            </div>
                            <div className='d-flex align-items-start'>
                                <div className='dblimg'>
                                    <img src="/assets/img/bouns/DBL6.png" alt="" className='h-100 w-100' />
                                </div>
                                <div className='earn ms-2'>
                                    Earn 20 DBL
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center slots my-3'>
                        <div className="detimg">
                            <img src="/assets/img/bouns/goldbox.png" alt="" className='h-100 w-100' />
                        </div>
                        <div className='details d-flex flex-column align-item-start ms-4'>
                            <div className='d-flex align-items-center my-2'>
                                <div className="time">
                                    Just wager on
                                </div>
                                <div className='ms-3 quection'>


                                </div>
                            </div>
                            <div className="expire">
                                Daily wager reaches $ 100.
                            </div>
                            <div className='d-flex align-items-start'>
                                <div className='dblimg'>
                                    <img src="/assets/img/bouns/DBL6.png" alt="" className='h-100 w-100' />
                                </div>
                                <div className='earn ms-2'>
                                    Earn 20 DBL
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center slots mb-2'>
                        <div className="circle">
                            <div className="inner-circle">
                                1/4
                            </div>

                        </div>
                        <div className='details d-flex flex-column align-item-start ms-4'>
                            <div className='d-flex align-items-center my-2'>
                                <div className="time">
                                    Slots Master
                                </div>
                                <div className='ms-3 quection d-none'>
                                    <img src="/assets/img/bouns/quection.png" alt="" className='h-100 w-100' />

                                </div>
                            </div>
                            <div className='d-flex gap-md-5 gap-2'>
                                <div className="expire">
                                    A single-day profit reaches $ 4.00 in any third-party Slots game.
                                </div>
                                <button className='okbtn'>GO</button>
                            </div>
                            <div className='d-flex align-items-start'>
                                <div className='dblimg'>
                                    <img src="/assets/img/bouns/DBL6.png" alt="" className='h-100 w-100' />
                                </div>
                                <div className='earn ms-2'>
                                    Earn 20 DBL
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>
        </>
    )
}

export default Hunthub