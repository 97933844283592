import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useAuth } from "../../../../hooks/useAuth";
import { useAuthModal } from "../../../../hooks/useAuthModal";
import { useSelector } from "react-redux";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../../config/firebase";
import { errorToaster, succesToaster } from "../../../../utils/toaster";
import { USER_LANG } from "../../../../constants";
import { postAPI } from "../../../../service/apiInstance";
import { useSearchParams } from "react-router-dom";
import { getCustomAuthError } from "../../../../utils/customFirebaseError";

const OtpSignIn = ({
    select,
    number,
    setIsOTP,
    browserName
}) => {
    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const inputRefs = useRef([]);
    const { setToken } = useAuth();
    const { authModalObject, setAuthModalObject } = useAuthModal();
    const ipAddress = useSelector((state) => state.ipAddress);
    const country = useSelector((state) => state.country);
    const country_id = localStorage.getItem("country_Id");
    const [isLoading, setIsLoading] = useState(false)
    const [isMetamask, setIsMetamask] = useState(false);
    const [isTelegram, setIsTelegram] = useState(false);
    const [userObj, setUserObj] = useState({});
    const [otpError, setOtpError] = useState(false)
    const [timer, setTimer] = useState(60);
    const [resendLoading, setResendLoading] = useState(false);
    const [reset, setReset] = useState(false);
    const [otpLoader, setOtpLoader] = useState(false);
    const [query, setQuery] = useSearchParams()

    const generateRecaptcha = (random) => {
        const recaptchaParent = document.getElementById("recaptcha-parent");
        if (recaptchaParent) {
            recaptchaParent.innerHTML = `<span id=${random} />`
        }
        window.recaptchaVerifier = new RecaptchaVerifier(
            `${random}`,
            {
                size: "invisible",
                callback: (response) => { },
            },
            auth
        );
        window.recaptchaVerifier.render().then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
        });
    };

    const handleSend = (values) => {
        setResendLoading(true)
        const random = Math.random() + 1000000
        generateRecaptcha(random);
        let appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(
            auth,
            `${select?.phoneCode ? select.phoneCode : "+91"} ${number}`,
            appVerifier
        )
            .then((confirmationResult) => {
                succesToaster("OTP SENT!");
                window.confirmationResult = confirmationResult;
                // setLogOn(false);
                // setPhoneOtp(true);
                setTimer(60)
            })
            .catch((error) => {
                // errorToaster(error.message.split('(')[1].split(')')[0].split('/')[1]);
                // errorToaster(error.message);
                errorToaster(getCustomAuthError(error))
                const recaptchaContainer = document.getElementById(`${random}`)
                if (recaptchaContainer) { recaptchaContainer.remove(); }

                // errorToaster(getFriendlyErrorMessage(error.code))
                // errorToaster(error.message)

            })
            .finally(() => {
                setResendLoading(false);
                // setReset(true)
            });
    };

    useEffect(() => {
        if (timer > 0) {
            const countdown = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);
            return () => clearInterval(countdown);
        }
    }, [timer]);

    useEffect(() => {
        inputRefs.current[0].focus()
    }, [])

    const handleOtpChange = (e, index) => {
        const updatedOtp = [...otp];
        updatedOtp[index] = e.target.value;
        setOtp(updatedOtp);

        if (e.target.value !== "" && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === "Backspace" && index > 0 && event.target.value === "") {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleVerifyOtp = (event) => {
        if (timer === 0 || !timer) {
            errorToaster('OTP Expired')
            return
        }
        const otpValue = otp.join("");

        if (otpValue.length === 6) {
            setOtpError(false)
            setIsLoading(true)
            let confirmationResult = window.confirmationResult;
            confirmationResult
                ?.confirm(otpValue)
                .then((result) => {
                    // const lang = JSON.parse(localStorage.getItem(USER_LANG));
                    const lang = localStorage.getItem(USER_LANG)
                    const body = {
                        phone: number,
                        ipAddress: ipAddress ? ipAddress : "",
                        country: country ? country : "",
                        // language: lang?.symbol,
                        language: lang,
                        phoneCodeId: country_id,
                        browser: browserName

                    };
                    postAPI("user/login-user", body)
                        .then((res) => {
                            if (res?.data?.success) {
                                succesToaster(res.data.message);
                                setToken(res?.data.data?.auth_token);
                                // setLogOn(true);
                                // setPhoneOtp(false);
                                setOtpLoader(false);
                                setAuthModalObject((pre) => ({ ...pre, isAuthOpen: false }));
                                setQuery({ action: "" })
                localStorage.removeItem('clickid')
                            } else {
                                errorToaster(res.data.message);
                                setOtpLoader(false);
                            }
                        })
                        .catch((err) => {
                            // errorToaster("something went wrong");
                errorToaster(getCustomAuthError(err))
                            setOtpLoader(false);
                        });
                    setOtp(["", "", "", "", "", ""]);
                    setIsLoading(false);
                })
                .catch((error) => {
                errorToaster(getCustomAuthError(error))
                    // setLogOn(true);
                    // setPhoneOtp(false);
                    setOtp(["", "", "", "", "", ""]);
                    // errorToaster(error.message.split('(')[1].split(')')[0].split('/')[1]);
                    // errorToaster(error.message);
                    setIsLoading(false);
                })

        } else {
            setOtpError(true)
        }

    };
    return (
        <form autoComplete='off'>
            <div className="col-12 mt-2">
                <div className="inpWrapper position-relative">
                    <div className='d-flex justify-content-between w-100'>
                        {
                            otp.map((digit, index) => (
                                <input
                                autoComplete='off'
                                    className='otpField mx-1 px-1'
                                    type="mail"
                                    name=""
                                    id=""
                                    key={index}
                                    maxLength={1}
                                    value={digit}
                                    onChange={(e) => handleOtpChange(e, index)}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            const otpValue = otp.join("");
                                            if (otpValue.length !== 6) {
                                                errorToaster("OTP Field can't be empty")
                                                return
                                            }
                                            setIsLoading(true)
                                            handleVerifyOtp(event)
                                        }
                                        handleKeyDown(event, index)
                                    }}
                                    ref={(ref) => (inputRefs.current[index] = ref)}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="col-12 mt-3">
                <button
                    className='signUpBtnNew'
                    type="button"
                    onClick={(e) => {
                        handleVerifyOtp(e)
                    }}
                > {isLoading ? <Spinner size="sm" color="dark" /> : <span>Sign In</span>}
                </button>
            </div>
            {
                timer ? (
                    <div className="cursor-pointer text-light py-2">
                        Didn't received OTP, resend in {timer}s
                    </div>
                ) : (
                    <button
                        className="playBtn border-0 position-relative d-flex align-items-center justify-content-start cursor-pointer"
                        style={{ width: '150px' }}
                        onClick={handleSend}
                        type="button"
                    >
                        <span className="playBtnInner d-flex align-items-center justify-content-center px-4 w-100">
                            {
                                resendLoading ? <Spinner size="sm" color="dark" /> : "Resend OTP"
                            }
                        </span>
                    </button>
                )
            }
            <div id="recaptcha-parent" />
            {/* <div className="col-12 mt-3">
      Didn't 
    </div> */}
        </form>);
};

export default OtpSignIn;
