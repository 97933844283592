import React, { useState } from "react";


export const LogoutModalContext = React.createContext(null)

const LogoutModalContextProvider = ({ children }) => {
    const [isLogoutOpen, setIsLogoutOpen] = useState(false)
    return <LogoutModalContext.Provider value={{ isLogoutOpen, setIsLogoutOpen }}>{children}</LogoutModalContext.Provider>;
};

export default LogoutModalContextProvider;
