import React, { useEffect, useState } from 'react'
import SocialLogin from './SocialLogin'
import SignInNumber from './signInOptions/SignInNumber'
import SignInEmail from './signInOptions/SignInEmail'
import { useAuthModal } from '../../../hooks/useAuthModal'
import { AUTH_MODAL_TABS } from '../../../constants'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const SignIn = ({ browserName, isForgotPassword, setIsForgotPassword }) => {
  const [isMobileActive, setIsMobileActive] = useState(false)
  const { authModalObject, setAuthModalObject } = useAuthModal()
  const [query, setQuery] = useSearchParams()
  const { t } = useTranslation()
  const authLabels = t("AuthLabels", { returnObjects: true }) //string has to be same as language json key 


  useEffect(() => {
    if (query.get('action') !== "forgotPassword") {
      setIsForgotPassword(false)
    }
  }, [query.get('action'), authModalObject.selectedTab])

  return (
    <div className="signupForm h-100">
      <div className="row h-100 align-items-center flex-column">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col">
              {/* <div className="sign">{isForgotPassword ? 'Forgot Password' : 'Sign In'}</div> */}
              <div className="sign">{isForgotPassword ? authLabels.Forget_Password : authLabels.Sign_In}</div>
            </div>
            <div className="col-auto">
              <div
                className="close cursor-pointer"
                onClick={() => {
                  setAuthModalObject((pre) => ({
                    ...pre,
                    isAuthOpen: false
                  }))
                  setQuery({ action: "" })
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                  <path d="M9.23043 1.13304C9.48961 0.873846 9.48961 0.453598 9.23043 0.194402C8.97124 -0.0648007 8.55098 -0.0648007 8.29179 0.194402L4.71242 3.77379L1.13304 0.194402C0.873846 -0.0648007 0.453598 -0.0648007 0.194402 0.194402C-0.0648007 0.453598 -0.0648007 0.873846 0.194402 1.13304L3.77379 4.71242L0.194402 8.29179C-0.0648007 8.55098 -0.0648007 8.97124 0.194402 9.23043C0.453598 9.48961 0.873846 9.48961 1.13304 9.23043L4.71242 5.65105L8.29179 9.23043C8.55098 9.48961 8.97124 9.48961 9.23043 9.23043C9.48961 8.97124 9.48961 8.55098 9.23043 8.29179L5.65105 4.71242L9.23043 1.13304Z" fill="white" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        {
          !isForgotPassword ?

            <div className="col-12">
              <div className="row mx-0 my-3 gx-3">
                <div className="col-6 px-0">
                  <button className={`emailBtn ${isMobileActive ? '' : 'active'}`}
                    onClick={() => setIsMobileActive(false)}
                  // >Email</button>
                  >{authLabels.Email}</button>
                </div>
                <div className="col-6 px-0">
                  <button className={`emailBtn ${!isMobileActive ? '' : 'active'}`}
                    // onClick={() => setIsMobileActive(true)}>Phone Number</button>
                    onClick={() => setIsMobileActive(true)}>{authLabels.Phone_Number}</button>
                </div>
              </div>
            </div> : ''
        }
        {
          !isMobileActive ? (
            <SignInEmail
              isForgotPassword={isForgotPassword}
              setIsForgotPassword={setIsForgotPassword}
              browserName={browserName}
            />
          ) : (
            <SignInNumber
              browserName={browserName}
            />
          )
        }
        <div className="col-12">
          <div className="account">
            {authLabels.New_to_App}
            <button
              className='LogBtn'
              onClick={() => {
                setAuthModalObject(pre => ({ ...pre, selectedTab: AUTH_MODAL_TABS.SIGN_UP }))
                setQuery({ action: "register" })
              }}
            >
              {/* Create account */}
              {authLabels.Create_account}
            </button>
          </div>
        </div>
        <div className="col"></div>
        <SocialLogin parent={"signin"} />
      </div>
    </div>
  )
}

export default SignIn