import React, { useState } from 'react'
import NewWithdraw from '../../../components/newWithdraw/NewWithdraw'

const Withdraw = () => {
  const [activeTab , setActiveTab] = useState('crypto')
  return (
    <>
        <NewWithdraw
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
    </>
  )
}

export default Withdraw