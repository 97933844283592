import React from 'react'
import { useState } from 'react'
import { useKado } from '../../../hooks/useKado'
import SelectNetwork from './SelectNetwork'
import { useSelector } from 'react-redux'
import { swapAssetFromAction, swapAssetToAction, swapNetworkFromAction, swapNetworkToAction } from '../../../store/action'


const clickBtnOption = {
    FROM_BUTTON: "FROM_BUTTON",
    TO_BUTTON: "TO_BUTTON"
}

const SwapTab = ({ }) => {
    const [showFees, setShowFees] = useState(false)
    const { setKadoWalletObject } = useKado()
    // const [showCurrency, setShowCurrency] = useState(false)
    const [showNetwork, setShowNetwork] = useState(false)
    const [showNetworkTo, setShowNetworkTo] = useState(false)
    const [clickedBtn, setClickedBtn] = useState("")
    const networkFrom = useSelector(state => state?.kadoSwap.networkFrom)
    const assetFrom = useSelector(state => state?.kadoSwap.assetFrom)
    const networkTo = useSelector(state => state?.kadoSwap.networkTo)
    const assetTo = useSelector(state => state?.kadoSwap.assetTo)

    return (
        <>
            <div className="row payCard mt-4 mx-0">
                <div className="col ps-0">
                    <div className="usdTxt">Pay</div>
                    <div className="usdAmount">1</div>
                </div>
                <div className="col-auto pe-0">
                    <div className="usdCard" onClick={() => {
                        setClickedBtn(clickBtnOption.FROM_BUTTON)
                        setShowNetwork(true)
                    }}
                    >
                        <div className="row w-100 mx-0 pe-2">
                            <div className="col-auto position-relative ps-0 d-flex justify-content-center align-items-center">
                                <div className="flag d-flex justify-content-center align-items-center">
                                    <img src={assetFrom?.image} alt="" />
                                </div>
                                <div className="flagSub">
                                    <img src={assetFrom?.blockChainId?.image} alt="" />
                                </div>
                                {/* <div className='col-12'>Ethereum</div> */}
                            </div>
                            <div className="col ps-0">
                                <div className='ethTxt'>{assetFrom?.name}</div>
                                <div className='ethTxt1'>{assetFrom?.blockChainId?.network}</div>
                            </div>
                            <div className="col-auto d-flex align-items-center justify-content-center pe-0">
                                <div className="down d-flex align-items-center justify-content-center">
                                    <img src="assets/img/heroBanner/down.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="divider my-3"></div>
                <div className="col-12 d-flex justify-content-between px-0">
                    <div className="to">From</div>
                    <button className='connectBtn'>Connect</button>
                </div>
            </div>
            <div className="row mx-0 py-4 my-1">
                <div className="col-12 d-flex justify-content-center"><div className="divider position-relative"><div className="bg"><img src="assets/img/heroBanner/swap.png" alt="" /></div></div></div>
            </div>

            <div className="row payCard  mx-0">
                <div className="col ps-0">
                    <div className="usdTxt">Receive</div>
                    <div className="usdAmount">1</div>
                </div>
                <div className="col-auto pe-0">
                    <div className="usdCard" onClick={() => {
                        setShowNetworkTo(true)
                        setClickedBtn(clickBtnOption.TO_BUTTON)
                    }}
                    >
                        <div className="row w-100 mx-0 pe-2">
                            <div className="col-auto position-relative ps-0 d-flex justify-content-center align-items-center">
                                <div className="flag d-flex justify-content-center align-items-center">
                                    <img src={assetTo?.image} alt="" />
                                </div>
                                <div className="flagSub">
                                    <img src={assetTo?.blockChainId?.image} alt="" />
                                </div>
                                {/* <div className='col-12'>Ethereum</div> */}
                            </div>
                            <div className="col ps-0">
                                <div className='ethTxt'>{assetTo?.name}</div>
                                <div className='ethTxt1'>{assetTo?.blockChainId?.network}</div>
                            </div>
                            <div className="col-auto d-flex align-items-center justify-content-center pe-0">
                                <div className="down d-flex align-items-center justify-content-center">
                                    <img src="assets/img/heroBanner/down.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="divider my-3"></div>
                <div className="col-12 d-flex justify-content-between px-0">
                    <div className="to">To</div>
                    <button className='connectBtn'>Connect</button>
                </div>
            </div>
            <div className="row mx-0 mt-2 align-items-center">
                <div className="col-6 ps-0">
                    <div className="txt">1ETH = 2073.4324 USD</div>
                </div>
                <div className="col-6 d-flex justify-content-end pe-0">
                    <div className="row mx-0 align-items-center justify-content-end">
                        <div className="col-auto px-0">
                            <div className="icon d-flex align-items-center"><img src="assets/img/heroBanner/clock.png" alt="" /><span className='ps-1'>5m</span></div>
                        </div>
                        <div className="col-auto pe-0 d-flex align-items-center">
                            <div className="icon"><img src="assets/img/heroBanner/flash.png" alt="" /><span className='ps-1'>5.07 USD</span></div>
                            <div className={`icon2 ps-2 cursor-pointer ${showFees ? 'rotate' : ''}`} onClick={() => setShowFees(!showFees)}>
                                <img src="assets/img/heroBanner/down.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3 px-0 mb-4 d-flex justify-content-center">
                    <button
                        onClick={() => setKadoWalletObject((prev) => ({
                            ...prev,
                            isBuySellOpen: false,
                            isSelectWalletOpen: true
                        }))}
                        className='ConnectwalletBtn'>Connect Wallet</button>
                </div>
            </div>
            <div className="row justify-content-center align-items-center gx-2">
                <div className="col-auto" style={{ borderRight: '1px solid #B2B6C5' }}>
                    <div className="we">We accept</div>
                </div>
                <div className="col-auto lisks">
                    <img loading='lazy' src="assets/img/heroBanner/applepay.png" alt="" />
                </div>
                <div className="col-auto lisks">
                    <img loading='lazy' src="assets/img/heroBanner/Gpay.png" alt="" />
                </div>
                <div className="col-auto lisks">
                    <img loading='lazy' src="assets/img/heroBanner/Mastercard.png" alt="" />
                </div>
                <div className="col-auto lisks">
                    <img loading='lazy' src="assets/img/heroBanner/visa.png" alt="" />
                </div>
                <div className="col-auto lisks">
                    <img loading='lazy' src="assets/img/heroBanner/SamsungPay.png" alt="" />
                </div>
            </div>
            {/* fess  */}
            <div className={`position-absolute feesWrapper ${showFees ? 'active' : ''}`}>
                <div className="row">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <div className="feeHeading">Fees</div>
                        <div className="closeFee" onClick={() => setShowFees(!showFees)}>
                            <img src="assets/img/heroBanner/closee.png" alt="" />
                        </div>
                    </div>
                    <div className="col-12 d-flex mt-3 justify-content-between">
                        <div className="feeSubTxt">Processing Fee</div>
                        <div className="feeAmount">1.99 USD</div>
                    </div>
                    <div className="col-12 d-flex mt-3 justify-content-between">
                        <div className="feeSubTxt">Network Fee</div>
                        <div className="feeAmount">1.99 USD</div>
                    </div>
                </div>
            </div>
            {/* <SelectNetwork
                setShowNetwork={setShowNetwork}
                showNetwork={showNetwork}
                network={clickedBtn === clickBtnOption.FROM_BUTTON ? networkFrom : networkTo}
                networkTwo={clickedBtn === clickBtnOption.FROM_BUTTON ? networkFrom : networkTo}
                asset={clickedBtn === clickBtnOption.FROM_BUTTON ? assetFrom : assetTo}
                setNetwork={clickedBtn === clickBtnOption.FROM_BUTTON ? swapNetworkFromAction : swapNetworkToAction}
                setAsset={clickedBtn === clickBtnOption.FROM_BUTTON ? swapAssetFromAction : swapAssetToAction}
            /> */}
            <SelectNetwork
                setShowNetwork={setShowNetwork}
                showNetwork={showNetwork}
                network={networkFrom}
                networkTwo={networkFrom}
                asset={assetFrom}
                setNetwork={swapNetworkFromAction}
                setAsset={swapAssetFromAction}
            />
            <SelectNetwork
                setShowNetwork={setShowNetworkTo}
                showNetwork={showNetworkTo}
                network={networkTo}
                networkTwo={networkTo}
                asset={assetTo}
                setNetwork={swapNetworkToAction}
                setAsset={swapAssetToAction}
            />
        </>
    )
}

export default SwapTab