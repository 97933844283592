import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

const KadoIframeComp = ({
    iframeOpen,
    account,
    // metamask,
    setIframeOpen,
    amount,
    currency,
    asset,
    network
}) => {
    const userDetails = useSelector(state => state.userDetails)
    useEffect(() => {
        const handleMessage = (event) => {
            if (iframeOpen) {
                if (event?.data?.payload?.orderId) {
                }
                if (event.origin === `https://app.kado.money/?apiKey=${"b3325baf-da83-4d17-bb7a-5f8c29bec573"}&onPayAmount=${amount}&onPayCurrency=${currency?.code}&onRevCurrency=${asset?.symbol}&onToAddress=${account ? account : ""}&network=${network?.network}&product=BUY&mode=minimal&email=${userDetails?.email ? userDetails?.email : ""}`) {
                    // if (event.origin === `https://app.kado.money/?apiKey=${"b3325baf-da83-4d17-bb7a-5f8c29bec573"}&onPayAmount=${amount}&onPayCurrency=${currency?.code}&onRevCurrency=${asset?.symbol}&cryptoList=ETH,AVAX,USDC&onToAddress=${account ? account : ""}&network=${network?.network}&networkList=ETHEREUM,AVALANCHE&product=BUY&productList=BUY&mode=minimal&email=${userDetails?.email ? userDetails?.email : ""}`) {
                    // Process the message data
                } else {
                    console.error('Received message from untrusted origin:', event.origin);
                }
            }
        };
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);
    return (
        <>
            <div
                className={`modal fade  selectWalletWrapper ${iframeOpen ? 'show d-block' : ''} `}
                // className={`modal fade  selectWalletWrapper ${ kadoWalletObject?.isSelectWalletOpen ? 'show d-block' : '' } `} 
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-0" style={{ minHeight: '83vh' }}>
                        <div className="modal-body d-flex flex-column">
                            <div className="row navBottomBar mx-0 px-2 py-3">
                                <div className="col d-flex align-items-center">
                                    <div className="SelectWallet d-flex align-items-center">
                                        <img
                                            // onClick={() => setKadoWalletObject((prev) => ({
                                            //     ...prev,
                                            //     isSelectWalletOpen: false,
                                            //     isBuySellOpen: true
                                            // }))}
                                            onClick={() => setIframeOpen(false)}
                                            src="assets/img/heroBanner/selectArrow.png" alt="" />
                                        <span>
                                            Kado Money
                                        </span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className='d-flex col-auto justify-content-end align-items-center w-100'>
                                        <div className="close"
                                            // onClick={() => setKadoWalletObject((prev) => ({
                                            //     ...prev,
                                            //     isSelectWalletOpen: false,
                                            // }))}
                                            onClick={() => setIframeOpen(false)}

                                        ><img src="assets/img/heroBanner/closee.png" alt="" /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row flex-fill mx-0">
                                <div className="col-12 px-0">
                                    <iframe
                                        className='w-100 h-100'
                                        src={iframeOpen ? `https://app.kado.money/?apiKey=${"b3325baf-da83-4d17-bb7a-5f8c29bec573"}&onPayAmount=${amount}&onPayCurrency=${currency?.code}&onRevCurrency=${asset?.symbol}&onToAddress=${account ? account : ""}&network=${network?.network}&product=BUY&mode=minimal&email=${userDetails?.email ? userDetails?.email : ""}` : ""}

                                    // src={iframeOpen ? `https://app.kado.money/?apiKey=${"b3325baf-da83-4d17-bb7a-5f8c29bec573"}&onPayAmount=${amount}&onPayCurrency=${currency?.code}&onRevCurrency=${asset?.symbol}&cryptoList=ETH,AVAX,USDC&onToAddress=${account ? account :  ""}&network=${network?.network}&networkList=ETHEREUM,AVALANCHE&product=BUY&productList=BUY&mode=minimal&email=${userDetails?.email ? userDetails?.email : ""}` : ''}
                                    // src={"https://sandbox--kado.netlify.app"}
                                    >
                                    </iframe>
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </div >
        </>
    )
}

export default KadoIframeComp