import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Mousewheel, Keyboard } from 'swiper/modules';
import { getAPI, postAPIAuth } from '../../../service/apiInstance';
import { Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES_CONST } from '../../../constants/routeConstant';
import { useAuth } from '../../../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthModal } from '../../../hooks/useAuthModal';
import { AUTH_MODAL_TABS, BIG_DESKTOP_SCREEN_WIDTH, DESKTOP_SCREEN_WIDTH, MAC_SCREEN_WIDTH, MOBILE_SCREEN_WIDTH } from '../../../constants';
import { succesToaster } from '../../../utils/toaster';
import GamesCard from '../../../components/common/GamesCard/GamesCard';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { topRatedGamesAction } from '../../../store/action';
import SkeletonLoader from '../../../components/skeletonLoader/SkeletonLoader';

const CasionGames = () => {
    const [category, setCategory] = useState('top-rated')
    const [games, setGames] = useState('')
    const [gamesLoader, setGamesLoader] = useState(true)
    const [searchValue, setSearchValue] = useState('')
    const navigate = useNavigate()
    const { token } = useAuth()
    const userDetails = useSelector(state => state.userDetails)
    const { setAuthModalObject } = useAuthModal()
    const isMobile = useMediaQuery({ maxWidth: MOBILE_SCREEN_WIDTH })
    const isMac = useMediaQuery({ maxWidth: MAC_SCREEN_WIDTH })
    const isDesktop = useMediaQuery({ maxWidth: DESKTOP_SCREEN_WIDTH })
    const isBigDesktop = useMediaQuery({ maxWidth: BIG_DESKTOP_SCREEN_WIDTH })
    const { t } = useTranslation()
    const casinoLabels = t("casinoLabels", { returnObjects: true })
    const dispatch = useDispatch()  
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);


    console.log('isMac', isMac, isDesktop, isBigDesktop)

    const handleSlideChange = (swiper) => {
      setActiveSlideIndex(swiper.activeIndex);
    };


    const getGames = async () => {
        setGamesLoader(true)
        try {

            if (category) {
                const res = await getAPI(`games/${category}?is_mobile=${isMobile ? "true" : "false"}`)
                setGames(res?.data?.data)
                if(category === 'top-rated') {
                    console.log('fun chala')
                    dispatch(topRatedGamesAction(res?.data?.data))
                }
            }
        } catch (error) {
        } finally {
            setGamesLoader(false)
        }
    }

    useEffect(() => {
        getGames()
    }, [category])


    const searchQueryHandler = (event) => {
        event.preventDefault()
        if (event?.key === "Enter" && searchValue?.length > 0) {
            navigate(`${ROUTES_CONST.SLOT_PAGE}?${searchValue}`)
        }
    }

    const favouriteGame = async (id) => {
        if (token) {
            let params = {
                "gameId": id,
                "userId": userDetails?.id
            }
            const res = await postAPIAuth('crypto/create-favourite-games', params)
            if (res.data.status) succesToaster(res.data.message)
        } else {
            setAuthModalObject(pre => ({ isAuthOpen: true, selectedTab: AUTH_MODAL_TABS.LOG_IN }))
        }
    }

    const setBluredClass = (index) => {
        if(!isMac) {
            if(isDesktop) {
                const value = index === activeSlideIndex + 6 ? 'bluredSlide' : ''
                return value
            } else {
                console.log('aaya isMac')
                if(isBigDesktop) {
                    const value = index === activeSlideIndex + 7 ? 'bluredSlide' : ''
                    return value
                } else return ''
            }
        } return ''
    }
    return (
        <div className="container-fluid px-0 position-relative casinoGamesSlide">
            <div className="topratedgames-tab-main position-relative">
                <div className="topratedgames-tab-content">
                    <div className="row align-items-center justify-content-between">
                        <div className="col">
                            <div className="diceBox">
                                {/* <img src="assets/img/dice.png" alt="" /> */}
                                {casinoLabels.CASINO_GAMES}
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="d-flex align-items-center gap-2">
                            <div className='commonNavBtn newGamesPrevBtn d-flex'>
                                <img src="assets/img/prevImg.png" alt="" />
                            </div>
                            <div className='commonNavBtn newGamesNextBtn d-flex'>
                                <img src="assets/img/nextImg.png" alt="" />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-content">
                <div className="tab-pane fade show active">
                    <div className="container-fluid toprated-games-main">
                        <div className="toprated-games">
                            <div className="row">
                                <div className="col-12 games-card-main px-0">
                                    <div className="mySwiper cardSwiper casinoCard1">
                                        <Swiper className="swiper"
                                            onSlideChange={handleSlideChange}
                                            navigation={{
                                                nextEl: ".newGamesNextBtn",
                                                prevEl: ".newGamesPrevBtn",
                                            }}
                                            // breakpoints={{
                                            //     0: {
                                            //         slidesPerView: 3,
                                            //         spaceBetween: 8,
                                            //     },
                                            //     420: {
                                            //         slidesPerView: 3,
                                            //         spaceBetween: 8,
                                            //     },
                                            //     500: {
                                            //         slidesPerView: 4,
                                            //         spaceBetween: 8,
                                            //     },
                                            //     768: {
                                            //         slidesPerView: 5,
                                            //         spaceBetween: 8,
                                            //     },
                                            //     992: {
                                            //         slidesPerView: 6,
                                            //         spaceBetween: 8,
                                            //     },
                                            //     1100: {
                                            //         slidesPerView: 7,
                                            //         spaceBetween: 8,
                                            //     },
                                            //     1200: {
                                            //         slidesPerView: 7,
                                            //         spaceBetween: 10,
                                            //     },
                                            //     1280: {
                                            //         slidesPerView: 8,
                                            //         spaceBetween: 10,
                                            //     },
                                            //     1400: {
                                            //         slidesPerView: 8,
                                            //         spaceBetween: 10,
                                            //     },
                                            //     1600: {
                                            //         slidesPerView: 8,
                                            //         spaceBetween: 10,
                                            //     }
                                            // }}
                                            breakpoints={{
                                                0: {
                                                    slidesPerView: 3,
                                                    spaceBetween: 8,
                                                },
                                                420: {
                                                    slidesPerView: 3,
                                                    spaceBetween: 8,
                                                },
                                                500: {
                                                    slidesPerView: 4,
                                                    spaceBetween: 8,
                                                },
                                                768: {
                                                    slidesPerView: 5,
                                                    spaceBetween: 8,
                                                },
                                                992: {
                                                    slidesPerView: 'auto',
                                                    spaceBetween: 11,
                                                },
                                            }}
                                            modules={[Navigation]}
                                            spaceBetween={11}
                                            >
                                            {!gamesLoader ?
                                                games?.length > 0 ?
                                                    games?.map((item, index) => (
                                                        // <SwiperSlide key={item?._id} className={`swiper-slide gameCardSwiperSlide  ${!isMac ? ( isDesktop ? (index === activeSlideIndex + 6 ? 'bluredSlide' : '') : '') : !isDesktop ? (index === activeSlideIndex + 7 ? 'bluredSlide' : '') : !isBigDesktop ? '' : ''}`} onClick={(e) => {
                                                        //     e.stopPropagation()
                                                        // }}>
                                                        <SwiperSlide key={item?._id} className={`swiper-slide gameCardSwiperSlide ${setBluredClass(index)}`} onClick={(e) => {
                                                            e.stopPropagation()
                                                        }}>
                                                            <GamesCard
                                                                // RTP={false}
                                                                RTP={item?.rtp}
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    favouriteGame(item?._id)
                                                                }}
                                                                image={item?.image}
                                                                name={item?.name}
                                                                // isFav={false}
                                                                isFav={item?.isFav}
                                                                favButton={false}
                                                                uuid={item?.uuid}
                                                            />
                                                        </SwiperSlide>
                                                    ))
                                                    : <div className="w-100 h-100">
                                                        <h6 className="text-light"> No data available</h6>
                                                    </div>
                                                :
                                                <SkeletonLoader type={'gameCategory'}/>
                                            }
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CasionGames