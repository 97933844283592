import React from 'react'

const StackingTypes = ({setModalObject}) => {

  const handleClick =  (type)=> {
    setModalObject(prev=>({
      ...prev,
      show: true,
      type
    }))
  }
  return (
    <>
      <div className="my-4">
        <div className="CommonTitle">Staking types</div>
        <div className="row row-cols-md-2 row-cols-1 g-2 g-md-3">
          <div className="col">
            <div className="stackingTypeCard">
              <div className="row">
                <div className="col-12 cardTitle">
                  <img src="./assets/img/stacking/flexibleIcon.png" alt="" />
                  Flexible
                </div>
                <div className="col sText">
                  Flexible Staking is a hassle-free way of enjoying higher-than-average APRs without the need to lock your funds on the platform. Everything you need to do...
                </div>
                <div className="col-auto">
                  <button onClick={()=>handleClick('flexible')} className='commonLightBtn'>More details</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="stackingTypeCard">
              <div className="row">
                <div className="col-12 cardTitle">
                  <img src="./assets/img/stacking/fixedTermIcon.png" alt="" />
                  Fixed-Term
                </div>
                <div className="col sText">
                  Fixed-Term staking provides higher rewards in exchange for your assets being committed for fixed terms with predetermined redemption dates. We..
                </div>
                <div className="col-auto">
                  <button onClick={()=>handleClick('fixedTerm')} className='commonLightBtn'>More details</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="stackingTypeCard">
              <div className="row">
                <div className="col-12 cardTitle">
                  <img src="./assets/img/stacking/boostedIcon.png" alt="" />
                  Boosted Fixed-Term
                </div>
                <div className="col sText">
                  Our enhanced fixed-term pools offer an exciting opportunity to boost your earnings through the perfect blend of higher APRs, fixed-term commitment,...
                </div>
                <div className="col-auto">
                  <button onClick={()=>handleClick('boostedFixedTerm')} className='commonLightBtn'>More details</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="stackingTypeCard">
              <div className="row">
                <div className="col-12 cardTitle">
                  <img src="./assets/img/stacking/exclusive.png" alt="" />
                  Exclusive
                </div>
                <div className="col sText">
                Exclusive pools offer distinguished opportunities for our discerning users. These limited-time pools are designed to elevate your crypto investment...
                </div>
                <div className="col-auto">
                  <button onClick={()=>handleClick('exclusive')} className='commonLightBtn'>More details</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StackingTypes