import React from "react";
import { useTranslation } from "react-i18next";


const SortBySelect = ({ handleButtonClick1, setSortBy, sortBy }) => {
    // allGamesLabels.Popular, 'A-Z', 'Z-A', allGamesLabels.New
    const { t } = useTranslation()
    const elements = ['Popular', 'A-Z', 'Z-A', 'New']
    const allGamesLabels = t("allGamesLabels", { returnObjects: true })
    const resultModal = t("resultModal", { returnObjects: true })

    return (
        <div className="dropDown dropdown">
            <button
                onClick={handleButtonClick1}
                className=" dropBtn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {/* SORT BY : <span>{sortBy ? sortBy : allGamesLabels.Select}</span> */}
                {resultModal.SORT_BY} : <span>{sortBy ? sortBy : allGamesLabels.Select}</span>
            </button>
            <div className="dropMenu dropdown-menu dropMenuOne dropSort">
                <div className="row">
                    {/* <div className="col-12">
                        <div className="dropHeader position-relative">
                            <input className='dropSearch' type="search" name="" id="" />
                            <div className="serachImg">
                                <img src="assets/img/heroBanner/searchh.png" alt="" />
                            </div>
                        </div>
                    </div> */}
                    <div className="col-12 mt-2 bodyWrapper">

                        {
                            [allGamesLabels.Popular, 'A-Z', 'Z-A', allGamesLabels.New].map(item => (
                                <div className={`dropBody d-flex align-items-center ${sortBy === item ? "active" : ""}`}
                                    onClick={() => {
                                        setSortBy(item)
                                        // handleButtonClick1()
                                    }}
                                    key={`${Math.random()}--${Math.random()}`}
                                >
                                    <div className="form-check formCheck">
                                        {/* <input className="dropdownInp  d-none" type="radio" name="flexRadioDefault" /> */}
                                        <span className="dropdownLabel d-flex align-items-center" >
                                            {/* <div className='dropdownCheckbox'></div> */}
                                            <div className='dropdownTxt'>{item}</div>
                                            {/* <div className='dropdownValue ms-auto'>100</div> */}
                                        </span>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                    <div className="col-12">
                        <div className="dropFooter"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SortBySelect;
