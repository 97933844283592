import React, { useEffect, useRef, useState } from 'react'
import { useResultModal } from '../../hooks/useResult';
import SlotPage from '../../pages/SlotPage';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { LG_SCREEN_WIDTH, MD_SCREEN_WIDTH, MOBILE_SCREEN_WIDTH, SM_SCREEN_WIDTH, XL_SCREEN_WIDTH } from '../../constants';
import { useTranslation } from 'react-i18next';
import { postAPIAuth } from '../../service/apiInstance';
import SearchBar from '../../pages/SlotPage/components/SearchBar';
import { Spinner } from 'react-bootstrap';
import GamesCard from '../common/GamesCard/GamesCard';
import SearchInput from './components/SearchInput';
import ProviderSelect from './components/ProviderSelect';
import SortBySelect from './components/SortBySelect';
import { useAuth } from '../../hooks/useAuth';

const ResultModal = () => {
    const searchValue = useLocation()?.search?.replace(/\?/, '')
    const userDetails = useSelector(state => state?.userDetails)
    const [gameList, setGameList] = useState([])
    const [search, setSearch] = useState('')
    const [sortBy, setSortBy] = useState('')
    const [selectedProvider, setSelectedProvider] = useState([])
    const [paging, setPaging] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const [loader, setLoader] = useState(true)
    const [isLoadMore, setIsLoadMore] = useState(false)
    const debounceRef = useRef(null)
    const [noData, setNoData] = useState(false)
    const isMobile = useMediaQuery({ maxWidth: MOBILE_SCREEN_WIDTH })
    const isSmScreen = useMediaQuery({ maxWidth: SM_SCREEN_WIDTH })
    const isMdScreen = useMediaQuery({ maxWidth: MD_SCREEN_WIDTH })
    const isLgScreen = useMediaQuery({ maxWidth: LG_SCREEN_WIDTH })
    const isXlScreen = useMediaQuery({ maxWidth: XL_SCREEN_WIDTH })
    const [isClassAdded, setClassAdded] = useState(false);
    const [isClassAdded1, setClassAdded1] = useState(false);
    const { resultObject, setResultObject } = useResultModal()

    const { t } = useTranslation()
    const allGamesLabels = t("allGamesLabels", { returnObjects: true })
    const commonText = t('commonText', { returnObjects: true })
    const resultModal = t('resultModal', { returnObjects: true })
    const {token} = useAuth()
    // Function to get game list
    const getGameList = async () => {
        if(resultObject?.isResultOpen ) {

        
        setLoader(true)
        let params = {
            page: 1,
            userId: userDetails?.id,
            is_mobile: isMobile ? "true" : "false",
            limit: isMobile ? 9 : isSmScreen ? 12 : isMdScreen ? 10 : isLgScreen ? 16 : isXlScreen ? 16 : 18,
            // limit: isMobile ? 9 : isSmScreen ? 12 : isMdScreen ? 10 : isLgScreen ? 10 : isXlScreen ? 12 : 14,
        }

        // name: search, sortBy: sortBy, 
        if (search) params.name = search;
        if (sortBy) params.sortBy = sortBy;
        if (selectedProvider?.length) params.providerNamesArray = selectedProvider

        const result = await postAPIAuth(`crypto/get-games`, params, token)
        if (result?.data?.success) {
            setGameList(result.data.data)
        } else {
            setGameList([])
        }
        setLoader(false)
        setNoData(false)
    }
    }

    const loadMore = async (page) => {
        setIsLoadMore(true)
        let params = {
            page: page ? page : 1,
            userId: userDetails.id,
            is_mobile: isMobile ? "true" : "false",
            // limit: isMobile ? 9 : isSmScreen ? 12 : isMdScreen ? 10 : isLgScreen ? 10 : isXlScreen ? 12 : 14,
            limit: isMobile ? 9 : isSmScreen ? 12 : isMdScreen ? 10 : isLgScreen ? 16 : isXlScreen ? 16 : 18,

        }
        if (search) params.name = search.trim();
        if (sortBy) params.sortBy = sortBy;
        if (selectedProvider?.length) params.providerNamesArray = selectedProvider

        const result = await postAPIAuth(`crypto/get-games`, params, token)
        if (result?.data?.success) {
            if (page) setPaging(prev => prev + 1)
            if (!totalPage) setTotalPage(result.data.allData)
            if (page) setGameList(prev => ([...prev, ...result.data.data]))
            else setGameList(result.data.data)
            setNoData(false)

        } else {
            setNoData(true)
            // setGameList([])
        }
        setIsLoadMore(false)
    }

    useEffect(() => {
        //debouncing
        if (debounceRef?.current) {
            clearTimeout(debounceRef?.current)
        }
        debounceRef.current = setTimeout(() => {
            getGameList()
        }, 400)
        return () => clearTimeout(debounceRef?.current)
    }, [search, sortBy, selectedProvider, token, resultObject?.isResultOpen])


    // useEffect(() => {
    //     setSearch(searchValue)
    // }, [searchValue])





    const handleButtonClick = () => {
        setClassAdded(!isClassAdded);
        setClassAdded1(false);

    };
    const handleButtonClick1 = () => {
        setClassAdded1(!isClassAdded1);
        setClassAdded(false);
    };
    return (
        <>
            <div className={`modal fade resultModalWrapper ${resultObject?.isResultOpen ? 'show d-block' : ''}`} id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen resultModalDialog">
                    <div className="modal-content resultModalcontent">
                        <div className={`modal-header ${isClassAdded ? 'added-class' : ''} ${isClassAdded1 ? 'added-classs' : ''}`}>
                            <div className="container">
                            <div className="row align-items-center w-100 mx-0">
                                <div className="col-auto ps-0">
                                    {/* <div className="modal-title" id="exampleModalLabel">RESULTS:</div> */}
                                    <div className="modal-title" id="exampleModalLabel">{resultModal.RESULTS}:</div>
                                </div>
                                <div className="col d-flex justify-content-end">
                                    <div className="row w-100 justify-content-end">
                                        <SearchInput search={search} setSearch={setSearch} />

                                        <div className="col-auto d-md-block d-none">
                                            {
                                                resultObject?.isResultOpen ? 
                                                    <ProviderSelect
                                                        handleButtonClick={handleButtonClick}
                                                        selectedProvider={selectedProvider}
                                                        setSelectedProvider={setSelectedProvider}
                                                    />
                                                : ''
                                            }
                                        </div>
                                        <div className="col-auto d-md-block d-none">
                                            {/* sort by */}
                                            <SortBySelect
                                                handleButtonClick1={handleButtonClick1}
                                                setSortBy={setSortBy}
                                                sortBy={sortBy}
                                            />

                                        </div>
                                        <div className="col-auto d-flex justify-content-center align-items-center">
                                            <button className="closeModal btn-close p-0"
                                                onClick={() => setResultObject((prev) => ({
                                                    isResultOpen: false
                                                }))}
                                            >
                                                <img src="assets/img/heroBanner/closee.png" alt="" />
                                            </button>
                                        </div>
                                    </div>
                                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                </div>

                                <div className="col-12 px-0">
                                    <div className="row mx-0 mt-3 justify-content-end">
                                        <div className="col-6 d-block d-md-none ps-0">
                                            {
                                                resultObject?.isResultOpen ?
                                                    <ProviderSelect
                                                        handleButtonClick={handleButtonClick}
                                                        selectedProvider={selectedProvider}
                                                        setSelectedProvider={setSelectedProvider}
                                                    />
                                                : ''
                                            }
                                            {/* <div className="dropDown">
                                                <button onClick={handleButtonClick} className=" dropBtn" type="button">
                                                    Provider : <span>ALL</span>
                                                </button>
                                                <div className="dropMenu">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="dropHeader position-relative">
                                                                <input className='dropSearch' type="search" name="" id="" />
                                                                <div className="serachImg">
                                                                    <img src="assets/img/heroBanner/searchh.png" alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mt-2 bodyWrapper">
                                                            <div className="dropBody d-flex align-items-center">
                                                                <div className="form-check formCheck">
                                                                    <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1" />
                                                                    <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault1">
                                                                        <div className='dropdownCheckbox'></div>
                                                                        <div className='dropdownTxt'>Provider</div>
                                                                        <div className='dropdownValue ms-auto'>100</div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="dropBody mt-2 d-flex align-items-center">
                                                                <div className="form-check formCheck">
                                                                    <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault11" id="flexRadioDefault11" />
                                                                    <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault11">
                                                                        <div className='dropdownCheckbox'></div>
                                                                        <div className='dropdownTxt'>Provider</div>
                                                                        <div className='dropdownValue ms-auto'>100</div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="dropBody mt-2 d-flex align-items-center">
                                                                <div className="form-check formCheck">
                                                                    <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault12" id="flexRadioDefault12" />
                                                                    <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault12">
                                                                        <div className='dropdownCheckbox'></div>
                                                                        <div className='dropdownTxt'>Provider</div>
                                                                        <div className='dropdownValue ms-auto'>100</div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="dropBody mt-2 d-flex align-items-center">
                                                                <div className="form-check formCheck">
                                                                    <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault12" id="flexRadioDefault12" />
                                                                    <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault12">
                                                                        <div className='dropdownCheckbox'></div>
                                                                        <div className='dropdownTxt'>Provider</div>
                                                                        <div className='dropdownValue ms-auto'>100</div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="dropBody mt-2 d-flex align-items-center">
                                                                <div className="form-check formCheck">
                                                                    <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault12" id="flexRadioDefault12" />
                                                                    <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault12">
                                                                        <div className='dropdownCheckbox'></div>
                                                                        <div className='dropdownTxt'>Provider</div>
                                                                        <div className='dropdownValue ms-auto'>100</div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="dropBody mt-2 d-flex align-items-center">
                                                                <div className="form-check formCheck">
                                                                    <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault12" id="flexRadioDefault12" />
                                                                    <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault12">
                                                                        <div className='dropdownCheckbox'></div>
                                                                        <div className='dropdownTxt'>Provider</div>
                                                                        <div className='dropdownValue ms-auto'>100</div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="dropBody mt-2 d-flex align-items-center">
                                                                <div className="form-check formCheck">
                                                                    <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault12" id="flexRadioDefault12" />
                                                                    <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault12">
                                                                        <div className='dropdownCheckbox'></div>
                                                                        <div className='dropdownTxt'>Provider</div>
                                                                        <div className='dropdownValue ms-auto'>100</div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="dropFooter"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="col-6 d-block d-md-none pe-0">
                                            <SortBySelect
                                                handleButtonClick1={handleButtonClick1}
                                                setSortBy={setSortBy}
                                                sortBy={sortBy}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="modal-body toprated-games-main gameSearchPage">
                            <div className="container">
                                <div className="row toprated-games mx-0">
                                    {/* <div className="col-12 px-0 games-card-main">
                                    <div className="row mySwiper searchGameCardWrapper w-100 g-2 row-cols-8  casinoCard1 ">
                                        <div className="games-card h-100">
                                            <div className="games-img rounded-2 position-relative bigImg">
                                                <img src="https://stage.gis-static.com/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/29db6352d7edc6cd328a78b1dc320aab4e71b10a.png" alt="card" className="h-100 w-100 img-fluid rounded-2"/>
                                                <div className="overlay">
                                                    <div className="row mx-0">
                                                        <div className="col-12">
                                                            <div className="overlayTxt text-uppercase">Triple Royal Gold</div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="overlayTxt mt-1">EFFECTIVE RTP</div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="overlayTxt1 overlayTxt2">0%</div>
                                                        </div>
                                                        <div className="col-12">
                                                            <button className="addToFav "> Add to favorites</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                    {/* <div className="row toprated-games gap-2 mx-0 pt-4"> */}
                                        {
                                            loader && gameList.length === 0 ? (
                                                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                                    <Spinner animation="border" variant="light" size="lg" />
                                                </div>
                                            ) : gameList.length === 0 ? (
                                                <div className="text-light">{commonText.NoDataAvailable}</div>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        <div className="col-12 px-0 games-card-main">
                                            <div
                                                className="row mySwiper searchGameCardWrapper w-100 g-2 row-cols-8  casinoCard1 "
                                            >
                                                {gameList.length === 0 ? (
                                                    <>
                                                        {/* // <div>No data available</div> */}
                                                    </>
                                                ) : (
                                                    gameList?.map((item, index) => (
                                                        <GamesCard
                                                            RTP={item?.rtp}
                                                            image={item?.image}
                                                            name={item?.name}
                                                            isFav={item?.isFav}
                                                            id={item?._id}
                                                            uuid={item?.uuid}
                                                            favButton={true}
                                                            key={item?._id}
                                                        />
                                                    ))
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="d-flex align-items-center justify-content-center loadmoremain">
                                                {
                                                    !loader ? (
                                                        <span

                                                            onClick={() => {
                                                                if (noData) return
                                                                if (gameList.length === 0) {
                                                                    setSearch('')
                                                                    setSortBy('')
                                                                    setSelectedProvider([])
                                                                } else {
                                                                    loadMore(paging + 1)
                                                                }
                                                            }}
                                                            className="text-decoration-none loadBtn text-light rounded-2 cursor-pointer"
                                                        >
                                                            {
                                                                isLoadMore ? (
                                                                    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                                                        <Spinner animation="border" variant="light" size="sm" />
                                                                    </div>
                                                                ) : (
                                                                    noData ? (
                                                                        commonText.NoDataAvailable
                                                                    ) : (
                                                                        gameList.length === 0 ? (
                                                                            allGamesLabels.REMOVE_FILTERS
                                                                        ) : (
                                                                            allGamesLabels.LOAD_MORE
                                                                        )
                                                                    )
                                                                )
                                                            }
                                                        </span>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    {/* </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResultModal