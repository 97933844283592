import React from 'react'
import { getAPIAuth } from '../../../service/apiInstance';
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import GamesCard from "../../../components/common/GamesCard/GamesCard";
import { MOBILE_SCREEN_WIDTH } from "../../../constants";

const FreeSpin = () => {
  const [gameList, setGameList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userDetails = useSelector(state => state.userDetails)
  const isMobile = useMediaQuery({ maxWidth: MOBILE_SCREEN_WIDTH })
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState(1)
  const perPage = 14;
  const recentGames = t("recentGames", { returnObjects: true })
  const commonText = t('commonText', { returnObjects: true })

  const recentGame = async () => {
    if (userDetails?.id) {
      setIsLoading(true);
      try {
        const res = await getAPIAuth(`games/casino/getFreeSpinGames?page=${currentPage}&perPage=${perPage}&is_mobile=${isMobile ? "true" : "false"}`);
        if (res?.data?.success) {
          setGameList(res.data?.data ? res.data?.data : []);
        }
      } catch (error) {
        //   errorToaster(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    recentGame();
  }, [userDetails?.id]);
  return (
    <>
    <div className="toprated-games-main resultpage p-0">
    <div className="row toprated-games gap-2 mx-0 pt-4">
              <div className="col-12 px-0 games-card-main">
                {isLoading ? (
                  <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="light" size="sm" />
                  </div>
                ) : (
                  gameList?.length === 0 ? (
                    <div className="text-light w-100">
                      {commonText.NoDataAvailable}
                    </div>
                  ) : (
                    <div className="row mySwiper searchGameCardWrapper w-100 g-2 row-cols-8  casinoCard1 ">
                      {
                        gameList?.map((item, index) => (
                          <>
                           
                           <GamesCard
                              RTP={item?.rtp}
                              image={item?.image}
                              name={item?.name}
                              isFav={item?.is_Fav}
                              id={item?._id}
                              key={item?._id}
                              favButton={true}
                              uuid={item?.uuid}
                              freeSpinLoader={true}
                            />
                          </>
                        ))
                      }
                    </div>
                  ))}
              </div>
              <div className="col-12">
              </div>
            </div>
    </div>
    </>
  )
}

export default FreeSpin