import React, { useEffect } from 'react'
import memestop from '../../assets/img/Memestop.png'
import video from '../../assets/img/videoImage.png'
import videoIcon from '../../assets/img/Polygon.png'
import copy from '../../assets/img/copy.png'
import download from '../../assets/img/downloadBtn.png'
import { Fancybox } from '@fancyapps/ui'
// import { Fancybox } from "@fancyapps/ui";
// import "@fancyapps/ui/dist/fancybox.css";




const UnderConstructionAffilate = () => {
  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
      // Custom options
    });

    // Cleanup on unmount
    return () => {
      Fancybox.destroy();
    };
  }, []);

  
  return (
    <div className='main vh-100'>
      <div className='mainContaint'>
        <div className='homepage '>
          <div className='container-fluid underAffilateSection'>
            <div className='container'>
              <div className='row gap-3'>
                <div className='col-12'>
                  <div className='d-flex align-items-center gap-3 headingMain'>
                    <span className='iconStar'><img src={memestop} alt="memestop" /></span> REFER TO EARN Program
                  </div>
                </div>
                <div className='col-12'>
                  <ul class="nav nav-tabs border-0 " id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link NavBtn active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Home</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link NavBtn" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Retention Program</button>
                    </li>
                  </ul>
                </div>
                <div className="col-12">
                  <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                      <div className='videoTabs'>
                        <div className='row gap-lg-0 gap-4'>
                          <div className='col-lg-5'>

                            <div className='h-100' id="gallery-wrapper">
                              <a data-fancybox="gallery" href={'https://www.youtube.com/watch?v=l2ONv77alCE'}className='position-relative h-100'>
                                <img src={video} alt="video" className='w-100 h-100' />
                                <button className='videoBtn'><img src={videoIcon} alt="videoIcon" /></button>
                              </a>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className='row'>
                              <div className='col-12'>
                                <div className='peravideo'>Refer our brand and earn money through participating in Stake.com’s REFER TO EARN Program! You will receive a commission for users who register and play on Stake.com through your links.</div>
                                <div className='peravideo mt-2'>Being a Stake.com REFER TO EARN means you will earn commission for all bets placed on both our Casino and Sportsbook - which unlike traditional REFER TO EARN programs, means that despite if they win or lose, you still make the same commission!</div>
                                <div className='perasmall mt-3'>*Please note that referrals from accounts with the same IP address will not be recognised.</div>
                              </div>
                              <div className='col-12 mt-1'>
                                <label className='labelInput'>Referral Link</label>
                                <div className='row align-items-center'>
                                  <div className='col-sm'>
                                    <div>
                                      <div class="input-group InputGroupVideo ">
                                        <input type="text" class="form-control input" aria-label="Username" aria-describedby="basic-addon1" />
                                        <span class="input-group-text copyIcon" id="basic-addon1"><img src={copy} alt="copy" /></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-sm-auto'>
                                    <div>
                                      <button className='downloadBtn mt-2'>Download Banners <span className='downIcon'> <img src={download} alt="download" /></span></button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">...</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnderConstructionAffilate
