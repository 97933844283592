import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import './styles.css';
import { Autoplay, FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTES_CONST } from '../../../constants/routeConstant';
import { getAPI } from '../../../service/apiInstance';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_SCREEN_WIDTH } from '../../../constants';

const CasinoDetail = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const topRatedGames = useSelector(state => state?.topRatedGames)
  const navigate = useNavigate()
  const [gamesLoader, setGamesLoader] = useState(true)
  const isMobile = useMediaQuery({ maxWidth: MOBILE_SCREEN_WIDTH })
  const [games, setGames] = useState([])



  const getGames = async () => {
    setGamesLoader(true)
    try {

            const res = await getAPI(`games/recentBigGames?is_mobile=${isMobile ? "true" : "false"}`)
            setGames(res?.data?.data)
    } catch (error) {
    } finally {
        setGamesLoader(false)
    }
}

useEffect(() => {
    getGames()
}, [])


  return (
    <>
      <div className="container-fluid px0 pt-4 pt-lg-5 casinoDetails">
        <div className="px-0">
          <div className="row align-items-strentch gy-3">
            <div className="col-12 col-lg-8 col-xl-9 px-0 pe-lg-2 ps-lg-0 h-auto detailsPrimarySwiper">
              <Swiper
                style={{
                  '--swiper-navigation-color': '#fff',
                  '--swiper-pagination-color': '#fff',
                }}
                spaceBetween={10}
                // navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                // loop={true}
                modules={[Autoplay,FreeMode, Navigation, Thumbs]}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                className="mySwiper2 h-100"
              >
                {
                  games?.length ? 
                    games?.slice(0, 5)?.map(item=>(
                      <SwiperSlide className='h-100'>
                        <div className="posterSection position-relative h-100">
                          <img className='h-100 w-100 object-fit-cover' src={item?.destopImage ? item?.destopImage : item?.image} alt="" />
                          <div className="text-container">
                            <div className="head">
                              {item?.name}
                            </div>
                            <div className="subHead">
                              {item?.description}
                              {/* Trigger the {item?.name} feature for a chance to enjoy a round of free spins where every spin brings you closer to a big win. */}
                            </div>
                    
                    <button className='playBtn' onClick={()=>navigate(`${ROUTES_CONST.SLOT_PAGE}/${item?.uuid}`) }>
                              Play Game
                            </button>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))
                  : ''
                }
              </Swiper>
            </div>
            <div className="col-12 col-lg-4 col-xl-3 px-0 ps-lg-2 pe-lg-0 h-auto thumbsDetils detailsWrapper">
              <Swiper
                onSwiper={setThumbsSwiper}
                // spaceBetween={10}
                slidesPerView={1}
                // loop={true}
                breakpoints={{
                  0: {
                    slidesPerView: 2,
                    // spaceBetween: 10,
                  },
                  576: {
                    slidesPerView: 2,
                    // spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 2.5,
                    // spaceBetween: 20,
                  },
                  868: {
                    slidesPerView: 3,
                    // spaceBetween: 20,
                  },
                  992: {
                    slidesPerView: 1,
                    // spaceBetween: 50,
                  },
                }}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper d-flex flex-column"
              >
                {
                  games?.length ? 
                    games?.slice(0, 5)?.map(item=>(
                      <SwiperSlide>
                        <div className="detailsCard">
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <div className="detailsImg">
                                <img className='h-100 w-100 object-fit-cover' src={item?.image} alt="" />
                              </div>
                            </div>
                            <div className="col">
                              <div className="details-text">
                                {item?.name}
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))
                  : ''
                }
              </Swiper>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default CasinoDetail