import React from 'react'
import { useState } from 'react'
import { postAPIAuth } from '../../../service/apiInstance'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES_CONST } from '../../../constants/routeConstant'

const SearchInput = () => {
  const [searchValue, setSearchValue] = useState('')
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const debounceRef = useRef(null)
  const navigate = useNavigate()

  const getGames = async ()=> {
    setLoading(true)
    try {
      if(searchValue?.length > 2) {
        const params = {
          name : searchValue
        }
        const result = await postAPIAuth(`crypto/get-games`, params)
        setData(result?.data?.data)
      } else {
        setData([])
      }
    } catch (error) {
      
    } finally {
      setLoading(false)
    }
  }

  useEffect(()=> {
    if (debounceRef?.current) {
      clearTimeout(debounceRef?.current)
    }
    debounceRef.current = setTimeout(() => {
      getGames()
    }, 300)
    return () => clearTimeout(debounceRef?.current)
  }, [searchValue])
  return (
    <>
      <div className="gameSearchInputBox position-relative w-100">
          <input type="text" 
            placeholder='Search your game' 
            value={searchValue}
            onChange={(e)=>setSearchValue(e.target.value)}
            className='gameSeachInput rounded-pill shadow-none form-control w-100'/>
          <div className="searchIcon">
              <img className='h-100 d-flex w-100 object-fit-contain' src="assets/img/options/searchIcon.png" alt="" />
          </div>
          {
            searchValue?.length > 0 ?
            <div className="searchResultBox">
              {
                searchValue?.length > 2 ? 
                !loading ? 
                data?.length > 0 ? 
                data?.map((item)=> (
                  <div className="searchResultCard d-flex align-items-center"
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate(`${ROUTES_CONST.SLOT_PAGE}/${item?.uuid}`)
                  }}>
                      <div className="searchResultImg">
                          <img src={item?.image} className='h-100 w-100 object-fit-cover' alt="" />
                      </div>
                      <div>
                          <div className="searchGameName">{item?.name}</div>
                          <div className="searchProviderName">{item?.provider}</div>
                          <div className="searchGameCat d-flex align-items-center gap-2 mt-1">
                              <div className="searchCatPill">{item?.type}</div>
                          </div>
                      </div>
                  </div>
                ))
                : <div className='text-white text-center'>No data</div>
                : <div className='text-white text-center'>Loading...</div>
                : <div className='text-white text-center'>Search requires at least 3 characters</div> 
              }
            </div>
            : ''
          }
      </div>
    </>
  )
}

export default SearchInput