import React, { useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { getAPIAuth, postAPIAuth } from '../../../service/apiInstance';
import { useAuth } from '../../../hooks/useAuth';
import { selectFiat, setRubleAmountAction } from '../../../store/action';
import { Spinner } from 'react-bootstrap'
import { useWalletModal } from '../../../hooks/useWallet';
import { APP_NAME, WALLET_MODAL_TABS } from '../../../constants';
import { errorToaster } from '../../../utils/toaster';

const Fiat = () => {
  const { token } = useAuth();
  const [fiatList, setFiatList] = useState([]);
  const [search, setSearch] = useState('');
  const userDetails = useSelector(state => state.userDetails);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [paymentGateways, setPaymentGateways] = useState([]);
  const [amount, setAmount] = useState(10);
  const fiat = useSelector(state => state?.selectedCurrency?.fiat)
  const dispatch = useDispatch()
  const [showInput, setShowInput] = useState(false)
  const [selectedPayment, setSelectedPayment] = useState({})
  const [paymentLoader, setPaymentLoader] = useState(true)
  const { walletModalObject, setWalletModalObject } = useWalletModal()
  const [buttonLoader, setButtonLoader] = useState(false)


  console.log("selectedPayment", { selectedPayment })
  // name
  useEffect(() => {
    if (fiat?._id) {
      setSelectedCurrency(fiat)
    }
  }, [fiat?._id])

  const getFiatCurrency = async () => {
    try {
      const res = await getAPIAuth(`currency/get-currency?userId=${userDetails?.id}&currency=${search}`, token);
      setFiatList(res.data.data);
      if (res.data?.data?.[0] && !fiat?._id) {
        setSelectedCurrency(res.data?.data?.[0])
        dispatch(selectFiat(res.data?.data?.[0]))

      }
    } catch (error) {
    }
  }
  useEffect(() => {
    // if (!Object.entries(selectedCurrency).length) {
    //   setSelectedCurrency(userDetails?.currency);
    // }
    getFiatCurrency();
  }, [search]);

  useEffect(() => {
    if (selectedCurrency?._id) {
      setPaymentLoader(true)
      getPaymentGateway();
    }
  }, [selectedCurrency?._id]);

  const getPaymentGateway = async () => {
    try {
      const res = await getAPIAuth(`get-payment-method?currency_id=${selectedCurrency._id}`, token);
      setPaymentGateways(res.data.data);
    } catch (error) {
    } finally {
      setPaymentLoader(false)
    }
  }
  const handleInputValues = (value) => {
    setAmount(value);
  }


  const handlePayToPlay = async () => {
    if (!amount.toString().length) {
      console.log(`${amount}`?.length)
      errorToaster("Please enter valid amount")
      setButtonLoader(false)
      return
    }

    const body = {
      fiat: 'rub',
      fiat_amount: amount,
      redirect_url: "url will come here"
    }
    try {
      const res = await postAPIAuth('/depositByPlay2pay', body)
      if (res?.data?.status) {
        window.location.href = res?.data?.data?.payment_url
      } else {
        errorToaster(res.data?.message)
      }
    } catch (error) {
      errorToaster("Something went wrong")
      console.log('ruble error', error)
    } finally {
      setButtonLoader(false)
    }

  }

  // const handleKeyPress = (event) => {
  //   const charCode = event.which || event.keyCode;
  //   if ((charCode >= 48 && charCode <= 57) || charCode === 8) {
  //     if ((amount?.length + 1) <= 15 || charCode === 8) {
  //       return true;
  //     }
  //   }
  //   event.preventDefault();
  //   return false;
  // };
  console.log("selectedCurrency", { selectedCurrency })
  return (
    <>
      <div className="row fiatModal align-items-center">
        <div className="col">
          <div className="depCurr">Deposit currency</div>
        </div>
        <div className="col-auto">
          <Dropdown className='fialDropdown' align={'end'}>
            <Dropdown.Toggle id="dropdown-basic">
              {selectedCurrency?.code}
              <img src={selectedCurrency?.icon} alt="" />
            </Dropdown.Toggle>

            <Dropdown.Menu className='currDropMenu'>
              <div className="header">
                <div className="searchInp">
                  <img src="./assets/img/searchGray.png" alt="" />
                  <input type="text" className="form-control shadow-none" placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)} />
                </div>
              </div>
              <div className="body">
                {fiatList?.map((item) => (
                  <Dropdown.Item className='menuItem' as="button" onClick={() => {
                    setSelectedCurrency(item)
                    dispatch(selectFiat(item))
                    setShowInput(false)
                    setSelectedPayment({})

                  }}>
                    <div className="country">
                      <div className="img">
                        <img src={item.icon} alt="" />
                      </div>
                      {item.code}
                    </div>
                    {/* <div className="cName">United States Dollar</div> */}
                    <div className="prizeeee">{item?.wallet?.totalBalance ? Number(item?.wallet?.totalBalance).toFixed(2) : '0.00'}
                    </div>
                  </Dropdown.Item>
                ))}
              </div>
              <div className="footer">
                <img src="./assets/img/weAccept.png" alt="" />
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {
          showInput ? (<>
            <div className="col-12">
              <div className="borderDiv"></div>
            </div>
            <div className="col">
              <div className="depCurr">Deposit currency</div>
            </div>
            <div className="col-auto" onClick={() => {
              setShowInput(false)
              setSelectedPayment({})
            }}>
              <Dropdown className='fialDropdown' align={'end'}>
                <Dropdown.Toggle id="dropdown-basic">
                  {selectedPayment?.name}
                  <img src={selectedPayment?.image} alt="" />
                </Dropdown.Toggle>

                <Dropdown.Menu className='currDropMenu'>
                  <div className="header">
                    <div className="searchInp">
                      <img src="./assets/img/searchGray.png" alt="" />
                      <input type="text" className="form-control shadow-none" placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)} />
                    </div>
                  </div>
                  <div className="body">
                    {paymentGateways?.map((item) => (
                      <Dropdown.Item className='menuItem' as="button"
                        onClick={() => {
                          // setSelectedCurrency(item)
                          // dispatch(selectFiat(item))
                          setSelectedPayment(item)

                        }}
                      >
                        <div className="country">
                          <div className="img">
                            <img src={item.icon} alt="" />
                          </div>
                          {item.name}
                        </div>
                        {/* <div className="cName">United States Dollar</div> */}
                        <div className="prizeeee">{item?.wallet?.totalBalance ? Number(item?.wallet?.totalBalance).toFixed(2) : '0.00'}
                        </div>
                      </Dropdown.Item>
                    ))}
                  </div>
                  <div className="footer">
                    <img src="./assets/img/weAccept.png" alt="" />
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>) : (<></>)
        }

        <div className="col-12">
          <div className="borderDiv"></div>
          {
            showInput ? (<>
              <div className="amountTxt">Amount</div>
              <div className="position-relative amtInpBox">
                <input
                  // onKeyDown={handleKeyPress}
                  type="text" value={amount} className='form-control shadow-none'
                  onChange={(e) => {
                    console.log("rnun");
                    if (isNaN(Number(e.target.value))) {
                      return
                    }
                    setAmount(e.target.value)
                  }} />
                <div className="extraAmount">Extra +{selectedCurrency?.symbol}200</div>
              </div>
              <div className="bonusTxt">Get extra <span>200%</span> bonus on minimum of <span>10 {selectedCurrency?.symbol}</span> deposit</div>
              <div className="row g-2 mb-4">
                <div className="col-4">
                  <input type="radio" className='d-none euroInp'
                    checked={amount == 10}
                    name='euroAmnt' id='amt1' onClick={() => handleInputValues('10')} />
                  <label htmlFor="amt1" className='euroLabel'>
                    {selectedCurrency?.symbol} 10
                    <span className="bonusAmt">+200%</span>
                    <img src="./assets/img/amtCheck.png" className='amtCheck' alt="" />
                  </label>
                </div>

                <div className="col-4">
                  <input type="radio"
                    checked={amount == 50}
                    className='d-none euroInp' name='euroAmnt' id='amt2' onClick={() => handleInputValues('50')} />
                  <label htmlFor="amt2" className='euroLabel'>
                    {selectedCurrency?.symbol} 50
                    <span className="bonusAmt">+200%</span>
                    <img src="./assets/img/amtCheck.png" className='amtCheck' alt="" />
                  </label>
                </div>

                <div className="col-4">
                  <input type="radio"
                    checked={amount == 500}
                    className='d-none euroInp' name='euroAmnt' id='amt3' onClick={() => handleInputValues('500')} />
                  <label htmlFor="amt3" className='euroLabel'>
                    {selectedCurrency?.symbol} 500
                    <span className="bonusAmt">+200%</span>
                    <img src="./assets/img/amtCheck.png" className='amtCheck' alt="" />
                  </label>
                </div>
              </div>
              <button
                className="submitBtn"
                onClick={() => {
                  setButtonLoader(true)
                  if (selectedCurrency?.code === "RUB") {
                    console.log({ amount })
                    handlePayToPlay()
                  }
                }}
              >
                {buttonLoader ? <Spinner /> : "Deposit"}
              </button>

            </>) : (<>
              <div className="depCurr mb-2">Choose a payment method</div>
              <div className="paymentBox">
                {/* <div className="recommendedTxt">Recommended</div> */}
                <div className="recommendedTxt">Bank Transfer</div>
                {
                  paymentLoader ? (
                    <>
                      <div className='w-100 h-100 '>
                        <Spinner />
                      </div>
                    </>
                  ) : (
                    paymentGateways.length != 0 ? (
                      paymentGateways?.map((payment, index) => (
                        <div key={index} className="paymentDiv"
                          onClick={() => {
                            setShowInput(true)
                            setSelectedPayment(payment)
                          }}
                        >
                          <span className="fastest">Fastest</span>
                          <img className='infoImg' src={payment.image} alt="" />
                          <div className="payImg">
                            <img src={payment?.image} alt="" />
                          </div>
                          <div className="cardDiv">
                            <div className="cardType">{payment.name}</div>
                            <div className="eat">EAT: {payment.estimated_time} min</div>
                          </div>
                          <div className="amtDiv">{payment.range} {payment.currency_id.code}</div>
                          <div className="arrowDiv">
                            <img src="./assets/img/carouselRightArrow.png" alt="" />
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className='w-100 h-100  recommendedTxt'>
                        No payment method available
                      </div>
                    )
                  )
                }
              </div>
            </>)
          }


          <div className="securityMsg">
            <div className="imgDiv">
              <img src="./assets/img/shield.png" alt="" />
            </div>
            <div className="msg">You will be redirected to a third-party site verified by {APP_NAME}
              for a secure and trustworthy browsing experience</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Fiat