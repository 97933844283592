import React, { createContext, useState } from "react"
import { PROFILE_OPTIONS } from "../constants"

export const ProfileContext = createContext(null)

const ProfileContextProvider = ({children})=> {
  const [profileObject, setProfileObject] = useState({
    isProfileModalOpen : false,
    activeTab : PROFILE_OPTIONS?.VIEW_PROFILE
  })
  return (
    <ProfileContext.Provider value={{profileObject, setProfileObject}}>{children}</ProfileContext.Provider>
  )
}

export default ProfileContextProvider