import React, { useEffect, useState } from "react";
import { getAPI, postAPIAuth } from "../../service/apiInstance";
import { Spinner } from "react-bootstrap";
import { DEFAULT_IMAGE, TABS_OPTIONS, USER_LANG } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import i18next from "i18next"
import { LANGUAGE } from "../../constants/languageConstant";
import { errorToaster, succesToaster } from "../../utils/toaster";
import { useLocation } from "react-router-dom";
import { ROUTES_CONST } from "../../constants/routeConstant";
import { useTranslation } from "react-i18next";
import starimg from "../../assets/img/star.png";

const UnAuthCurrencyModal = ({ isOpen, setIsOpen, selectedTab, setSelectedTab }) => {
    const [selectedCurrency, setSelectedCurrency] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [fiat, setFiat] = useState([])
    const userDetails = useSelector(state => state.userDetails)
    const dispatch = useDispatch()
    const { token } = useAuth()
    const [language, setLanguage] = useState([])
    const [isLangLoading, setIsLangLoading] = useState(false)
    const location = useLocation()
    const { t } = useTranslation()
    const commonText = t("commonText", { returnObjects: true })
    const textLabels = t("UnAuthCurrencyModal", { returnObjects: true })

    useEffect(() => {
        if (isOpen) {
            setLanguage(localStorage.getItem(USER_LANG))
        }
        getAllFiat();
    }, [isOpen])

    const getAllFiat = async () => {
        try {
            const res = await getAPI("currency/get-currency");
            if (res?.data.success) {
                setFiat(res?.data.data);
            }
        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    }
    // will be of used
    // const currencyHandler = async (item) => {

    //     if (!token) {
    //         setIsOpen(false)
    //         setAuthModalObject(pre => ({ ...pre, isAuthOpen: true }))
    //         return
    //     }
    //     if (!userDetails?.id) return
    //     if (userDetails?.usercurrency) {
    //         const body = {
    //             userId: userDetails.id,
    //             currency: item.code,
    //             image: item.icon,
    //         }

    //         try {
    //             const res = await postAPIAuth("user/save-user-currency", body)
    //             if (res?.data?.success) {
    //                 const response = await fetchUserDetails(token)
    //                 dispatch({ type: reducerConst.USER_DETAILS, payload: response?.data?.data ? response.data.data : {} })
    //             }
    //         } catch (error) {

    //         }
    //     }
    //     localStorage.setItem(SELECTED_CURRENCY, JSON.stringify(item))
    //     setSelectedCurrency(item)
    //     //------------------------------------////------------------------------------------------//

    //     if (token) {
    //         // when user is logged in 
    //         if (!userDetails?.id) return
    //         if (userDetails?.usercurrency) {
    //             const body = {
    //                 userId: userDetails.id,
    //                 currency: item.code,
    //                 image: item.icon,
    //             }

    //             try {
    //                 const res = await postAPIAuth("user/save-user-currency", body)
    //                 if (res?.data?.success) {
    //                     const response = await fetchUserDetails(token)
    //                     dispatch({ type: reducerConst.USER_DETAILS, payload: response?.data?.data ? response.data.data : {} })
    //                     localStorage.setItem(SELECTED_CURRENCY, JSON.stringify(item))
    //                 }
    //             } catch (error) {

    //             }
    //         }
    //     } else {
    //         // when user is not logged in
    //         localStorage.setItem(SELECTED_CURRENCY, JSON.stringify(item))
    //         setSelectedCurrency(item)
    //         setIsOpen(false)
    //         setAuthModalObject(pre => ({ ...pre, isAuthOpen: true }))
    //     }
    // }

    // useEffect(() => {
    //     if (isOpen) {
    //         // getAllFiat()
    //     }
    // }, [isOpen])
    //---------------------------------this is language api , can be of used in future -----------//
    // const getLanguage = async () => {
    //     try {
    //         const res = await getAPI('user/language')
    //         if (res?.data?.status) {
    //             setLanguage(res.data.data)
    //         }
    //     } catch (error) {
    //     } finally {
    //         setIsLangLoading(false)
    //     }
    // }

    const languageHandler = async (item) => {

        if (token) {
            try {
                const body = {
                    language: item?.symbol
                }
                const res = await postAPIAuth(`user/change-user-lang?userId=${userDetails?.id}`, body, token)
                if (res.data.success) {
                    i18next.changeLanguage(item.symbol)
                    // caching language user has chosen
                    localStorage.setItem(USER_LANG, item?.symbol)
                    succesToaster(`${textLabels.Language_successfully}`)
                    setLanguage(item?.symbol)
                } else {
                    errorToaster(`${textLabels.Language_Error}`)
                }
            } catch (error) {
                errorToaster(`${textLabels.Language_Error}`)
                // errorToaster("Error while changing language")
            }
        } else {
            localStorage.setItem(USER_LANG, item?.symbol)
            i18next.changeLanguage(item.symbol)
            setLanguage(item?.symbol)
            // succesToaster("Language changed successfully ")
            succesToaster(`${textLabels.Language_successfully}`)

        }
    }
    // useEffect(() => {
    //     getLanguage()
    // }, [])
    return (
        <>
            <div
                className={`modal fade currencyModal border-0 test ${isOpen ? "show" : ""}`}
                id="currency"
                style={{ display: isOpen ? "block" : "none" }}
            >
                <div className="modal-dialog modalDailog modal-dialog-centered  modal-dialog-scrollable">
                    <div className="modal-content modalContentUser "
                    style={{
                        height:"90vh"
                    }}
                    >
                        <div className="modal-header border-0 pb-0">
                            <div className="row align-items-center w-100 gx-0">
                                <div className="col row">
                                    <div className="col-lg-3 col-5 px-0 modal-title d-flex align-items-center text-white" id="currencyLabel">
                                        <button
                                            onClick={() => setSelectedTab(TABS_OPTIONS.language)}
                                            className={`bg-transparent tab-btn pb-2 text-light ${selectedTab === TABS_OPTIONS.language ? "tab-acitve" : ""} `}
                                        >
                                            {textLabels.Language}
                                        </button>
                                    </div>
                                    {/* will be of future use */}
                                    <div className="col-lg-6 col-5 modal-title d-flex align-items-center text-white" id="currencyLabel">
                                        <button
                                            onClick={() => setSelectedTab(TABS_OPTIONS.currency)}
                                            className={`bg-transparent tab-btn pb-2 text-light ${selectedTab === TABS_OPTIONS.currency ? "tab-acitve" : ""} `}
                                        >
                                            View in fiat
                                        </button>
                                    </div>
                                </div>

                                <div className="col-auto">
                                    <button
                                        onClick={() => setIsOpen(false)}
                                        type="button"
                                        className="btn border-0 shadow-none d-flex align-items-center justify-content-center closeicon pe-0"
                                    >
                                        <img src="assets/img/aboutJonathan/close.png" alt="close" className="img-fluid object-fit-contain  h-100 w-100" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            {
                                selectedTab === TABS_OPTIONS.currency ? (
                                
                                    <div className="row row-cols-lg-12 row-cols-sm-2 row-cols-1 gy-2 fiatgroup">
                                          
                                        {
                                            isLoading ? (
                                                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                                    <Spinner animation="border" variant="light" size="sm" />
                                                </div>
                                            ) : (
                                                fiat?.length === 0 ? (
                                                    <div className="w-100 h-100">
                                                        <h6 className="text-light"> {commonText?.NoDataAvailable}</h6>
                                                    </div>
                                                ) : (
                                                <>
                                                    <div className="col">
                                                            <div className="faits d-flex align-items-center gap-2 py-2 px-3">
                                                                <div className="coinImg rounded-circle overflow-hidden">
                                                                    <img
                                                                        src={starimg}
                                                                        alt="currency"
                                                                        className="w-100 h-100"
                                                                    />
                                                                </div>
                                                                <div className="coincuntry  d-flex align-content-center gap-2">
                                                                   None
                                                                </div>
                                                            </div>
                                                        </div>
                                                   { fiat?.map((item) => (
                                                        <div className="col" key={item?._id}>
                                                            <div className={`faits d-flex align-items-center gap-2 py-2 px-3 ${item._id === selectedCurrency?._id ? "active" : ""} `}
                                                            // onClick={() => currencyHandler(item)}
                                                            >
                                                                <div className="coinImg rounded-circle overflow-hidden">
                                                                    <img
                                                                        src={item.icon ? item.icon : DEFAULT_IMAGE}
                                                                        alt="currency"
                                                                        className="w-100 h-100"
                                                                    />
                                                                </div>
                                                                <div className="coincuntry  d-flex align-content-center gap-2">
                                                                    {item.code}
                                                                    <span className="d-inline-flex text-white-50">
                                                                        {item.name}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                 </>
                                                )
                                            )
                                        }
                                    </div>
                                ) : (
                                    <div className="row row-cols-lg-3 row-cols-sm-2 row-cols-1 gy-2 fiatgroup">
                                        {
                                            isLangLoading ? (
                                                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                                    <Spinner animation="border" variant="light" />
                                                </div>
                                            ) : (
                                                LANGUAGE.length === 0 ? (
                                                    <div className="w-100 h-100">
                                                        <h6 className="text-light">{commonText?.NoDataAvailable}</h6>
                                                    </div>
                                                ) : (
                                                    LANGUAGE.map((item, index) => (
                                                        <div className="col"
                                                            onClick={() => languageHandler(item)}
                                                            key={item?.symbol}
                                                        >
                                                            <div className={`faits d-flex align-items-center gap-2 py-2 px-3 ${language === item.symbol ? "active" : ""}`}
                                                            >{/*active */}
                                                                <div className="coincuntry  d-flex align-content-center gap-2">
                                                                    <span className="d-inline-flex text-white-50">
                                                                        {item?.displayName}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                )
                                            )
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UnAuthCurrencyModal;
