import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postAPIAuth } from "../../../service/apiInstance";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import { fetchUserDetails } from "../../../service/fetchUserDetails";
import { reducerConst } from "../../../constants/storeConstants";
import { useAuth } from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";


function Profile() {
  const userDetails = useSelector((state) => state.userDetails);
  const [data, setData] = useState('')
  const [userName, setUserName] = useState('')
  const [error, setError] = useState(false)
  const dispatch = useDispatch();
  const { token } = useAuth();
  const { t } = useTranslation()
  const accountPageLabels = t("accountPageLabels", { returnObjects: true })
  const commonText = t('commonText', { returnObjects: true })


  const userId = userDetails?.id;
  // const PrevioususerName = userDetails?.username;

  useEffect(() => {
    setUserName(userDetails?.username)
  }, [userDetails])

  const handleChangeUser = async () => {
    if (userName?.length >= 3) {
      setError(false)
      try {
        const res = await postAPIAuth('user/update-username', {
          userId,
          userName
        })
        if (res?.data?.success) {
          succesToaster('Name Changed Successfully')
          setData(res)
          // setUserName('')
          
        } else {
          errorToaster(res?.data?.message)
        }
      } catch (error) {
        errorToaster(error?.message)
      }
    } else {
      setError(true)
    }
  }

  useEffect(() => {
    if (token) {
      const getData = async () => {
        const res = await fetchUserDetails(token);
        dispatch({
          type: reducerConst.USER_DETAILS,
          payload: res?.data?.data ? res.data.data : {},
        });
      };
      getData();
    }
  }, [data, token]);

  return (
    <>
      <div className="row">
        <div className="col-12 d-md-block d-none">
          <div className="d-flex align-items-center accountCardHeading">
            <div className="cardImg">
              <img
                src="assets/img/sidebar/profile.png"
                alt="profile"
                className="h-100 w-100 img-fluid "
              />
            </div>
            {accountPageLabels.PROFILE}
          </div>
        </div>
        <div className="col-12 cardsubHeading">{accountPageLabels.CHANGE_USERNAME}</div>
        <div className="col-12">
          <div className="accountInput">
            <label htmlFor="username" className="accountLabel">
              {accountPageLabels.NEW_USERNAME}  <span>*</span>
            </label>
            <div className="inputmain d-flex align-items-center ">
              <input
                type="text"
                className="userNameInput form-control shadow-none border-0"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <button
                className="customBtn d-flex border-0 align-items-center justify-content-center "
                onClick={handleChangeUser}
              >
                {accountPageLabels.CHANGE}
              </button>
            </div>
            {error ? <div className="text-danger errorTxt">{accountPageLabels.Invalid_name}</div> : ''}
          </div>
        </div>
        {/* will be used in future */}
        {/* <div className="col-12 pt-md-4 pt-3">
          <div className="privateProfile d-flex align-items-center">
            <div className="form-check form-switch mb-0">
              <input
                className="form-check-input border-0 shadow-none"
                type="checkbox"
                role="switch"
                id="private"
              />
            </div>
            <label htmlFor="private" className="checkLabel">
              Private profile
            </label>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Profile;
