import React, {useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { getAPI } from '../../../service/apiInstance';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_SCREEN_WIDTH } from '../../../constants';

function BuyGames() {
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true)
    const isMobile = useMediaQuery({ maxWidth: MOBILE_SCREEN_WIDTH })


    const getGames = async () => {
        setLoader(true)
        try {
                const res = await getAPI(`casino/live-wins`)
                setData([...res?.data?.data, ...res?.data?.data, ...res?.data?.data , ...res?.data?.data , ...res?.data?.data, ...res?.data?.data, ...res?.data?.data, ...res?.data?.data, ...res?.data?.data, ...res?.data?.data])
        } catch (error) {
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        getGames()
    }, [])

    return (
        <>
            <div className="container-fluid px0 py-4 pt-0 buyGameSection">
                <div className="liveWinTxt">LIVE WINs</div>
                <div className="row">
                    <div className="col-12" style={{padding: '16px 14px', borderRadius: 8, background: '#151D20'}}>
                        {
                            data?.length ? 
                                <Swiper
                                    slidesPerView={'auto'}
                                    spaceBetween={12}
                                    className="mySwiper"
                                    loop={true}
                                    modules={[Autoplay]}
                                    autoplay={{
                                        delay: 800,
                                        disableOnInteraction: false,
                                    }}
                                >
                                    {
                                        data?.map((item, index) => (
                                            <SwiperSlide key={item + index} style={{width: '67px'}}>
                                                <div className="buyGamesCard cursor-pointer">
                                                    <div className="gameImg">
                                                        <img className='h-100 w-100' src={item?.image} alt="" />
                                                    </div>
                                                    <div className="text-container pt-1">
                                                        <div className="head d-flex align-items-center">
                                                            <img style={{height: 16, marginTop: 3, marginRight: 2}} className='object-fit-contain' src={item?.rank?.image} alt="" />
                                                            <div className='text-nowrap overflow-hidden text-truncate'>
                                                                {item?.username}
                                                            </div>
                                                        </div>
                                                        <div className="gamePrice">
                                                            ${item?.amount}
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))
                                    }
                                </Swiper>
                            : ''
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default BuyGames