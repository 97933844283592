import React from "react";

const SpinnerComingSoon = ({ isOpen, setIsOpen }) => {
    return (
        <>
            <div
                className={`modal fade bankModal ${isOpen ? 'show d-block' : ''}`}
                id="bankModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog bankdialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content bankContent">
                        <div className="modal-header bankHeader border-0 py-0">
                            <nav className="overflow-x-auto py-1">
                                <div 
                        
                                className="nav nav-tabs border-0 flex-nowrap text-nowrap bankTab">
                                    <button
                                        // onClick={() => setWalletModalObject((prev) => ({
                                        //     ...prev,
                                        //     selectedTab: WALLET_MODAL_TABS?.DEPOSIT
                                        // }))}
                                        className={`nav-link bankBtn invisible  active`}
                                    >

                                    </button>
                                </div>
                            </nav>
                            <div className="ps-3">
                                <button type="button"
                                    // onClick={() => setWalletModalObject((prev) => ({
                                    //   isWalletOpen: false,
                                    // }))}
                                    onClick={()=>setIsOpen(false)}
                                    className="btn-close p-0 shadow-none border-0 d-flex align-items-center justify-content-center"
                                >
                                    <img src="assets/img/sidebar/close.png" alt="close" className="h-100 w-100 img-fluid" />
                                </button>
                            </div>
                        </div>
                        <div className={`modal-body bankbody pb-4 ${"walletModalObject?.selectedTab === WALLET_MODAL_TABS?.BUY_CRYPTO " ? 'showBuyCrypto' : ''}`}>
                            <div className="tab-content banktabContent text-light">
                                Spinner coming soon
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SpinnerComingSoon;


// ${isOpen === WALLET_MODAL_TABS?.BUY_CRYPTO ? 'showBuyCrypto' : ''}