import React, { useState, createContext } from 'react'

export const SpinModalContext = createContext(null)

const SpinModalContextProvider = ({children}) => {
  const [spinModalObject, setSpinModalObject] = useState({
    isModalOpen : false
  })
  return (
    <SpinModalContext.Provider value={{spinModalObject, setSpinModalObject}}>{children}</SpinModalContext.Provider>
  )
}

export default SpinModalContextProvider