
export function convertToEightDigitsAfterDecimal(number) {
  const numString = number.toString();
  const decimalIndex = numString.indexOf('.');
  
  if (decimalIndex !== -1 && numString.length - decimalIndex - 1 > 8) {
      // If decimal point exists and there are less than 8 digits after the decimal
      // console.log(number.toFixed(8))
      return parseFloat(Number(number).toFixed(8));
  } else {
      // If no decimal point or already 8 or more digits after decimal
      return number;
  }
}