import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../../hooks/useAuth'
import { getAPIAuth, postAPIAuth } from '../../../../../service/apiInstance'
import moment from 'moment/moment'
import NoData from '../../NoData'
import { useSelector } from 'react-redux'
import { errorToaster, succesToaster } from '../../../../../utils/toaster'

const ActivePositions = () => {
  const [data, setData] = useState([])
  const {token} = useAuth()
  const [activeTab, setActiveTab] = useState('Flexible')
  const userDetails = useSelector((state) => state.userDetails);


  const getActivePositions = async ()=> {
    try {
      const res = await getAPIAuth(`crypto/active-positions?sel_curr=${userDetails?.usercurrency}&pool_type=${activeTab}`, token)
      if(res?.data?.success) {
        setData(res?.data?.data)
      } else {
        setData([])
      }
    } catch (error) {
      
    }
  }

  useEffect(()=> {
    if (token) {
      getActivePositions()
    }
  }, [token, activeTab])

  const handleClaim = async (id)=> {
    const params = {
      stake_id: id
    }
    try {
      const res = await postAPIAuth('crypto/claim-staking', params, token)
      console.log('claim', res)
      if(res?.data?.success) {
        succesToaster('Stacking earning claimed successfully')
        getActivePositions()
      } else {
        errorToaster('Oops! you have nothing to claim')
      }
    } catch (error) {
      errorToaster('Oops! something wrong has happened')
    }
  }

  return (
    <>
      <div className="mt-3">
        <div className="stackingTabs">
          <button onClick={()=>setActiveTab('Flexible')} className={`stackingTab ${activeTab === 'Flexible' ? 'active' : ''}`}>Flexible</button>
          <button onClick={()=>setActiveTab('Fixed-Term')} className={`stackingTab ${activeTab === 'Fixed-Term' ? 'active' : ''}`}>Fixed-Term</button>
        </div>
      </div>
      <div className="stackingHistoryTable table-responsive">
        {
          data?.length ?
          <table className="table text-white">
            <thead>
              <tr className='text-nowrap'>
                <th>token</th>
                <th>date</th>
                <th>amount</th>
                <th>est.apr</th>
                <th>available for claim</th>
                <th>cumulative interest</th>
              </tr>
            </thead>
            <tbody>
              { 
                  data?.map(item=>(
                    <tr key={item?._id}>
                      <td className='bold'>
                        <div className="d-flex align-items-center text-uppercase">
                          <img className='coinImg' src={item?.coin_icon} alt="" />
                            {item?.coin_name}
                        </div>
                      </td>
                      <td className='text-nowrap'>
                        <span className='d-block'>{moment(item?.createdAt).format('L')}</span>
                        <span className='d-block'>{moment(item?.createdAt).format('LTS')}</span>
                      </td>
                      <td>
                        {Number(item?.amount)?.toFixed(8)}{item?.coin_name?.toUpperCase()} <br />
                        {/* {userDetails?.currency?.symbol}{item?.amount_cnv?.toFixed(8)} */}
                      </td>
                      <td className='bold'>{item?.apr}%</td>
                      <td>
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            {Number(item?.tobe_claim)?.toFixed(8)}{item?.coin_name?.toUpperCase()} <br />
                            {/* {userDetails?.currency?.symbol}{item?.tobe_claim_cnv?.toFixed(8)} */}
                          </div>
                          <button onClick={() =>handleClaim(item?._id)} className={`commonBlueBtn small claimBtn ms-2 ${item?.is_claimed ? 'disabled' : ''}`}>Claim</button>
                        </div>
                      </td>
                      <td>
                        {Number(item?.claimed_amount)?.toFixed(8)}{item?.coin_name?.toUpperCase()} <br />
                        {/* {userDetails?.currency?.symbol}{item?.claimed_amount_cnv?.toFixed(8)} */}
                      </td>
                    </tr>
                  ))
              }
            </tbody>
          </table>
          : 
              <div className="mt-3">
                <NoData/>
              </div>
        }
      </div>
      
    </>
  )
}

export default ActivePositions