import React, { useEffect, useRef, useState } from "react";
import AppFooter from "../../components/AppFooter/AppFooter";
import "./style.scss";
import { Spinner } from "react-bootstrap";
import { getAPI, getAPIAuth } from "../../service/apiInstance";
import { useNavigate } from "react-router-dom";
import { ROUTES_CONST } from "../../constants/routeConstant";
import { useTranslation } from "react-i18next";

const GameProviders = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [providers, setProviders] = useState([]);
  const [search, setSearch] = useState()
  const debounceRef = useRef()
  const navigate = useNavigate();
  const { t } = useTranslation()
  const gameProviderLabels = t("gameProviderLabels", { returnObjects: true })
  const commonText = t('commonText', { returnObjects: true })

  const getData = async () => {
    try {
      const res = await getAPI(`games/get-all-providers?provider=${search ? search : ""}`);
      if (res.data.success) {
        setProviders(res.data.data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleFunction = (provider) => {
    // navigate("/all-games", { state: { provider_id: provider } });
    // navigate(ROUTES_CONST.SLOT_PAGE, { state: { provider_id: provider } });
    navigate(`${ROUTES_CONST.PROVIDERS}/${provider}`);

  };
  useEffect(() => {
    //debounce
    if (debounceRef.current) {
      clearTimeout(debounceRef.current)
    }
    debounceRef.current = setTimeout(() => {
      getData();
    }, 500)
    return () => {
      clearTimeout(debounceRef.current)
    }
  }, [search]);
  
  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage providerPage pt-md-0">
            <div className="row">
              <div className="col-12">
                <div className="providerBtn d-inline-flex align-items-center justify-content-center">
                  <img
                    src="assets/img/app/provider.svg"
                    alt="provider icon"
                    className="providerIcon me-3"
                    style={{filter: 'brightness(100)', height: 18, width :18}}
                  />
                  {gameProviderLabels.Providers}
                </div>
              </div>
              <div className="col-12">
                <div className="searchInputBox position-relative">
                  <img
                    src="./assets/img/options/searchIcon.png"
                    alt="search icon"
                    className="searchIcon position-absolute"
                  />
                  <input
                    type="text"
                    className="form-control searchInput shadow-none"
                    placeholder={`Search your game`}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 mt-4">
                <div className="row row-cols-lg-5 row-cols-md-4 row-cols-sm-3 row-cols-2 g-3">
                  {isLoading ? (
                    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                      <Spinner animation="border" variant="light" size="sm" />
                    </div>
                  ) : providers?.length === 0 ? (
                    <div className="w-100 h-100">
                      <h6 className="text-light">{commonText.NoDataAvailable}</h6>
                    </div>
                  ) : (
                    providers?.map((item) => (
                      <div className="col"
                        key={`${item?.provider}-${Math.random()}`}
                      >
                        <div
                          className="providerCard"
                          onClick={() =>
                            handleFunction(item?.provider)
                          }
                        >
                          <img
                            src={item?.image}
                            alt={`${item?.provider}`}
                            className="providerImg img-fluid"
                          />
                          <div className="providerGame">{item?.gameCount}</div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <AppFooter />
      </main>
    </>
  );
};

export default GameProviders;
