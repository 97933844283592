import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';

// import './styles.css';

// import required modules
// import { Pagination } from 'swiper/modules';

function GamesCards() {
    return (
        <>
            <div className="container-fluid px0 py-4 mt-lg-5 pt-lg-5 gameCardSection d-none d-lg-block">
                <div className="row">
                    <div className="col-12 px-0">
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            loop={true}
                            pagination={{
                                clickable: true,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                1100: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                1200: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                            }}
                            modules={[Pagination, Autoplay]}
                            // autoplay={{
                            //     delay: 2500,
                            //     // disableOnInteraction: false,
                            //   }}
                            
                            className="mySwiper gamesCardSwiper newGamePrompCards"
                        >
                            <SwiperSlide>
                                <div className="GameCard">
                                    <div className="row gx-0">
                                        <div className="col-12 col-sm-6">
                                            <div className="head">
                                                CASINO
                                            </div>
                                            <div className="subHead">
                                                Play more than 20,000+ Casino games at MemeStop.
                                            </div>
                                            <div className="roundedCardDiv">
                                                <div className="roundedcard">
                                                    <img className='h-100 w-100 object-fit-cover' src="assets/img/rounded04.png" alt="" />
                                                </div>
                                                <div className="roundedcard leftmarginCard">
                                                    <img className='h-100 w-100 object-fit-cover' src="assets/img/rounded03.png" alt="" />
                                                </div>
                                                <div className="roundedcard leftmarginCard">
                                                    <img className='h-100 w-100 object-fit-cover' src="assets/img/rounded02.png" alt="" />
                                                </div>
                                                <div className="roundedcard leftmarginCard">
                                                    <img className='h-100 w-100 object-fit-cover' src="assets/img/rounded01.png" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 pt-5 pt-sm-0">
                                            <div className="gameImg">
                                                <img className='h-100 w-100 object-fit-contain' src="assets/img/gamesCard01.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="GameCard sportsCard">
                                    <div className="row gx-0">
                                        <div className="col-12 col-sm-6">
                                            <div className="head">
                                                SPORTS
                                            </div>
                                            <div className="subHead">
                                                More than a 1000 of events to bet on every month with our top betting platform.
                                            </div>
                                            <div className="roundedCardDiv">
                                                <div className="roundedcard spCard">
                                                    <img className='h-100 w-100 object-fit-cover' src="assets/img/spCard01.png" alt="" />
                                                </div>
                                                <div className="roundedcard spCard leftmarginCard">
                                                    <img className='h-100 w-100 object-fit-cover' src="assets/img/spCard02.png" alt="" />
                                                </div>
                                                <div className="roundedcard spCard leftmarginCard">
                                                    <img className='h-100 w-100 object-fit-cover' src="assets/img/spCard03.png" alt="" />
                                                </div>
                                                <div className="roundedcard spCard leftmarginCard">
                                                    <img className='h-100 w-100 object-fit-cover' src="assets/img/spCard04.png" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 pt-5 pt-sm-0">
                                            <div className="gameImg">
                                                <img className='h-100 w-100 object-fit-contain' src="assets/img/sportsCard.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="GameCard sportsCard">
                                    <div className="row gx-0">
                                        <div className="col-12 col-sm-6">
                                            <div className="head">
                                                POKER
                                            </div>
                                            <div className="subHead">
                                                Challenge friends or join players worldwide. Poker Skillis the quickest.
                                            </div>
                                            <div className="roundedCardDiv">
                                                <div className="roundedcard">
                                                    <img className='h-100 w-100 object-fit-cover' src="assets/img/rounded04.png" alt="" />
                                                </div>
                                                <div className="roundedcard leftmarginCard">
                                                    <img className='h-100 w-100 object-fit-cover' src="assets/img/rounded03.png" alt="" />
                                                </div>
                                                <div className="roundedcard leftmarginCard">
                                                    <img className='h-100 w-100 object-fit-cover' src="assets/img/rounded02.png" alt="" />
                                                </div>
                                                <div className="roundedcard leftmarginCard">
                                                    <img className='h-100 w-100 object-fit-cover' src="assets/img/rounded01.png" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 pt-5 pt-sm-0">
                                            <div className="gameImg">
                                                <img className='h-100 w-100 object-fit-contain' src="assets/img/pokerCrad.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="GameCard sportsCard">
                                    <div className="row gx-0">
                                        <div className="col-12 col-sm-6">
                                            <div className="head">
                                                POKER
                                            </div>
                                            <div className="subHead">
                                                Challenge friends or join players worldwide. Poker Skillis the quickest.
                                            </div>
                                            <div className="roundedCardDiv">
                                                <div className="roundedcard">
                                                    <img className='h-100 w-100 object-fit-cover' src="assets/img/rounded04.png" alt="" />
                                                </div>
                                                <div className="roundedcard leftmarginCard">
                                                    <img className='h-100 w-100 object-fit-cover' src="assets/img/rounded03.png" alt="" />
                                                </div>
                                                <div className="roundedcard leftmarginCard">
                                                    <img className='h-100 w-100 object-fit-cover' src="assets/img/rounded02.png" alt="" />
                                                </div>
                                                <div className="roundedcard leftmarginCard">
                                                    <img className='h-100 w-100 object-fit-cover' src="assets/img/rounded01.png" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 pt-5 pt-sm-0">
                                            <div className="gameImg">
                                                <img className='h-100 w-100 object-fit-contain' src="assets/img/pokerCrad.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GamesCards