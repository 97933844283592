// import React, { useState } from "react";
// import { errorToaster, succesToaster } from "../../utils/toaster";
// import { getAPI, postAPIAuth } from "../../service/apiInstance";
// import { Spinner } from "react-bootstrap";
// import { useReferralModal } from "../../hooks/useReferralModal";
// import { useDispatch, useSelector } from "react-redux";
// import { useAuth } from "../../hooks/useAuth";
// import { fetchUserDetails } from "../../service/fetchUserDetails";
// import { reducerConst } from "../../constants/storeConstants";

// const ReferralModal = () => {
//     const { isReferral: isOpen, setIsReferral: setIsOpen, isReferralInput, setIsReferralInput } = useReferralModal()
//     const [isLoading, setIsLoading] = useState(false)
//     const [referral, setReferral] = useState('')
//     const [username, setUsername] = useState("")
//     const userDetails = useSelector((state) => state.userDetails);
//     const { token } = useAuth()
//     const dispatch = useDispatch()
    
    
//     const onClickHandler = () => {
//         if (isReferralInput) {
//             if (!!referral && !!username) {
//                 referralHandler()
//                 handleChangeUser()
//             } else if (!!referral) {
//                 referralHandler()
//             } else if (!!username) {
//                 handleChangeUser()
//             }
//         } else {
//             if (!!username) {
//                 handleChangeUser()
//             }
//         }
//         setIsOpen(false)
//     }

//     const referralHandler = async () => {
//         try {
//             const res = await getAPI(`user/verifyRefferCode?refferCode=${referral}`)
//             if (res.data.success) {
//                 succesToaster("Referral Code Applied");
//                 setIsOpen(false)
//                 setReferral("")
//             } else {
//                 errorToaster(res.data.message)
//             }
//         } catch (error) {
//             errorToaster(error.message)
//         } finally {
//             setIsLoading(false)
//         }
//     }

//     const handleChangeUser = async () => {
//         if (username?.length >= 3) {
//             try {
//                 const res = await postAPIAuth('user/update-username', {
//                     userId: userDetails?.id,
//                     userName: username
//                 }, token)
//                 if (res?.data?.success) {
//                     succesToaster('Name Changed Successfully')
//                     const data = await fetchUserDetails(token)
//                     if (data.data.success) {
//                         dispatch({
//                             type: reducerConst.USER_DETAILS,
//                             payload: data?.data?.data ? data.data.data : {},
//                         });
//                     }
//                 } else {
//                     errorToaster(res?.data?.message)
//                 }
//             } catch (error) {
//                 errorToaster(error?.message)
//             }
//         } else {
//         }
//     }

//     return (
//         <div
//             className={`modal fade loginModal ${isOpen ? "show" : ""}`}
//             id="referral"
//             style={{
//                 display: isOpen ? "block" : "none"
//             }}
//         >
//             <div className="modal-dialog logindialog  modal-dialog-centered modal-dialog-scrollable ">
//                 <div className="modal-content loginContent h100 overflow-hidden border-0">
//                     <div className="modal-header header border-0 d-lg-none d-flex bg-transparent">
//                         <div className="modal-title logoimg" id="exampleModalLabel">
//                             <img loading='lazy' src="assets/img/appLogo.png" className="h-100" alt="" /></div>
//                         <span
//                             onClick={() => {
//                                 setIsOpen(false)
//                             }}
//                             // type="span"
//                             className="btn-close shadow-none border-0 btnClose smbtnClose  d-flex align-items-center justify-content-center"
//                             aria-label="Close"
//                         >
//                             <img src="assets/img/sidebar/close.png" alt="close" className="h-100 w-100 img-fluid" />
//                         </span>
//                     </div>
//                     <div className="modal-body p-0 position-relative">

//                         <span
//                             // type="span"
//                             className="btn-close shadow-none border-0 btnClose position-absolute d-lg-flex d-none align-items-center justify-content-center"
//                             data-bs-dismiss="modal"
//                             aria-label="Close"
//                             onClick={() => {
//                                 setIsOpen(false)
//                             }}
//                         >
//                             <img src="assets/img/sidebar/close.png" alt="close" className="h-100 w-100 img-fluid" />
//                         </span>
//                         <div className="row h-100 mx-0">
//                             <div className="col-lg-12 loginformMain order-2 order-lg-1">
//                                 <div className="logindata p-0 px-2  row">

//                                     <div className="tacontent col-12 tabdata" id="myTabContent">
//                                         <div
//                                             className="tapane"
//                                         >
//                                             <div>
//                                                 <div className="row gap-3 gx-0 ">
//                                                     {
//                                                         isReferralInput ? (
//                                                             <div className="col-12 inputdata">
//                                                                 <label
//                                                                     htmlFor="Referral"
//                                                                     className="inputLabel my-4"
//                                                                 >
//                                                                     Enter Referral/Promo code
//                                                                 </label>
//                                                                 <input
//                                                                     type="text"
//                                                                     id="Referral"
//                                                                     className="form-control inputlogin border-0 shadow-none"
//                                                                     placeholder="Referral/Promo code"
//                                                                     name="referral"
//                                                                     value={referral}
//                                                                     onChange={(e) => setReferral(e.target.value)}
//                                                                     onKeyDown={(event) => {
//                                                                         if (event.key === "Enter") {
//                                                                             onClickHandler(event)
//                                                                         }

//                                                                     }}
//                                                                 />
//                                                             </div>
//                                                         ) : (
//                                                             <></>
//                                                         )
//                                                     }

//                                                     <div className="col-12 inputdata">
//                                                         <label
//                                                             htmlFor="Username"
//                                                             className="inputLabel my-4"
//                                                         >
//                                                             Enter Your Username
//                                                             {/* <span>*</span> */}
//                                                         </label>
//                                                         <input
//                                                             type="text"
//                                                             id="Username"
//                                                             className="form-control inputlogin border-0 shadow-none"
//                                                             placeholder="username"
//                                                             name="Username"
//                                                             value={username}
//                                                             onChange={(e) => setUsername(e.target.value)}
//                                                             onKeyDown={(event) => {
//                                                                 if (event.key === "Enter") {
//                                                                     onClickHandler(event)
//                                                                 }
//                                                             }}
//                                                         />
//                                                     </div>
//                                                 </div>
//                                                 <div className="row mt-4 gap-4 mb-5">
//                                                     <div className="col-10 mx-auto">
//                                                         <span
//                                                             className="playBtn position-relative d-flex align-items-center justify-content-center">
//                                                             <span
//                                                                 // type="button"
//                                                                 onClick={onClickHandler}
//                                                                 className="playBtnInner border-0 d-flex align-items-center justify-content-center w-100"
//                                                             >
//                                                                 {
//                                                                     isLoading ? (
//                                                                         <div className="w-100 h-100 d-flex justify-content-center align-items-center">
//                                                                             <Spinner animation="border" variant="light" size="sm" />
//                                                                         </div>
//                                                                     ) : (
//                                                                         "Play now!"
//                                                                     )
//                                                                 }
//                                                             </span>
//                                                         </span>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div >
//     )
// };

// export default ReferralModal;


import React from 'react'

const ReferralModal = () => {
  return (
    <></>
  )
}

export default ReferralModal
