import { Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import * as Yup from "yup";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../config/firebase";
import { postAPI } from "../../../service/apiInstance";
import { useAuth } from "../../../hooks/useAuth";
import { useAuthModal } from "../../../hooks/useAuthModal";
import { useSelector } from "react-redux";
import { USER_LANG } from "../../../constants";
import { Spinner } from "react-bootstrap";
import TelegramComp from "../../socialMedia/TelegramLoginButton";
import { GoogleLogin } from "../../socialMedia/GoogleLogin";
import MetaMaskModal from "../../socialMedia/MetamaskModal";
import TelegramInputModal from "../../socialMedia/TelegramInputModal";

const validationSchema = Yup.object({
    password: Yup.string().required("This field is required"),
});

const PasswordField = ({ checks, email, setPasswordView, setActive, username, passwordView, referral, browserName }) => {
    const { setToken } = useAuth()
    const { authModalObject, setAuthModalObject } = useAuthModal()
    const [isHidden, setIsHidden] = useState(true)
    const ipAddress = useSelector(state => state.ipAddress)
    const country = useSelector(state => state.country)
    const [isLoading, setIsLoading] = useState(false)
    const [isMetamask, setIsMetamask] = useState(false);
    const [isTelegram, setIsTelegram] = useState(false);
    const [userObj, setUserObj] = useState({});

    useEffect(() => {
        if (!authModalObject.isAuthOpen) {
            setPasswordView(false)
            setActive(true)
        }
    }, [authModalObject.isAuthOpen])


    async function createUserWithEmailAndPasswordF(values) {
        if (!checks.terms) {
            errorToaster("User agreement is required");
            setIsLoading(false)
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                email,
                values.password,
            );
            const user = userCredential.user;
            // const lang = JSON.parse(localStorage.getItem(USER_LANG))
            const lang = localStorage.getItem(USER_LANG)
            const body = {
                email: userCredential._tokenResponse.email,
                idToken: userCredential._tokenResponse.idToken,
                localId: userCredential._tokenResponse.localId,
                refreshToken: userCredential._tokenResponse.refreshToken,
                password: values.password,
                terms: checks.terms,
                promotion: checks.promotions,
                username: username,
                ipAddress: ipAddress ? ipAddress : "",
                country: country ? country : "",
                // language: lang?.symbol ? lang?.symbol : localStorage.getItem(USER_LANG),
                language: lang,
                refercode: referral,
                browser: browserName


            };
            const res = await postAPI("user/register-user", body);
            if (res.data.success) {
                succesToaster(res.data.message);
                setToken(res?.data.data?.auth_token);
                setAuthModalObject(pre => ({ ...pre, isAuthOpen: false }))
                // resetForm()
            } else {
                errorToaster(res?.data?.message);
            }
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message.substr(9, 30);
            if (error.message === 'Firebase: Error (auth/email-already-in-use).') {
                errorToaster('Email already exists');
            } else {
                errorToaster(errorMessage);
            }
        } finally {
            setIsLoading(false)
        }
    }
    useEffect(() => {
        if (!passwordView) {
            setIsLoading(false)
        }
    }, [passwordView])


    return (
        <>
            <Formik
                initialValues={{
                    password: ""
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    setIsLoading(true)
                    createUserWithEmailAndPasswordF(values)
                }}
            >
                {
                    ({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                    }) => (
                        <form className="h-100 d-flex flex-column justify-content-between">
                            <div className="row gap-3 gx-0">
                                <div className="col-12 inputdata">
                                    {/* EMAIL */}
                                    <label htmlFor="text" className="inputLabel">
                                        EMAIL
                                        <span>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="text"
                                        className="form-control inputlogin border-0 shadow-none"
                                        // onBlur={handleBlur}
                                        // onChange={handleChange}
                                        placeholder="Email"
                                        value={email}
                                        readOnly
                                        name="email"

                                    />
                                </div>
                                <div className="col-12 inputdata passwordInput pt-0">
                                    <label
                                        htmlFor="password1"
                                        className="inputLabel"
                                    >
                                        PASSWORD
                                        <span>*</span>
                                    </label>
                                    <div className="position-relative passwordGroup">
                                        <input
                                            type={`${isHidden ? "password" : "text"}`}
                                            id="password1"
                                            className="form-control inputlogin border-0 shadow-none"
                                            placeholder="enter your password"
                                            name="password"
                                            value={values.password}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {
                                            isHidden ? (
                                                <img onClick={() => setIsHidden(false)} src="assets/img/newIcon/eyeclose.svg" className="eye active  open cursor-pointer" alt="" />
                                            ) : (
                                                <img onClick={() => setIsHidden(true)} src="assets/img/newIcon/eyeopen.svg" className="eye active close cursor-pointer" alt="" />
                                            )
                                        }
                                    </div>
                                    <ErrorMessage>
                                        {errors.password && touched.password && errors.password}
                                    </ErrorMessage>
                                </div>


                            </div>
                            {/* footer section*/}
                            <div className="row mt-4 gap-4">
                                {/* button section*/}
                                <div className="col-12 mx-auto">
                                    <span
                                        className="playBtn position-relative d-flex align-items-center justify-content-center">
                                        <button
                                            type="submit"
                                            onClick={handleSubmit}
                                            className="playBtnInner border-0 d-flex align-items-center justify-content-center w-100"
                                        >
                                            {
                                                isLoading ? <Spinner size="sm" color="dark" /> : "Play now!"
                                            }
                                        </button>
                                    </span>
                                </div>
                                {/* divider*/}
                                <div className="col-12 mx-auto ">
                                    <div className="loginbutton text-center">
                                        <span className="directLogin text-decoration-none">
                                            Log in directly with
                                        </span>
                                    </div>
                                </div>
                                {/* social links*/}
                                <div className="col-12 mx-auto">
                                    <div className="row align-items-center justify-content-center g-2">
                                        {/* google */}
                                        <div className="col-auto">
                                            <span className="text-decoration-none">
                                                <div className="link">
                                                    <GoogleLogin
                                                        parent={"signUp"}
                                                    // setIsReferral={setIsReferral}
                                                    >
                                                        <img src="assets/img/newIcon/google.svg" alt="" />
                                                    </GoogleLogin>
                                                </div>
                                            </span>
                                        </div>
                                        {/* <div className="col-auto">
                                            <span className="text-decoration-none">
                                                <div className="link">
                                                    <img src="assets/img/newIcon/telegram.svg" alt="" />
                                                </div>
                                            </span>
                                        </div> */}
                                        {/* metamask */}
                                        {/* <div className="col-auto">
                                            <span className="text-decoration-none cursor-pointer">
                                                <div className="link" onClick={() => setIsMetamask(true)}>
                                                    <img
                                                        src="assets/img/newIcon/metamask-grayscale-logo.svg"
                                                        alt=""
                                                    />
                                                </div>
                                            </span>
                                        </div> */}
                                        <MetaMaskModal
                                            isOpen={isMetamask}
                                            setIsOpen={setIsMetamask}
                                            parent={"signUp"}
                                        // setIsReferral={setIsReferral}
                                        />
                                        {/* telegram */}

                                        <TelegramComp
                                            isOpen={isTelegram}
                                            setIsOpen={setIsTelegram}
                                            userObj={userObj}
                                            setUserObj={setUserObj}
                                            // setIsReferral={setIsReferral}
                                            authModalObject={authModalObject}

                                        />
                                        {/* <div className="col-auto cursor-pointer">
                                            <span className="text-decoration-none position-relative">
                                                <div className="link"><img src="assets/img/newIcon/telegram.svg" alt="" /></div>
                                                {authModalObject.isAuthOpen ? (
                                                    <div className="col-auto d-flex justify-content-center position-absolute opacity-0 top-0 bottom-0 start-0 end-0">
                                                        <TelegramComp
                                                            isOpen={isTelegram}
                                                            setIsOpen={setIsTelegram}
                                                            userObj={userObj}
                                                            setUserObj={setUserObj}
                                                        // setIsReferral={setIsReferral}
                                                        />
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                            </span>
                                        </div> */}
                                        {/* {authModalObject.isAuthOpen ? (
                                            <div className="col-auto d-flex justify-content-center my-3">
                                                <TelegramComp
                                                    isOpen={isTelegram}
                                                    setIsOpen={setIsTelegram}
                                                    userObj={userObj}
                                                    setUserObj={setUserObj}
                                                />
                                            </div>
                                        ) : (
                                            <></>
                                        )} */}
                                    </div>
                                </div>
                            </div>
                        </form>
                    )
                }
            </Formik>
            {/* <MetaMaskModal
                isOpen={isMetamask}
                setIsOpen={setIsMetamask}
                parent={"signUp"}
            // setIsReferral={setIsReferral}
            /> */}
            {/* <TelegramInputModal
                isOpen={isTelegram}
                setIsOpen={setIsTelegram}
                userObj={userObj}
                setUserObj={setUserObj}
            // setIsReferral={setIsReferral}
            /> */}
        </>
    );
};

export default PasswordField;
