import { Modal } from "react-bootstrap"



const DepositBonusRules = ({ isOpen, setIsOpen }) => {
    return (
        <>
            <Modal className='modalSection' centered show={isOpen} onHide={() => setIsOpen(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Deposit Bonus Rules
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row gap-3'>
                        <div className='col-12'>
                            <div className='bounseRouleHeading'>Memestop offers an outstanding deposit match bonus package, providing a rewarding opportunity to gain extra Crypto and obtain our distinctive Crypto coins, DBL (DBL), to play with or your personal use. Seize this remarkable chance to boost your Crypto portfolio with Memestop's exceptional bonuses.</div>
                        </div>
                        <div className='col-12'>
                            <div className='row g-sm-3 g-2'>
                                <div className='col-6'>
                                    <div className='bounsCard'>
                                        <div className='row gap-2'>
                                            <div className='col-12'>
                                                <div className='bounseImge'>
                                                    <img src="assets/img/bouns/depositbouns4.png" alt="bounseImge1" className='w-100 h-100' />
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <div className='subheadingDeposite'>Minimum Deposit</div>
                                                        <div className='subheadingDeposite'>Get up to</div>

                                                    </div>
                                                    <div className='col-auto'>
                                                        <div className='headingDeposite text-end'>$ 10</div>
                                                        <div className='headingDeposite text-end'>20,000 DBL</div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='bounsCard'>
                                        <div className='row gap-2'>
                                            <div className='col-12'>
                                                <div className='bounseImge'>
                                                    <img src="assets/img/bouns/depositbouns2.png" alt="bounseImge1" className='w-100 h-100' />
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <div className='subheadingDeposite'>Minimum Deposit</div>
                                                        <div className='subheadingDeposite'>Get up to</div>
                                                    </div>
                                                    <div className='col-auto'>
                                                        <div className='headingDeposite text-end'>$ 50</div>
                                                        <div className='headingDeposite text-end'>40,000 DBL</div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='bounsCard'>
                                        <div className='row gap-2'>
                                            <div className='col-12'>
                                                <div className='bounseImge'>
                                                    <img src="assets/img/bouns/depositbouns3.png" alt="bounseImge1" className='w-100 h-100' />
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <div className='subheadingDeposite'>Minimum Deposit</div>
                                                        <div className='subheadingDeposite'>Get up to</div>

                                                    </div>
                                                    <div className='col-auto'>
                                                        <div className='headingDeposite text-end'>$ 100</div>
                                                        <div className='headingDeposite text-end'>60,000 DBL</div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='bounsCard'>
                                        <div className='row gap-2'>
                                            <div className='col-12'>
                                                <div className='bounseImge'>
                                                    <img src="assets/img/bouns/depositbouns1.png" alt="bounseImge1" className='w-100 h-100' />
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <div className='subheadingDeposite'>Minimum Deposit</div>
                                                        <div className='subheadingDeposite'>Get up to</div>
                                                    </div>
                                                    <div className='col-auto'>
                                                        <div className='headingDeposite text-end'>$ 200</div>
                                                        <div className='headingDeposite text-end'>100,000 DBL</div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DepositBonusRules