import React, { useEffect, useState } from 'react'
import { getAPI } from '../../../service/apiInstance';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProviderSortComp = ({ selectedProvider, setSelectedProvider, selectedCategory }) => {
  const [providerList, setProviderList] = useState([]);
  const [providerName, setProviderName] = useState('All')
  const { t } = useTranslation()
  const allGamesLabels = t("allGamesLabels", { returnObjects: true })

  const getProvider = async () => {
    try {
      const result = await getAPI(`crypto/get-provider?casinoCatId=${selectedCategory}`);
      if (result.data.success) {
        setProviderList(result?.data?.data);
      } else {
      }
    } catch (error) { }
  };

  useEffect(() => {
    if(selectedCategory) {

      getProvider();
    }
  }, [selectedCategory]);

  return (
    <>
    <div className="roundedDropdown dropdown position-relative">
        <div className="roundedDropBtn text-capitalize" data-bs-toggle="dropdown" aria-expanded="false">
            {allGamesLabels.PROVIDER.toLowerCase()} 
            <span>
              {/* {selectedProvider ? selectedProvider?.length :'ALL'} */}
              {providerName}
              </span>
            <img src="assets/img/options/downArrow.png" className='downArrow' alt="" />
        </div>
          <div class="roundedDropMenu dropdown-menu">
              <div className="row">
                  <div className="col-12 bodyWrapper">
                      {providerList.map((item) => (
                        <div className="dropBody mt-2 d-flex align-items-center" key={item?.result?._id}>
                        <div class="form-check formCheck">
                            <div
                              onClick={()=> {
                                setSelectedProvider(item?.result?._id)
                                setProviderName(item?.result?.provider)
                              }}
                              class={`dropdownLabel d-flex align-items-center ${item?.result?._id === selectedProvider ? 'active' : ''}`}>
                                <div className='dropdownCheckbox'></div>
                                <div className='dropdownTxt'>{item?.result?.provider}</div>
                                <div className='dropdownValue ms-auto'>{item.count}</div>
                            </div>
                        </div>
                    </div>
                      ))}
                  </div>
              </div>
          </div>
    </div>
    </>
  )
}

export default ProviderSortComp