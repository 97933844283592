import React, { useEffect, useState } from 'react'
import './style.scss'
import poster1 from '../../../assets/img/poster1.png'
import poster2 from '../../../assets/img/poster2.png'
import poster3 from '../../../assets/img/poster3.png'
import { useGames } from '../../../hooks/useGame'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { BONUS_DROPDOWN_OPTIONS, GAME_OPTION_TABS, MOBILE_SCREEN_WIDTH } from '../../../constants'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import { getAPIAuth } from '../../../service/apiInstance'
import GamesCard from '../../common/GamesCard/GamesCard'
import { useMediaQuery } from 'react-responsive'

const FreeSpinGames = ({closeBonusDropdown}) => {
    const {bonusObject, setGameObject } = useGames()
    const navigate = useNavigate()
    const [games, setGames] = useState([])
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [pagination, setPagination] = useState({})
    const perPage = 18;
    const isMobile = useMediaQuery({ maxWidth: MOBILE_SCREEN_WIDTH })
    const [query, setQuery] = useSearchParams()


    const getFreeGames = async ()=> {
        setLoading(true)
        try {
            const res = await getAPIAuth(`games/casino/getFreeSpinGames?page=${currentPage}&perPage=${perPage}&is_mobile=${isMobile ? "true" : "false"}`)
            if(res?.data?.success) {
                setGames(res?.data?.data)
                setPagination(res?.data?.pagination?.[0])
            }
        } catch (error) {
            
        } finally {
            setLoading(false)
        }
    }

    const handleLoadMore =  async ()=> {
        try {
            const res = await getAPIAuth(`games/casino/getFreeSpinGames?page=${currentPage + 1}&perPage=${perPage}`)
            if(res?.data?.success) {
                setGames(prev=>([
                    ...prev,
                    ...res?.data?.data
                ]))
                setPagination(res?.data?.pagination?.[0])
                setCurrentPage(currentPage + 1)
            }
        } catch (error) {
            
        }
    }

    useEffect(()=> {
        getFreeGames()
    }, [bonusObject])

    return (
        <>
            <div className="bonusDropDownCloseLayer" onClick={closeBonusDropdown}></div>

            <div className="bonusDetailWrapper bonusDropModal h-100">
                <div className="row mx-0 h-100 bonusDropModalInner flex-nowrap flex-column">
                    <div className="col-12 bonusDropModalHeader">
                        <div className="row mx-0 pb-2">     
                            <div className="col px-0"><div className="heading">Bonus Details</div></div>
                            <div className="col-auto pe-0">
                                <div className="close cursor-pointer" onClick={closeBonusDropdown}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10" fill="none">
                                    <path d="M9.22987 1.13297C9.48903 0.873792 9.48903 0.45357 9.22987 0.19439C8.9707 -0.0647967 8.55046 -0.0647967 8.29129 0.19439L4.71213 3.77356L1.13297 0.19439C0.873792 -0.0647967 0.45357 -0.0647967 0.19439 0.19439C-0.0647967 0.45357 -0.0647967 0.873792 0.19439 1.13297L3.77356 4.71213L0.19439 8.29129C-0.0647967 8.55046 -0.0647967 8.9707 0.19439 9.22987C0.45357 9.48903 0.873792 9.48903 1.13297 9.22987L4.71213 5.65071L8.29129 9.22987C8.55046 9.48903 8.9707 9.48903 9.22987 9.22987C9.48903 8.9707 9.48903 8.55046 9.22987 8.29129L5.65071 4.71213L9.22987 1.13297Z" fill="white"/>
                                    </svg>
                                </div>
                            </div>
                            <div className="col-12 px-0"><div className="subHeading">Special Bonus <span>100 Free Spins</span></div></div>
                        </div>
                    </div>
                    <div className="col flex-fill px-0 bonusDropModalBody toprated-games-main">
                        <div className="row mx-0 p-2 toprated-games">
                            <div className="col-12 px-0 games-card-main">
                                <div className="row mx-0 g-2 mySwiper">
                                    {
                                        !loading ? 
                                            games?.length ? 
                                                games?.map(item=> (
                                                    <div className="col-4">
                                                        <GamesCard
                                                                // RTP={false}
                                                                RTP={item?.rtp}
                                                                // onClick={(e) => {
                                                                //     e.stopPropagation()
                                                                //     // favouriteGame(item?._id)
                                                                // }}
                                                                image={item?.image}
                                                                name={item?.name}
                                                                // isFav={false}
                                                                isFav={item?.isFav}
                                                                favButton={false}
                                                                uuid={item?.uuid}
                                                                isFromBonus={true}
                                                                freeSpinLoader={true}
                                                                closeBonusDropdown={closeBonusDropdown}
                                                            />
                                                    </div>
                                                ))
                                            : ''
                                        : ''
                                    }
                                    {
                                        currentPage < pagination?.totalPages ? 
                                        <div className="col-12 mt-3 d-flex align-items-center justify-content-center">
                                            <button className='loadMoreBtn' onClick={handleLoadMore}>Load More</button>
                                        </div>: ''
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 bonusDropModalFooter d-flex justify-content-center">
                        <button className='GoBtn'
                            onClick={() => {
                                closeBonusDropdown()
                                setGameObject((prev) => ({
                                    selectedTab: GAME_OPTION_TABS?.FREE_SPIN
                                }))
                                // navigate(ROUTES_CONST.GAME_PAGE)
                                setQuery({ q: "freeSpin" })
                                navigate(`${ROUTES_CONST.GAME_PAGE}?q=${encodeURIComponent('freeSpin')}`)
                            }}>Go to all games 
                                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
                                    <path d="M0.447007 6.29212C0.358734 6.29264 0.272308 6.26657 0.198747 6.21724C0.125186 6.1679 0.067824 6.09754 0.0339738 6.01512C0.000123601 5.93269 -0.00868107 5.84195 0.00868236 5.75445C0.0260458 5.66694 0.0687906 5.58665 0.131467 5.52381L2.4869 3.14698L0.131467 0.770152C0.0477807 0.685546 0.000766196 0.570795 0.000766196 0.451144C0.000766196 0.391899 0.0123085 0.333234 0.0347342 0.278499C0.0571598 0.223764 0.0900298 0.17403 0.131467 0.132137C0.172904 0.0902445 0.222097 0.0570136 0.276238 0.0343415C0.330378 0.0116693 0.388406 0 0.447007 0C0.565357 0 0.67886 0.0475312 0.762547 0.132137L3.42908 2.82797C3.51185 2.91216 3.55831 3.02603 3.55831 3.14473C3.55831 3.26343 3.51185 3.37731 3.42908 3.46149L0.762547 6.15733C0.721387 6.19978 0.672302 6.23356 0.618138 6.25669C0.563974 6.27983 0.505807 6.29187 0.447007 6.29212Z" fill="white"/>
                                    <path d="M3.44701 6.29212C3.35873 6.29264 3.27231 6.26657 3.19875 6.21724C3.12519 6.1679 3.06782 6.09754 3.03397 6.01512C3.00012 5.93269 2.99132 5.84195 3.00868 5.75445C3.02605 5.66694 3.06879 5.58665 3.13147 5.52381L5.4869 3.14698L3.13147 0.770152C3.04778 0.685546 3.00077 0.570795 3.00077 0.451144C3.00077 0.391899 3.01231 0.333234 3.03473 0.278499C3.05716 0.223764 3.09003 0.17403 3.13147 0.132137C3.1729 0.0902445 3.2221 0.0570136 3.27624 0.0343415C3.33038 0.0116693 3.38841 0 3.44701 0C3.56536 0 3.67886 0.0475312 3.76255 0.132137L6.42908 2.82797C6.51185 2.91216 6.55831 3.02603 6.55831 3.14473C6.55831 3.26343 6.51185 3.37731 6.42908 3.46149L3.76255 6.15733C3.72139 6.19978 3.6723 6.23356 3.61814 6.25669C3.56397 6.27983 3.50581 6.29187 3.44701 6.29212Z" fill="white"/>
                                </svg>
                            </button>
                    </div>
                </div>
            </div>  
        </>
    )
}

export default FreeSpinGames