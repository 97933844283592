import React, { useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { useAuth } from '../../../hooks/useAuth'
import { getAPIAuth } from '../../../service/apiInstance';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrency, selectFiat } from '../../../store/action';


const Fiat = () => {
  const { token } = useAuth();
  const { t } = useTranslation();
  const walletLabels = t("wallet", { returnObjects: true });
  const [currency, setCurrency] = useState([]);
  const fiat = useSelector(state => state?.selectedCurrency?.fiat)
  const [selectedCurrency, setSelectedCurrency] = useState(fiat);
  const [amount, setAmount] = useState("")
  const userDetails = useSelector(state => state?.userDetails)
  console.log("currency---", {  selectedCurrency });
  const dispatch = useDispatch()

  useEffect(() => {
    if (fiat?._id) {
      setSelectedCurrency(fiat)
    }
  }, [fiat?._id])
  const getCurrency = async () => {
    try {
      const res = await getAPIAuth(`currency/get-currency?userId=${userDetails?.id}`, token)
      console.log(res)
      setCurrency(res.data.data);
      if(!fiat?._id){
        setSelectedCurrency(res.data.data[0]);

      }
      if (res.data.success) {
        // setAvailCrypto(res.data.data)
        // const data = res?.data?.data?.filter(item => item?.code !== 'eth')
        // setSelCryptoNetwork(res.data.data)
      }
    } catch (error) {
    }
  }
  useEffect(() => {
    getCurrency();
  }, [])

  return (
    <>
      <div className="withdrawBody">
        <div className="row gx-2 align-items-center">
          <div className="col withTitle">{walletLabels.Withdraw_currency}</div>
          <div className="col-auto fiatModal">
            <Dropdown className='fialDropdown' align={'end'}>
              <Dropdown.Toggle id="dropdown-basic">
                {selectedCurrency.name}
                <img src={selectedCurrency.icon} alt="" />
              </Dropdown.Toggle>

              <Dropdown.Menu className='currDropMenu'>
                <div className="header">
                  <div class="searchInp">
                    <img src="./assets/img/searchGray.png" alt="" />
                    <input type="text" class="form-control shadow-none" placeholder="Search" />
                  </div>
                </div>
                <div className="body">
                  {currency?.length === 0 ? (
                    <div className="w-100 h-100">
                      <h6 className="text-light"> No data available</h6>
                    </div>
                  ) : (
                    currency?.map((item) => (
                      <Dropdown.Item key={item.id} className='menuItem' as="button" onClick={() => {
                        setSelectedCurrency(item)
                        dispatch(selectFiat(item))
                      }}>
                        <div className="country">
                          <div className="img">
                            <img src={item.icon} alt="" />
                          </div>
                          {item.name}
                        </div>
                        <div className="cName">{item.code}</div>
                        <div className="prizeeee">{item?.wallet?.totalBalance ? Number(item?.wallet?.totalBalance).toFixed(2) : '0.00'}</div>
                      </Dropdown.Item>
                    )))}
                </div>
                <div className="footer">
                  <img src="./assets/img/weAccept.png" alt="" />
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-12 mt-3">
            <div className="lineDivider"></div>
          </div>
          <div className="col-12 withTitle mt-3">Withdraw Amount</div>
          <div className="col-12">
            <input type="text" className='form-control receivingInput shadow-none border-0' placeholder='Enter Amount'
              value={amount}
              onChange={e => {
                if (isNaN(Number(e.target.value))) return
                setAmount(e.target.value)
                // priceConverter(e)
              }}
            />
          </div>
          <div className="col-12 mt-1">
            <div className="row">
              <div className="col">
                <div className="withTitle">Available:  <span>0.00</span></div>
              </div>
              <div className="col-auto">
                <div className="withTitle">Locked funds:  <span>0.00</span></div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-3">
            <div className="row mx-0 amountDetailBox gx-0">
              <div className="col-12 d-flex align-items-center justify-content-between">
                <div className="withTitle">Withdraw Amount</div>
                <div className="withTitle"><span>0.00 INR</span></div>
              </div>
              <div className="col-12 d-flex align-items-center justify-content-between py-1">
                <div className="withTitle">
                  Fee
                  <img className="infoImg" src="./assets/img/infoGreen.png" alt="" />
                </div>
                <div className="withTitle"><span>0.00 INR</span></div>
              </div>
              <div className="col-12 d-flex align-items-center justify-content-between">
                <div className="withTitle mb-0">Total withdrawal amount</div>
                <div className="withTitle mb-0"><span className='green'>0.00 INR</span></div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-3 fiatModal">
            <div className="depCurr mb-2">Choose a payment method</div>
            <div className="paymentBox">
              <div className="recommendedTxt">Recommended</div>
              <div className="paymentDiv">
                <span className="fastest">Fastest</span>
                <img className='infoImg' src="assets/img/sidebar/info.png" alt="" />
                <div className="payImg">
                  <img src="./assets/img/payMet.png" alt="" />
                </div>
                <div className="cardDiv">
                  <div className="cardType">Credit Card</div>
                  <div className="eat">EAT: 1 min</div>
                </div>
                <div className="amtDiv">10~500 EUR</div>
                <div className="arrowDiv">
                  <img src="./assets/img/carouselRightArrow.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div class="securityMsg">
              <div class="msg">Notice: For security purposes, large or suspicious withdrawal may take 1-6 hours for audit process. We appreciate your patience!</div>
            </div>
          </div>
          <div className="col-12">
            <button className='shadow-none withdrawBtn'>Withdraw</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Fiat