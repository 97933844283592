import React, { useEffect, useState } from 'react'
import Chart from './components/Chart';
import CalculateBox from './components/CalculateBox';
import { getAPIAuth, postAPIAuth } from '../../../../service/apiInstance';
import { useAuth } from '../../../../hooks/useAuth';

const CalculateEarning = ({ data }) => {
  const { token } = useAuth();
  const [ profit,setProfit ] = useState(null);
  const [ inputValue,setInputValue ] = useState("");
  const [ selectedCrypto,setSelectedCrypto ] = useState({});
  const [ selectedPoolType,setSelectedPoolType ] = useState({});
  const [ selectedTabValue,setSelectedTabValue ] =useState({});
  const [ selectedYear,setSelectedYear ]=useState(1);    
  const [flexible, setFlexible] = useState([])
  const [fixedTerm, setFixedTerm] = useState([])
  const [boostedFixedTerm, setBoostedFixedTerm] = useState([])

  const getProfits=async(body)=>{
    try {
      const res =await postAPIAuth("crypto/calculate-profit",body,token);
      setProfit(res?.data?.data);
    } catch (error) {
      setProfit(null)
    }
  }

  console.log('inputValue', inputValue)

  useEffect(()=>{
    const timer = setTimeout(()=>{
      if(inputValue){
        getProfits({
          crypto_id:selectedCrypto?.currency?._id,
          amount:Number(inputValue),
          apr:"30",
          tab_val:selectedTabValue?.name,
          year:selectedYear,
          pool_type:selectedPoolType?.poolType,
          cryptostakingparamter: selectedPoolType?.poolType === 'Fixed-Term' ? fixedTerm?._id : selectedPoolType?.poolType === "Boosted Fixed-Term" ? boostedFixedTerm?._id : flexible?._id
      })
      }else{
        console.log("empty->>>>>>>>>>>")
        setProfit(null);
      }
    },300);

    return ()=>clearTimeout(timer);
  },[inputValue,selectedCrypto,selectedPoolType,selectedTabValue,selectedYear]);//eslint-disable-line


  console.log('selectedCrypto', selectedCrypto)

  useEffect(() => {
    const flexibleData = selectedCrypto?.term?.filter(item => item?.poolType === "Flexible")
    setFlexible(flexibleData?.[0])
    const fixedTermData = selectedCrypto?.term?.filter(item => item?.poolType === "Fixed-Term")
    setFixedTerm(fixedTermData?.[0])
    const boostedFixedTermData = selectedCrypto?.term?.filter(item => item?.poolType === "Boosted Fixed-Term")
    setBoostedFixedTerm(boostedFixedTermData?.[0])
}, [selectedCrypto])


  return (
    <div className="my-5 calculateCryptoEarning">
      <div className="row calculateCryptoEarningInner">
        <div className="col-12 CommonTitle">Calculate your crypto earnings</div>
        <div className="col-lg-6">
          <CalculateBox 
            data={data} 
            inputValue={inputValue} 
            setInputValue={setInputValue}
            selectedCrypto={selectedCrypto} 
            setSelectedCrypto={setSelectedCrypto}
            selectedPoolType={selectedPoolType}
            setSelectedPoolType={setSelectedPoolType} 
            selectedTabValue={selectedTabValue}
            setSelectedTabValue={setSelectedTabValue}
            />
        </div>
        <div className="col-lg-6">
          <div className="charBox h-100 d-flex justify-content-between flex-column">
            <div className="row align-items-center mt-3 mt-lg-0">
              <div className="col-sm-auto col-12">

                <div className='estimateText'>Estimated Earnings</div>

                <div className=""><div className="estimategreenText">{!isNaN(profit?.usdt_profit) && profit?.usdt_profit !== null?`+$${profit?.usdt_profit}`:""}</div></div>
              
                <div className=""><div className="estimatelightText">{!isNaN(profit?.coin_profit) && profit?.coin_profit !== null ?`${profit?.coin_profit} ${selectedCrypto?.currency?.name}`:""}</div></div>
              </div>
              <div className="col-sm col-12 d-flex justify-content-between">
                <div className="tabs graphTabs">
                  <button className={selectedYear === 1 ? 'active' : 'graphBtns'} onClick={() => setSelectedYear(1)}>1 year</button>
                  <button className={selectedYear === 2 ? 'active' : 'graphBtns'} onClick={() => setSelectedYear(2)}>2 year</button>
                  <button className={selectedYear === 3 ? 'active' : 'graphBtns'} onClick={() => setSelectedYear(3)}>3 year</button>
                  <button className={selectedYear === 5 ? 'active' : 'graphBtns'} onClick={() => setSelectedYear(5)}>5 year</button>
                  {/* Content for tabs */}
                  {/* <div className="tab-content">
                    {activeTab === 1 && <div>Content for Tab 1</div>}
                    {activeTab === 2 && <div>Content for Tab 2</div>}
                    {activeTab === 3 && <div>Content for Tab 3</div>}
                    {activeTab === 4 && <div>Content for Tab 4</div>}
                  </div> */}
                </div>
              </div>
            </div>
            <Chart selectedYear={selectedYear} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalculateEarning