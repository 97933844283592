import React from "react";
import AppFooter from "../../components/AppFooter/AppFooter";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { getAPIAuth } from "../../service/apiInstance";
import { useState } from "react";
import GamesCard from "../../components/common/GamesCard/GamesCard";
import { useSelector } from "react-redux";
import { MOBILE_SCREEN_WIDTH } from "../../constants";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

function RecentGames() {
  const [gameList, setGameList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userDetails = useSelector(state => state.userDetails)
  const isMobile = useMediaQuery({ maxWidth: MOBILE_SCREEN_WIDTH })
  const { t } = useTranslation()
  const recentGames = t("recentGames", { returnObjects: true })
  const commonText = t('commonText', { returnObjects: true })

  const recentGame = async () => {
    if (userDetails?.id) {
      setIsLoading(true);
      try {
        const res = await getAPIAuth(`games/recent-games?userId=${userDetails?.id}&is_mobile=${isMobile ? "true" : "false"}`);
        if (res?.data?.success) {
          setGameList(res.data?.data ? res.data?.data : []);
        }
      } catch (error) {
        //   errorToaster(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    recentGame();
  }, [userDetails?.id]);

  return (
    <main className="main vh-100" id="main">
      <div className="mainContaint">
        <div className="homepage">
          <div className="container-fluid resultpage toprated-games-main position-relative">
            <div className="row align-items-center gap-sm-0 gap-3">
              <div className="col-sm">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="resultHeading"> {recentGames.RECENT} :</div>
                </div>
              </div>

            </div>
            <div className="row toprated-games gap-2 mx-0 pt-4">
              <div className="col-12 px-0 games-card-main">
                {isLoading ? (
                  <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="light" size="sm" />
                  </div>
                ) : (
                  gameList.length === 0 ? (
                    <div className="text-light w-100 text-center"> {commonText.NoDataAvailable} </div>
                  ) : (
                    <div className="row mySwiper searchGameCardWrapper w-100 g-2 row-cols-8  casinoCard1 ">
                      {
                        gameList?.map((item) => (
                          <>
                            <GamesCard
                              RTP={item?.rtp}
                              image={item?.image}
                              name={item?.name}
                              isFav={item?.is_Fav}
                              id={item?._id}
                              key={item?._id}
                              favButton={true}
                              uuid={item?.uuid}
                            />
                          </>
                        ))
                      }
                    </div>
                  )
                )}
              </div>
              <div className="col-12">
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  **** Footer section *** */}
      <AppFooter />
    </main>
  );
}

export default RecentGames;
