import React, { useState } from 'react'
import Deposit from '../../../components/newWallet/components/Deposit'
import Fiat from '../../../components/newWallet/components/Fiat'

const DepositWallet = () => {
  const [activeTab, setActiveTab] = useState('crypto')
  return (
    <>
    <div className="accountDepositTabs row">
      <div className="col-6">
        <button onClick={()=>setActiveTab('crypto')} className={`w-100 accountDepositTab ${activeTab === 'crypto' ? 'active' : ''}`}>Crypto</button>
      </div>
      <div className="col-6">
        <button onClick={()=>setActiveTab('fiat')} className={`w-100 accountDepositTab ${activeTab === 'fiat' ? 'active' : ''}`}>Fiat</button>
      </div>
    </div>
    <div className="bankModal">
      <div className="bankdialog fullPageDialog">
        <div className="bankContent">
          <div className="banktabContent">
            {
              activeTab === 'crypto' ? <Deposit/> : 
              activeTab === 'fiat' ? <Fiat/> : ''
            }
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default DepositWallet