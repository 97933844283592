import React from 'react'
import Modal from 'react-bootstrap/Modal';
import './style.scss'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTES_CONST } from '../../constants/routeConstant';

const DepositeMessage = ({showDepositMsg, setShowDepositMsg, depositMsgData}) => {
  const handleClose = () => setShowDepositMsg(false);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return (
    <>
      <Modal className='depositeMessageModal' centered show={showDepositMsg} onHide={handleClose}>
        <Modal.Header>
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="mTitle">Deposit</div>
            <div className="mCloseBtn" onClick={handleClose}>
              <img src="assets/img/sidebar/close.png" alt="" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='checkImg'>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"></path><path d="M256 76c48.1 0 93.3 18.7 127.3 52.7S436 207.9 436 256s-18.7 93.3-52.7 127.3S304.1 436 256 436c-48.1 0-93.3-18.7-127.3-52.7S76 304.1 76 256s18.7-93.3 52.7-127.3S207.9 76 256 76m0-28C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z"></path></svg>
          </div>
          <div className="depAmt">{Number(depositMsgData?.amount)?.toFixed(8)} {depositMsgData?.sCoin}</div>
          <div className="depComp">Deposit Complete</div>
          <div className="depMsg">Payment received. It might take a minute to be shown in your account.</div>
          <div className="viewTranx"
            onClick={()=> {
                navigate(ROUTES_CONST.ACCOUNT)
                dispatch({
                  type: "DEPOSITS",
                  payload: "Deposits",
                })
                handleClose()
            }}
          >View Transaction Details</div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DepositeMessage