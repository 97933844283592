import React, { useEffect } from 'react'
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { getAPIAuth, postAPIAuth } from '../../../service/apiInstance';
import { useAuth } from '../../../hooks/useAuth'
import { fetchUserDetails } from '../../../service/fetchUserDetails';
import { useDispatch, useSelector } from 'react-redux';
import { reducerConst } from '../../../constants/storeConstants';


const FiatModal = ({showFiatModal, setShowFiatModal}) => {
  const dispatch = useDispatch();
const { token } = useAuth();
  const handleClose = () => setShowFiatModal(false);
  const [currency, setCurrency] = useState([]);
  const userDetails = useSelector(state => state.userDetails)

  const getCurrency = async () => {
    try {
        const res = await getAPIAuth("currency/get-currency", token)
        setCurrency(res.data.data);
    } catch (error) {
    }
}

const handleChange=(item)=>{
  if (!userDetails?.id) return
  if (userDetails?.usercurrency) {
      const body = {
          userId: userDetails.id,
          currency: item.code,
          image: item.icon,
      }
      try {
          const res =  postAPIAuth("user/save-user-currency", body);
            const response =  fetchUserDetails(token)
              dispatch({ type: reducerConst.USER_DETAILS, payload: response?.data?.data ? response.data.data : {} })
             handleClose()
        } catch (error) {

      }
  }


}
useEffect(()=>{
  getCurrency();
},[showFiatModal])
  return (
    <>
      <Modal show={showFiatModal} className='viewInFiatModal' centered onHide={handleClose}>
        <Modal.Header>
          <div className="row w-100 mx-0 g-0">
            <div className="col modalTitle">
              Information
            </div>
            <div className="col-auto">
              <img className='closeBtn' onClick={handleClose} src="assets/img/sidebar/close.png" alt="" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row g-3">
            <div className="col-12 msg">
             Please note that these are currency approximations. All bets & transactions will be calculated in equivalent currencies. For more details feel free to contact our live support.
            </div>
            {
              currency?.map((item)=>(
                <div className="col-4" key={item.id}>
                  <input type="radio" className='d-none fiatSelInp' name='fiat' id={`fiat-${item.id}`} onChange={()=>handleChange(item)}
                  checked={item.code===userDetails.usercurrency}
                  />
                  <label htmlFor={`fiat-${item.id}`} className='fiatSelLabel'>
                    <div className="checkDiv"></div>
                    <div className="Txt">{item.code}</div>
                    <div className="imgDiv d-flex justify-content-end">
                      <img src={item.icon} alt="" />
                    </div>
                  </label>
                </div>
              ))
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default FiatModal