import React, { useState } from 'react'

const TokenTable = () => {
  const [activeTableIndex, setActiveTableIndex] = useState(0)

  console.log('activeTableIndex', activeTableIndex)

  const handleNext = () => {
    console.log('next')
    if (activeTableIndex === 3) {
      setActiveTableIndex(0)
    } else {
      setActiveTableIndex(activeTableIndex + 1)
    }
  }

  const handlePrev = () => {
    if (activeTableIndex === 0) {
      setActiveTableIndex(3)
    } else {
      setActiveTableIndex(activeTableIndex - 1)
    }
  }

  return (
    <>
      <div className="row g-3">
        <div className="col-lg-9">
          <div className="tokenTableWrapper">
            <table className="table tokenTable mb-0">
              <thead>
                <tr>
                  <th>Token/Est.APR</th>
                  <th>
                    <span className="upperDiv"></span>
                    <img className='tokenTableHeadImg' src="./assets/img/stacking/appLogo.png" alt="" />
                  </th>
                  <th className={`mobHiddenTd ${activeTableIndex === 0 ? 'active' : ''}`}>
                    <div className="navBtns">
                      <img onClick={handlePrev} src="./assets/img/stacking/tokenPrev.png" alt="" />
                      <img onClick={handleNext} src="./assets/img/stacking/tokenNext.png" alt="" />
                    </div>
                    <img className='tokenTableHeadImg' src="./assets/img/stacking/cex.png" alt="" />
                  </th>
                  <th className={`mobScrollTh mobHiddenTd ${activeTableIndex === 1 ? 'active' : ''}`}>
                    <div className="navBtns">
                      <img onClick={handlePrev} src="./assets/img/stacking/tokenPrev.png" alt="" />
                      <img onClick={handleNext} src="./assets/img/stacking/tokenNext.png" alt="" />
                    </div>
                    <img className='tokenTableHeadImg small' src="./assets/img/stacking/cexGreen.png" alt="" />
                  </th>
                  <th className={`mobHiddenTd ${activeTableIndex === 2 ? 'active' : ''}`}>

                    <div className="navBtns">
                      <img onClick={handlePrev} src="./assets/img/stacking/tokenPrev.png" alt="" />
                      <img onClick={handleNext} src="./assets/img/stacking/tokenNext.png" alt="" />
                    </div>
                    <img className='tokenTableHeadImg' src="./assets/img/stacking/cexBlue.png" alt="" />
                  </th>
                  <th className={`mobHiddenTd ${activeTableIndex === 3 ? 'active' : ''}`}>

                    <div className="navBtns">
                      <img onClick={handlePrev} src="./assets/img/stacking/tokenPrev.png" alt="" />
                      <img onClick={handleNext} src="./assets/img/stacking/tokenNext.png" alt="" />
                    </div>
                    <img className='tokenTableHeadImg' src="./assets/img/stacking/brandx.png" alt="" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* {[1,1,1,1]?.map(item=>( */}
                <tr>
                  <td>
                    <div className="d-flex align-items-center tableTokenBodyImg">
                      <img src="./assets/img/stacking/table04.png" alt="" />
                      BNB
                    </div>
                  </td>
                  <td>60%</td>
                  <td className={`mobHiddenTd ${activeTableIndex === 0 ? 'active' : ''}`}>7.7%</td>
                  <td className={`mobHiddenTd ${activeTableIndex === 1 ? 'active' : ''}`}>7.7%</td>
                  <td className={`mobHiddenTd ${activeTableIndex === 2 ? 'active' : ''}`}>7.7%</td>
                  <td className={`mobHiddenTd ${activeTableIndex === 3 ? 'active' : ''}`}>7.7%</td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center tableTokenBodyImg">
                      <img src="./assets/img/stacking/table01.png" alt="" />
                      USDT
                    </div>
                  </td>
                  <td>60%</td>
                  <td className='mobHiddenTd active'>0.3%</td>
                  <td className='mobHiddenTd'>0.3%</td>
                  <td className='mobHiddenTd'>0.3%</td>
                  <td className='mobHiddenTd'>0.3%</td>
                </tr><tr>
                  <td>
                    <div className="d-flex align-items-center tableTokenBodyImg">
                      <img src="./assets/img/stacking/table03.png" alt="" />
                      ETH
                    </div>
                  </td>
                  <td>60%</td>
                  <td className='mobHiddenTd active'>0.3%</td>
                  <td className='mobHiddenTd'>0.3%</td>
                  <td className='mobHiddenTd'>0.3%</td>
                  <td className='mobHiddenTd'>0.3%</td>
                </tr><tr>
                  <td>
                    <div className="d-flex align-items-center tableTokenBodyImg">
                      <img src="./assets/img/stacking/table02.png" alt="" />
                      BTC
                    </div>
                  </td>
                  <td>60%</td>
                  <td className='mobHiddenTd active'>1.1%</td>
                  <td className='mobHiddenTd'>1.1%</td>
                  <td className='mobHiddenTd'>1.1%</td>
                  <td className='mobHiddenTd'>1.1%</td>
                </tr>
                {/* ))} */}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="tokenBanner d-none d-lg-block">
            <img className='w-100 ' src="./assets/img/stacking/tokenTableTxt.png" alt="" />
            <img className='speaker' src="./assets/img/stacking/tokenTableSpeaker.png" alt="" />
            <img className='coin' src="./assets/img/stacking/tokenTableCoin.png" alt="" />
          </div>
          <div className="tokenBannerMob d-lg-none">
            <img className='mobBanner w-100' src="./assets/img/stacking/tokenTableMobile.png" alt="" />
          </div>
        </div>
        <div className="col-12">
          <div className="sText">* Please, refer to the APR details on the popup section for each specific token.</div>
        </div>
      </div>
    </>
  )
}

export default TokenTable