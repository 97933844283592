import React, { useState } from "react";
import { WALLET_MODAL_TABS } from "../constants";

export const WalletModalContext = React.createContext(null)

const WalletModalContextProvider = ({ children }) => {
    const [walletModalObject, setWalletModalObject] = useState({
        isWalletOpen: false,
        selectedTab: WALLET_MODAL_TABS.DEPOSIT,
        freeSpinCheck: false
    })
    return <WalletModalContext.Provider value={{ walletModalObject, setWalletModalObject }}>{children}</WalletModalContext.Provider>;
};

export default WalletModalContextProvider;
