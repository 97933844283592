import React from "react";
import AppFooter from "../../components/AppFooter/AppFooter";
import OurPartnerCard from "./components/OurPartnerCard";
import { getAPIAuth } from "../../service/apiInstance";
import { useState, useEffect } from "react";

const OurPartners = () => {
    const [getData, setGetData] = useState('')

    const getPartnerApi = async () => {
        try {
            const res = await getAPIAuth('get-PartnersData')
            setGetData(res.data?.data)
            
        } catch (error) {
            
        }
    }
    useEffect(() => {
        getPartnerApi()
    }, [])
    return (
        <main className="main vh-100" id="main">
            <div className="mainContaint">
                <div className="homepage">
                    <div className="sponsorshipPage">
                        <div className="container-fluid sponsorshipPageFluid">
                            <div className="row">
                                {
                                    Array.isArray(getData) && getData?.map((item, index) => (
                                        <OurPartnerCard cardInfo={item} index={index} key={item?.id} />
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  **** Footer section *** */}
            <AppFooter />
        </main>

    );
};

export default OurPartners;
