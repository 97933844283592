import React from "react";

const ErrorMessage = ({ children }) => {
  return (
    <span className="text-danger mt-1"
      style={{ display: "flex", width: "100%", fontSize: "12px", fontWeight: 500 }}
    >
      {children}
    </span>
  );
};

export default ErrorMessage;
