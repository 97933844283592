import React, { useEffect, useState } from "react";
import { auth, provider } from "../../config/firebase";
import { signInWithPopup } from "firebase/auth";
import "firebase/compat/auth";
import { postAPI } from "../../service/apiInstance";
import { errorToaster, succesToaster } from "../../utils/toaster";
import { useAuth } from "../../hooks/useAuth";
import { useAuthModal } from "../../hooks/useAuthModal";
import { useSelector } from "react-redux";
import { USER_LANG } from "../../constants";
import { useReferralModal } from "../../hooks/useReferralModal";
import { useBtAuth } from "../../hooks/useBtAuth";
import { useSpinModal } from "../../hooks/useSpinModal";
import { useSearchParams } from "react-router-dom";
import { getCustomAuthError } from "../../utils/customFirebaseError";

export function GoogleLogin({
  children,
  parent,
  checks,
  hero,
  click_id,
  new_click_id,
  bonus
}) {
  const [value, setValue] = useState("");
  const { setToken } = useAuth()
  const { setAuthModalObject } = useAuthModal()
  const ipAddress = useSelector(state => state.ipAddress)
  const country = useSelector(state => state.country)
  const { isReferral, setIsReferral, isReferralInput, setIsReferralInput } = useReferralModal()
  const { setBtToken } = useBtAuth()
  const { setSpinModalObject } = useSpinModal()
  const [query, setQuery] = useSearchParams()


  console.log('checks', checks)

  // for registration
  const handleRegister = async () => {
    const data = await signInWithPopup(auth, provider)
    const lang = localStorage.getItem(USER_LANG)

    const body = {
      email: data._tokenResponse.email,
      idToken: data._tokenResponse.idToken,
      localId: data._tokenResponse.localId,
      refreshToken: data._tokenResponse.refreshToken,
      loginMethod: "google",
      ipAddress: ipAddress ? ipAddress : "",
      country: country ? country : "",
      // language: lang?.symbol
      language: lang,
      trackingToken: click_id ? click_id : null,
      clickid : !!new_click_id ? new_click_id : null,
      bonus: !!bonus ? bonus.bonus : false
    }
    const res = await postAPI("user/social-authentication", body);
    if (res?.data?.success) {
      succesToaster("user register successful!")
      setToken(res.data.data.token)
      setAuthModalObject(pre => ({ ...pre, isAuthOpen: false }))
      // setIsReferral(true)
      // setIsReferralInput(true)
      setBtToken(res?.data?.data?.betByToken)
      localStorage.removeItem('clickid')
      setSpinModalObject(prev => ({
        ...prev,
        isModalOpen: true
      }))
      window?.dataLayer?.push({
        'event': 'GA4_event',
        'event_name': 'game_sign_up'
      });
      setQuery({action:""})
    } else {
      errorToaster("something went wrong")
    }
    setValue(data.user.email);
    localStorage.setItem("email", data.user.email);

  };

  // for Logging in
  const handleLogin = async () => {
    try {
      const data = await signInWithPopup(auth, provider)
      // const lang = JSON.parse(localStorage.getItem(USER_LANG))
      const lang = localStorage.getItem(USER_LANG)
      const body = {
        email: data._tokenResponse.email,
        idToken: data._tokenResponse.idToken,
        localId: data._tokenResponse.localId,
        refreshToken: data._tokenResponse.refreshToken,
        loginMethod: "google",
        ipAddress: ipAddress ? ipAddress : "",
        country: country ? country : "",
        // language: lang?.symbol
        language: lang
      }
      const res = await postAPI("user/social-authentication", body);
      if (res.data.success) {
        succesToaster("login successful!")
        setToken(res.data.data.token)
        setAuthModalObject(pre => ({ ...pre, isAuthOpen: false }))
        setBtToken(res?.data?.data?.betByToken)
        localStorage.removeItem('clickid')
      setQuery({action:""})
      } else {
        errorToaster("something went wrong")
      }
      setValue(data.user.email);
      localStorage.setItem("email", data.user.email);
    } catch (error) {
      // errorToaster(error.message)
      errorToaster(getCustomAuthError(error))
    }


  };

  const handleCall = () => {
    if (parent == 'signup') {

      handleRegister()
    } else if (parent == 'signin') {
      handleLogin()
    }
  }

  useEffect(() => {
    setValue(localStorage.getItem("email"));
  }, []);

  return (
    <div className="col"
      onClick={() => {
        if (parent == "signin") {
          handleCall()
          return
        } else {
          if (!checks?.terms) {
            errorToaster("Please read and tick the bottom agreement");
            return
          }
        }
        handleCall()
      }}
    >
      {children}
    </div>
  )

  // return (
  //   <>
  //     {
  //       hero ? (
  //         <div className='joinAnchor'
  //           // onClick={() => {
  //           //   if (hero) {
  //           //     handleCall()
  //           //   }
  //           // }}
  //           onClick={() => {
  //             // if (parent == "signIn") {
  //             // handleCall()
  //             // } else {
  //             if (!checks.terms) {
  //               // errorToaster()
  //               errorToaster("Please read and tick the bottom agreement");
  //               return
  //             }
  //             // }
  //             handleCall()
  //           }}
  //         >
  //           {children}
  //         </div>
  //       ) : (
  //         <>
  //           <div className="authIcon d-flex align-items-center justify-content-center">
  //             <img src="assets/img/auth/google.png" alt="" />
  //           </div>
  //           <div
  //             onClick={() => {
  //               if (parent == "signIn") {
  //                 handleCall()
  //               } else {
  //                 if (!checks.terms) {
  //                   // errorToaster()
  //                   errorToaster("Please read and tick the bottom agreement");
  //                   return
  //                 }
  //               }
  //               handleCall()
  //             }}
  //             className="authTxt d-flex align-items-center justify-content-center rounded-pill"
  //           >
  //             {children}
  //           </div>
  //         </>
  //       )
  //     }

  //   </>
  // )
}

