import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';

const AboutStackingTypesModal = ({ modalObject, setModalObject }) => {
    const handleClose = () => {
        setModalObject(prev => ({
            ...prev,
            show: false,
            type: ''
        }))
    };

    const handleTabClick = (type) => {
        setModalObject(prev => ({
            ...prev,
            type
        }))
    }

    return (
        <>
            <Modal
                className='firstCryptoModal secondCryptoModal aboutStackingModal'
                show={modalObject?.show}
                onHide={handleClose}
                centered
                scrollable
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header className='modalHeader d-flex justify-content-between align-items-center'>
                    <Modal.Title className='modalTitle'>About Staking types</Modal.Title>
                    <div className="closeBTn cursor-pointer" onClick={handleClose}>
                        <svg className='size-6 text-white' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path></svg>
                    </div>
                </Modal.Header>
                <Modal.Body className='modalBody' style={{ background: '#151D20 ' }}>
                    <div className="row">
                        <div className="col-12">
                            <div className="tabs">
                                <div className="tab-buttons">
                                    <button className={modalObject?.type === 'flexible' ? 'active' : 'tabBtn'} onClick={() => handleTabClick('flexible')}>Flexible</button>
                                    <button className={modalObject?.type === 'fixedTerm' ? 'active' : 'tabBtn'} onClick={() => handleTabClick('fixedTerm')}>Fixed-Term</button>
                                    <button className={modalObject?.type === 'boostedFixedTerm' ? 'active' : 'tabBtn'} onClick={() => handleTabClick('boostedFixedTerm')}>Boosted Fixed-Term</button>
                                    <button className={modalObject?.type === 'exclusive' ? 'active' : 'tabBtn'} onClick={() => handleTabClick('exclusive')}>Exclusive</button>
                                </div>
                                <div className="tab-content">
                                    {modalObject?.type === 'flexible' && <div className='text-white'>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="contentBoxHeading"><img className='me-2' src="./assets/img/stacking/flexibleIcon.png" alt="" />Flexible</div>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <div className="textlight">
                                                    <span>Flexible Staking is a hassle-free way of enjoying higher-than-average APRs without the need to lock your funds on the platform.</span> Everything you need to do is simply deposit your funds on your game balance, approve your preferred tokens and start earning extra profit on your preferred crypto. With this type of staking your assets remain at your full disposal, providing you with the ultimate flexibility, high APRs and full control over your assets.
                                                    <div className="textlight">
                                                        With fixed-term staking models, you’re often required to commit your assets for extended periods, restricting the ability to access or use those funds. However, flexible staking turns the tables by offering
                                                    </div>
                                                    <div className="textlight mb-3"><span>
                                                        competitive APRs while allowing you to retain full ownership of your cryptocurrencies.</span></div>

                                                    <div className="textlight mb-3"> This approach caters to individuals seeking to maximize their crypto investments without compromising liquidity. Whether you're a seasoned investor or just stepping into the world of crypto, flexible staking opens doors to <span>secure, risk-free, and rewarding opportunities.</span> It empowers you to seize market opportunities, diversify your holdings, and make timely decisions without being tied down by locked assets.</div>

                                                    <div className="textlight">
                                                        Flexible staking presents <span>a dynamic solution that aligns with the modern investor's needs.</span> Your funds are always within reach, ready for betting, trading, withdrawing, or reinvesting as you see fit. It's a game-changer, bringing the best of both worlds: impressive returns and financial freedom, all in one place.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {modalObject?.type === 'fixedTerm' && <div className='text-white'>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="contentBoxHeading"><img className='me-2' src="./assets/img/stacking/fixedTermIcon.png" alt="" />Fixed-Term</div>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <div className="textlight">
                                                    <span>
                                                        Fixed-Term staking provides higher rewards in exchange for your assets being committed for fixed terms with predetermined redemption dates. </span>
                                                </div>
                                                <div className="textlight mb-3">
                                                    We understand that life is unpredictable, and financial needs can change, hence, you can still opt for early redemption provided that minimum platform requirements are met.
                                                </div>
                                                <div className="textlight mb-3">
                                                    This powerful tool offers substantially higher APRs compared to flexible options. While it requires locking your funds for a set period, it rewards you with APRs that are truly unparalleled in the crypto space.
                                                </div>
                                                <div className="textlight mb-3">
                                                    <span>
                                                        With options ranging from 30, 60, 90, 180, to 365 days, you have the flexibility to choose the term that aligns best with your financial goals and risk tolerance.  </span>
                                                </div>
                                                <div className="textlight mb-3">
                                                    The longer the term, the higher the potential APR, allowing you to strategically plan and optimize your returns.
                                                </div>
                                                <div className="textlight mb-3">
                                                    Fixed-term staking is not only about financial rewards but also about stability and predictability. It provides a safe and reliable way to make your crypto work for you while securing higher returns than what the market typically offers. This is your opportunity to earn confidently and strategically, with the flexibility to adapt as your financial situation evolves.
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {modalObject?.type === 'boostedFixedTerm' && <div className='text-white'>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="contentBoxHeading"><img className='me-2' src="./assets/img/stacking/boostedIcon.png" alt="" />Boosted Fixed-Term</div>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <div className="textlight mb-3">
                                                    Our enhanced fixed-term pools offer an exciting opportunity to boost your earnings through the perfect blend of higher APRs, fixed-term commitment, and gaming strategy.
                                                </div>
                                                <div className="textlight mb-3">
                                                    <span>
                                                        Everything that needs to be done to receive incredibly high APRs is to meet the minimum wager requirements. </span>
                                                </div>
                                                <div className="textlight mb-3">
                                                    With options spanning 30, 60, 90, 180, and 365 days, you can choose the term that suits your financial goals. What sets this apart is the potential for incredibly high APRs,<span> surpassing the industry's standard,</span>
                                                </div>
                                                <div className="textlight mb-3">
                                                    provided you meet the minimum wager requirements. <span>
                                                        The magic happens when your gaming strategy aligns with your staking period. </span>
                                                </div>
                                                <div className="textlight">
                                                    By engaging in our platform and meeting the minimum wagering requirements, you unlock the door to remarkable APRs that outshine the competition. This unique feature empowers you to capitalize on your crypto investments like never before.
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {modalObject?.type === 'exclusive' && <div className='text-white'>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="contentBoxHeading"><img className='me-2' src="./assets/img/stacking/exclusive.png" alt="" />About Staking types</div>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <div className="textlight mb-3">
                                                    Exclusive pools offer distinguished opportunities for our discerning users. These limited-time pools are designed to elevate your crypto investment strategy to unprecedented heights.
                                                </div>
                                                <div className="textlight mb-3">
                                                    Discover our Exclusive Staking Pools, offering sky-high APRs that surpass industry standards for maximized returns.
                                                </div>
                                                <div className="textlight mb-3"><span>
                                                    With a limited-time engagement, curated tokens</span>, and options for both effortless access and exclusive rewards, redefine your crypto investment with precision, finesse, and unmatched advantages.
                                                </div>
                                                <div className="textlight">
                                                    <span className=''>Divergent Paths to Exclusive Rewards: </span>
                                                    <ul className='m-0 mt-3'>
                                                        <li>
                                                            Open Access Pools: A frictionless entry into the world of strategic staking, devoid of restrictive prerequisites.
                                                        </li>
                                                        <li>
                                                            Elite Staking Enclaves: For the connoisseurs of exclusivity, our Elite Pools present entry barriers in the form of wager requirements and a certain amount of DGW tokens on the balance
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="textlight">
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='modalFooter'>
                    <div className="row w-100">
                        <div className="col-6"><Link to='' className='termsLink'>Terms and Conditions <svg className='' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path></svg></Link></div>
                        <div className="col-6"><button className='closeBtn' onClick={handleClose}>Close</button></div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AboutStackingTypesModal