import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getAPIAuth } from '../../../service/apiInstance';
import { useAuth } from '../../../hooks/useAuth';



const CalendarModal = ({ showModal, setShowModal }) => {
    const { t } = useTranslation()
    const VIPClubLabels = t("VIPClubLabels", { returnObjects: true })
    const commonText = t('commonText', { returnObjects: true })
    const userBonusDetail = useSelector(state => state.userBonusDetail)
    const [dailyBonus, setDailyBonus] = useState([])
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const currentDate = new Date();
    const {token} = useAuth()

    useEffect(()=> {
        // Get the first day of the current month
        const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        setStartDate(firstDayOfCurrentMonth)

        // Get the last day of the current month
        const lastDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        setEndDate(lastDayOfCurrentMonth)
    }, [])

    const handlePrevMonth = ()=> {
        const firstDayOfPreviousMonths = new Date(startDate);
        firstDayOfPreviousMonths.setMonth(startDate.getMonth() - 1);
        firstDayOfPreviousMonths.setDate(1);
        setStartDate(firstDayOfPreviousMonths)

        const lastDayOfPreviousMonths = new Date(startDate);
        lastDayOfPreviousMonths.setDate(0);
        setEndDate(lastDayOfPreviousMonths)
    }

    const handleNextMonth = ()=> {
        const firstDayOfPreviousMonths = new Date(startDate);
        firstDayOfPreviousMonths.setMonth(startDate.getMonth() + 1);
        firstDayOfPreviousMonths.setDate(1);
        setStartDate(firstDayOfPreviousMonths)

        const lastDayOfPreviousMonths = new Date(firstDayOfPreviousMonths);
        lastDayOfPreviousMonths.setMonth(firstDayOfPreviousMonths.getMonth() + 1, 0);
        setEndDate(lastDayOfPreviousMonths)
    }


const getDailyBonus = async () => {
    if(startDate && endDate) {
        try {
            // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws'
            const res = await getAPIAuth(`user/getCalenderBonus?startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`, token)
            if (res?.data?.success) {
                setDailyBonus(res?.data?.data)
            }
        } catch (error) {

        }
    }
}

useEffect(()=> {
    getDailyBonus()
},[startDate, endDate])


const checkIsCurrentDate = (date)=> {
    const itemDate = new Date(date)
    if(itemDate?.getDate() === currentDate?.getDate() && itemDate?.getMonth() === currentDate?.getMonth() && itemDate?.getFullYear() === currentDate?.getFullYear()) {
        return true
    }
    return false
}

    return (
        <>
            <div
                className={`modal fade calendarmodal ${showModal ? "show" : ""}`}
                style={{
                    display: showModal ? "block" : "none"
                }}
            >
                <div className="modal-dialog mx-auto calendardialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content calendarcontent">
                        <div className="modal-header border-0">
                            <div className="modal-title" id="exampleModalLabel">{VIPClubLabels.PENDING_REWARDS}: <span>$0.00</span></div>
                            <div className="d-flex align-items-center gap-sm-3 gap-1">
                                <div className="d-flex align-items-center btngroup">
                                    <button onClick={handlePrevMonth} className="sliderbtn border-0 d-flex align-items-center justify-content-center sliderprev">
                                        <span className="sliderimg d-flex align-items-center justify-content-center">
                                            <img src="assets/img/affiliatenew/leftarrow.png" alt="rightarrow" className="h-100 w-100" />
                                        </span>
                                    </button>
                                    <button onClick={handleNextMonth} className="sliderbtn border-0 d-flex align-items-center justify-content-center slidenext">
                                        <span className="sliderimg d-flex align-items-center justify-content-center">
                                            <img src="assets/img/affiliatenew/rightarrow.png" alt="rightarrow" className="h-100 w-100" />
                                        </span>
                                    </button>
                                </div>
                                <button onClick={() => setShowModal(false)} type="button" className="btn-close closebtn shadow-none d-flex align-items-center justify-content-center" data-bs-dismiss="modal" aria-label="Close">
                                    <img src="assets/img/mohitModel/close.png" alt="close" className="h-100 w-100 img-fluid" />
                                </button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="row calendarcardmain g-md-3 g-2">
                                {
                                    dailyBonus?.length ? 
                                    dailyBonus?.map((item, index)=> (
                                        <>
                                            <div className="col"
                                                key={item?.date + index}>

                                                <div className={`cards1 h-100 ${checkIsCurrentDate(item?.date) ? 'active' : ''}`}>
                                                    <div className="row flex-column h-100">
                                                        <div className="col-12 pb-2">
                                                            <div className="date">{moment(item?.date).format("MMM DD")}</div>
                                                        </div>
                                                        <div className={`col-12 py-2 py-sm-3 d-flex align-items-center justify-content-center  ${item?.dailyBonus != '0' ? "" : "invisible"}`}>
                                                            <div className="icons dollorIcon"><img src="assets/img/vipPage/dollor.png" alt /></div>
                                                        </div>
                                                        {
                                                            item?.dailyBonus != "0" ? (
                                                                <div className="col-12">
                                                                    <div className="dollor activeDollar">
                                                                        $ {
                                                                            !isNaN(Number(item?.dailyBonus)) ? Number(item?.dailyBonus).toFixed(2) : '0.00'
                                                                        }
                                                                    </div></div>
                                                            ) : (
                                                                <>
                                                                    <div className="col-12"><div className="dollor">
                                                                        $0.00</div></div>
                                                                    <div className="renew flex-fill d-flex justify-content-center align-items-end">No Reward</div>
                                                                </>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))
                                    : 'no data'
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default CalendarModal