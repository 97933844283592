import React, { useEffect } from 'react'
import { useState } from 'react'
import { getAPIAuth } from '../../../service/apiInstance';
import DepositTable from './newTransactionComponent/DepositTable';
import WithdrawTabel from './newTransactionComponent/WithdrawTabel';

const filterBetsOptions = [
  {
    label: "Deposit",
    value: "deposit",
  },
  {
    label: "Withdraw",
    value: "withdraw",
  },
];

const NewTransactions = () => {
  const [selectedBet, setSelectedBet] = useState('deposit')
  const [listLoader, setListLoader] = useState(true)
  const [cryptoList, setCryptoList] = useState([])
  const [activeCrypto, setActiveCrypto] = useState('ALL')


    // getting all crypto id
    const getCryptoList = async () => {
      setListLoader(true)
      try {
        const res = await getAPIAuth(`crypto/get-crypt-favorite-by-admin`)
        if (res?.data?.success) {
          setCryptoList(res?.data?.data)
          setActiveCrypto("ALL")
        }
      } catch (error) {
  
      } finally {
        setListLoader(false)
      }
    }
  
    useEffect(() => {
      getCryptoList()
    }, [])

  return (
    <>
    <div className="row">
      <div className="col-6 col-md-4">
        <div className="dropdown reportsDropdown">
          <button className="btn btn-secondary marketBtn dropdown-toggle dropdownToggle text-capitalize" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {selectedBet ? selectedBet : 'Deposit'}
          </button>
          <ul className="dropdown-menu dropdownMenu w-100" >
            {
              filterBetsOptions?.map((item) => (
                <li className={`menuLinkDrop ${item?.label === selectedBet ? 'active' : ''}`}
                  onClick={() => setSelectedBet(item?.label?.toLowerCase() === "all" ? '' : item?.label?.toLowerCase())}
                  key={item?.label}
                >{item?.label}
                </li>
              ))
            }
          </ul>
        </div>
      </div>
      <div className="col-6 col-md-4">
        <div className="dropdown reportsDropdown">
          <button className="btn btn-secondary marketBtn dropdown-toggle dropdownToggle text-uppercase" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {activeCrypto ? activeCrypto : 'All'}
          </button>
          <ul className="dropdown-menu dropdownMenu w-100" >
              <li className={`menuLinkDrop ${'ALL' === activeCrypto ? 'active' : ''}`}
                  onClick={() => setActiveCrypto('ALL')}
                >ALL
                </li>
            {
              cryptoList?.map((item) => (
                <li className={`menuLinkDrop text-uppercase ${item?.code === activeCrypto ? 'active' : ''}`}
                  onClick={() => setActiveCrypto(item?.code?.toLowerCase() === "All" ? '' : item?.code?.toLowerCase())}
                  key={item?.code}
                >{item?.code}
                </li>
              ))
            }
          </ul>
        </div>
      </div>
      <div className="col-12">
        {
          selectedBet === 'deposit' ? <DepositTable activeTab={selectedBet} activeCrypto={activeCrypto}/> : 
          selectedBet === 'withdraw' ? <WithdrawTabel activeTab={selectedBet} activeCrypto={activeCrypto}/> : ''
        }
      </div>
    </div>
    </>
  )
}

export default NewTransactions