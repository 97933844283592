import React from 'react'
import AppFooter from '../../components/AppFooter/AppFooter'
import { APP_LEGAL_MAIL } from '../../constants'

const SportsPolicy = () => {
return (
<>
    <main className="main vh-100" id="main">
        <div className="mainContaint">
            <div className="sportpolicypage">
                <div className="container-fluid sportpolicySection">
                    <div className="row gap-2" >
             

                        <div className="col-12 sportheading">
                            SPORTS POLICY
                        </div>
                        <div className="col-12 policydate ">
                            Last updated: August, 08 2023.
                        </div>
                        <div className="col-12 sportsubheading introducation mt-0">
                            BASIC TERMS AND DEFINITIONS 
                        </div>
                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail"><strong>Client (bettor)</strong> is a betting participant who entered into an agreement with a Betting company.</li>
                                <li className="sportdetail"><strong>Bet</strong> is an agreement concluded between the Client and the Betting company on accepting bets from the participants of the agreement on the terms offered in the line of the Betting company. According to the terms of the bet, a losing party shall fulfill its obligation.</li>
                                <li className="sportdetail"><strong>Line </strong> is a list of events offered by the Betting company for betting on their outcomes that have certain winning odds.                                </li>
                                <li className="sportdetail"><strong>Bet </strong> is a cash deposit used to for wagering. </li>
                                <li className="sportdetail"><strong>Outcome </strong>  is the result of an event on which the Client made a bet with a Betting company.</li>
                                <li className="sportdetail"><strong>Winning odds </strong>  means quotes of various event outcomes offered by the Betting company.  Teams in the betting line are labeled "Team 1" (indicated by "1") and "Team 2" (indicated by "2"). At the same time, details of the event venue are for information purposes only. </li>
                            </ul>
                        </div>
                        <div className="col-12 sportsubheading">GENERAL TERMS</div>
                        <div className="col-12 sportdetail">The main activity of the Betting company is to accept bets on sports competitions and other events, such as public, political, entertainment, or any other (hereinafter referred to as the "Events"). All Bids are accepted in strict accordance with these rules, which is a confirmation that the Client (bettor) is familiar with and fully agrees with the current rules. </div>
                        <div className="col-12 sportdetail">The Betting company has the right to amend the above provisions, rules, and procedures of the payments without prior notice to the Clients. In this case, all subsequent bets are accepted in accordance with the amendments to the rules, and the terms of previously made bets remain unchanged.</div>
                        <div className="col-12 sportdetail">Making bets are allowed only Clients of full age, who have full responsibility for the legality of gambling via the Internet in their country of residence and for providing information about wins or losses to the fiscal or law enforcement agencies of their region or country.
                         </div>
                        <div className="col-12 sportdetail">The Betting company guarantees non-disclosure of the Client's confidential and privileged information by its employees. It is not responsible for the consequences if the Client's confidential and privileged information gets to third parties.</div>
                        <div className="col-12 sportdetail">The Betting company cannot be held liable for any loss or damage claimed as incurred due to the content of the website of the bookmaker's company or its use.</div>
                        <div className="col-12 sportdetail">This provision applies equally to the Client's misuse of the content of the website of the bookmaker's company, or inability to connect to or use the website, any errors, typos or omissions in the content of the website, as well as delays in its operation or data transmission due to technical failures.
                            In relation to the general rules, priority is given to sport or competition rules.
                            </div>
                        <div className="col-12 sportdetail">The Betting company initially makes every effort to ensure that the content of the website is correct but due to various circumstances emphasizes that data on the current sports and other events is for information purposes only.  The Betting company is not liable for possible inaccuracies in the current score or time of the match, calling to use alternative sources of information on an ongoing basis.
                        </div>
                        <div className="col-12 sportdetail">Rule regarding the prohibition for users from Russia and Belarus to use the service, and in case of detection, their accounts will be immediately closed:</div>


                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">Users residing in the Russian Federation or the Republic of Belarus are strictly prohibited from using our service.</li>
                                <li className="sportdetail">In the event of detecting the use of our service by users from Russia or Belarus, we reserve the right to immediately close their accounts without prior notice.
                                </li>
                                <li className="sportdetail">Account closure will result in the termination of all associated privileges, including access to the service, storage of data, and utilization of functional features.      </li>
                                <li className="sportdetail">We disclaim any liability for any losses, damages, or consequences arising from the violation of this rule and the use of the service by users from Russia or Belarus. </li>
                                <li className="sportdetail">All information collected from users in Russia and Belarus will be promptly deleted in accordance with our privacy policy.
                                </li>
                              
                            </ul>
                        </div>
                      

                        <div className="col-12 sportsubheading">TYPES OF BETS</div>
                        <div className="col-12 sportdetail">The Betting company offers the following betting options for sports and/or other events:</div>
                        <div className="col-12 sportdetail"><strong>"Ordinar bet"</strong>  means a bid on the outcome of an single event. The ordinar bet win is the product of the sum of bet and odds of the event.</div>
                        <div className="col-12 sportdetail"> <strong>"Combo"</strong> means a bet on several independent events. The "Combo" win is the product of the sum of bet by "Combo" odds, received by multiplying the odds of the outcomes of all events included in the "Combo". The "Combo" may include any combination of outcomes of unrelated events from any sports or other events. If all events are correctly predicted, the "Combo" is considered won. At least one incorrectly predicted event outcome means that the entire bet of this option is lost. The maximum odds value for "Combo" bets is 10 000.  There shall be at least two events in one "Combo". </div>
                        <div className="col-12 sportdetail">It is forbidden to include different outcomes in the same event, as well as different bets on the same athlete in the same tournament (for example, winning a match and winning a tournament) in one "Combo", even if bets and events are not directly related. If any events related to this match were included in the "Combo", then such a bet is subject to refund unless the software  for accepting bets initially blocked this "Combo". Taking into account the peculiarities of determining odds for certain types of outcomes (correctly predicted totals, handicaps, etc.), the final winning odds for all the above types of bets are determined. In case of cancellation or postponement of events for the period specified in these rules, odds equal to "1" are used to calculate such outcomes.
                        </div>
                 
                      
                 
                        <div className="col-12 sportsubheading">MAIN TYPES OF OUTCOMES OFFERED FOR BETTING</div>
                        <div className="col-12 sportdetail">Bets are accepted on <strong>To Win To Nil (Team or athlete 1)"</strong> " (indicated in the line by <strong>"1"</strong> ), a draw (indicated by <strong> "X"</strong>), or <strong>"To Win To Nil (Team or athlete  2)" </strong> (indicated by <strong>"2"</strong> ). If the outcome of the match was predicted correctly, the bet is considered won.
                        </div>
                        <div className="col-12 sportdetail"><strong> "Double Chance (Team or athlete 1)" or a draw </strong>  is indicated by <strong>"1X"</strong> . To win a bet on such an outcome, the first team or athlete shall not fail. <strong>"Double Chance" (victory of the first or second team or athlete )</strong>  is indicated by the combination "12". A victory of one of the teams or athletes is necessary to win a bet with such an outcome. Double Chance (Team or athlete 2) or a draw is indicated by the combination <strong>"X2"</strong> . To win a bet on such an outcome, the second team or athlete shall not fail.
                        </div>
                        <div className="col-12 sportdetail"><strong>Participant results</strong>  In such bets, it is necessary to predict whether the team or athlete will reach a certain stage of the competition (for example, 1/8, quarter-finals, semi-finals, finals, etc.), or what place he will take in the tournament or group. If the team or athlete declared in the tournament did not participate in the competition for some reason, then all bets on events with his participation are calculated with odds equal to "1".
                        </div>
                        <div className="col-12 sportdetail"><strong>"Who Will Go On". </strong> In the proposed pairs, it is recommended to name the team or athlete, who will go further in the tournament grid. The best will be considered the team or athlete who has gone further in the tournament grid. If both teams or athletes were eliminated from the competition at the same stage, or if one of the teams or athletes refused to participate in the competition before it began, winning odds for such bets are equal to "1". </div>
                        <div className="col-12 sportdetail"><strong>"Winner".</strong> The team or athlete who takes first place in the tournament, or a participant in other non-sports events, who wins first place, is considered the winner. If a participant withdraws from the competition before the start of the tournament, winning odds for bets are considered to be equal to "1".
                        </div>
                        <div className="col-12 sportdetail">The <strong>"First Blood"</strong>  bet involves killing the game character by the characters of the opposing team. The first kill on the map by the opponent's creeps/minions (without the help of allied characters) or neutral units is not counted. In this case, the bet remains valid until the first game character is killed by the opposing team.
                        </div>
                        <div className="col-12 sportdetail">The <strong>"First Roshan"</strong>  bet means that the Client shall correctly predict which team will kill Roshan first.
                        </div>
                        <div className="col-12 sportdetail">The <strong>"Round Duration"</strong>  bet means that the Client shall correctly predict the duration of the round in minutes (more or less than the suggested value). Only the whole number of minutes played is counted.
                        </div>
                        <div className="col-12 sportdetail">The Client can also bet on events with a <strong> handicap</strong> (indicated by <strong>"H"</strong> ). The handicap assumed by the Client is added to the result of the selected team. The bet is considered won if the If the score for the chosen team is correctly predicted after the addition of the handicap . If a draw is resulted after adding a handicap, the bet is refunded and the "Combo" is calculated with winning odds of this event as equal to <strong>"1"</strong> . If the other team wins after the addition of the handicap, the bet is a loser.
                        </div>
                        <div className="col-12 sportdetail">The Client can bet on the Total (indicated by <strong>"T" or "Total"</strong> ) which means the number of certain actions in the match. To win, you need a correct prediction of what number of specified actions will be performed in the match: over  (<strong>"O"</strong>) or under ( <strong>"U"</strong>).</div>
                        <div className="col-12 sportdetail">A bet on the <strong>Individual Total of Teams</strong>  (Participants) (indicated by <strong> "iT"</strong> or <strong>"iTotal"</strong> ) provides a prediction for the number of actions performed in the match for one of the teams or one of the participants of the competition. To win, a Client need to correctly predict whether over (Ov) or under (Un) of the specified actions will be performed in the match by the selected team or participant. In the case of scoring <strong>Total</strong> , the bet is refunded and "Combos" are calculated with winning odds of this event as equal to <strong>"1"</strong> .
                         </div>
                        <div className="col-12 sportdetail">Unless otherwise specified in the line, the Total, Handicap, Even/Odd total of the round, or individual total is indicated in "Deaths". "Death" is considered the death of a game character for any reason (caused by enemy unit, creeps, Roshan, etc ).</div>
                        <div className="col-12 sportdetail"><strong>Double Asian Handicap</strong>  is a bet on the game with a handicap in which the Handicap value (H) is multiple of 0.25, but not multiple of 0.5, for example: H = -0.25, +0.25, -0.75, +0.75, etc. Such a bet is interpreted as two (simple, "half") bets with the same odds and with the nearest common Handicap value (H1 = H - 0.25 and H2 = H + 0.25). The amount of each "half" bet is equal to the half of the "Double" bet amount.</div>
                        <div className='col-12 sportdetail'><strong>Double Total Bet</strong>  is a Total bet in which the Total value (T) is multiple of 0.25, but not multiple of 0.5, for example: T = 2.25, 2.75, 3.25, etc. Such bet is interpreted as two (simple, "half") bets at the same odds and with the nearest common Total value (T1 = T - 0.25 and T2 = T + 0.25). The amount of each "half" bet is equal to the half of the "Double" bet amount.
                        </div>
                        <div className='col-12 sportdetail'>The following examples are simplified four options of settlement for such "Double" bets:</div>
                        <div className='col-12 sportdetail'><strong>K(g)</strong> - general odd for bet;</div>
                        <div className='col-12 sportdetail'><strong>K -</strong>  place bet odd;</div>
                        <div className='col-12 sportdetail'><strong>K1 -</strong> the odd of the first half bet;</div>
                        <div className='col-12 sportdetail'><strong>K2 -</strong> the odd of the second half bet;</div>

                        <div className="col-12 sportdetail">
                            <div className="row">
                                <div className="col-md-8 col-12 mx-auto">
                                <div className="table-responsive">
                                    <table className="table w-100 table-bordered sporttabletwo  sporttable text-center align-middle">
                                        <thead className="align-middle">
                                            <tr className="sportheader">
                                                <th scope="col" className="tableData">Result of the first half bet</th>
                                                <th scope="col" className="tabletitle">Result of the second half bet</th>
                                                <th scope="col" className="tabletitle">Calculation of K(g)</th>
                                            </tr>
                                        </thead>
                                        <tbody className="sportbody align-middle">
                                            <tr>
                                                <td scope="row" className="tableData">Win</td>
                                                <td className="tabletitle">Win</td>
                                                <td className="tabletitle">K(g)= К</td>
                                              
                                            </tr>
                                            <tr>
                                                <td scope="row" className="tableData">
                                                    <div>Win</div>
                                                    <div>k1=K</div>
                                                </td>
                                                <td className="tabletitle">
                                                    <div>Refund</div>
                                                    <div>k2=1</div>
                                                </td>
                                                <td className="tabletitle">K(g)=(k1+1)/2</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" className="tableData">
                                                    <div>Lose</div>
                                                    <div>k1=0</div>
                                                </td>
                                                <td className="tabletitle">
                                                    <div>Refund</div>
                                                    <div>k2=1</div>
                                                </td>
                                                <td className="tabletitle">K(g)=(0+1)/2=0,5</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" className="tableData">
                                                    <div>Lose</div>
                                                    <div>k1=0</div>
                                                </td>
                                                <td className="tabletitle">
                                                    <div>Lose</div>
                                                    <div>k2=0</div>
                                                </td>
                                                <td className="tabletitle">K(g)=0</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 sportdetail">
                            <div className="row">
                                <div className="col-md-8 col-12 mx-auto">
                                    <div className='sportdetail'>Example:</div>
                                    <div className='sportdetail'>Bet amount is $200, Handicap 1 (-1.75), odd is 1.8.,  match result 2:0.</div>

                                <div className="table-responsive">
                                    <table className="table w-100 table-bordered sporttabletwo  sporttable text-center align-middle">
                                        <thead className="align-middle">
                                            <tr className="sportheader">
                                                <th scope="col" className="tableData">H1 (-2) is [(-1,75) - 0,25]</th>
                                                <th scope="col" className="tabletitle">H1 (-1,5) is [(-1,75) + 0,25]

</th>

                                            </tr>
                                        </thead>
                                        <tbody className="sportbody align-middle">
                                            <tr>
                                                <td scope="row" className="tableData">Refund</td>
                                                <td className="tabletitle">Win</td>
                                              
                                            </tr>
                                            <tr>
                                                <td scope="row" className="tableData">
                                                k1=1
                                                </td>
                                                <td className="tabletitle">
                                                Win
                                                </td>
                                            </tr>
                                            <tr>
                                                <td scope="row" className="tableData">
                                                К(g)=(1+1,8)/2= 1,4
                                                </td>
                                                <td className="tabletitle">
                                                    
                                                </td>
                                            </tr>
                                            <tr>
                                                <td scope="row" className="tableData">
                                                Payment amount: 1,4х200= $280.
                                                </td>
                                               
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                        </div>



                        <div className="col-12 sportsubheading">CONDITIONS FOR ACCEPTING BETS</div>
                        <div className="col-12 sportdetail">Bets on the outcomes of the events are accepted based on the line, which is a list of upcoming events for which the Betting company sets certain quotes (winning odds). After any bet (winning odds, Totals, Handicaps, limits on the maximum bets, on "Combo", etc.), the line may be amended but conditions of previously placed bets remain the same.</div>
                        <div className="col-12 sportdetail">The sum of bets on any event cannot exceed the maximum sum specified in the line during one game day (on a line dated by a single number). When the total maximum bet for the same event is reached, no more bets for this event will be accepted.</div>
                        <div className="col-12 sportdetail">Bets are accepted before the event starts, except for bets on "live" matches. Bets made by the Client after the actual start of the event or competition, regardless of the reasons, are considered by the Betting company as invalid. They are either subject to refund or excluded from "Combos". Bets on "live" events are considered valid until the end of the competitions. If they are accepted for any reason after the end of the event or match or tournament, the bet is calculated with odds equal to "1".</div>
                        <div className="col-12 sportdetail">The date and the time for the event start is listed on site is for information purposes only. The incorrectly specified date or time in such a case is not sufficient reason to cancel the bet. The Betting company is not responsible for the accuracy of the results information during the "live" event. Also, the start date and time of the event indicated in the line are for information purposes only, and therefore the wrong date and time are also not sufficient grounds for canceling the bet. In such a case, bets placed before the actual start of the event are considered valid. When calculating outcomes, the actual start time of the event is considered as the actual start time of the competition according to official information from the matches or tournaments organizer, as well as the websites that the Betting company refers to when collecting statistics.
                        </div>
                        <div className="col-12 sportdetail">The Betting company is not responsible for accurate translation or spelling of the names of athletes, teams, and cities where competitions (matches, tournaments) are held.
                        </div>
                        <div className="col-12 sportdetail">In the event of software failures when accepting bets (discrepancy of odds in one or more positions, obvious typos in odds, etc.) or staff errors, the Betting company has the right to consider these bets invalid if there are arguments that would clearly confirm that the bet was incorrect. The management of the Betting company has the right to cancel a bet made after the start of the event or on a deliberately "incorrect" line.</div>
                        <div className="col-12 sportdetail">The Client cannot cancel or change the bet after it has been placed on the server, and the confirmation message with the bet number has been appeared on the website page and/or bet slip received. If the bet has already been registered on the server, A connection failure and other technical mistakes in communication are no reasons to change or cancel a bet.  </div>


                    
                    
                   
                     
                    
                 
                        
                        <div className="col-12 sportsubheading">SPECIAL CONDITIONS
                            If:
                            </div>

                        <div className="col-12 sportdetail">
                        The event did not start on the scheduled date and/or was officially postponed by the organizer for more than 36 - 48 hours from the start time indicated in the bet slip, - all bets on this event are calculated with the odds 1 (unless otherwise specified in the rules for that sport).</div>
                        <div className="col-12 sportdetail">The exact settlement interval is determined by the bookmaker individually within the specified time range.</div>
                        <div className="col-12 sportdetail">The event was interrupted and, according to official data, was not finished within 12 - 48 hours from the start time indicated in the bet slip - all bets on this event are calculated based on the results at the time of the event interruption. Odds equal to "1" are used to calculate bets on outcomes, the results of which are not defined (unless otherwise specified in the rules for that sport).
                        </div>
                        <div className="col-12 sportdetail">The exact settlement interval is determined by the bookmaker individually within the specified time range.
                        </div>
                        <div className="col-12 sportdetail">The initial result of the event is counted for calculation in case of cancellation or change of the result (protest, disqualification for doping, etc.). </div>

                        <div className="col-12 sportdetail">If more than one participant (team or athlete) is declared the competition winner, then bets placed on winning the competition of these participants (teams or athletes) are calculated with odds equal to "1".
The actual results of the competition are announced by the Betting company based on official protocols and other reliable sources of information. In case results from different sources do not match or there are obvious errors, the Betting company makes the final decision on determining the results for calculating bets in the event of disputes. 
</div>
                        <div className="col-12 sportdetail">If the Betting company has reason to believe that the bet was made after the outcome of an event became known or after the selected athlete/ team got a clear advantage (significant advantage in the score, sending off or substitution of player, etc.), it gets the right to cancel these bets (make a refund), both winning and losing.</div>
                        <div className="col-12 sportdetail">The Betting company uses its own data on the actual course of competitions for betting settlement. If the results of events cannot be determined (broadcast failure, lack of results in official information sources, or other reasons), these events will be settled with odds 1. 
                        </div>
                        <div className="col-12 sportdetail">The Client must check the correctness of the bet slip because, in case of an error, the Betting company can refund the bet, regardless of the reason caused this situation and the culprit of the incident.  </div>
                        <div className="col-12 sportdetail">Claims on disputed issues are accepted within 10 days from the date of disputed bet calculation. No claims will be accepted after this application date expired. In the questionable situations, which do not have precedents the Betting company has a right to make a final decision.</div>

                        <div className="col-12 sportsubheading">FINANCIAL RESTRICTIONS</div>
             
           
                        <div className="col-12 sportdetail">The maximum bet sum can be determined by the Betting company specifically for each event or sports, and is subject to change without prior written notice. The maximum bet sum for individual events may be limited by the Betting company.</div>
                        <div className="col-12 sportdetail">The maximum odds for the "Combo" are 10 000. If the product of odds for a winning "Combo" exceeds the maximum amount, such an "Combo" is calculated with odds equal to 10 000. The winning sum cannot exceed the size of the maximum payout for a single bet. "Combo +" odd is a regular odd.</div>
                        <div className="col-12 sportsubheading">BETS SETTLEMENT</div>


                        <div className="col-12 sportdetail">Bets on the eSports events are calculated based on the General Rules, but the specific nature of the calculation is also taken into account. </div>
                        <div className="col-12 sportdetail ">Formats of Bo1, Bo2, Bo3, etc., eSports games (Best of 1, 2, 3, 5, etc.) mean the total sum of maps in the match, in which you need to collect the prevailing number of victories. The winner of the match is defined based on the number of winning maps: a Team needs to get 3 winning maps to win a Bo5 match and at least 2 winning maps to win a Bo3 match.</div>
                        <div className="col-12 sportdetail">In case of a technical defeat (TD) on the map (in the round) or the whole match, bets on the match result, handicap or totals on the maps, and the uncertain outcomes during the announcement of TL, are settled with odds equal to "1". The reason for a technical defeat (TD) is the d lateness of the participant or the entire team, the replacement of player during the match, a DDoS attack and/or other circumstances (by the decision of the judges or organizers).
                        </div>

                        <div className="col-12 sportsubheading pb-1">Cash Out</div>
                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">"CashOut" option is the ability to settle your bet before its outcome is known.
                                </li>
                                <li className="sportdetail">"CashOut" amount depends on the course of events and changes of odds in the game that you selected. It may be higher or lower than your original bet.
                                </li>
                                <li className="sportdetail">"CashOut" option is available only for your unsettled bets.
                                </li>
                                <li className="sportdetail">"CashOut" option will be active both for Pre-match and Live betting.</li>
                                <li className="sportdetail">If you have several bets, then "CashOut" option will be available separately for each of these bets.
                                </li>
                                <li className="sportdetail">"CashOut" option is available only for the whole Parlay but not for its separate events. 
                                </li>
                                <li className="sportdetail">"CashOut" option is provided on the condition that the markets, in which you have placed your bets, are still open.</li>
                                <li className="sportdetail">You can apply "CashOut" option to your unsettled bets in the "Account History" tab.</li>
                                <li className="sportdetail">The settlement amount will be displayed next to each of your bets for which "CashOut" is available.</li>
                                <li className="sportdetail">Betting company does not guarantee that "CashOut" option will be available at any time.</li>
                                <li className="sportdetail">If your "CashOut" request for an individual bet is successful, then your bet will be settled instantly and the appropriate amount of funds will be credited to your gaming account. In this case, the result of selected event will not matter for settlement of this bet.
                                </li>
                                <li className="sportdetail">Betting company reserves the right to accept or reject any "CashOut" request for any kinds of sports, competitions or markets.</li>


                            </ul>
                        </div>


                        <div className="col-12 sportsubheading pb-1">RULES FOR ENROLLMENT WINNINGS</div>
                        


                        <div className="col-12 sportdetail">After the announcement of the actual sports events results on official websites of the competition organizers, the Betting company credites of winnings for client bets.</div>
                        <div className="col-12 sportdetail">BC reserves the right to suspend the settlement of bets and accrual of winnings if the Betting company has substantial grounds to believe that the that the following happened:</div>

                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">prerequisites to suspect a fixed match;
                                </li>
                                <li className="sportdetail">certain doubts about event fairness;

                                </li>
                                <li className="sportdetail">manipulations with the turnover or bet odds.

                                </li>
                               


                            </ul>
                        </div>

                        <div className="col-12 sportdetail">Evidence for the above may be based on the volume, size and structure of certain types of bets in BC. Further proceedings and investigations on events, that show signs of any fraud, will be conducted by the official partners of the Betting company. Bets on events with signs of fraud will not be settled until all ongoing proceedings and investigations are completed. If the deception of the event is confirmed in the official conclusions of the partners, BC may decide to refuse payments and declare all bets on such events invalid. The verdict rendered, based on the final decision made in the course of proceedings and investigations by the company's official partners authorized to take such actions, is final. However, until the investigation is officially completed, BC reserves the right to review written applications from Clients requesting a refund of bets on any event that is being reviewed and/or investigated.
                        </div>
                        <div className="col-12 sportdetail">If there are certain suspicions of fraudulent actions detected (arbitrage betting, multi-accounting or using software to automate bets), the Betting company may suspend client's gaming account for any operations (future bets, cash withdrawal, others) for the investigation period. The detailed verification process can take up to fourteen business days.</div>
                        <div className="col-12 sportdetail">If facts of fraudulent actions are confirmed, the Betting company may decide to cancel all bets made by the Client and refund all the funds credited to the balance of Client’s gaming account</div>
                        <div className="col-12 sportdetail">The Betting company may also declare an agreement concluded with a Client (before or after an event that shows signs of fraud) as invalid, refusing to fulfill any obligations under such an agreement (payment of winnings or refund of bets), if such winnings or bets are proved to be directly related to the fraudulent outcome of the event in which the Client:</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">1) is an athlete, coach, employee or owner of a sports club that took part in an event which is showing signs of fraud;</div>
                        <div className="col-12 sportdetail">2) is an active athlete, referee or commentator;</div>
                        <div className="col-12 sportdetail ">3) appeared as a person in respect of whom bets were made on behalf of any persons involved, specified in subclauses "1" or "2". <br />
                        This rule is applied by the Betting company regardless of the period (when it became known that its Client appears as one of the designated persons). 
<br />If the Betting company has reasonable suspicions about a dishonest sports game or influence on the immediate course of an event in respect of which the Client and the Betting company have entered into a bet, the Betting company may recognize the bet on the outcome of this event (in full or any part thereof) as doubtful. This will result in the suspension of bet calculation and payment of winnings to the Client on this bet until the official end of all proceedings initiated by the Betting company or other persons regarding suspicions of fraud and unfair athletic game. If the fact/facts of the unfair sport game during or the influence of the result of the event are established during investigations, the Betting company may refuse to pay the winnings or refund the bet to the Client, simultaneously contacting law enforcement officials for assistance in establishing the involvement/complicity of the Client in an unfair contest and holding him/her accountable as established by current legislation. 
                        </div>
                        <div className="col-12 sportdetail">If the specified investigations are not completed within 365 days after the Betting company recognizes the bet or part thereof as doubtful, the Betting company may declare the doubtful bet invalid based on the Client's written request. The bet is calculated with odds equal to "1".
                        </div>
                        <div className="col-12 sportdetail ">An sport game showing all the signs of deception includes situations where the game result was completely or partially predetermined (but not limited to):</div>



                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">fixed match as defined in the regulations for various sports competitions;
                                </li>
                                <li className="sportdetail">collusion of the event participants (with each other and/or with a third party); 


                                </li>
                                <li className="sportdetail">Illegal influence on the result of an official sports competition or another event.

                                </li>
                               


                            </ul>
                        </div>


                        <div className="col-12 sportdetail">Checking the integrity of the game, the Betting company can request the Client to explain the principles of certain bets, why these events interested him/her, what considerations guided his/her decision-making regarding a bet or several bets, or any other information related to the bet that the Betting company considers appropriate to ask. If the answers provided by the Client do not dispel doubts about bet integrity and game transparency, this may lead to a loss of confidence of the Betting company toward the Client. And if, for some reason, Client's account balance has become negative, the Betting company may also take this fact into account before making any payments to the Client.</div>
                        <div className="col-12 sportsubheading pb-1">"Combo +"</div>


                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">All combo bets with size N is added the odds X. Where: <br/>
                                N - the number of outcomes in the combo;
                                <br />
                                X - is a multiplier/odd "Combo +" which increases the payout to the player.
                                </li>
                                <li className="sportdetail">
                                Size table "Combo +":
                                <ul>
                                <li className="sportdetail">4 events - X odd is 1.08;</li>
                                <li className="sportdetail">6 events - X odd is 1.15;</li>
                                <li className="sportdetail">8 events - X odd is 1.2;</li>
                                <li className="sportdetail">10 events - X odd is 1.5;</li>
                                </ul>

                                </li>
                                <li className="sportdetail">Only outcomes with odds ≥ 1.4.</li>
                                <li className="sportdetail">Live and prematch bets.</li>
                                <li className="sportdetail">Any bet amount.</li>
                                <li className="sportdetail">If there are more than 2 events in the combo that is calculated as a "refund", the odd X=1.
                                </li>
                                <li className="sportdetail">Сashout cancels " Сombo +" multiplier. That is, if the client wants to calculate the bet by cashout, the combo service is canceled.</li>
                                <li className="sportdetail">If at least one outcome is settled as HalfWon, HalfLost, or Refund, the booster odd will be recalculated according to the new odds.</li>


                               


                            </ul>
                        </div>
                        <div className='col-12 sportsubheading pb-1'>Football</div>



                        <div className="col-12 sportdetail">Bets on football matches are accepted for the main time stipulated by the rules of the match or tournament that includes the compensated time added by the match referee after the main time of the half or the match. Events that took place during the time compensated by the referee are considered to have occurred at the 45th or 90th minute.                  </div>
                        <div className="col-12 sportdetail ">The football bets are available for one team to win or draw or with handicap. Bets in Cup matches are also available on whether one of the teams go to the next round, depends by the number of matches played.
                        </div>
                        <div className="col-12 sportdetail">The line also offers bets on double results:</div>

     <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">1Х – home team team victory or draw;
                                </li>
                                <li className="sportdetail">
                                X2 – draw or away team victory;

                                </li>
                                <li className="sportdetail">12 – home or away team victory.</li>
                                
                            </ul>
                        </div>

                        <div className="col-12 sportdetail pt-md-4 pt-3">Bets are accepted on handicaps and totals (total number of goals scored). The Client can choose different values of handicaps (both positive and negative) and totals in the line. If the Client bet on an integer "Total" and get into it, the bet is calculated with odds equal to "1". Similarly, the Client can bet on the individual "Total" of the team. If the Client get into the "Total", the bet is calculated with odds equal to "1". </div>
                        <div className="col-12 sportdetail">The bet on " Who will score more goals " offers a comparison of two teams from different matches when the team that scores more goals will be recognized as the best. Bets are not accepted for "Combos" and "Systems" with the proposed teams and are calculated with odds equal to "1" upon the same performance from both teams.</div>

                        <div className="col-12 sportdetail pt-md-4 pt-3">There are available bets on match listing:</div>

                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">"Match Result" bet (the Client need to predict the final match score);
                                </li>
                                <li className="sportdetail">
                                "Half time/ Full time Result" bet (the Client need to predict both the result of the first half and the entire match). Initial letters are used in the line to indicate these outcomes: V - victory, D - draw. The first place indicates the outcome of the first half, and the second one indicates the match outcome. The outcome of the second half does not matter for the calculation of this bet. For example, if the match finished 1:1, and the first half 1:0 - the bet on the "V1D" outcome is winning; if the match finished 1:0, and the first half 0:0 - the bet on the "DV1" outcome is winning; if both the first half and the match finished 1:0 - the bet on "V1V1" is winning;

                                </li>
                                <li className="sportdetail">"Goal in the First Half" bet means that Client can choose between "Yes/No" options to predict whether a goal will be scored before the break; </li>
                                <li className="sportdetail">The Client can bet on the time and player scoring the first goal, on the totals of the first and/ or second half, etc.;
                                </li>
                                <li className="sportdetail">All players who took part in the match are considered as runners. If for any reason an unlisted player scores a goal all bets on listed players stand. If a player is listed but does not take part in the match, all related bets made on the player will be considered as void.</li>
                                <li className="sportdetail">goals scored by replaced players are also taken into account for the "At Least One Players Scores Two or More Goals" bet.
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail">For the "Home/Away" game, the client is asked to predict whether the difference between the goals scored by the "Home" and "Away" teams will be more or less than the "handicap" offered.
                        For the "round total" game, the client is asked to predict whether the total number of goals scored by the "Home" and "Away" teams together will be more or less than the number offered by the Betting company. Additionally, Clients can bet on playing round:
                        </div>

                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">number of "Home" victories;
                                </li>
                                <li className="sportdetail">
                                number of "Away" victories;

                                </li>
                                <li className="sportdetail">number of draws in the round;</li>
                                <li className="sportdetail">number of "Away" points, etc.
                                </li>
                              
                            </ul>
                        </div>

                        <div className="col-12 sportdetail ">"Home/ Away" and "round total" bets are not accepted in "Combos" with any other events of the same tour. If one or more matches in the tour were interrupted and were not completed within 12 hours, then bets for "Home/Away" and "round total" games are calculated with odds equal to "1".
Bets on "Corner Kicks, Yellow/Red Cards, Fouls, Shots on Target, Offsides, and Percentage of Ball Possession, Substitutes" include the following: 
</div>
                        <div className="col-12 sportdetail">- Corner kicks assigned, but not completed are not counted in the statistics. If the referee does not count the corner kick but assigns it again, the corner kick will be counted only once.</div>
                        <div className="col-12 sportdetail ">- The second yellow card which will lead to the in the player's sending off, is considered both a second yellow card and a red card. A player will be considered as sent-off if he was in the field at the time of being sent off.). Cards shown to players on the bench or to team personnel (coaching staff, managers, etc.) are not counted. Cards shown after the end of the first half are considered as shown in the second half.
                        </div>
                        <div className="col-12 sportdetail">If two or more players from different teams get yellow or red cards based on a single game episode - bets on the "Match First Yellow/Red Card" will be calculated with odds equal to "1".</div>
                        <div className="col-12 sportdetail ">Booking Markets .Yellow card counts as 1, red card counts as 2. Second yellows are ignored for settlement purposes (e.g. maximum card count per player is 3).</div>
                        <div className="col-12 sportdetail pt-3">Booking Points Markets. Yellow card counts as 10 points and red or yellow red cards as 25. The 2nd yellow for one player which leads to a yellow red card is not considered. As a consequence one player cannot cause more than 35 booking points. </div>
                        <div className="col-12 sportdetail ">For the bet on "Time of the First or Last Goal" in a match, indicating the time intervals, the Client needs to predict during which period the first or last goal in the match will be scored. If the game finishes 0:0, the bet will be calculated with odds equal to "1".</div>
                        <div className="col-12 sportdetail">In the " Who will score the next goal " market, there are three possible outcomes for the Client: "Team 1", "Team 2" or "Zero Score".
                        </div>
                        <div className="col-12 sportdetail ">For bets on "Most Productive Match", "Least Productive Match" or "Match with Most (Least) Goals Scored", the Client needs to predict the match/matches from the selected list in which the most or least goals will be scored. If the highest number of goals is scored in two or more matches - bets on these matches are settled at the odds 1. </div>
                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">If the rules or format of a match differ from our accepted norm, we reserve the right to void any market.
                                </li>
                                <li className="sportdetail">
                                If one or more matches selected in the line were postponed for more than 36 - 48 hours from the originally officially specified time, or were interrupted and not finished within 12 - 48 hours, all bets on these games will be settled at the odds 1.The exact settlement interval is determined by the bookmaker individually within the specified time range.

                                </li>
                                <li className="sportdetail">In case of unusual time periods (e.g. 3 periods of 30 minutes each), bets will stand as long as the total statutory time of the match is between 45 minutes and 120 minutes.</li>
                             
                              
                            </ul>
                        </div>


                        <div className="col-12 sportdetail">The bookmaker line accepts bets on the event that first occurs in a certain game period. The Client need to predict what will happen during a certain time period: a goal scored, a corner kick, a goal kick, an offside, a foul, an out, or none of these. Similarly, bets on comparisons of the above events are available -  the Clients can bet which of the specified events (for example, a goal or offside, a goal kick or corner kick) will occur earlier in a certain period, or none of the above.</div>
                        <div className="col-12 sportdetail ">The following events are considered as occurred: a corner kick in the case of a kick executed from a corner flag; a goal kick in the case of an actual goal kick; offside in the case of a free kick performed upon recording the offside; out in the case of an actual throw-in; foul in the case of a free or penalty kick performed upon recording a violation.</div>
                        <div className="col-12 sportdetail">The source for determining the results and matches statistics can be checked from the following sites:</div>
                        <div className="col-12 sportdetail pt-3">
                            <ul>
                                <li className="sportdetail"><a  href="http://www.premierleague.com" className="text-decoration-none sportdetail">- Premier League, England</a>
                                </li>
                                <li className="sportdetail"><a href="http://www.bundesliga.de/de/liga/ " className="text-decoration-none sportdetail"> - Bundesliga, Germany</a>
                                </li>
                                <li className="sportdetail"><a href="http://www.lequipe.fr/Football/Ligue-1/ " className="text-decoration-none sportdetail"> - League 1, France</a>
                                </li>
                                <li className="sportdetail"><a href="http://www.raisport.rai.it/ " className="text-decoration-none sportdetail"> - Serie A, Italy</a>
                                </li>
                                <li className="sportdetail"><a href="https://www.uefa.com/" className="text-decoration-none sportdetail"> -  UEFA Champions League and UEFA Europa League
                                </a>
                                </li>
                                <li className="sportdetail">
                                When determining the statistical indicators of football matches, the source for determining the results is the information posted on the website https://www.whoscored.com/.

                                </li>
                                <li className="sportdetail">
                                In the absence of information on this resource, the calculation of statistical indicators will be carried out on the basis of an alternative source https://www.flashscore.com/.

                                </li>
                                <li className="sportdetail">
                                If a situation arises that none of these resources provides the necessary information on statistical indicators, the company reserves the right to make a calculation based on internal/alternative sources.

                                </li>
                              
                            </ul>
                        </div>
                        <div className="col-12 sportdetail">In the absence of the necessary information or obvious errors in the protocols on these sources, the Betting company can sattle bets based on statistical data from its own internal sources.</div>
                        <div className="col-12 sportdetail">Time accounting of each event to calculate the following: goal – the same time as the timer at the moment of the ball crossing the goal line (scored goal);  corner kick – time at the moment of the kick from the corner mark; foul – time at the moment of the free or penalty kick upon recording a violation of the rules; offside – time at the moment of the free kick upon recording the offside; goal kick – time at the moment of the goal kick; out – the same time as the timer at the moment of the out thrown. The bet is sattled based on the match timer for the TV broadcaster, which will be specified in the comments to a specific match on the line. The parallel timer which is starled in parallel by the Betting company is considered the main timer for sattling the bet when there is no time indicator in the TV broadcast. To determine and make a final decision in disputed situations, the Betting company can operate information from internal sources about the match.</div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">In the "First 5-Minute Match Prediction" the Client proposed to predict before the match (pre-match bet) what event will take place first within the first five minutes (see Clause 18 for live bets):
                        </div>
                        <div className="col-12 sportdetail pt-3">
                            <ul>
                                <li className="sportdetail">Goal scored;
                                </li>
                                <li className="sportdetail">Penalty appointed;
                                </li>
                                <li className="sportdetail">Corner kick appointed;
                                </li>
                                <li className="sportdetail">Yellow or red card shown;

                                </li>
                                <li className="sportdetail">None of the above.

                                </li>
                              
                            </ul>
                        </div>

                        <div className="col-12 sportdetail py-3">
                        Bets on the first five minutes of the match will be sattled based on the period from 0:00 to 5:00 (from the first to the fifth minute inclusive) of the event. To consider a bet as a winning, the goal shall be scored in the specified period (the goal kick and the ball crossing the goal line shall be recorded in a certain period). If the kick was made in a given period, and the goal was scored beyond such period, the bet will be considered as lost. The corner kick shall be assigned within the specified time period and, if assigned, will be counted. The penalty shall also be assigned within the specified time period. Penalties will be ignored if the penalty event happened within the allotted time period, and its appointment by referee outside of the period . If the penalty is awarded within the specified period, and the attempt to execute it occurs later - the bet is still considered won. Yellow or red cards will be counted at the moment when they will be shown by the referee, but not at the moment of the foul commitment. If the reason for the yellow or red card belongs to the specified period but the direct display of the card does not, the card will not be counted. Only cards shown to the field players who participate in the match at the time of card shown will be counted. Appointed penalty is considered as the winning outcome if the penalty had been appointed before the referee showed the card for a foul.
It is proposed to the Client to specify the outcome of the match based on the European handicap. In case of a bet with a European handicap, the Client needs to predict the outcome of the match with condition that the match begins from a certain score, specified in the value of the handicap. The final score for such bets is calculated by adding the game result and the handicap value. The following outcomes are possible when betting with the European handicap:

                        </div>
                        <div className="col-12 sportdetail pt-3">
                            <ul>
                                <li className="sportdetail">Home win with handicap;

                                </li>
                                <li className="sportdetail">Away win with handicap;

                                </li>
                                <li className="sportdetail">Draw with handicap.

                                </li>
                            </ul>
                        </div>

                        <div className="col-12 sportdetail">The European handicap does not imply a partial or full refund of the bet: the client can either win or lose the bet in full. </div>
                        <div className="col-12 sportdetail ">Example 1. Handicap (0-1) means a bet assuming that after the end of the game, 0 is added to the goals scored by the home team, and 1 is added to the goals scored by the away team. The "home win with handicap" outcome will be a win if the match ends with the home team winning by 2 or more goals, for example, 2:0 or 3:1 (2:1 or 3:2 with 0-1 handicap), etc.  The "draw with handicap" outcome will only be a win if the home team wins exactly 1 goal, for example, 1-0 or 2:1 (1:1 or 2:2 with the selected handicap 0-1), etc. The "away win with handicap" outcome will be a win if the away team ends the match with a draw or win, for example, 0:0 or 0:1 (0:1 or 0: 2 with the selected handicap 0-1). </div>
                        <div className="col-12 sportdetail">Example 2. Handicap (1-0) means a bet assuming that after the end of the game, 1 is added to the goals scored by the home team, and 0 is added to the goals scored by the away team. The prediction for the "home win with handicap" outcome will play for any home win or draw, for example, 1:0 or 1:1 (2:0 or 2:1 with the selected handicap 1-0), etc. The bet on the "draw with handicap" outcome will only take place if the away team wins by a single goal difference, for example, 0:1 or 1:2 (1:1 or 2:2 with the selected handicap 1-0), etc. The "away win with handicap" outcome will only be won if the away team triumphs with a difference of two or more goals in 2 or more goals, for example, 0:2 or 1:3 (1:2 or 2:3 with the selected handicap 1-0), etc.</div>
                        <div className="col-12 sportdetail">The "Shots on the goal/crossbar kick" market: when settled this bet, one takes into account shots at the goal post or crossbar, after which the ball remained in play (flying out into the pitch  or touching the referee, or a player of one of the teams, or another post or crossbar after a rebound). 
The ball hitting the post or crossbar is not considered in the following cases:
</div>
<div className="col-12 sportdetail pt-3">
                            <ul>
                                <li className="sportdetail">the goal was scored (the ball flew into the net);

                                </li>
                                <li className="sportdetail">the game was stopped before the ball hit the post or crossbar;

                                </li>
                                <li className="sportdetail">after hitting the crossbar, the ball crossed the goal line without touching the pitch.

                                </li>
                            </ul>
                        </div>

                        <div className="col-12 sportdetail pt-md-4 pt-3">Bet on "goalless time periods" assumes that if "n" goals are scored during a match, then the minutes, when goals have been scored, divide a time period of a match (from 0 to 90 minutes) into "n+1" goalless time periods. The duration of goalless time periods is calculated as the difference between:</div>
                        <div className="col-12 sportdetail pt-3">
                            <ul>
                                <li className="sportdetail">minutes of the first goal and match start;  </li>
                                <li className="sportdetail">minutes of successive goals;  </li>
                                <li className="sportdetail">minutes of the match end and the last goal. </li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail">The match starts at 0 minute. Goals scored in the time compensated by the referee for the first and second half are considered goals scored at the 45th and 90th minutes respectively. If there have not been scored any goals, thus, it is considered that there has been one 90-minute goalless period. </div>
                        <div className="col-12 sportdetail pt-md-4 pt-3">Example 1. If a match has been completed within the main time with 0:0 score, thus, it is considered that there has been only one 90-minute goalless period.</div>
                                                    <div className="col-12 sportdetail">Example 2. If there have been scored 2 goals in a match: at 23rd and 62nd minutes, then there have been only 3 goalless periods: 23 minutes (23-0), 39 minutes (62-23), and 28 minutes (90-62). 
                            Example 3. If there have been scored 3 goals in a match: at the 15th, 45th, and 90th minutes, then there have been only 4 goalless periods: 15 minutes (15-0), 30 minutes (45-15), 45 minutes (90-45), and a 0-minute period (90-90).
                            </div>
                                                    <div className="col-12 sportdetail ">"Cyberfootball" is a betting on the FIFA PC game which is a football simulator with with 2 players take part in it. Games played with settings "two 5-minute halves" and the "beginner" complexity level. If a match is stopped due to technical reasons (disconnect or computer failure) and a re-match is scheduled, bets on the stopped match are settled according to the General Rules, and bets on the re-match are accepted as for a new event.  <strong>eFootball</strong>

                            </div>
                                                    <div className="col-12 sportdetail">Bets on the FIFA PC game (a football simulator with two players) are accepted for matches held with the game settings "two halves 5-minute each" and the “beginner” level. </div>
                        <div className="col-12 sportdetail ">If a match is stopped due to technical reasons (disconnect, computer failure, other) and re-match appointment - bets on the interrupted match are calculated according to the General Rules, and bets on the re-match are accepted as on a new event. It is not a reason to cancel bets, except in situations where it is impossible to define the final result of the match, or a technical malfunction that interrupts the video broadcast of the event.
                        </div>
                        <div className="col-12 sportdetail"><a  className="sportdetail" href='https://esportsbattle.com/en'>https://esportsbattle.com/en</a></div>

                        <div className='col-12 sportsubheading pb-1'>Basketball</div>
                        <div className="col-12 sportdetail pt-2">The Betting company accepts bets on basketball matches taking into account overtimes (OT), if the scheduled game time ends with a draw. In addition to individual bets on a draw or a clean victory of one of the teams (1, X, 2), bets on such outcomes are accepted for the scheduled game time. In basketball matches, determining the winner of the confrontation provided for two games (home and away games), the match can end in a draw without overtime. </div>
                        <div className="col-12 sportdetail pt-3">
                            <ul>
                                <li className="sportdetail">Example 1: play-off match (according to the regulations, a winner is determined based on the sum of two matches), the first match ends with a score of 85-80, the second match ends with a score of 75-75 (without overtime). In the second match, the rules do NOT provide for overtime, since the score of two matches is 160:155, which guarantees the first team to get to the next round. </li>
                                <li className="sportdetail">Example 2: play-off match (according to the regulations, a winner is determined based on the sum of two matches), the first match ends with a score of 78-72 in favor of team 1, the second match ends with a score of 87-81 in favor of team 2. Since the sum of two matches is 159:159, overtime in the second match is appointed to determine a team to pass to the next round (this overtime is only considered when betting on the "Qualifying to the next round)" market). </li>
                            
                            </ul>
                        </div>




                        <div className="col-12 sportdetail pt-3">When betting on a combined total or individual total, the Betting company offers the Clients to predict the total number of the points in a match scored by teams combined or the score of one individual team. </div>
                        <div className="col-12 sportdetail">In some specific matches bets available for each quarter of the match. For example, the client must predict the most/least productive quarter of the match.</div>
                        <div className="col-12 sportdetail">Bets on number of points, rebounds scored by the players of either team according to the line are also avaliable. The bet will be refunded if one of the basketball players does not appear on the pitch</div>
                        <div className="col-12 sportdetail">In the "Duel of players" betting market, the Betting company offers the Client to predict which who will will score more points, make more assists or collect more rebounds. Such bets are settled based on the official match protocol (including overtime, if the scheduled game time ends in a draw). The bet will be refunded if one of the basketball players does not appear on the pitch.</div>
                        <div className="col-12 sportdetail">Bets on general "Performance" for basketball players take into account the total sum of points, assists and rebounds of a particular athlete. Blocked shots and steals are not counted as Performance points.  Bets are settled according to the official match protocol (considering overtime). The bet will be refunded if one of the basketball players does not appear on the pitch.</div>
                        <div className='col-12 sportsubheading pb-1'>eBasketball</div>

                        <div className="col-12 sportdetail">The calculation rules are in accordance with the rules for calculating basketball bets. Matches are held 4 quarters of 8 minutes each.</div>
                        <div className="col-12 sportdetail">Basketball bets on the winning of any team, and a draw, as well as on any results of the second half and fourth quarter are accepted of main time. Bets on all other proposed results are accepted taking into overtime, unless otherwise specified in the coupon and/or line. Bets on Total for a quarter take into account points scored by teams only for this quarter, and not for the entire match right up until the end of this quarter.</div>
                        <div className="col-12 sportdetail">Sources:</div>
                        <div className="col-12 sportdetail">Sources, which the company uses (not only these) for determining the results of eSports events: <a  className="sportdetail" href="https://esportsbattle.com/en">https://esportsbattle.com/en</a></div>


                        <div className='col-12 sportsubheading pb-1'>Tennis</div>

                        <div className="col-12 sportdetail">The Betting company offers the Clients the following betting markets for tennis:</div>
                        <div className="col-12 sportdetail pt-3">
                            <ul>
                                <li className="sportdetail">match winner;</li>
                                <li className="sportdetail">score by sets (2:0 or 2:1 for three-set matches, 3:0, 3:1, 3:2 for five-set matches); </li>
                                <li className="sportdetail">bet with a handicap on match (handicap can be either positive or negative);</li>
                                <li className="sportdetail">bet with a set handicap in a match (handicap can be either positive or negative);</li>
                                <li className="sportdetail">bet on the total number of games in a set or match;</li>
                                <li className="sportdetail">bet on the number of aces per match for a certain player;</li>
                                <li className="sportdetail">bet on the number of double faults for a certain player;</li>
                                <li className="sportdetail">bet on a certain player victory in the specified set;</li>
                                <li className="sportdetail">bet on whether the match will have a tie-break in one or more sets (the set score is 7:6 or 6:7).</li>
                                <li className="sportdetail">The following live bets are also available:
                                    <ul className='my-2'>
                                    <li className="sportdetail">who will win the "x" number of games in a set; </li>
                                    <li className="sportdetail">who will win the "x" number of points in the game (there is a difference between the number of points in the game and the score of the game: if the current score of the game is 30:15, then three points have played, but not forty five, while the fourth point will be played next in the game, and then the fifth);
                                    </li>
                                    <li className="sportdetail">game score;</li>
                                    <li className="sportdetail">whether there will be a score of 40:40 in a game, etc.</li>

                                    </ul>
                                </li>
                                <li className="sportdetail">The company uses its own data about the actual course of the game for the live bets settlement. If, for any reason (lack of results in official information sources or broadcast loss), the results of competition cannot be reliably established, then bets on these events are settled with odds equal to "1". Any claims for bets on a live event are accepted not later than 24 hours after the competition ends.</li>

                            
                            </ul>
                        </div>
                        <div className="col-12 sportdetail mt-3">All bets on tennis matches remain valid until the end of the tournament.</div>
                        <div className="col-12 sportdetail">All bets on tennis matches remain valid if the tennis courts surface have been replaced during the tournament. The Betting company provides data about the surface of tennis courts (ground, grass, hard) for information purposes only and does not accept claims about inconsistencies in the data about the type of court surface in the betting line information.</div>

                        <div className='col-12 sportdetail py-2'>
                            <ul>
                                <li className="sportdetail">In case of a retirement and walk over of any player all undecided bets are considered void (including the market for the Match Winner).</li>
                                <li className="sportdetail">If a player retires all undecided markets are considered void.</li>
                                <li className="sportdetail">If penalty point(s) are awarded by the umpire, all bets on that game will stand.</li>
                                <li className="sportdetail">In case of a match is finished before certain points/games were finished, all affected point/game related markets are considered void.</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail">If the regulations have been changed (the planned five-set match was replaced by a three-set match, or vice versa), the bets on a match winner, first set winner, total of the first set and game handicap for the first set are sattled at the at the odds offered in the line. All other bets will be refunded.</div>
                        <div className="col-12 sportdetail">If one or more players of one of the teams in team competitions are replaced (for example, the Davis Cup), regardless of the reasons, bets on the match outcome and qualifying to the next round remain valid.</div>
                        <div className="col-12 sportdetail">Bets on tennis matches in doubles are calculated with odds equal to "1", if the composition of the pair was initially specified when replacing at least one of the Clients. Otherwise, the bet remains relevant.
If two or more tennis Clients win the tournament at the same time, then bets on the victory of these Clients in the tournament are calculated with odds equal to "1".
</div>
                        <div className="col-12 sportdetail ">If a so-called "championship tie-break" (a race to 10 points won) is played instead of a decisive set in a match, a score of a decisive set is considered as 1:0.  Accordingly, the overall the handicap and the overall total of the match are settled basic on this score.</div>
                        <div className="col-12 sportdetail">If markets remain open with an incorrect score which has a significant impact on the prices, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail ">If the players/teams are displayed incorrectly, we reserve the right to void betting.</div>
                        <div className='col-12 sportsubheading pb-1'>Special settlement rules</div>



                        <div className="col-12 sportdetail ">If one of a tennis match players refuses to continue the match for any reason, or has been disqualified, then the following options are possible: </div>
                        <div className="col-12 sportdetail ">
                        1. If at least one set was completely played:
                        <ul>
                            <li className='sportdetail'>All bets that have been placed at the time of the match interruption are considered valid; </li>
                            <li className='sportdetail'>Regardless of the score, all bets on the match made on the handicap on sets and games, and sets are subject of refund;</li>
                            <li className='sportdetail'>all bets on winning the match are considered valid, and the match winner is determined in accordance with the decision of the referee;</li>
                        </ul>
                        </div>
                        <div className="col-12 sportdetail ">
                        2. If the first set is not completely played:
                        <ul>
                            <li className='sportdetail'>All bets on the match are subject to refund, except for those that had actually settled when the game interrupted </li>
                        </ul>
                        </div>
                        <div className="col-12 sportdetail ">
                        Example 1. Zverev vs. Medvedev. The match has been interrupted with a score of (4:6, 5:3). In this case:
                        <ul>
                            <li className='sportdetail'>a bet on the first set "Zverev with a handicap +1.5" is lost;</li>
                            <li className='sportdetail'>"second set total" bet is more than 7.5 is won;</li>
                            <li className='sportdetail'>"match total" bet is less than 17.5 is lost as 18 games have actually been played;</li>
                            <li className='sportdetail'>"Second set total" bet is more than 9.5, "second set total" bet is less than 8.5 or "second set total" bet is more than 18.5 are subject to refund.</li>
                        </ul>
                        </div>
                        <div className="col-12 sportdetail ">
                        Example 2. Zverev vs. Medvedev. The match has been interrupted in the first set with the score of 5:5. In this case
                        <ul>
                            <li className='sportdetail'>"first set total" bet is more than 9.5 is won;</li>
                            <li className='sportdetail'>"who is to win four games first" bet is calculated according to the result;</li>
                            <li className='sportdetail'>"First set total" bet is less than 10.5, "first set total" bet is more than 10.5, a bet on the first set "Zverev with a handicap +2.5" or a bet on the first set "Medvedev with a handicap -2.5" are subject to refund.</li>
                         
                        </ul>
                        </div>
                        <div className='col-12 sportsubheading pb-1'>eTennis</div>


                        <div className="col-12 sportdetail">Virtual tennis consists of two tournaments Grandstand Open and Britannia Open, each of which attended by 16 players. One tournament consists of 15 matches and goes in four stages: 1/8 finals, 1/4 finals, semifinals, finals. The duration of one tournament is 26 minutes. The duration of one stage\match is 3 min 45 sec. Matches are held with three sets format (before winning in two sets).</div>
                        <div className="col-12 sportdetail ">The acceptance of bets on the forthcoming Grandstand Open stage is held during the Britannia Open and vice versa. The stages for each tournament changed by alternatively:</div>
                        <div className="col-12 sportdetail">
                            <ul>
                            <li className='sportdetail'>1/8 Britannia Open - Grandstand Open final</li>
                            <li className='sportdetail'>1/4 Britannia Open - 1/8 Grandstand Open Britannia</li>
                            <li className='sportdetail'>Open semifinals - 1/4 Grandstand Open </li>
                            <li className='sportdetail'>Britannia Open final - Grandstand Open semifinals</li>

                            </ul>
                        </div>
                        <div className="col-12 sportdetail py-1">Types of bets:</div>

                        <div className="col-12 sportdetail">
                        <ul>
                            <li className='sportdetail'>Winning the match (P1 - win 1st player, P2 – win 2nd player);</li>
                            <li className='sportdetail'>Total (more, less) is calculated by game;</li>
                            <li className='sportdetail'>Exact score (2:0; 2:1; 0:2; 1:2);
                            </li>
                            <li className='sportdetail'>Winning the first set (P1 - win 1st player, P2 – win 2nd player);
                            </li>
                            <li className='sportdetail'>First set Total (more, less) is calculated by game of first set. 
                            </li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">Bets are calculated immediately after the match. The result of each event isn’t determined by the Betting Company, and also doesn’t depend on the Betting Company, nor on the client, nor on the amount of placed bets.</div>
                        <div className="col-12 sportdetail">If the event didn’t take place due to an application malfunction and also due incorrect bets or odds, then bets will be refunded. Сonnection failure, communication failure, browser errors, closing the window and other technical reasons aren't grounds for canceling a bet, because they don't affect the course of the match, their result, or bets calculation. Broadcast delays also aren't a reason for canceling a bet. Client is responsible for the correct bet selection.</div>

                        <div className='col-12 sportsubheading pb-1'>Hockey</div>

                        <div className="col-12 sportdetail">The Betting company offers clients to bet on hockey matches of various championships. The Client can bet on the match result (a clean victory for one of the teams or a draw), as well as on handicaps and totals. The Betting company offers a line of totals and handicaps (positive and negative) to bet. e of scoring the "total", such bets are calculated with odds equal to "1".</div>

                        <div className="col-12 sportdetail">Bets on hockey matches are accepted only for the regular time, excluding overtimes (if this is assumed by the competition regulations), except for specially stipulated bets. </div>

                        <div className="col-12 sportdetail ">In the "Performance of Periods" betting market, the Client is asked to predict which of the three periods will be the most productive for the both teams in total (the highest number of goals will be scored).</div>
                        <div className="col-12 sportdetail">The following types of bets are available in the betting line:</div>
                        <div className="col-12 sportdetail">
                        <ul>
                            <li className='sportdetail'>bets on the clear victory of the selected team or a draw, as well as win with the selected handicap in any of the match periods;</li>
                            <li className='sportdetail'>bets on the total number of goals scored in certain match periods;</li>
                            <li className='sportdetail'>bets on the time of the first goal and the goalscorer;
                            </li>
                            <li className='sportdetail'>bets on the total points ("goal+pass") scored by a particular hockey player in a particular match;
                            </li>
                            <li className='sportdetail'>for NHL matches (regular championship and the playoffs of the Stanley Cup), bets on the number of penalty minutes, individual performance (excluding penalty shootouts) and others; 
                            </li>
                            <li className='sportdetail'>For the matches held under the auspices of the International Ice Hockey Federation (IIHF), bets on shots on goal, penalty time and individual performance of players are accepted for the main time;
                            </li>
                            </ul>
                        </div>

                        <div className="col-12 sportdetail py-2">Also, clients can use the "home/away" betting market, which includes the following options:</div>
                        <div className="col-12 sportdetail">
                        <ul>
                            <li className='sportdetail'>The Client needs to predict the difference between home and away shots on goal attempts (whether it will be less or more than the offered handicap);</li>
                            <li className='sportdetail'>The Client needs to predict whether the sum of home and away shots on goal attempts will be less or more than the offered total;
                            </li>
                            <li className='sportdetail'>The Client needs to predict the overall result of the playing round: the number of home and away points gained, the number of draws, the number of home and away shots on goal attempts, etc.

                            </li>
                           
                            </ul>
                        </div>
                        <div className="col-12 sportdetail">The home/away market bets are not accepted in "Combo" bets along with any other matches of the corresponding round. If one or more matches have been interrupted and not completed within the time limit set by the rules - such bets of the "home/away" market will be calculated with odds equal to "1".</div>
                        <div className="col-12 sportdetail ">There is also a "Next Goalscorer" bet available for Clients in the betting line, where they can choose one of three options: "Team 1", "Team 2" or "No Score".</div>
                        <div className="col-12 sportdetail">According to the terms of the "When will be scored the first (last) goal" bet, indicating the time periods, the clients need to predict in what time period the first/last goal will be scored in the match. If the regular time of the match ends in a draw, the bet will be calculated with odds equal to "1". </div>
                        <div className="col-12 sportdetail ">As part of the " Come From Behind and Win" bet in a match, the Client needs to predict which team will lose in the score during the game but will be able to win in the of the match in regular time.</div>
                        <div className="col-12 sportdetail">If, for some reason, an ice hockey match has not been played on the scheduled date, then all bets on this match are calculated with odds equal to"1".</div>
                        <div className="col-12 sportdetail ">The Clients can verify the results of hockey matches on the following websites:</div>
                        <div className="col-12 sportdetail">
                            <ul>
                                <li className='sportdetail'>NHL -<a href="https://www.nhl.com/" className='sportdetail'>www.nhl.com</a></li>
                                <li className='sportdetail'> World Cup<a href="https://www.iihf.com/" className='sportdetail'>www.iihf.com
                                </a></li>
                                <li className='sportdetail'>Champions League -<a href="https://www.championshockeyleague.com/" className='sportdetail'> www.championshockeyleague.net
                                </a></li>
                                <li className='sportdetail'>Sweden -<a href="https://www.swehockey.se/" className='sportdetail'> www.swehockey.se
                                </a></li>
                                <li className='sportdetail'>Czech Republic - <a href="https://www.hokej.cz/" className='sportdetail'> www.hokej.cz</a></li>
                                <li className='sportdetail'>Finland -<a href="https://www.finhockey.fi/" className='sportdetail'>www.finhockey.fi</a></li>
                                <li className='sportdetail'>KHL -<a href="https://www.khl.ru/" className='sportdetail'>www.khl.ru</a></li>
                                <li className='sportdetail'>Germany -<a href="https://www.deb-online.de/" className='sportdetail'> www.deb-online.de</a></li>
                                <li className='sportdetail'>Norway -<a href="https://www.hockey.no/" className='sportdetail'>www.hockey.no </a></li>
                                <li className='sportdetail'>Belarus -<a href="https://hockey.by/" className='sportdetail'>www.hockey.by</a></li>
                                <li className='sportdetail'>VHL -<a href="https://www.vhlru.ru/" className='sportdetail'> www.vhlru.ru</a></li>



                            </ul>
                        </div>
                        <div className='col-12 sportsubheading pb-1'>eHockey</div>

                        <div className="col-12 sportdetail">Bets are accepted on the computer game NHL (a hockey simulator in which is played by 2 players). Matches are held with the settings of the game “3 periods of 3 minutes”, the difficulty of the game and the goalkeeper “semi pros”, the match mode “Arcade”. </div>
                        <div className="col-12 sportdetail ">In case of stopping the match due to technical reasons (computer failure, connection failure, etc) and appointing replay game, bets on a stopped game are settled according to the general rules for calculating bets on interrupted events.</div>
                        <div className="col-12 sportdetail">On replay game, bets are accepted as a new event. If due to a technical malfunction the video broadcast of the event is interrupted (connection failure, DDoS, etc.), then this isn’t a reason for canceling bets, except in situations where it is impossible to find out the result of the match. <a className='sportdetail' href="https://esportsbattle.com/en
">https://esportsbattle.com/en
</a></div>

                        <div className='col-12 sportsubheading pb-1'>Table Tennis</div>

                        <div className="col-12 sportdetail pt-md-4 pt-3">Bets on totals and handicaps in table tennis matches are indicated in points unless otherwise indicated in the betting line of the Betting company.</div>
                        <div className="col-12 sportdetail">If the match was interrupted or one of the teams or an athlete has been disqualified, or for some reason has refused to continue the match, the following options are possible:</div>
                        <div className="col-12 sportdetail ">Example 1. If at least one set has been played in full, then all bets on the match winner are considered valid. All bets that have been actually completed by the time of the game interruption are also considered valid, but bets on handicaps on sets or points in a match, regardless of the score, will be refunded.</div>
                        <div className="col-12 sportdetail">DExample 2. If the first set has not been played in full, then all bets are calculated based on the current result at the time of the game interruption, and all other bets are calculated with odds equal to "1". <a href="https://tabletennis.setkacup.com/en/" className='sportdetail'>https://tabletennis.setkacup.com/en/</a></div>
                        <div className='col-12 sportsubheading pb-1'>Volleyball</div>


                        <div className="col-12 sportdetail ">The Betting company offers clients to place bets on volleyball matches with the following markets in the line:</div>
                        <div className="col-12 sportdetail">
                            <ul>
                                <li className='sportdetail'>a match or a set winner;</li>
                                <li className='sportdetail'>whether there will be a tie-break in a match;</li>
                                <li className='sportdetail'>on totals or handicaps in a set or a match.</li>
                              

                            </ul>
                        </div>
                        <div className="col-12 sportdetail">Handicaps and totals for volleyball matches are indicated in points unless otherwise indicated in the betting line.</div>
                        <div className="col-12 sportdetail ">If the rules of a volleyball match have been changed (sets have been played to 21 points), then bets on set or match winner and exact score of the match are calculated based on accepted odds. The "Gold Set" is not taken into account when bet is settled. All other bets will be refunded.</div>
                        <div className='col-12 sportsubheading pb-1'>Handball</div>
                        <div className="col-12 sportdetail">The Betting company accepts various bets on handball matches of national championships or European cups, taking into account the result of the game in the regular time and the match rules. Exceptions apply if otherwise specified in the betting line.</div>
                        <div className='col-12 sportsubheading pb-1'>Badminton</div>

                        <div className="col-12 sportdetail">Bets on totals and handicaps in badminton matches are indicated in points unless otherwise indicated in the betting line of the Betting company.</div>
                        <div className="col-12 sportdetail">If the match was interrupted or one of the teams or an athlete has been disqualified, or for some reason has refused to continue the match, the following options are possible:</div>
                        <div className="col-12 sportdetail">Example 1. If at least one set has been played in full, then all bets on the match winner are considered valid. All bets that have been actually completed by the time of the game interruption are also considered valid, but bets on handicaps on sets or points in a match, regardless of the score, will be refunded.</div>
                        <div className="col-12 sportdetail">Example 2. If the first set has not been played in full, then all bets are calculated based on the current result at the time of the game interruption, and all other bets are calculated with odds equal to "1".
                        Setka Cup Badminton Results: <a href="https://www.facebook.com/setkacupbadminton/" className='sportdetail'>https://www.facebook.com/setkacupbadminton/</a>
                        </div>
                        <div className='col-12 sportsubheading pb-1'>American football</div>

                        <div className="col-12 sportdetail">In American football, the Client can wager on one of the teams to win or win with handicap. In case of a tie, the bets will be refunded.</div>
                        <div className="col-12 sportdetail ">Wagering on total (how many points will be scored) is offered. If the total falls on the spread, the bets will be refunded.</div>
                        <div className="col-12 sportdetail">If for some reason the match was not played on the announced day, the bets will be refunded.</div>
                        <div className="col-12 sportdetail ">
                            <ul>
                                <li className='sportdetail'>Unless otherwise stated, all Pre-match bets on American Football are determined on the basis of the result after the extra (over) time. This does not apply to Live-betting where all bets are settled after Regular time unless otherwise stated.</li>
                                <li className='sportdetail'>In case of any delay (rain, darkness…) all markets remain unsettled and the trading will be continued as soon as the match continues.</li>
                                <li className='sportdetail'>All offered players are considered as runners.</li>
                                <li className='sportdetail'>If no further touchdown is scored, the market will be voided.</li>
                                <li className='sportdetail'>Players which are not listed are considered as “Competitor1 other player” or “Competitor2 other player” for settlement purposes. Note this does not include players which are listed without an active price.</li>
                                <li className='sportdetail'>Players of the Defense - or Special team are considered as “Competitor1 d/st player” or “Competitor2 d/st player” for settlement purposes, even if the player is listed as dedicated outcome.</li>
                                <li className='sportdetail'>Market will be settled based on TV insert and statistics provided by official associations unless there is clear evidence that statistics are not correct.</li>
                                <li className='sportdetail'>Touchdown / Field Goal Markets (including ‘Time of First’, ‘Longest Scored’, ‘Shortest Scored’, and ‘Team to Score Longest’): In the event of no score (via the specified method) bets will be void.</li>
                                <li className='sportdetail'>Will Either Team Score 3 Unanswered Times - Forecast whether either team will score 3 consecutive times during the game. A Score excludes any PAT’s (point after touchdown or 2 point conversions)</li>
                                

                            </ul>
                        </div>
                        <div className='col-12 sportsubheading pb-1'>Rapid Markets:</div>
                        <div className="col-12 sportdetail">New first down & yards gained by play penalties will not be considered for settlement purposes</div>
                        <div className="col-12 sportdetail ">In case of no plays, all markets will be settled with the next play or voided if the drive ends before reaching the respective play
                        </div>
                        <div className="col-12 sportdetail">In case of the drive ended before the respective play number was reached, all markets for the respective play will be considered void. This includes punts and field goals</div>
                        <div className="col-12 sportdetail">Field goal yardage will not be considered for total yards gained in a play</div>
                        <div className="col-12 sportdetail">A touchdown is credited as a first down only when the offence scores a touchdown</div>
                        <div className='col-12 sportsubheading pb-1'>Baseball</div>

                        <div className="col-12 sportdetail ">The Betting company accepts bets on baseball within the approved betting line, but there are a number of features when calculating bets.</div>
                        <div className="col-12 sportdetail">For a bet to be considered valid, the minimum duration of a baseball game shall be at least five innings, except when the home team leads after four and a half innings. If a match is interrupted or postponed for any reason, the game winner is determined according to the result after the last full inning, except cases when the home team evens the score or takes the first lead in the second half of the period. Then the winner is determined with a result at the match interruption. If the game is interrupted after the home team has evened the score, bets on the game are calculated with odds equal to "1".
                        </div>
                        <div className="col-12 sportdetail ">Bets on the outcome of the first five innings of a match are settled based on the result of the game result after the full five innings. In case of a draw, bets on the victory of one of the teams are calculated with odds equal to "1".</div>
                        <div className="col-12 sportdetail">To consider a bet on totals and handicaps in a match as valid, at least nine innings need to be played or, if the home team leads in the score, eight and a half. If a match is postponed or interrupted for any reason in extra innings, the match result is determined after the last full inning, except cases when the a home team evens the score or takes the first lead in the second half of the period. Then the winner is determined at the time of the match interruption. If from 5.5 to 8 innings have been played, the values of a handicap and totals, which have not been specified to the moment of interruption, are settled with odds equal to "1". Bets on the "home/away" market on the number of wins of the home and/or away team are also calculated. The rest of the outcomes are settled with odds equal to "1".
                        </div>
                        <div className="col-12 sportdetail ">In the "home/away" baseball market, the game-day matches are counted with the number of matches indicated. And in the "Most/Least Productive Match" market, bets are settled based on the highest/lowest game score in any of the specified matches. The "Number of Shutout Matches" bet is settled based on the number of teams that have gained no points in a given game day. If two matches have been played on the same game day, and the Betting company recorded only one, the result is counted for the first among them.</div>
                        <div className="col-12 sportdetail">The "Number of Extra Innings " bet is settled in accordance with a total number of additional innings played in all matches.</div>
                        <div className="col-12 sportdetail ">If the pitcher was substituted in the match, this event does not affect the match outcome.
                        </div>
                        <div className="col-12 sportdetail">If the match has not taken place for some reason within the scheduled time and/or has been postponed for more than twelve hours from the time indicated in the betslip, all bets are settled with odds equal to "1". 
                        </div>
                        <div className="col-12 sportdetail ">If the match has not been finished due to bad weather conditions or other reasons, bets on the match winner are settled based on the result at the time of the game interruption, assuming that this result is approved by the tournament organizers as final. Otherwise, all bets on this match are settled with odds equal to "1".
                        </div>
                        <div className='col-12 sportsubheading pb-1'>Rapid Markets</div>


                        <div className="col-12 sportdetail">
                            <ul>
                                <li className='sportdetail'>If a hit and an error occur within the same play, a hit will be considered for settlement purposes.</li>
                                <li className='sportdetail'>If a player does not show up at the plate again but related player markets have been offered, undecided markets are considered void.</li>
                                <li className='sportdetail'>A foul ball will always be considered as strike for settlement purposes.</li>
                            </ul>
                        </div>
                        <div className='col-12 sportsubheading pb-2'>Cricket</div>
                        <div className='col-12 sportsubheading '>1. To win/3-way betting:</div>


                        <div className="col-12 sportdetail ">All match betting will be settled in accordance with official competition rules.</div>
                        <div className="col-12 sportdetail">In matches affected by adverse weather, bets will be settled according to the official result.</div>
                        <div className="col-12 sportdetail ">If there is no official result, all bets will be void.</div>
                        <div className="col-12 sportdetail">In the case of a tie, if the official competition rules do not determine a winner then dead-heat rules will apply. </div>
                        <div className="col-12 sportdetail">In competitions where a bowl off or super over determines a winner, bets will be settled on the official result.</div>

                        <div className="col-12 sportdetail">In First Class Matches, if the official result is a tie, bets will be settled as a dead-heat between both teams and not as a draw. If the Draw was offered, bets on the Draw will be settled as losers in the case of a Tied match.</div>
                        <div className="col-12 sportdetail ">If a match is abandoned due to external factors, then bets will be void unless a winner is declared based on the official competition rules.</div>
                        <div className="col-12 sportdetail">If a match is cancelled then all bets will be void if it is not replayed or restarted within 48 hours of its advertised start time. If there is a change in teams from originally advertised then all bets will be void.
                        In First Class Matches, in case of Draw, To win(2-way) bets will be void.
                        </div>
                        <div className='col-12 sportsubheading pb-1'>2. Double Chance:</div>

                        <div className="col-12 sportdetail ">A tie will be settled as a dead heat.</div>
                        <div className="col-12 sportdetail">All match betting will be settled in accordance with official competition rules.</div>
                        <div className="col-12 sportdetail ">If there is no official result, all bets will be void</div>

                        <div className='col-12 sportsubheading pb-1'>3. Tied Match: </div>


                        <div className="col-12 sportdetail">All bets will be settled according to the official result at the end of the match not including super overs, bowl offs or any other means of deciding the winner in the event of a tied match. Must be an official match result for bets to stand. </div>
                        <div className="col-12 sportdetail ">If the match is abandoned or there is no official result, all bets will be void.</div>
                        <div className="col-12 sportdetail">For First Class matches a tie is when the match has reached a natural conclusion with all innings being completed and the final scores are level. If all innings are not completed in their entirety at the end of the match then the official match result will stand. A declared innings counts as a completed innings. 
                        </div>
                        <div className='col-12 sportsubheading pb-1'>4. Toss winner:</div>
                        <div className="col-12 sportdetail">If no toss takes place, all bets will be void.</div>
                        <div className='col-12 sportsubheading pb-1'>5. Most Fours/ Most Sixes:</div>



                        <div className="col-12 sportdetail ">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled in either innings due to external factors, including bad weather, unless settlement of the bet has already been determined. or the match has reaches its natural conclusion without either innings being shortened to less than 80% of the originally alloted overs. Bets will stand if either innings is shortened due to it reaching a natural conclusion. </div>
                        <div className="col-12 sportdetail">"In First Class matches resulting in a draw, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail">Only fours/sixes scored from the bat (off any delivery – legal or not) will count towards the total fours. Overthrows, all run fours and extras do not count.</div>
                        <div className="col-12 sportdetail">Fours scored in a super over do not count.</div>
                        <div className="col-12 sportdetail">In First Class games and Test Matches, only first innings fours/sixes will count.</div>

                        <div className='col-12 sportsubheading pb-1'>6. Most Extras: </div>


                        <div className="col-12 sportdetail">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled in either innings due to external factors, including bad weather, unless settlement of the bet has already been determined or the match has reaches its natural conclusion without either innings being shortened to less than 80% of the originally alloted overs. Bets will stand if either innings is shortened due to it reaching a natural conclusion.</div>
                        <div className="col-12 sportdetail">In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail ">All wide deliveries, no balls, byes, leg byes and penalty runs in the match count towards the final result. If there are runs off the bat as well as extras from the same delivery, the runs off the bat do not count towards the final total.</div>
                        <div className="col-12 sportdetail">6Extras in a super over do not count.</div>
                        <div className="col-12 sportdetail">In First Class games, only first innings extras will count.</div>

                        <div className='col-12 sportsubheading pb-1'>7. Most Run Outs Whilst Fielding:</div>

                        <div className="col-12 sportdetail">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, unless settlement has been determined.</div>


                        <div className="col-12 sportdetail">In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail">Run Outs in a super over do not count.</div>
                        <div className="col-12 sportdetail">In First Class games, only first innings run outs will count.</div>
                        <div className='col-12 sportsubheading pb-1'>8. Most catches/keeper catches: </div>


                        <div className="col-12 sportdetail">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled in either innings due to external factors, including bad weather, unless settlement of the bet has already been determined or the match has reaches its natural conclusion without either innings being shortened to less than 80% of the originally alloted overs. Bets will stand if either innings is shortened due to it reaching a natural conclusion.</div>
                        <div className="col-12 sportdetail">In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined. Catches in a super over do not count.</div>

                        <div className='col-12 sportsubheading pb-1'>9. Most keeper stumpings: </div>


                        <div className="col-12 sportdetail">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled in either innings due to external factors, including bad weather, unless settlement of the bet has already been determined or the match has reaches its natural conclusion without either innings being shortened to less than 80% of the originally alloted overs. Bets will stand if either innings is shortened due to it reaching a natural conclusion.</div>
                        <div className="col-12 sportdetail">In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.
                        </div>
                        <div className="col-12 sportdetail">Stumpings in a super over do not count. </div>
                        <div className='col-12 sportsubheading pb-1'>10. 3-way betting. Runs in over #: </div>


                        <div className="col-12 sportdetail">The specified over must be completed for bets to stand unless settlement has already been determined. If during the first over the innings is ended due to external factors, including bad weather, all bets will be void, unless settlement has already been determined.</div>
                        <div className="col-12 sportdetail ">In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail">In First Class matches the market refers only to each team’s first innings.</div>

                        <div className='col-12 sportsubheading pb-1'>11. Highest score in First 5/6/10/12/15/20 Overs: </div>


                        <div className="col-12 sportdetail">If the specified number of overs are not completed in both innings the bet will be void, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail">In limited overs matches, if the match is shortened due to external factors after the start of the first innings, the market will be void.
                        </div>
                        <div className="col-12 sportdetail">In First Class matches the market refers only to each team’s first innings.</div>
                        <div className="col-12 sportdetail">The market will be settles according to the official result.</div>
                        <div className='col-12 sportsubheading pb-1'>12. The best result of the first partnership / Highest score at 1st dismissal:  </div>

                        <div className="col-12 sportdetail">If the batting team reaches the end of their allotted overs, reaches their target or declares before the first wicket falls, the result will be the total amassed.</div>
                        <div className="col-12 sportdetail">For settlement purposes, a batsman retiring hurt does not count as a wicket.</div>
                        <div className="col-12 sportdetail ">In limited overs matches, bets will be void if the innings has been reduced (by more than 10%)due to external factors, including bad weather, unless settlement has already been determined.</div>

                        <div className="col-12 sportdetail">In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined. </div>
                        <div className="col-12 sportdetail">In First Class matches the market refers only to each team’s first innings.</div>
                        <div className='col-12 sportsubheading pb-1'>13. Total Fours / Total Sixes / Total Boundary: </div>


                        <div className="col-12 sportdetail">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled due to external factors, including bad weather, unless settlement of the bet has already been determined. </div>
                        <div className="col-12 sportdetail">In drawn First Class matches the whole match counts. Bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined. </div>
                        <div className="col-12 sportdetail">Only fours/sixes/boundaries scored from the bat (off any delivery – legal or not) will count towards the total fours/sixes. Overthrows, all run fours and extras do not count.</div>
                        <div className='col-12 sportsubheading pb-1'>14. Wickets Total: </div>


                        <div className="col-12 sportdetail">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail">In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail ">Retired hurt does not count as a dismissal.</div>

                        <div className='col-12 sportsubheading pb-1'>15. Total runs / Team total runs:  </div>


                        <div className="col-12 sportdetail">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail">In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail">Runs scored in super over do not count.</div>

                        <div className='col-12 sportsubheading pb-1'>16.  Total Ducks:  </div>

                        <div className="col-12 sportdetail ">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail">In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined. 
                        Ducks scored in super-over do not count.
                        </div>
                        <div className='col-12 sportsubheading pb-1'>17. Extras Total:  </div>

                        <div className="col-12 sportdetail">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled due to external factors, including bad weather, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail ">In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail">All wide deliveries, no balls, byes, leg byes and penalty runs in the match count towards the final result. If there are runs off the bat as well as extras from the same delivery, the runs off the bat do not count towards the final total.
                        </div>


                        <div className="col-12 sportdetail">Extras in a super over do not count.</div>
                        <div className='col-12 sportsubheading pb-1'>18. Total Run Outs:  </div>
                        <div className="col-12 sportdetail">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail">In First Class matches the whole match counts but in the case of a drawn match bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.
                            Run outs in a super over do not count.
                            </div>
                        <div className='col-12 sportsubheading pb-1'>19. Total in highest scoring over:  </div>

                        <div className="col-12 sportdetail">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled due to external factors, including bad weather, unless settlement of the bet has already been determined.</div>

                        <div className="col-12 sportdetail ">In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.</div>

                        <div className="col-12 sportdetail ">All runs, including extras, count towards settlement.</div>
                        <div className="col-12 sportdetail ">Super overs do not count.</div>
                        <div className='col-12 sportsubheading pb-1'>20. Top batter:   </div>


                        <div className="col-12 sportdetail ">In limited overs matches, bets will be void if it has not been possible to complete at least 50% of the overs scheduled to be bowled in either innings at the time the bet was placed due to external factors, including bad weather unless the innings has reached a natural conclusion.</div>
                        <div className="col-12 sportdetail ">Top batsmen bets for First Class matches apply only to the first innings of each team, and will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined. If a player was named at the toss, but later is removed as a concussion sub, that player will still be counted, as will the replacement player. If a batsman does not bat, but was named in the starting XI, bets on that batsman will stand. If a batsman is substituted in after the in-play market has been offered, and scores the highest individual score, bets on the market will be void, unless there is a dead-heat. If a substitute (concussion, or otherwise) not named in the original XI is able to bat in their teams first innings, regardless of if they actually bat or not , bets on the market will stand.
                        </div>
                        <div className="col-12 sportdetail ">When two or more players score the same number of runs, these bets will settle as winners.</div>
                        <div className="col-12 sportdetail ">Runs scored in a super over do not count.</div>
                        <div className='col-12 sportsubheading pb-1'>21. Top bowler:   </div>


                        <div className="col-12 sportdetail ">In limited overs matches, bets will be void if it has not been possible to complete at least 50% of the overs scheduled to be bowled in either innings at the time the bet was placed due to external factors, including bad weather unless the innings has reached a natural conclusion.</div>
                        <div className="col-12 sportdetail ">Top bowler bets for First Class matches apply only to the first innings of each team, and will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail ">If a player was named at the toss, but later is removed as a concussion sub, that player will still be counted, as will the replacement player. If a bowler does not bowl, but was named in the starting XI, bets on that bowler will stand. If a bowler is substituted in after the in-play market has been offered, and takes the most wickets, bets on the market will be void, unless there is a dead-heat. If a substitute (concussion, or otherwise) not named in the original XI bowls at least one delivery in their teams first innings,bets on the market will stand.
                        </div>
                        <div className="col-12 sportdetail ">If two or more bowlers have taken the same number of wickets, the bowler who has conceded the fewest runs will be the winner. If there are two or more bowlers with the same wickets taken and runs conceded, these bets will settle as winners. Wickets taken in a super over don’t count.
                        </div>
                        <div className='col-12 sportsubheading pb-1'>22. Player of the match:   </div>


                        <div className="col-12 sportdetail ">Bets will be settled on the officially declared man of the match.</div>
                        <div className="col-12 sportdetail ">When two or more players are declared as Players of the match, these bets will settle as winners.
                        If no man of the match is officially declared then all bets will be void.
                        </div>
                        <div className='col-12 sportsubheading pb-1'>23. First Ball Of Match / Second Ball Of Match / Third Ball Of Match:   </div>


                        <div className="col-12 sportdetail ">Dead balls do not count, at least one ball must be delivered or will be made void. </div>
                        <div className="col-12 sportdetail ">Wides/No Balls/Bye/leg byes - runs allotted to these do not apply in this market e.g. 5 wides is a settled as Wide.</div>

                        <div className='col-12 sportsubheading pb-1'>24. Wide balls total:   </div>

                        <div className="col-12 sportdetail ">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled due to external factors, including bad weather, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail ">In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail ">Wides in a super over do not count.</div>
                        <div className='col-12 sportsubheading pb-1'>25. Runs total in delivery:   </div>


                        <div className="col-12 sportdetail ">The result will be determined by the number of runs added to the team total, off the specified delivery.</div>
                        <div className="col-12 sportdetail ">For settlement purposes, all illegal balls count as deliveries. For example, if an over starts with a wide, then the first delivery will be settled as 1 and, although there has not been a legal ball bowled, the next ball will be deemed as delivery 2 for that over.
                        </div>
                        <div className="col-12 sportdetail ">If a delivery leads to free hit or a free hit is to be re-bowled because of an illegal delivery, the runs scored off the additional delivery do not count.</div>
                        <div className="col-12 sportdetail ">All runs, whether off the bat or not are included. For example, a wide with three extra runs taken equates to 4 runs in total off that delivery.</div>
                        <div className='col-12 sportsubheading pb-1'>26. Runs total in Over #:   </div>


                        <div className="col-12 sportdetail ">The specified over must be completed for bets to stand unless settlement has already been determined. If an innings ends during an over then that over will be deemed to be complete unless the innings is ended due to external factors, including bad weather, in which case all bets will be void, unless settlement has already been determined.</div>
                        <div className="col-12 sportdetail ">If the over does not commence for any reason, all bets will be void.</div>
                        <div className="col-12 sportdetail ">Extras and penalty runs will only count if they are accredited to a particular delivery within that over.</div>

                        <div className='col-12 sportsubheading pb-1'>27. Wicket in Over #:   </div>



                        <div className="col-12 sportdetail ">As “Runs total in Over”.</div>
                        <div className="col-12 sportdetail ">For settlement purposes, any wicket will count, including run outs. A batsman retiring hurt does not count as a wicket. If a batsman is timed out or retired out then the wicket is deemed to have taken place on the previous ball.</div>
                        <div className='col-12 sportsubheading pb-1'>28. Over Odd/Even:   </div>

                        <div className="col-12 sportdetail ">As “Runs total in Over”.</div>
                        <div className="col-12 sportdetail ">Zero will be deemed to be an even number.</div>
                        <div className='col-12 sportsubheading pb-1'>29. Match Odd/Even:    </div>

                        <div className="col-12 sportdetail ">As “Total runs”.</div>
                        <div className='col-12 sportsubheading pb-1'>30. First 5/6/10/15/20 Overs total:  </div>


                        <div className="col-12 sportdetail ">If the specified number of overs are not complete the bet will be void, unless the team is all out, declares, reaches their target or settlement of the bet has already been determined.</div>
                        <div className='col-12 sportsubheading pb-1'>31. Total dismissals at first 5/10/15 Overs:  </div>

                        <div className="col-12 sportdetail ">If the specified number of overs are not complete the bet will be void, unless the team is all out, declares, reaches their target or settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail ">For settlement purposes, if a batsman is timed out or retired out then the wicket is deemed to have taken place on the previous ball.</div>

                        <div className='col-12 sportsubheading pb-1'>32. 3-way betting. Innings:</div>
                        <div className="col-12 sportdetail ">Both teams must complete their first innings for bets to stand.</div>
                        <div className='col-12 sportsubheading pb-1'>33. Innings Runs: </div>

                        <div className="col-12 sportdetail ">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather, unless settlement of the bet has already been determined. Bets placed on a future innings will remain valid regardless of the runs scored in any current or previous innings.</div>
                        <div className="col-12 sportdetail ">In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined. Bets will also be void in drawn first class matches, if less than 60 overs have been bowled in an incomplete innings, unless settlement of the bet has already been determined. If a team declares, that innings will be considered complete for the purposes of settlement.</div>
                        <div className="col-12 sportdetail ">Penalty runs awarded after the conclusion of the innings, will not count towards innings runs.</div>
                        <div className='col-12 sportsubheading pb-1'>34. Innings Dismissals total:  </div>

                        <div className="col-12 sportdetail ">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail ">In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail ">Retired hurt does not count as a dismissal.</div>
                        <div className='col-12 sportsubheading pb-1'>35. Innings Fours total / Innings Sixes total:  </div>

                        <div className="col-12 sportdetail ">Only fours/sixes scored from the bat (off any delivery – legal or not) will count towards the total fours/sixes. Overthrows, all run fours and extras do not count.</div>
                        <div className="col-12 sportdetail ">Fours/sixes scored in a super over do not count.</div>
                        <div className='col-12 sportsubheading pb-1'>36. Innings Extras total:  </div>

                        <div className="col-12 sportdetail ">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather, unless settlement of the bet has already been determined. Bets placed on a future innings will remain valid regardless of the runs scored in any current or previous innings.</div>
                        <div className="col-12 sportdetail ">In first class or Test matches at least 60 overs must be bowled in the Innings unless team is all out or declares. All wide deliveries, no balls, byes, leg byes and penalty runs in the match count towards the final result. If there are runs off the bat as well as extras from the same delivery, the runs off the bat do not count towards the final total.</div>
                        <div className="col-12 sportdetail ">Extras in a super over do not count.</div>
                        <div className='col-12 sportsubheading pb-1'>37. Innings Run Outs total:  </div>


                        <div className="col-12 sportdetail ">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather, unless settlement of the bet has already been determined. Bets placed on a future innings will remain valid regardless of the runs scored in any current or previous innings.</div>
                        <div className="col-12 sportdetail ">In first class or Test matches at least 60 overs must be bowled in the Innings unless team is all out or declares.</div>
                        <div className="col-12 sportdetail ">Run outs in a super over do not count.</div>
                        <div className='col-12 sportsubheading pb-1'>38. Innings Wide balls total: </div>


                        <div className="col-12 sportdetail ">Wides in a super over do not count.</div>
                        <div className='col-12 sportsubheading pb-1'>39. Top batter in Innings:  </div>

                        <div className="col-12 sportdetail ">The result of this market is determined on the batsman with the highest individual score in a team’s innings.</div>
                        <div className="col-12 sportdetail ">In limited overs matches, bets will be void if it has not been possible to complete at least 50% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather.</div>
                        <div className="col-12 sportdetail ">Top batsman bets for First Class matches apply only to the first innings of each team, and will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail ">If a player was named at the toss, but later is removed as a</div>
                        <div className="col-12 sportdetail ">concussion sub, that player will still be counted, as will the replacement player.</div>
                        <div className="col-12 sportdetail ">If a batsman does not bat, but was named in the starting XI, bets on that batsman will stand.
                        </div>
                        <div className="col-12 sportdetail ">If a batsman is substituted in after the in-play market has been offered, and scores the highest individual score, bets on the market will be void, unless there is a dead-heat.</div>
                        <div className="col-12 sportdetail ">If a substitute (concussion, or otherwise) not named in the original XI is able to bat in their teams first innings, regardless of if they actually bat or not , bets on the market will stand.</div>
                        <div className="col-12 sportdetail ">In the case of two or more batsmen winning with the same total the winner shall be the one who has faced the fewest deliveries, if that is the same the winner shall be who has hit the most boundaries, if there is still no clear winner then bets will be void</div>
                        <div className="col-12 sportdetail ">Runs scored in a super over do not count.</div>
                        <div className='col-12 sportsubheading pb-1'>40. Top Bowler in Innings:</div>


                        <div className="col-12 sportdetail ">The result of this market is determined on the bowler with the highest individual number of wickets in an individual innings.</div>
                        <div className="col-12 sportdetail ">In limited overs matches, bets will be void if it has not been possible to complete at least 50% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather.</div>
                        <div className="col-12 sportdetail ">Top bowler bets for First Class matches apply only to the first innings of each team, and will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined. If a player was named at the toss, but later is removed as a concussion sub, that player will still be counted, as will the replacement player.  If a bowler does not bowl, but was named in the starting XI, bets on that bowler will stand.  If a bowler is substituted in after the in-play market has been offered, and takes the most wickets, bets on the market will be void, unless there is a dead-heat. If a substitute (concussion, or otherwise) not named in the original XI bowls at least one delivery in their teams first innings,bets on the market will stand."</div>
                        <div className="col-12 sportdetail ">If two or more bowlers have taken the same number of wickets, the bowler who has conceded the fewest runs will be the winner. If there are two or more bowlers with the same wickets taken and runs conceded, dead heat rules will apply. Wickets taken in a super over don’t count.
                        </div>
                        <div className="col-12 sportdetail ">If no bowlers take a wicket in an innings then all bets will be void.</div>
                        <div className='col-12 sportsubheading pb-1'>41. Total Runs Player / Player to score 25/50/75/100 runs / Highest individual score / Top batter total:</div>


                        <div className="col-12 sportdetail ">If the batsman finishes the innings not out, as a result of a declaration, the team reaching the end of their allotted overs, or the team reaching their target; his score will be the final result. If a batsman does not bat, the bet will be void. If a batsman is not in the starting 11, bets will be void.</div>
                        <div className="col-12 sportdetail ">If a batsman retires hurt, but returns later, the total runs scored by that batsman in the innings will count. If the batsman does not return later, the final result will be as it stood when the batsman retired.</div>
                        <div className="col-12 sportdetail ">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, unless settlement has been determined, or goes on to be determined. Result will be considered determined if the line at which the bet was placed is passed, or the batsman is dismissed.
                        </div>
                        <div className="col-12 sportdetail ">In drawn First Class matches, bets will be void if fewer than 200 overs are bowled, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail ">Runs scored in a super over do not count.</div>
                        <div className='col-12 sportsubheading pb-1'>42. Will score 50/100 runs:</div>

                        <div className="col-12 sportdetail ">Same as "Total Runs Player"</div>
                        <div className='col-12 sportsubheading pb-1'>43. Player Total Fours / Player Total Sixes: </div>

                        <div className="col-12 sportdetail ">If a batsman finishes the innings not out, as a result of a declaration, the team reaching the end of their allotted overs, or the team reaching their target; his number of fours will be the final result. If a batsman does not bat, the bet will be void. If a batsman is not in the starting XI, bets will be void.</div>
                        <div className="col-12 sportdetail ">If a batsman retires hurt, but returns later, the total fours hit by that batsman in the innings will count. If the batsman does not return later, the final result will be as it stood when the batsman retired.</div>
                        <div className="col-12 sportdetail ">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, unless settlement has been determined, or goes on to be determined. Result will be considered determined if the line at which the bet was placed is passed, or the batsman is dismissed.
                        </div>
                        <div className="col-12 sportdetail ">In drawn First Class matches, bets will be void if fewer than 200 overs are bowled, unless settlement of the bet has already been determined.
                        </div>
                        <div className="col-12 sportdetail ">Only fours/sixes scored from the bat (off any delivery – legal or not) will count towards the total fours/sixes. Overthrows, all run fours and extras do not count.
                        </div>
                        <div className="col-12 sportdetail ">Fours scored in a super over do not count.
                        </div>
                        <div className='col-12 sportsubheading pb-1'>44. Dismissal method (2 ways) / Dismissal method (7 ways) / Player Dismissal method:</div>

                        <div className="col-12 sportdetail ">If the specified batsman is not out, all bets will be void.</div>
                        <div className="col-12 sportdetail ">If the specified batsman retires, and does not return to bat later, all bets will be void. If that batsman does return to bat later and is out, bets will stand. If the specified wicket does not fall, all bets will be void.</div>
                        <div className='col-12 sportsubheading pb-1'>45. Fall of # wicket: </div>
                        <div className="col-12 sportdetail ">If the batting team reaches the end of their allotted overs, reaches their target or declares before the specified wicket falls, the result will be the total amassed.</div>
                        <div className="col-12 sportdetail ">For settlement purposes, a batsman retiring hurt does not count as a wicket.</div>
                        <div className="col-12 sportdetail ">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, unless settlement has already been determined, or goes on to be determined. Result will be considered determined if the line at which the bet was placed is passed, or the wicket in question falls.</div>
                        <div className="col-12 sportdetail ">In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.</div>
                        <div className='col-12 sportsubheading pb-1'>46. Batters H2H:  </div>


                        <div className="col-12 sportdetail ">Bets will settle based on the official scores for the specified batsmen in the innings, as detailed in the “Total Runs Player” section above.</div>
                        <div className="col-12 sportdetail ">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, after the bet is placed unless settlement has already been determined.</div>
                        <div className='col-12 sportsubheading pb-1'>47. Bowlers H2H: </div>


                        <div className="col-12 sportdetail ">In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, unless settlement has been determined.</div>
                        <div className="col-12 sportdetail ">In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.</div>
                        <div className="col-12 sportdetail ">Both players must be named in the starting XI If either does not then subsequently bowl all bets are still settled.</div>
                        <div className="col-12 sportdetail ">Wickets taken in a super over do not count.</div>
                        <div className='col-12 sportsubheading pb-1'>48. Series Winner: </div>


                        <div className="col-12 sportdetail ">Bets on Series markets stand as long as at least one scheduled match is completed, unless stated otherwise.</div>
                        <div className="col-12 sportdetail ">If there is no further play in a Series after bets are placed, then bets will be void.</div>
                        <div className="col-12 sportdetail ">If a Series is drawn and no draw option is offered, bets will be void.</div>
                        <div className='col-12 sportsubheading pb-1'>49. Series winner after 2 Tests: </div>


                        <div className="col-12 sportdetail ">Bets on Series markets stand as long as at least two scheduled matches is completed, unless stated otherwise. </div>
                        <div className="col-12 sportdetail ">If there is no further play in a Series after bets are placed, then bets will be void.</div>
                        <div className='col-12 sportsubheading pb-1'>50. Series Handicap: </div>


                        <div className="col-12 sportdetail ">Bets will be void if the number of scheduled games (after bets are placed) are not played, unless settlement of bets is determined.</div>

                        <div className='col-12 sportsubheading pb-1'>51. Team With Highest Innings Score:</div>


                        <div className="col-12 sportdetail ">If a Series is drawn, bets will be void.</div>
                        <div className='col-12 sportsubheading pb-1'>52. Series Correct Score:</div>

                        <div className="col-12 sportdetail ">Series Correct Score - Bets will be void if the number of scheduled games are not played.</div>
                        <div className='col-12 sportsubheading pb-1'>53. Result of the 1st Match Test/Series: </div>

                        <div className="col-12 sportdetail ">Bets on Series markets stand as long as at least one scheduled match is completed, unless stated otherwise.</div>
                        <div className="col-12 sportdetail ">If there is no further play in a Series after bets are placed, then bets will be void.</div>
                        <div className="col-12 sportdetail ">If a Series is drawn and no draw option is offered, bets will be void.</div>
                        <div className='col-12 sportsubheading pb-1'>54. Player Of The Series: </div>

                        <div className="col-12 sportdetail ">Any quoted player, who takes no part in the specified series, will be void. Winner as declared by the ICC. Dead-heat rules apply.</div>
                        <div className='col-12 sportsubheading pb-1'>55. Double chance:</div>


                        <div className="col-12 sportdetail ">All match betting will be settled in accordance with official results.</div>
                        <div className="col-12 sportdetail ">If there is no official result, all bets will be void.</div>
                        <div className='col-12 sportsubheading pb-1'>56. Players sent off / retired out:</div>


                        <div className="col-12 sportdetail ">A player being sent off is viewed as retired out, so will be settled as a wicket.</div>
                        <div className='col-12 sportsubheading pb-1'>57. Concussion substitutions:</div>

                        <div className="col-12 sportdetail ">When a player leaves the field as a concussion substitute, this will not count as a wicket. If the player does not return later, the final result will be as it stood when the player left the field. When a player enters the match as a concussion substitute, for settlement purposes both they and the player replaced will be looked upon as to have played a full part in the match.</div>
                        <div className='col-12 sportsubheading pb-1'>Ecricket</div>

                        <div className="col-12 sportdetail ">
                            <ul>
                                <li className="sportdetail">Match Winner</li>
                            </ul>
                        </div>
                        
                        <div className="col-12 sportdetail ">If the Match is not completed for whatever reason, and there is no official result, bets will be void.</div>
                        <div className="col-12 sportdetail ">Where there is a tie and a winner is declared due to a Super Over or something similar, bets will be settled on that declared result. In the event of disruptions due to technical issues, where no official winner is declared, bets will be void unless it is possible to resume the Match within 4 hours of the original start time, and a winner is declared.
                        </div>
                        <div className="col-12 sportdetail ">
                            <ul>
                                <li className="sportdetail">Innings Runs</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">The innings in its entirety must be bowled, unless the batting team is all out or reaches its target, or bets will be void – unless settlement is already determined.</div>
                        <div className="col-12 sportdetail ">
                            <ul>
                                <li className="sportdetail">Runs in Session</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">The specified Session in its entirety must be bowled, unless the batting team is all out or reaches its target within the Session, or bets will be void – unless settlement is already determined.</div>
                        <div className="col-12 sportdetail ">
                            <ul>
                                <li className="sportdetail">Runs in Next Over</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">The Over in its entirety must be bowled unless the batting team is All Out or reaches its target within the Over, unless the result of this market is already determined. Extras and Penalty Runs will count only where they are accredited to a particular delivery within that Over.</div>
                        <div className="col-12 sportdetail ">
                            <ul>
                                <li className="sportdetail">Fall of Wicket</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">The Team score at the fall of the named wicket will be taken for settlement purposes. If the Innings is completed or the batting team reaches its target then the final Innings score will be deemed the result for settlement purposes.</div>
                        <div className="col-12 sportdetail ">
                            <ul>
                                <li className="sportdetail">Innings Fours</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">The innings in its entirety must be bowled, unless the batting team is all out or reaches its target, or bets will be void – unless settlement is already determined.</div>
                        <div className="col-12 sportdetail ">
                            <ul>
                                <li className="sportdetail">Innings Sixes</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">The innings in its entirety must be bowled, unless the batting team is all out or reaches its target, or bets will be void – unless settlement is already determined.</div>
                        <div className="col-12 sportdetail ">
                            <ul>
                                <li className="sportdetail">Runs off Ball x</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">All runs accredited to that ball count, the ball must be a legal and completed. Any illegal deliveries (wides and no balls) will not be counted as balls for the purpose of this market. For example, even if there are two wides bowled in that over, there will still only be 6 legal balls bowled in the over. For clarity if the named ball is a wide and then followed by a boundary four, then the market result will be settled as 5. The ball must be legally bowled for bets to stand. If the named ball is a no ball and leads to a free hit, the runs scored off the free hit will count, for example if the ball is a no ball and then followed by a six off the free hit then the market result will be 7.</div>
                        <div className="col-12 sportdetail ">
                            <ul>
                                <li className="sportdetail">Method of Dismissal</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">Methods of dismissal only include: Bowled, Caught, LBW, Run Out, Stumped, Obstructing the Field, Hit the Ball Twice, Hit Wicket and Timed Out – any wicket that is not named will be considered ‘Others’. If a batsman Retires this does not count as a dismissal for settlement purposes. Market will be settled on the dismissal method for the specified wicket number, regardless of whether the players are different due to retirements or otherwise. If the Innings ends with the named wicket not falling, then all bets will be void.
                        </div>
                        <div className="col-12 sportdetail ">
                            <ul>
                                <li className="sportdetail">Most Fours</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">There must be an official match result for bets to stand unless settlement is already determined (the team batting second has already surpassed its target. Fours in a Super Over do not count.</div>
                            <div className="col-12 sportdetail ">
                            <ul>
                                <li className="sportdetail">Most Sixes</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">There must be an official match result for bets to stand unless settlement is already determined (the team batting second has already surpassed its target. Sixes in a Super Over do not count.</div>
                            <div className="col-12 sportdetail ">
                            <ul>
                                <li className="sportdetail">Match Fours</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">There must be an official match result for bets to stand unless settlement is already determined (the total has already been surpassed). Boundary fours scored from the bat only to count - overthrows, all run fours and extras do not. Fours scored in a super over do not count.</div>
                        <div className="col-12 sportdetail ">
                            <ul>
                                <li className="sportdetail">Match Sixes</li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">There must be an official match result for bets to stand unless settlement is already determined (the total has already been surpassed). Boundary sixes scored from the bat only to count. Sixes scored in a super over does not count.</div>
                        <div className="col-12 sportdetail ">
                            <ul>
                                <li className="sportdetail">Batsman Fours
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">Boundary Fours scored from the bat only to count - overthrows, all run fours and extras do not. Fours hit in a Super Over do not count.</div>
                        <div className="col-12 sportdetail ">
                            <ul>
                                <li className="sportdetail">Batsman Sixes
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">Boundary Sixes scored from the bat only to count. Sixes hit in a Super Over do not count.</div>
                        <div className="col-12 sportdetail ">
                            <ul>
                                <li className="sportdetail">Four in Next Over
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">Fours accredited to batsmen only to count. Byes, Leg byes or Wides do not count. Fours hit from a No Ball will count if the Four is accredited to the batsman. The Over in its entirety must be bowled unless the batting team is All Out or reaches its target within the Over, unless the result is already detemined. Overthrows do not count towards the batsman and so do not count for the purposes of this market.</div>
                        <div className="col-12 sportdetail ">
                            <ul>
                                <li className="sportdetail">Six in Next Over
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">Sixes hit by a batsman only to count. The Over in its entirety must be bowled unless the batting team is All Out or reaches its target within the Over, unless the result of this market is already determined. If a total of Six runs is scored from an individual delivery in the Over but this is not a Six hit by a batsman this does not count for the purposes of this market.</div>
                        <div className="col-12 sportdetail ">
                            <ul>
                                <li className="sportdetail">Wicket in Next Over
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">The Over in its entirety must be bowled unless the batting team is All Out or reaches its target within the Over, unless the result of this market is already determined.</div>
                        <div className="col-12 sportdetail ">Source:</div>
                        <div className="col-12 sportdetail "><a className='sportdetail' href="https://www.intelligentcricket.com/games/games-page?gameType=QUANTUM">https://www.intelligentcricket.com/games/games-page?gameType=QUANTUM</a></div>

                        <div className='col-12 sportsubheading pb-1'>MMA</div>
                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">The Betting company offers the Client the following types of bets:</li>
                                <li className="sportdetail">Who will win the fight: winning by knockout or technical knockout, by submission or surrender of an opponent, decision of the judges or technical decision (based on points), disqualification or failure, other</li>
                                <li className="sportdetail">The bet on the ahead-of-time win by winning by knockout or technical knockout, by submission or surrender of an opponent, disqualification or failure, other.</li>
                                <li className="sportdetail">The duration of the fight. Predict a specified number of rounds. Only the number of complete rounds counts for settlement purposes.
                                </li>
                                <li className="sportdetail">The bet on winning a round means that the last round held is considered a win if on of the participating fighters refuses to continue the fight in the break between rounds, or fails to enter the fight after the beginning of the next round.</li>
                                <li className="sportdetail">If the number of rounds in a match changes, bets on the fight outcome and the method of achieving it remain relevant. Bets on the number of rounds will be refunded.</li>
                                <li className="sportdetail">If the referees at MMA tournaments cannot determine the winner of the fight by setting a draw, bets on the fight victory are settled with odds equal to "1". All other bets are settled based on the actual fight outcome.</li>


                                

                            </ul>
                        </div>

                        <div className="col-12 sportdetail ">UFC has differences in rules: </div>
                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">In the case of a draw at UFC tournaments, bets on winning of the participants are settled at the odds "1". The remaining outcomes are settled according to the actual result.</li>
                                <li className="sportdetail">Win - includes winning by Decision (DEC), winning by KO, TKO, winning by Technical Decision (TD) based on points, Disqualification or Failure, winning by submission or surrender of an opponent</li>
                                <li className="sportdetail">Ahead-of-time win – includes winning by KO, TKO, Disqualification or Failure, winning by submission or surrender of an opponent
                                </li>
                                <li className="sportdetail">The duration of the fight. Predict a specified number of rounds. Half of the 5 minute round considered 2 minutes. 30 sec. Therefore, 2.5 rounds - this will be 2 minutes 30 seconds of the 3rd round. If the match ends exactly in the middle (2 minutes 30 seconds) of the round, the over/under rate on the total number of rounds held is returned to the players.
                                </li>
                                <li className="sportdetail">Win in the round - if the fighter refuses to continue the fight in the break between rounds, or after hitting the gong about the beginning of the next round, does not go out, then the last round held is considered to be winning.
                                </li>
                                <li className="sportdetail">If for any reason the selected number of rounds changes, then all bets on number of rounds will be refunded. The bets on the result of the fight and the method of its achieving remain in force</li>

                            </ul>
                        </div>
                        <div className='col-12 sportsubheading pb-1'>eMMA</div>


                        <div className="col-12 sportdetail ">Bets are accepted on a computer game UFC (fighting simulator with 2 players take part in).</div>
                        <div className="col-12 sportdetail ">Matches are played with game settings “3 rounds by 3 minutes each”, complexity level of the game - «Legend», match mode – “accelerated”, fighting in the ground (wrestling) and painful techniques without prompting. vIf the match is stopped due to technical reasons (computer failure, disconnection, etc.) and re-match appointment, bets on stopped game will be settled according to main rules of settling interrupted events.</div>
                        <div className="col-12 sportdetail ">Bets on re-match is accepted as for a new event.</div>
                        <div className="col-12 sportdetail ">If due to technical failure the live streaming of the event is interrupted (disconnection, DDoS, etc.), this is not a reason for canceling bets, except for situations in which it is impossible to know the final result of a match. <a className='sportdetail' href='https://www.facebook.com/esportsbattleufc'>https://www.facebook.com/esportsbattleufc</a>
                        </div>

                        <div className='col-12 sportsubheading pb-1'>Boxing</div>

                        <div className="col-12 sportdetail ">Betting company offers Clients to make the following types of bets on boxing matches and various types of martial arts:
                        </div>
                        <div className="col-12 sportdetail ">Who will win the fight: winning by knockout or technical knockout, by submission or surrender of an opponent, decision of the judges or technical decision (based on points), disqualification or failure, other.
                        </div>
                        <div className="col-12 sportdetail ">The bet on the ahead-of-time win by winning by knockout or technical knockout, by submission or surrender of an opponent, disqualification or failure, other.
                        </div>
                        <div className="col-12 sportdetail ">The bet on the fight duration means that clients need to predict the number of rounds completed by boxers or fighters.
                        </div>
                        <div className="col-12 sportdetail ">The bet on winning a round means that the last round held is considered a win if on of the participating fighters refuses to continue the fight in the break between rounds, or fails to enter the fight after the beginning of the next round.
                        </div>
                        <div className="col-12 sportdetail ">If the number of rounds in a match changes, bets on the fight outcome and the method of achieving it remain relevant. Bets on the number of rounds will be refunded.</div>
                        <div className="col-12 sportdetail ">If the referees cannot determine the winner of the fight by setting a draw, bets on the fighters' victories are settled with odds equal to "1". All other bets are settled based on the actual fight result.</div>
                        <div className='col-12 sportsubheading pb-1'>Futsal</div>


                        <div className="col-12 sportdetail ">The Betting company accepts various bets on handball matches of national championships or European cups, taking into account the result of the game in the regular time and the match rules. Exceptions apply if otherwise specified in the betting line.</div>
                        <div className='col-12 sportsubheading pb-1'>Golf</div>

                        <div className="col-12 sportdetail ">The following types of wagering are offered: </div>
                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">Comparison of two participants of the tournament. The winner is the participant with the lowest score. In case of similar results, the bet will be refunded.</li>
                                <li className="sportdetail">Total of the 1st place score – the score of the player who was announced as a winner of the tournament.
                                </li>
                                <li className="sportdetail">Total the 2nd place score – the score of the player who has finished on the second place in the tournament. If there are more than one participants who finished on the second place, score of only one participant's counts.
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">Score of a player is the number of the ball strokes that were made while passing the holes during the tournament.</div>
                        <div className="col-12 sportdetail ">In the event of a player withdrawing, the other opponent player is considered the winner. If both of the players withdraw, the lowest score after withdrawing will determine the winner.
                        </div>
                        <div className="col-12 sportdetail ">In the event of a player being disqualified after having teed off or withdrawing before the end of 2 rounds or before the end of the tournament, the opponent player is the winner.</div>
                        <div className="col-12 sportdetail ">In the event of a player being disqualified during the third or fourth round and the opponent player has already withdrawn, the disqualified player is the winner.
                        </div>
                        <div className="col-12 sportdetail ">All the results are taken on the tournament official sites.</div>
                        <div className='col-12 sportsubheading pb-1'>Snooker</div>

                        <div className="col-12 sportdetail ">The following types of wagers are offered: </div>
                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">Winner of the match;</li>
                                <li className="sportdetail">Handicaps by games in the match;</li>
                                <li className="sportdetail">Total games in the match.
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">All the bets on matches are valid until the end of the tournament in which they are held.</div>
                        <div className="col-12 sportdetail ">If for some reason one of the participants refuses to continue the competition or he is disqualified, he loses all incomplete games, and the bet will be settled by the received result. If the refusal (disqualification) occurred before the beginning of the match, the bets will be refunded.
                        </div>
                        <div className="col-12 sportdetail ">If the match regulations have been changed, the bets placed on a winner of the match will be settled at the accepted odds while the bets placed on handicaps or totals will be refunded.
                        </div>

                        <div className='col-12 sportsubheading pb-1'>Darts</div>

                        <div className="col-12 sportdetail ">In the case of a match not being finished all undecided markets are considered void.</div>
                        <div className="col-12 sportdetail ">If markets remain open with an incorrect score which has a significant impact on the prices, we reserve the right to void betting.
                        </div>
                        <div className="col-12 sportdetail ">If the players/teams are displayed incorrectly, we reserve the right to void betting.
                        </div>
                        <div className="col-12 sportdetail ">If a match is not completed all undecided markets are considered void.</div>
                        <div className="col-12 sportdetail ">Bullseye counts as red check out colour.</div>
                        <div className="col-12 sportdetail ">All bets on a match remain valid until the end of the tournament in which they take place.</div>
                        <div className="col-12 sportdetail ">If in a match one of the participants refuses to continue the game for any reason or he is disqualified, then in all games not played he is considered a loss, and the payment is made based on the result obtained. If refusal (disqualification) occurs before the start of the match, bets are refundable.
                        </div>
                        <div className="col-12 sportdetail ">If the regulations are changed, then bets on a player’s victory in the match are calculated according to the accepted odds, and bets on handicap and total will be returned.
                        </div>
                        <div className="col-12 sportdetail ">You can check the accuracy of the results on the websites:</div>
                        <div className="col-12 sportdetail ">International BDO <a href='http://www.bdodarts.com/' className='sportdetail'>http://www.bdodarts.com/</a></div>

                        <div className="col-12 sportdetail ">International Federation <a href=' http://www.pdc.tv/home' className='sportdetail'> http://www.pdc.tv/home</a></div>
                       
                        <div className="col-12 sportdetail ">In the absence of information from the sources above or in the event of obvious errors in the protocols, the Bookmaker Network reserves the right to make calculations based on internal sources.</div>
                        <div className='col-12 sportsubheading pb-1'>Aussie rules</div>

                        <div className="col-12 sportdetail ">All markets exclude overtime unless otherwise stated.</div>
                        <div className="col-12 sportdetail ">If a match is interrupted and continued within 48h after initial kickoff, all open bets will be settled with the final result (The bet can be voided, before 48 hours has passed, in agreement between the client). </div>
                        <div className="col-12 sportdetail ">Otherwise, all undecided bets are considered void.</div>
                        <div className="col-12 sportdetail ">Regular 80 Minutes: Markets are based on the result at the end of a scheduled 80 minutes play unless otherwise stated. This includes any added injury or stoppage time but does not include extra-time.
                        </div>
                        <div className="col-12 sportdetail ">Settlement and cancellation rules</div>
                        <div className="col-12 sportdetail ">If odds were offered with an incorrect match time (more than 2 minutes), we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail ">If a match is interrupted or postponed and is not continued within 48h after initial kick-off date betting will be void.</div>
                        <div className="col-12 sportdetail ">If the team names or category are displayed incorrectly, we reserve the right to void betting.</div>
                        <div className='col-12 sportsubheading pb-1'>Kabaddi</div>


                        <div className="col-12 sportdetail ">For the purpose of settlement, the terms Raiding and Defence Points shall include all points scored in the named raid or match excluding any extras assigned to the referenced team during a raid.</div>
                        <div className="col-12 sportdetail ">All markets relating to players will be void if that player is not selected in the starting squad. If the player is selected but fails to score any points, then settlement will be zero.</div>
                        <div className="col-12 sportdetail ">Points or action during extra time will not be considered in the settlement of any market, except for the Match Winner (2 Way) market that shall be settled on the final official result after any extra time or other qualifying process has been completed.
                        </div>
                        <div className="col-12 sportdetail ">All bets will be void if the match is postponed or cancelled and not replayed within 48 hours of original scheduled start.</div>
                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">Match Winner (2 Way) - Bets will be settled in accordance to the official match result. In the event of extra time, the settlement will be determined by the eventual winner, as per the official result. In the case of a tie, all bets will be void.</li>
                                <li className="sportdetail">Match Winner (3 Way) / DoubleChance (MainTime) - Bets will be settled in accordance to the official match result. Tied matches will be settled as a Tie, regardless of any extra time played.
                                </li>
                                <li className="sportdetail">Match Winner Margin / Handicap - The official result at the end of normal play shall be used to settle this market.</li>
                                <li className="sportdetail">Half Time / Full Time Combinations - The official result at the end of each half shall be used to determine the result.</li>
                                <li className="sportdetail">Total Match Points / Total Match Points (3 Way) / Total Match Points, Odd Even - Bets will be settled in accordance to the official match scoring. Points are to include all methods of points scoring.</li>
                                <li className="sportdetail">Total Raids / Total Match Successful Raids / Raid Points / Total Match Unsuccessful Raids / Tackle Points / Total Match Empty Raids - Bets will be settled in accordance to the official match scoring.
                                </li>
                                <li className="sportdetail">Total Match Bonus Points - Bets will be settled in accordance to the official match scoring. Bonus points are defined as points scored by a raiding team by placing their foot over the bonus line while the other foot is in the air.
                                </li>
                                <li className="sportdetail">Total Match Super Raids - Bets will be settled in accordance to our definitions. Super raids are defined as raids in which the raiding team score 3 points or more.
                                </li>
                                <li className="sportdetail">Total Match Super Tackles - Bets will be settled in accordance to our definitions. Super tackles are defined as successful tackles in which the defending team has 3 or fewer players on the mat.
                                </li>
                                <li className="sportdetail">Total Match All Outs - Bets will be settled in accordance to the official match scoring. All outs are when a team loses all of their players.
                                </li>
                                <li className="sportdetail">Match Multiples (e.g. Telugu to Win and More Than 70 Points in Game) - Bets will be settled in accordance to the official match scoring. Points are to include all methods of points scoring.
                                </li>
                                <li className="sportdetail">First Half Winner (2 Way) - Bets will be settled in accordance to the official match scoring. The market will be void if points are equal at half time.
                                </li>
                                <li className="sportdetail">First Half Winner (3 Way) / DoubleChance (Half1) - Bets will be settled in accordance to the official match scoring.
                                </li>
                                <li className="sportdetail">First Half Points / First Half Points, Odd Even - Bets will be settled in accordance to the official match scoring. Points are to include all methods of points scoring.
                                </li>
                                <li className="sportdetail">Successful Raid / Raid Points (e.g Raid 10 : Mumba Touch Point) - 
                                </li>
                                <li className="sportdetail">Bets will be settled in accordance to official scoring. Extras do contribute to the settlement but technical points and penalties do not.
                                </li>
                                <li className="sportdetail">Unsuccessful Raid / Tackle Points (e.g Raid 10: Telegu Tackle Point) - Bets will be settled in accordance to official scoring. Extras do contribute to the settlement but technical points and penalties do not.
                                </li>

                                <li className="sportdetail">Raid Points (e.g. Raid 10: Total Points) - Bets will be settled in accordance to our definition. All points, for both teams, whether through open play or penalties will contribute to the settlement of this market.</li>
                                <li className="sportdetail">Bonus Point (e.g. Raid 10: Bonus Point) - Bets will be settled in accordance to the official match scoring. Bonus points are defined as points scored by a raiding team by placing their foot over the bonus line.  Bets will be void if, due to insufficient defenders, it is not possible to score a bonus point in the named raid.
                                </li>
                                <li className="sportdetail">Super Raid (e.g. Raid 10: Super Raid) - Bets will be settled in accordance to our definition. Super raids are defined as raids in which the raiding team score 3 points or more, not including any all out points earned.
                                Bets will be void if, due to insufficient defenders, it is not possible to achieve a super raid in the named raid.</li>
                                <li className="sportdetail">Team Match Points / Team Half Points / Team Match Points, Odd Even - Bets will be settled in accordance to the official match scoring. Points are to include all methods of points scoring.
                                </li>
                                <li className="sportdetail">Team Match Successful Raids / Team Match Raid Points - Bets will be settled in accordance to our definition. Raiding points are defined as points scored by the team raiding, regardless of method.
                                </li>
                                <li className="sportdetail">Team Match Unsuccessful Raids / Team Match Tackle Points - Bets will be settled in accordance to our definition. Defence points are defined as points scored by the team defending a raid, regardless of method.
                                </li>
                                <li className="sportdetail">Team Match Bonus Points - Bets will be settled in accordance to the official match scoring. Bonus points are defined as points scored by a raiding team by placing their foot over the bonus line. </li>
                                <li className="sportdetail">Player Raiding Points (e.g. Khan Player Points) - Bets will be settled in accordance to our definition. Player raiding points are defined as points earned by the named player through touch points and bonus points and do not include tackling points, penalties or all out bonuses. If the player is not present in the starting 7 line-up then the bets will be voided. 
                                </li>
                                <li className="sportdetail">Highest Scoring Raider - Player raiding points are defined as points earned by the named player through touch points and bonus points and do not include tackling points, penalties or all out bonuses. In the event of a tie, the following criteria, in order, shall be used to determine the top raider: most successful raids, most bonus points, first raider to score a point. 
                                </li>
                                <li className="sportdetail">Player Match Up (e.g. Praven v Narwal) - Raiding points only will count. This includes bonus points but not extras or penalties.
                                </li>
                                <li className="sportdetail">Player Mash Up (e.g. Praven and Narwal) - Raiding points only will count. This includes bonus points but not extras or penalties.
                                </li>
                                <li className="sportdetail">Player Multiples (e.g. Praven Super Ten and Telugu Win) - Raiding points only will count. This includes bonus points but not extras or penalties. In the event of a Tied match the settlement for this market will be 'No'.
                                </li>
                                <li className="sportdetail">Home-away team market - Bets will be settled in accordance to the official match results of the teams for the game day

                                </li>
                                <li className="sportdetail">Tournament Team Points/Tournament Player Points - At least 95% of the originally scheduled matches must be played or bets will be void, unless settlement is already determined.
                                </li>
                              

                            </ul>
                        </div>

                        <div className='col-12 sportsubheading pb-1'>Bowls</div>

                        <div className="col-12 sportdetail ">In case of a retirement and walk over of any player all undecided bets are considered void.
                        </div>
                        <div className="col-12 sportdetail ">If a match is interrupted and continued within 48h after initial start time, all open bets will be settled with the final result (The bet can be voided, before 48 hours has passed, in agreement between the client). Otherwise, all undecided bets are considered void.</div>
                        <div className="col-12 sportdetail ">Settlement and cancellation rules</div>
                        <div className="col-12 sportdetail ">1. If markets remain open with an incorrect score which has a significant impact on the prices, we reserve the right to void betting.</div>
                        <div className="col-12 sportdetail ">2. If the players/teams are displayed incorrectly, we reserve the right to void betting.
                        </div>
                        <div className="col-12 sportdetail ">3. If a player retires all undecided markets are considered void.
                        </div>
                        <div className='col-12 sportsubheading pb-1'>Races</div>


                        <div className="col-12 sportdetail "> The following wagers are offered:</div>
                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">Wagering on the winner of the race. The driver who takes the first place in final classification is the winner.</li>
                                <li className="sportdetail">Bet on a certain place of the driver in the final protocol.
                                </li>
                                <li className="sportdetail">Wager "Finish - Not finish". Prediction whether a driver will finish the race or not. If a driver is qualified, he is considered to finish the race.
                                </li>
                                <li className="sportdetail">Wager "Fastest lap". Which driver will drive the fastest lap.
                                </li>
                                <li className="sportdetail">Wager "Who is higher". The Client predicts which driver in a predetermined pair will finish in a higher position after the race. If both drivers fall out of the race, the driver that finished more laps is the winner. If both drivers fall out of the race on the same lap, the bets will be refunded.
                                </li>
                                <li className="sportdetail">Wager "Which team is better in a race". The predetermined teams are put in pairs consisting of two drivers and the Client predicts which team will perform better. The better team will be determined by: a) which team has more drivers to finish the race and be classified; b) if both teams have the same amount of classified drivers, the better team is determined by adding up the sum of drivers` positions. The smaller sum, the better a team has performed. (If the sum is the same for both teams, the bets will be refunded). If drivers are changed in teams after the line has come out, all bets on these teams will be refunded.
                                </li>
                               

                            </ul>
                        </div>


                        <div className="col-12 sportdetail ">The positions of the drivers are determined by the official final protocol published immediately after the race. Subsequent disqualifications of the drivers and the changes in the protocol after the race will not be considered.</div>
                        <div className="col-12 sportdetail ">Warm-up lap is included in the race result.</div>
                        <div className="col-12 sportdetail ">In the disputable situations regarding winner bets and podium bets, the results will be determined according to the price giving ceremony.</div>
                        <div className="col-12 sportdetail ">All bets are valid until the end of the stage, regardless of the possible transfer of the start time and date.</div>
                        <div className="col-12 sportdetail ">We also offer the following types of qualification bets: </div>
                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">Bet on the winner of the qualification. The winner is the driver who has won first place in the specified qualifications.</li>
                                <li className="sportdetail">Bet "Who is higher". The Client predicts which driver in a predetermined pair will finish in a higher position after the qualification. The driver who showed the best qualification time in qualifying lap and took a higher place on the protocol of the specified qualifications is considered higher.</li>
                               
                            </ul>
                        </div>
                        <div className="col-12 sportdetail ">Bets on the driver who did not show qualification time in the specified qualifying lap as well as bets on comparison of him to other will be refunded.</div>
                        <div className="col-12 sportdetail ">The correctness of results can be checked from the following sites:</div>
                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail"> <a className='sportdetail' href="https://www.formula1.com/">https://www.formula1.com/</a></li>
                                <li className="sportdetail"> <a className='sportdetail' href="https://www.nascar.com/">https://www.nascar.com/</a></li>
                                <li className="sportdetail"> <a className='sportdetail' href="https://www.motogp.com/en">https://www.motogp.com/en</a></li>
                            </ul>
                        </div>
                        <div className='col-12 sportsubheading pb-1'>Biathlon</div>


                        <div className="col-12 sportdetail ">Wagering on winner. Winner is determined by the official protocol. If there is more than one winner - bets on these participants will be settled at the odds 1. If one of the participants mentioned in the list does not start - bets on him will be settled at the odds 1.</div>
                        <div className="col-12 sportdetail ">Wager "Who is higher". The Client predicts which competitor in a predetermined pair will finish at a higher position after the competition (team in relays). If both competitors fall out of the race or one of them does not start - the bets will be refunded.</div>
                        <div className="col-12 sportdetail ">Bet on the determining the participant's finishing position. It is proposed to specify the place at which the participant will finish the race. Variants: 1-3 places, 4-10 places, 11+ (i.e. below the 11th place in final protocol).
                        </div>
                        <div className="col-12 sportdetail ">Wagering on the total number of target misses of the race participants. If the number of target misses is equal to handicap, all bets will be refunded. The bets that have actually finished by the time the participant falls out of the race (or disqualified) are considered valid.
                        </div>
                        <div className="col-12 sportdetail ">Wagering on the number of misses in each shooting round (1st round, 2nd, 3rd, 4th, etc.). The bets that have actually finished by the time the participant falls out of the race (or disqualified) are considered valid.
                        </div>
                        <div className="col-12 sportdetail "> The participant has fallen out of the race after two shooting rounds. Bets on the number of misses in the 1st and 2nd shooting rounds are valid. Bets on the 3rd and 4th shooting rounds will be settled at the odds 1.
                        </div>
                        <div className="col-12 sportdetail ">Comparison - which participant will make more target misses in a predetermined pair. The Client should predict which of the two competitors will make more target misses. If one of the competitors does not start or finish the competition - bets will be refunded.</div>
                        <div className="col-12 sportdetail ">Comparison "Member of which of the teams will be higher". Select a team whose member finishes higher on the final protocol.</div>
                        <div className="col-12 sportdetail ">The final results of the race participants will be determined by the official protocol immediately after the competition. Possible changes after that like disqualification will be ignored.</div>
                        <div className="col-12 sportdetail "> You can check the statistics on the official website:</div>
                        <div className="col-12 sportdetail "><a className='sportdetail' href="https://www.biathlonworld.com/">https://www.biathlonworld.com/</a></div>
                        <div className='col-12 sportsubheading pb-1'>League of Legends</div>


                        <div className="col-12 sportdetail ">The final settlement is made based on the data recorded immediately after the destruction of the main building (Fortress/Nexus) of one of the opponents. If one of the teams surrenders (the Fortress/Nexus is not destroyed by the opponent), the calculation is made in the same way. The opposing team of the surrendered is awarded a victory.</div>
                        <div className="col-12 sportdetail ">Settlement with odds of "1" is possible only until the game start (exit of creeps / minions from the barracks on the line). Further bets in any unplanned situations (leave of player from any team, disconnect, etc.) or a technical defeat (TD) are calculated according to the results. When a technical defeat is appointed, or at any unplanned situations (disconnect, exit (leave) of players from either team, etc.), all further bets are settled according to the results.In case of a default loss, the map (round) won by the DL, is taken into account when maps are being numbered in the line. 
                        </div>
                        <div className="col-12 sportdetail ">Totals and handicaps in these disciplines are given for the time (minutes) of the map duration, destroyed fortresses, killed main characters, killed couriers, Roshans, maps, as well as any other, at the discretion of the Betting company.
                        </div>
                        <div className="col-12 sportdetail ">The advantage is added to the map before the event starts. According to the decision of the judges/ regulations, in some cases, one of the teams may be awarded a one-map victory "in absentia" (not to be confused with a handicap bet or a default loss). This "absentee" map is not counted in the line for the outcome of total maps (rounds) or for winning a specific map (in sequence). In this case, the first map actually played by the participants is considered the first map in the line.
                        </div>
                        <div className="col-12 sportdetail ">The "First Blood" bet is calculated if the game character is killed by the characters of the opposing team. The "First Blood" by neutral units or creeps/minions of the opponent is not taken into account on the map but is saved until the first character is killed by the opposing team. </div>
                        <div className="col-12 sportdetail ">The bet "First 10 Kills on the Map" is given to the team that first commits 10 kills of the opposing team's characters (champions) on the map.
                        </div>
                        <div className="col-12 sportdetail ">Re-host is a “rollback” of the game situation to the moment of auto-saving at a certain time (in case of network problems, DDoS attacks, server crashes, and other similar cases) is not a reason for refunding bets.
                        </div>
                        <div className="col-12 sportdetail ">Bets on the "Best Net Worth" market are accepted in the intervals from 1 to 18 minutes, from 1 to 12 minutes and from 1 to 5 minutes. The Client needs to guess which team will be ahead of the opponent in terms of game gold in the specified time intervals. The team that has more total game value based on "gold" is determined as a winner in these time periods. </div>

                        <div className="col-12 sportdetail ">In the case of the technical defeat in the round (on the map) or full match, outcomes that are not determined by gaming competition at the moment of the TD announcement are settled at the odds "1".
                        </div>
                        <div className='col-12 sportsubheading pb-1'>Counter-Strike</div>

                        <div className="col-12 sportdetail ">All bets for Counter-Strike are accepted taking into account overtimes. If a team or one of the players for any reason leaving the match - player’s team is credited with losing all remaining maps/rounds. If the match is stopped by the referee and a replay is ordered after more than 36 hours - the result of the interrupted game is not taken into account. </div>
                        <div className="col-12 sportdetail ">The first kill in the "pistol" round is the actual start of the match. To win a round, the team shall destroy all opponents on the map, as well as either detonate or defuse a bomb. An alternative victory option is also possible by ending the game time on the timer in the round. </div>
                        <div className="col-12 sportdetail ">Winning at least 13 rounds (unless otherwise specified in the rules of the tournament) can ensure victory on one of the maps. If the score for the rounds is 12:12, the organizers can assign overtime of six or ten additional rounds (OT) to determine the winner. To win overtime, a team shall either get a two-round advantage or end the game early if the opponent cannot even the score before the end of overtime (for example, win four starts when assigning an additional six OT rounds). If a draw was recorded after overtime, the organizers can again assign six or ten additional rounds.
                        </div>
                        <div className="col-12 sportdetail ">If according to the rules or the decision of the judges, the event starts with the initial advantage in the account of one of the teams, the bets are calculated with odds equal to "1", except in cases when this information was initially specified in the line. Also, bets are calculated with odds equal to "1" in cases of changes in the match format (number of rounds, cards, or other rules), except for bets on already defined outcomes.
                        </div>
                        <div className="col-12 sportdetail ">Bets on outcomes that are determined by the moment of default loss or interruption of the game/refusal of teams are calculated based on the results. If the outcome cannot be determined at the moment of stopping the match, the bet is calculated with odds equal to "1".</div>
                        <div className="col-12 sportdetail ">Bets are accepted on standard victories or draws, handicaps (rounds, maps, kills), and other specific outcomes, as well as on the winner of the tournament as a whole or the winner of a certain round on a certain map. Bets on handicaps and totals are accepted on maps, rounds, and kills in a given period.
                        Handicaps and Totals in matches are calculated by rounds, unless otherwise indicated in the market itself.
                    </div>
                        <div className="col-12 sportdetail ">In Duel matches, Handicap and Total are calculated based on the results of kills, taking into account OT.</div>

                        <div className='col-12 sportsubheading pb-1'>Dota 2</div>

                        <div className="col-12 sportdetail ">The final settlement is made based on the data recorded immediately after the destruction of the main building (Fortress/Nexus) of one of the opponents. If one of the teams surrenders (the Fortress/Nexus is not destroyed by the opponent), the calculation is made in the same way. The opposing team of the surrendered is awarded a victory.</div>
                        <div className="col-12 sportdetail ">Settlement with odds of "1" is possible only until the game start (exit of creeps / minions from the barracks on the line). Further bets in any unplanned situations (leave of player from any team, disconnect, etc.) or a technical defeat (TD) are calculated according to the results. When a technical defeat is appointed, or at any unplanned situations (disconnect, exit (leave) of players from either team, etc.), all further bets are settled according to the results.In case of a default loss, the map (round) won by the DL, is taken into account when maps are being numbered in the line. 
                        </div>
                        <div className="col-12 sportdetail ">Totals and handicaps in these disciplines are given for the time (minutes) of the map duration, destroyed fortresses, killed main characters, killed couriers, Roshans, maps, as well as any other, at the discretion of the Betting company.</div>
                        <div className="col-12 sportdetail ">The advantage is added to the map before the event starts. According to the decision of the judges/ regulations, in some cases, one of the teams may be awarded a one-map victory "in absentia" (not to be confused with a handicap bet or a default loss). This "absentee" map is not counted in the line for the outcome of total maps (rounds) or for winning a specific map (in sequence). In this case, the first map actually played by the participants is considered the first map in the line.
                        </div>
                        <div className="col-12 sportdetail ">The "First Blood" bet is calculated if the game character is killed by the characters of the opposing team. The "First Blood" by neutral units or creeps/minions of the opponent is not taken into account on the map but is saved until the first character is killed by the opposing team. </div>
                        <div className="col-12 sportdetail ">The bet "First 10 Kills on the Map" is given to the team that first commits 10 kills of the opposing team's characters (champions) on the map.</div>
                        <div className="col-12 sportdetail ">Re-host is a “rollback” of the game situation to the moment of auto-saving at a certain time (in case of network problems, DDoS attacks, server crashes, and other similar cases) is not a reason for refunding bets.
                        </div>
                        <div className="col-12 sportdetail ">Bets on the "Best Net Worth" market are accepted in the intervals from 1 to 18 minutes, from 1 to 12 minutes and from 1 to 5 minutes. The Client needs to guess which team will be ahead of the opponent in terms of game gold in the specified time intervals. The team that has more total game value based on "gold" is determined as a winner in these time periods. </div>
                        <div className="col-12 sportdetail ">In the case of the technical defeat in the round (on the map) or full match, outcomes that are not determined by gaming competition at the moment of the TD announcement are settled at the odds "1".
                        </div>

                        <div className='col-12 sportsubheading pb-1'>PUBG</div>

                        <div className="col-12 sportdetail ">Bets on PUBG are accepted for getting the teams or players represented in the line into the TOP, the number of surviving teams or players on the map, as well as on the total kills committed by a team or players in the form of outcomes. </div>
                        <div className="col-12 sportdetail ">If the game is interrupted due to a failure, player technical issues or technical reasons on the server, bets on all markets of this game are canceled, unless the results of the markets have been already determined. If the market results have been already determined at the time of game interruption - the bet remains valid.</div>
                        <div className='col-12 sportsubheading pb-1'>StarCraft II</div>

                        <div className="col-12 sportdetail ">All bets on StarCraft II are accepted on outcomes in the form of a victory for any of the teams, as well as on totals and handicaps. Bets are considered valid after the start of the game and confirmation by the judges and/or players. </div>
                        <div className="col-12 sportdetail ">All bets remain valid if a technical defeat (TD) is awarded after the start of the game (if the outcome can be determined at the time of the interruption and a default loss announcement). If a technical defeat (TD) is awarded before the start of the game, bets are calculated with odds equal to "1".</div>
                        <div className='col-12 sportsubheading pb-1'>Call of Duty</div>

                        <div className="col-12 sportdetail ">Bets are accepted on a victory or draw in the form of outcomes, as well as on totals and handicaps, and are considered valid after the start of the match.</div>
                        <div className="col-12 sportdetail ">In the event of a technical defeat (TD) or game interruption or complete stop, without the possibility of continuing and finishing the game - all the outcomes that can be determined at this time are calculated based on the current results. For outcomes that cannot be determined or  the game will not continue - bets are calculated with odds equal to "1".
                        </div>
                        <div className='col-12 sportsubheading pb-1'> Valorant</div>


                        <div className="col-12 sportdetail ">Bets are accepted on a victory or draw in the form of outcomes, as well as on totals and handicaps, and are considered valid after the start of the match.</div>
                        <div className="col-12 sportdetail ">In the event of a technical defeat (TD) or game interruption or complete stop, without the possibility of continuing and finishing the game - all the outcomes that can be determined at this time are calculated based on the current results. For outcomes that cannot be determined or  the game will not continue - bets are calculated with odds equal to "1".</div>
                        <div className='col-12 sportsubheading pb-1'> Rainbow 6</div>

                        <div className="col-12 sportdetail ">All bets are settled after the end of an event.</div>
                        <div className="col-12 sportdetail ">The minimum and maximum stakes are determined by the bookmaker for each selection individually.</div>
                        <div className="col-12 sportdetail ">The bookmaker may change stake limits without prior notice.</div>
                        <div className="col-12 sportdetail ">Should members of staff commit errors or should any software failures occur at the time of bet acceptance (e.g. obvious misprints of the odds, inconsistencies between the odds displayed in the Sports/Live sections or on the bet slip etc.) or should there be any other indications of bets accepted incorrectly, the bookmaker is entitled to declare such bets void.</div>
                        <div className="col-12 sportdetail ">In the event of unsportsmanlike conduct that influences the outcome of the battle such as complete inaction of allies, a team kill, or premature exit from the game, the bookmaker is entitled to declare such bets void and settle at odds of 1 (stakes will be refunded).</div>
                        <div className="col-12 sportdetail "> If a team surrenders or a player joins from a round other than the first the bookmaker is entitled to declare such bets void and settle at odds of 1 (stakes will be refunded).</div>
                        <div className="col-12 sportdetail ">Bets are accepted both before the start of a game and Live. All games are streamed online.</div>
                        <div className="col-12 sportdetail ">Win in Round. The team which destroys their opponent or achieves the objective of the map such as defusing the bomb, releasing the hostage, or capturing the position (the priority of the map) is considered the winner.</div>
                        <div className="col-12 sportdetail ">Win in the Match. The team which collects 4 points (when the number of points collected by the opposite team is less than 3) or 5 points is considered the winner.</div>
                        <div className="col-12 sportdetail ">Bets are accepted on battles in the multiplayer online game "Rainbow Six Siege". Team players are selected at random.</div>
                        <div className="col-12 sportdetail ">Team Score – the number of winning rounds.</div>

                        <div className='col-12 sportsubheading pb-1'>Rocket League</div>

                        <div className="col-12 sportdetail ">Bets are accepted on a victory or draw in the form of outcomes, as well as on totals and handicaps, and are considered valid after the start of the match.</div>
                        <div className="col-12 sportdetail ">In the event of a technical defeat (TD) or game interruption or complete stop, without the possibility of continuing and finishing the game - all the outcomes that can be determined at this time are calculated based on the current results. For outcomes that cannot be determined or  the game will not continue - bets are calculated with odds equal to "1".</div>

                        <div className='col-12 sportsubheading pb-1'>Rugby</div>
                        <div className="col-12 sportdetail">
                            <ul>
                                <li className="sportdetail">We offer to place bets on: outright winner of the match, draw, handicaps.</li>
                                <li className="sportdetail">Total number of scored points ("total"). If the number of scored points is equal to the total, the bets will be refunded.
                                </li>
                                <li className="sportdetail">The bets are accepted only for regular time in case of wagering on winning or draw of either team. Possible overtime counts for handicap and "total" betting.</li>
                                <li className="sportdetail">If for some reason the match was not played on the announced day, the bets will be refunded.
                                </li>
                            </ul>
                        </div>
                        <div className='col-12 sportsubheading pb-1'>Warcraft</div>
                        <div className="col-12 sportdetail ">No special requirement.</div>
                        <div className='col-12 sportsubheading pb-1'>King of Glory</div>
                        <div className="col-12 sportdetail ">No special requirement.</div>
                        <div className='col-12 sportsubheading pb-1'>Arena of Valor</div>

                        <div className="col-12 sportdetail ">If a Map starts with fewer than 10 competitors all bets on the Map will be void.</div>
                        <div className='col-12 sportsubheading pb-1'>Arena of Valor</div>

                        <div className="col-12 sportdetail ">If a competitor disconnects in the first 10 minutes and is unable to reconnect or be replaced for the rest of the Map, all bets on the Map will be void. If a competitor disconnects or quits after the 10th minute of play of a Map has started, bets have action according to the official result.</div>
                        <div className="col-12 sportdetail ">If a walkover or win by admin decision is given in the first 10 minutes of a Map, all bets on the Map will be void. If a win by admin decision is awarded after the 10th</div>
                        <div className='col-12 sportsubheading pb-1'>Mobile Legends</div>

                        <div className="col-12 sportdetail ">The final result is determined by the official data, fixed at the time of the victory of the team.</div>
                        <div className="col-12 sportdetail ">The advantage of one map before the start of event: according to the referees / regulations decision in some cases, the winning of one of the teams can be "in absentia" awarded in 1 map. In outcomes with the total of maps or winning in a particular (in sequence) map, this "absentia" map isn't counted. </div>
                        <div className="col-12 sportdetail ">That is, the first map in the betting-line is considered to be the first map played by the participants.</div>
                        <div className="col-12 sportdetail ">The regulation of the game - Bo1, Bo2, Bo3, etc. (the total number of game maps), is defined by the Tournament rules and noted in the betting-line. If the regulations of the game was changed, bets would be void.
                        </div>
                        <div className="col-12 sportdetail ">All bets are settled in accordance with the initial official result. Further changes after the match is finished (Tech defeat and other) are not considered. If the technical defeat was awarded before game started, all bets would be void. Requests for review bet result after 72 hours from the end of the match are not accepted,  except for specially stipulated cases.</div>

                        <div className="col-12 sportdetail ">If the team changed the name or was purchased by another organization but kept 50% of the line-up, the bets would remain valid.</div>
                    


                    </div>
                </div>

            </div>
        </div>
        <AppFooter/>
    </main>
</>
)
}

export default SportsPolicy