// import React, { useEffect, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { getAPIAuth } from '../../../service/apiInstance';
// import { useAuth } from '../../../hooks/useAuth';
// import { userBalanceAction } from '../../../store/action';

// const ModeOptionModal = ({ setDemo, setShowOptionModal, isLoading }) => {
//   const [loading, setLoading] = useState(false)
//   const favFiatDetails = useSelector(state => state.favFiat);
//   const favCryptoDetails = useSelector(state => state.favCrypto);
//   const userDetails = useSelector(state => state.userDetails)
//   const userBalance = useSelector(state => state.userBalance)
//   // console.log("favFiatDetails", { favFiatDetails, favCryptoDetails });
//   const dispatch = useDispatch()
//   const [search, setSearch] = useState()
//   const { token } = useAuth()
//   const [cryptoData, setCryptoData] = useState([])
//   const [fiatData, setFiatData] = useState([])

//   useEffect(() => {
//     if (favCryptoDetails) {
//       setCryptoData(favCryptoDetails)
//     }
//   }, [favCryptoDetails])

//   useEffect(() => {
//     if (favFiatDetails) {
//       setFiatData(favFiatDetails)
//     }
//   }, [favFiatDetails])

//   const handlePlayDemo = () => {
//     setDemo(true)
//     // setLoading(true)
//     // setTimeout(() => {
//     setShowOptionModal(false)
//     setLoading(false)
//     // }, 4000);
//   }

//   useEffect(() => {
//     if (search) {
//       console.log({ search });

//       const data = favCryptoDetails.filter(item => item?.code.toLowerCase().includes(search?.toLowerCase()) || item?.name.toLowerCase().includes(search?.toLowerCase()))
//       setCryptoData(data)
//       console.log({ data });

//       const dataFiat = favFiatDetails.filter(item => item?.code.toLowerCase().includes(search?.toLowerCase()) || item?.name.toLowerCase().includes(search?.toLowerCase()))
//       console.log({ dataFiat });

//       // setCryptoData(dataFiat)
//       setFiatData(dataFiat)
//     } else {
//       setCryptoData(favCryptoDetails)
//       setFiatData(favFiatDetails)

//     }
//   }, [search])
//   const handleChange = async (item) => {
//     try {
//       const res = await getAPIAuth(`user/setCryptoForUser?currency=${item.code}&type=${item.type}&userId=${userDetails.id}`, token);
//       if (res?.data?.success) {
//         // setUserCurrency(res.data.data);
//         // get user updated wallet info--->>>
//         const getUserBal = async () => {
//           try {
//             const res = await getAPIAuth(`user/user-balance?userId=${userDetails?.id}`, token)
//             if (res?.data?.success) {
//               // setUserBal(res.data.data)
//               dispatch(userBalanceAction(res.data.data))
//             }
//           } catch (error) {

//           }
//         }
//         getUserBal()
//         // const dropdownMenus = document.getElementsByClassName("dropdown-menu show");
//         // const dropdownMenusArray = Array.from(dropdownMenus);
//         // dropdownMenusArray.map(menu => {
//         //   menu.classList.remove("show");
//         // });

//       }
//     } catch (error) {
//       console.log(error)

//     } finally {
//       // setCurrencyLoader((pre) => ({ ...pre, index: '', status: false, type: "" }))
//     }

//   }

//   return (
//     <>
//       <div
//         className="row mx-0 w-100 justify-content-center align-items-center modeSelectDialogWrapper"
//       >
//         <div className="col-12 d-flex justify-content-center">
//           <div className="modeSelectDialog w-100 rounded p-4">
//             <div className="row lower">
//               <div className="col-sm-10 col-12 mx-auto">
//                 <div className="d-flex align-items-center justify-content-center gap-3 py-sm-3 py-2">
//                   <div className="playwithHeading text-light" >
//                     Play with Balance
//                   </div>
//                   <div className="dropdown coindropdown dropdowncenter ">
//                     <button
//                       className="btn btn-secondary dropdowntoggle coinbtn border-0 d-flex align-items-center gap-2"
//                       role="button"
//                       data-bs-toggle="dropdown"
//                       aria-expanded="false"
//                       data-bs-auto-close="outside"
//                     >
//                       <span className="coinImgs d-flex align-items-center justify-content-center rounded-pill overflow-hidden">
//                         <img
//                           src={userBalance?.setCurrencyData?.icon}
//                           alt="coin" className="h-100 w-100" />
//                       </span>
//                       {userBalance?.setCurrencyData?.code}
//                       {/* {userDetails.fiatStatus ? userDetails?.currency?.symbol : ''} */}
//                       {" "}
//                       {/* {userBalance?.balance || userBalance?.balance === 0 ? Number(userBalance?.balance)?.toFixed(2) : userDetails?.totalbalance ? Number(userDetails?.totalbalance).toFixed(2) : "0.00"} */}
//                     </button>
//                     <div className="dropdown-menu coinMenu p-4 rounded-1 dropdown-menu-end">
//                       <div className="row"
//                         style={{
//                           height: "300px",
//                           overflowY: "auto",
//                         }}
//                       >
//                         <div className="col-12 pb-1">
//                           <div className="input-group coinsearch rounded-2 align-items-center ps-2">
//                             <button className="input-group-text searchBtns ms-1 bg-transparent p-0 border-0" id="basic-addon1">
//                               <img src="assets/img/search.png" alt="search" className="h-100" />
//                             </button>
//                             <input
//                               type="search"
//                               className="form-control searchipt shadow-none bg-transparent border-0"
//                               placeholder="Search"
//                               aria-label="Username"
//                               aria-describedby="basic-addon1"
//                               value={search}
//                               onChange={(e) => setSearch(e.target.value)}
//                             />
//                           </div>
//                         </div>
//                         <div className="col-12 pt-2">
//                           <ul className="nav nav-tabs cointabs border-0 rounded-2" id="myTab" role="tablist">
//                             <li className="nav-item w-50" role="presentation">
//                               <button
//                                 className="nav-link d-flex align-items-center justify-content-center w-100 tablink border-0 rounded-1 active"
//                                 id="currancy-tab"
//                                 data-bs-toggle="tab"
//                                 data-bs-target="#currancy-tab-pane"
//                                 type="button"
//                                 role="tab"
//                                 aria-controls="currancy-tab-pane"
//                                 aria-selected="true"
//                               >
//                                 Currency
//                               </button>
//                             </li>
//                             <li className="nav-item w-50" role="presentation">
//                               <button
//                                 className="nav-link d-flex align-items-center justify-content-center w-100 tablink border-0 rounded-1"
//                                 id="mnft-tab"
//                                 data-bs-toggle="tab"
//                                 data-bs-target="#mnft-tab-pane"
//                                 type="button"
//                                 role="tab"
//                                 aria-controls="mnft-tab-pane"
//                                 aria-selected="false"
//                               >
//                                 mNFT
//                               </button>
//                             </li>
//                           </ul>
//                         </div>
//                         <div className="col-12 pt-3">
//                           <div className="tab-content" id="myTabContent">
//                             <div
//                               className="tab-pane fade show active"
//                               id="currancy-tab-pane"
//                               role="tabpanel"
//                               aria-labelledby="currancy-tab"
//                               tabIndex={0}
//                             >
//                               <p className='text-light m-0'>
//                                 Fiat
//                               </p>
//                               <ul className="list-unstyled gap-1 d-flex flex-column">
//                                 {
//                                   fiatData?.map((item) => (
//                                     <li
//                                       onClick={() => handleChange(item)}
//                                     >
//                                       <div
//                                         className={`d-flex align-items-center justify-content-between coinMain rounded-2 ${item?.code === userBalance?.setCurrencyData?.code ? 'active' : ''}`}>
//                                         <div className="d-inline-flex align-items-center gap-2">
//                                           <div className="dropcoinImg d-flex align-items-center justify-content-center overflow-hidden rounded-circle">
//                                             <img src={item?.icon} alt="coin" className="h-100 w-100 img-fluid" />
//                                           </div>
//                                           <div className="coinname">
//                                             {/* {item} */}
//                                             {item?.code}

//                                           </div>
//                                         </div>
//                                         <div className="coinPrice">
//                                           {/* 0.67589 */}
//                                           {userDetails?.fiatStatus ? userDetails?.currency?.symbol : ""} {item?.wallet?.totalBalance ? item.wallet?.totalBalance : "0"}

//                                         </div>
//                                       </div>
//                                     </li>
//                                   ))
//                                 }

//                               </ul>
//                               <p className='text-light m-0'>
//                                 Crypto
//                               </p>
//                               <ul className="list-unstyled gap-1 d-flex flex-column">
//                                 {
//                                   cryptoData?.map((item) => (
//                                     <li
//                                       onClick={() => handleChange(item)}
//                                     >
//                                       <div
//                                         className={`d-flex align-items-center justify-content-between coinMain rounded-2 ${item?.code === userBalance?.setCurrencyData?.code ? 'active' : ''}`}>
//                                         <div className="d-inline-flex align-items-center gap-2">
//                                           <div className="dropcoinImg d-flex align-items-center justify-content-center overflow-hidden rounded-circle">
//                                             <img src={item?.icon} alt="coin" className="h-100 w-100 img-fluid" />
//                                           </div>
//                                           <div className="coinname">
//                                             {/* {item} */}
//                                             {item?.code}
//                                           </div>
//                                         </div>
//                                         <div className="coinPrice">
//                                           {/* 0.67589 */}
//                                           {userDetails?.fiatStatus ? userDetails?.currency?.symbol : ""} {item?.wallet?.totalBalance ? item?.wallet?.totalBalance : "0"}
//                                         </div>
//                                       </div>
//                                     </li>
//                                   ))
//                                 }

//                               </ul>
//                             </div>
//                             <div className="tab-pane fade" id="mnft-tab-pane" role="tabpanel" aria-labelledby="mnft-tab" tabIndex={0}>...</div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-12 playwithdetail text-center pt-3 text-light">
//                 The selected currency will be displayed in {" "}
//                 <span className='currency-text'>
//                   {userDetails?.fiatStatus ? `${userDetails?.currency?.code} Fiat` : 'USD'}
//                 </span>, and if you change currency while playing, the game will refresh and restart.
//               </div>
//             </div>
//             <div className="row py-2 g-3">
//               <div className="col-12 dialogTxt">
//                 Please select the mode you want to play
//               </div>
//               <div className="col-sm-6">
//                 <button className='dialogBtn' onClick={() => {
//                   setDemo(false)
//                   setShowOptionModal(false)
//                 }}>Play with real money</button>
//               </div>
//               <div className="col-sm-6">
//                 <button className='dialogBtn freePlay' onClick={handlePlayDemo}>
//                   {
//                     loading ? 'loading...' : 'Play in demo mode'
//                   }
//                 </button>
//               </div>
//             </div>

//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// export default ModeOptionModal

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAPIAuth } from '../../../service/apiInstance';
import { useAuth } from '../../../hooks/useAuth';
import { userBalanceAction } from '../../../store/action';
import { Dropdown, Spinner, Tab, Tabs } from "react-bootstrap";
import { convertToEightDigitsAfterDecimal } from '../../../utils/convertToEightDigitsAfterDecimal';
import FiatErrorModal from '../../../components/fiatErrorModal/FiatErrorModal';


const ModeOptionModal = ({ setDemo, setShowOptionModal, isLoading, showError }) => {
  const [loading, setLoading] = useState(false)
  // const favFiatDetails = useSelector(state => state.favFiat);
  // const favCryptoDetails = useSelector(state => state.favCrypto);
  const userDetails = useSelector(state => state.userDetails)
  const userBalance = useSelector(state => state.userBalance)
  // console.log("favFiatDetails", { favFiatDetails, favCryptoDetails });
  const dispatch = useDispatch()
  const [search, setSearch] = useState()
  const { token } = useAuth()
  const [cryptoData, setCryptoData] = useState([])
  const [fiatData, setFiatData] = useState([])
  const [showFavoriteModal, setShowFavoriteModal] = useState(false)
  const [favCrypto, setFavCrypto] = useState([]);
  const [favFiat, setFavFiat] = useState([]);
  const favFiatDetails = useSelector(state => state.favFiat);
  const favCryptoDetails = useSelector(state => state.favCrypto);
  const [isChecked, setIsChecked] = useState(false);
  const [userCurrency, setUserCurrency] = useState();
  const [currencyLoader, setCurrencyLoader] = useState({
    index: "",
    status: false,
    type: ""
  })
  const [isHidden, setIsHidden] = useState(false);
  const [showFiatModal, setShowFiatModal] = useState(false)
  console.log({ favCrypto, favFiat })

  const getFav = async () => {
    try {
      const res = await getAPIAuth(`crypto/get-fav-currency-crypto?userId=${userDetails?.id}`, token);
      // console.log(res, "ress")
      if (res?.data?.success) {
        setFavFiat(res?.data?.fiat)
        setFavCrypto(res?.data?.crypto)
      }

    } catch (error) {

    }
  }

  const getUserBal = async () => {
    try {
      const res = await getAPIAuth(`user/user-balance?userId=${userDetails?.id}`, token)
      if (res?.data?.success) {
        // setUserBal(res.data.data)
        dispatch(userBalanceAction(res.data.data))
      }
    } catch (error) {

    }
  }
  useEffect(() => {
    if (userDetails?.id) {
      getUserBal();
      getFav();
      // userCryptoDetails();
    }
  }, [userDetails.id, favFiatDetails, favCryptoDetails, showFavoriteModal, isChecked])
  useEffect(() => {
    if (favCryptoDetails) {
      setCryptoData(favCryptoDetails)
    }
  }, [favCryptoDetails])

  useEffect(() => {
    if (favFiatDetails) {
      setFiatData(favFiatDetails)
    }
  }, [favFiatDetails])

  const handlePlayDemo = () => {
    setDemo(true)
    // setLoading(true)
    // setTimeout(() => {
    setShowOptionModal(false)
    setLoading(false)
    // }, 4000);
  }

  useEffect(() => {
    if (search) {
      console.log({ search });

      const data = favCryptoDetails.filter(item => item?.code.toLowerCase().includes(search?.toLowerCase()) || item?.name.toLowerCase().includes(search?.toLowerCase()))
      setCryptoData(data)
      console.log({ data });

      const dataFiat = favFiatDetails.filter(item => item?.code.toLowerCase().includes(search?.toLowerCase()) || item?.name.toLowerCase().includes(search?.toLowerCase()))
      console.log({ dataFiat });

      // setCryptoData(dataFiat)
      setFiatData(dataFiat)
    } else {
      setCryptoData(favCryptoDetails)
      setFiatData(favFiatDetails)

    }
  }, [search])
  const handleChange = async (item) => {
    try {
      const res = await getAPIAuth(`user/setCryptoForUser?currency=${item.code}&type=${item.type}&userId=${userDetails.id}`, token);
      if (res?.data?.success) {
        // setUserCurrency(res.data.data);
        // get user updated wallet info--->>>
        const getUserBal = async () => {
          try {
            const res = await getAPIAuth(`user/user-balance?userId=${userDetails?.id}`, token)
            if (res?.data?.success) {
              // setUserBal(res.data.data)
              dispatch(userBalanceAction(res.data.data))
            }
          } catch (error) {

          }
        }
        getUserBal()
        // const dropdownMenus = document.getElementsByClassName("dropdown-menu show");
        // const dropdownMenusArray = Array.from(dropdownMenus);
        // dropdownMenusArray.map(menu => {
        //   menu.classList.remove("show");
        // });

      }
    } catch (error) {
      console.log(error)

    } finally {
      setCurrencyLoader((pre) => ({ ...pre, index: '', status: false, type: "" }))
    }

  }


  const handleViewFiat = async (value) => {
    try {
      const res = await getAPIAuth(`user/setFiatForUser?fiatStatus=${value}&userId=${userDetails.id}`, token);
      if (res?.data?.success) {
        setIsChecked(res.data.data.fiatStatus)
        if (res.data.data.fiatStatus === true) {
          setShowFiatModal(true);
        } else if (res.data.data.fiatStatus === false) {
          setShowFiatModal(false)
        }
      }
    } catch (error) {
    }
  }
  const searchHandler = (e) => {
    if (e !== '') {
      const res = getAPIAuth(`crypto/get-fav-currency-crypto?userId=${userDetails.id}&search=${e.trim(' ')}`)
      console.log(res, "ress---")
      if (res?.data?.success) {
        setFavFiat(res?.data?.fiat)
        setFavCrypto(res?.data?.crypto)
      }
    }

  }
  return (
    <>
      <div
        className="row mx-0 w-100 justify-content-center align-items-center modeSelectDialogWrapper"
      >
        <div className="col-12 d-flex justify-content-center">
          <div className="modeSelectDialog w-100 rounded p-4 d-flex flex-column">
            <div className="row lower">
              <div className="col-sm-10 col-12 mx-auto">
                <div className="d-flex align-items-center justify-content-center gap-3 pb-sm-3 pb-2">
                  <div className="playwithHeading text-light" style={{
                    fontSize: 18,
                    fontWeight: 700
                  }}>
                    Play with Balance
                  </div>
                  <div className="dropdown coindropdown dropdowncenter ">
                    <button
                      className="btn btn-secondary bg-black dropdowntoggle coinbtn border-0 d-flex align-items-center gap-2 text-uppercase"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="outside"
                    >
                      <span className="coinImgs d-flex align-items-center justify-content-center rounded-pill overflow-hidden">
                        <img
                          src={userBalance?.setCurrencyData?.icon}
                          alt="coin" className="h-100 w-100" />
                      </span>
                      {userBalance?.setCurrencyData?.code}
                      {/* {userDetails.fiatStatus ? userDetails?.currency?.symbol : ''} */}
                      {" "}
                      {/* {userBalance?.balance || userBalance?.balance === 0 ? Number(userBalance?.balance)?.toFixed(2) : userDetails?.totalbalance ? Number(userDetails?.totalbalance).toFixed(2) : "0.00"} */}
                    </button>
                    <div className="dropdown-menu innerUsdtBtn userBalInnerDrop userBalInnerDropIframe">
                      <div className="row mx-0">
                        <div className="col-12 px-0">
                          <div className="row mx-0">
                            <div className="col px-0">
                              <div className="searchInp">
                                <img src="./assets/img/searchGray.png" alt="" />
                                <input
                                  type="text"
                                  className="form-control shadow-none"
                                  placeholder="Search"
                                  onChange={(e) => searchHandler(e.target.value)}

                                />
                              </div>
                            </div>
                            <div className="col-auto ps-2 pe-0">
                              <div className="plusBtn" onClick={() => setShowFavoriteModal(true)}>
                                +
                                {/* <AddCurrency showFavoriteModal={showFavoriteModal} /> */}
                              </div>
                            </div>
                            <div className="col-12 px-0">
                              <div className="dropTabs">
                                <div className="tab active">Currency</div>
                                <div className="tab">mNFT</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="innerScrollDiv">
                          <div className="col-12 px-0">
                            {favFiat.length > 0 ? (
                              <>
                                {isHidden ? "" : <div className="dropTitle mb-1">Fiat</div>}

                                <ul className="m-0 p-0 list-unstyled usdtImageDropdownInner">
                                  {
                                    favFiat?.map((item, index) => (
                                      <li key={index} className={`usdtImageDropdownInnerList d-flex justify-content-between align-items-center ${isHidden && (parseFloat(item.totalBalance) === 0 && parseFloat(item.totalBonus) === 0) ? "d-none" : ""}  ${item.code === userBalance?.setCurrencyData?.code ? 'active' : ''}`}
                                        onClick={() => {

                                          setCurrencyLoader((pre) => ({ ...pre, index: index, status: true, type: "fiat" }))

                                          handleChange(item)
                                        }}
                                      >
                                        <div className="d-flex align-items-center">

                                          {
                                            currencyLoader.index == index && currencyLoader.status && currencyLoader.type == 'fiat' ? (
                                              <><Spinner size="sm" /></>
                                            ) : (<>
                                              <img loading='lazy' className="rounded" src={item.icon} alt="" />
                                            </>)
                                          }
                                          {item.code}
                                        </div>
                                        <div>
                                          {/* {userDetails.fiatStatus ? item.symbol + ' ' + item.totalBalance : item.wallet?.totalBalance ? item.wallet?.totalBalance : '0'} */}
                                          {userDetails.fiatStatus ? userDetails?.currency?.symbol : ""} {item?.totalBalance ? convertToEightDigitsAfterDecimal(item?.totalBalance) : "0.00"}

                                          {userDetails.fiatStatus ? (
                                            <span className="smallVal"> {item.wallet?.totalBalance ? convertToEightDigitsAfterDecimal(item.wallet?.totalBalance) : '0.00'}
                                            </span>
                                          ) : (
                                            ''
                                          )
                                          }
                                        </div>
                                      </li>
                                    ))}
                                </ul>
                              </>) : null}
                          </div>
                          <div className="col-12 px-0">
                            {favCrypto.length > 0 ? (
                              <>
                                {/* <div className="dropTitle mb-1">Crypto</div> */}
                                {isHidden ? "" : <div className="dropTitle mb-1">Crypto</div>}

                                <ul className="m-0 p-0 list-unstyled usdtImageDropdownInner">
                                  {/*     */}
                                  {favCrypto?.map((item, index) => (

                                    <li
                                      key={index}
                                      className={`usdtImageDropdownInnerList d-flex justify-content-between align-items-center ${isHidden && (parseFloat(item.totalBalance) === 0 && parseFloat(item.totalBonus) === 0) ? "d-none" : ""}  ${item.code === userBalance?.setCurrencyData?.code ? 'active' : ''}`}
                                      onClick={() => {
                                        // setCurrencyLoader(true)
                                        setCurrencyLoader((pre) => ({ ...pre, index: index, status: true, type: "crypto" }))

                                        handleChange(item)
                                      }}
                                    >
                                      <div className="d-flex align-items-center">

                                        {
                                          currencyLoader.index == index && currencyLoader.status && currencyLoader.type == 'crypto' ? (
                                            <><Spinner size="sm" /></>
                                          ) : (<>
                                            <img loading='lazy' className="rounded" src={item.icon} alt="" />
                                          </>)
                                        }
                                        {item.code}
                                        {/* <img className="infoIcon"
                                                            src="assets/img/header/exclamationYellow.png" alt=""
                                                            onClick={() => setShowUsdtDetailModal(true)} /> */}
                                      </div>
                                      <div className="text-end">
                                        {/* {userDetails.fiatStatus ? item.currency.symbol + ' ' + item.totalBalance : item.wallet?.totalBalance ? item.wallet?.totalBalance : '0'} */}
                                        {userDetails.fiatStatus ? userDetails?.currency?.symbol : ""} {item?.totalBalance ? convertToEightDigitsAfterDecimal(item?.totalBalance) : "0.00"}
                                        {userDetails.fiatStatus ? <span className="smallVal"> {item.wallet?.totalBalance ? convertToEightDigitsAfterDecimal(item.wallet?.totalBalance) : '0.00'}</span> : ""}
                                        {/* <div className="usdtValue2"
                                                            onClick={() => setShowUsdtBonusModal(true)}
                                                        >
                                                            <img
                                                                src="assets/img/header/lockYellow.png" alt="" />
                                                            $ {userBalance?.lockedBonus || userBalance?.lockedBonus === 0 ? Number(userBalance?.lockedBonus).toFixed(2) : "0"}
                                                        </div> */}
                                      </div>
                                    </li>
                                  ))}
                                  {/* {
                                                currencyExchange?.length ? 
                                                    currencyExchange?.map((item)=> (
                                                      <>
                                                        <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded" src={item?.icon} alt="" />
                                                                {item?.name}
                                                            </div>
                                                            <div>
                                                                {Number(item?.usdAmount)?.toFixed(2)}
                                                            </div>
                                                        </li>
                                                        <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded" src={item?.icon} alt="" />
                                                                {item?.name}
                                                            </div>
                                                            <div>
                                                                {Number(item?.usdAmount)?.toFixed(2)}
                                                            </div>
                                                        </li>
                                                        <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded" src={item?.icon} alt="" />
                                                                {item?.name}
                                                            </div>
                                                            <div>
                                                                {Number(item?.usdAmount)?.toFixed(2)}
                                                            </div>
                                                        </li>
                                                        <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded" src={item?.icon} alt="" />
                                                                {item?.name}
                                                            </div>
                                                            <div>
                                                                {Number(item?.usdAmount)?.toFixed(2)}
                                                            </div>
                                                        </li>
                                                      </>  
                                                    ))
                                                : ''
                                            } */}
                                </ul>
                              </>) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 playwithdetail text-center text-light" style={{fontSize: 14}}>
                The selected currency will be displayed in {" "}
                <span className='currency-text'>
                  {userDetails?.fiatStatus ? `${userDetails?.currency?.code} Fiat` : 'USD'}
                </span>, and if you change currency while playing, the game will refresh and restart.
              </div>
            </div>
            {
              showError?.show ? <>
                <h6 className="text-danger text-center mt-3">
                  {showError?.msg}
                </h6>
                {/* <button className='refBtn mt-2'>refresh</button> */}
                <FiatErrorModal/>
              </> : <>
                <div className="row py-2 g-3">
                  <div className="col-12 dialogTxt" style={{fontSize: 14}}>
                    Please select the mode you want to play
                  </div>
                  <div className="col-sm-6">
                    <button className='dialogBtn' onClick={() => {
                      setDemo(false)
                      setShowOptionModal(false)
                    }}>Play with real money</button>
                  </div>
                  <div className="col-sm-6">
                    <button className='dialogBtn freePlay' onClick={handlePlayDemo}>
                      {
                        loading ? 'loading...' : 'Play in demo mode'
                      }
                    </button>
                  </div>
                </div>
              </>
            }

          </div>
        </div>
      </div>
    </>
  )
}

export default ModeOptionModal

// <div className="dropdown-menu coinMenu p-4 rounded-1 dropdown-menu-end">
//                       <div className="row"
//                         style={{
//                           height: "300px",
//                           overflowY: "auto",
//                         }}
//                       >
//                         <div className="col-12 pb-1">
//                           <div className="input-group coinsearch rounded-2 align-items-center ps-2">
//                             <button className="input-group-text searchBtns ms-1 bg-transparent p-0 border-0" id="basic-addon1">
//                               <img src="assets/img/search.png" alt="search" className="h-100" />
//                             </button>
//                             <input
//                               type="search"
//                               className="form-control searchipt shadow-none bg-transparent border-0"
//                               placeholder="Search"
//                               aria-label="Username"
//                               aria-describedby="basic-addon1"
//                               value={search}
//                               onChange={(e) => setSearch(e.target.value)}
//                             />
//                           </div>
//                         </div>
//                         <div className="col-12 pt-2">
//                           <ul className="nav nav-tabs cointabs border-0 rounded-2" id="myTab" role="tablist">
//                             <li className="nav-item w-50" role="presentation">
//                               <button
//                                 className="nav-link d-flex align-items-center justify-content-center w-100 tablink border-0 rounded-1 active"
//                                 id="currancy-tab"
//                                 data-bs-toggle="tab"
//                                 data-bs-target="#currancy-tab-pane"
//                                 type="button"
//                                 role="tab"
//                                 aria-controls="currancy-tab-pane"
//                                 aria-selected="true"
//                               >
//                                 Currency
//                               </button>
//                             </li>
//                             <li className="nav-item w-50" role="presentation">
//                               <button
//                                 className="nav-link d-flex align-items-center justify-content-center w-100 tablink border-0 rounded-1"
//                                 id="mnft-tab"
//                                 data-bs-toggle="tab"
//                                 data-bs-target="#mnft-tab-pane"
//                                 type="button"
//                                 role="tab"
//                                 aria-controls="mnft-tab-pane"
//                                 aria-selected="false"
//                               >
//                                 mNFT
//                               </button>
//                             </li>
//                           </ul>
//                         </div>
//                         <div className="col-12 pt-3">
//                           <div className="tab-content" id="myTabContent">
//                             <div
//                               className="tab-pane fade show active"
//                               id="currancy-tab-pane"
//                               role="tabpanel"
//                               aria-labelledby="currancy-tab"
//                               tabIndex={0}
//                             >
//                               <p className='text-light m-0'>
//                                 Fiat
//                               </p>
//                               <ul className="list-unstyled gap-1 d-flex flex-column">
//                                 {
//                                   fiatData?.map((item) => (
//                                     <li
//                                       onClick={() => handleChange(item)}
//                                     >
//                                       <div
//                                         className={`d-flex align-items-center justify-content-between coinMain rounded-2 ${item?.code === userBalance?.setCurrencyData?.code ? 'active' : ''}`}>
//                                         <div className="d-inline-flex align-items-center gap-2">
//                                           <div className="dropcoinImg d-flex align-items-center justify-content-center overflow-hidden rounded-circle">
//                                             <img src={item?.icon} alt="coin" className="h-100 w-100 img-fluid" />
//                                           </div>
//                                           <div className="coinname">
//                                             {/* {item} */}
//                                             {item?.code}

//                                           </div>
//                                         </div>
//                                         <div className="coinPrice">
//                                           {/* 0.67589 */}
//                                           {userDetails?.fiatStatus ? userDetails?.currency?.symbol : ""} {item?.wallet?.totalBalance ? item.wallet?.totalBalance : "0"}

//                                         </div>
//                                       </div>
//                                     </li>
//                                   ))
//                                 }

//                               </ul>
//                               <p className='text-light m-0'>
//                                 Crypto
//                               </p>
//                               <ul className="list-unstyled gap-1 d-flex flex-column">
//                                 {
//                                   cryptoData?.map((item) => (
//                                     <li
//                                       onClick={() => handleChange(item)}
//                                     >
//                                       <div
//                                         className={`d-flex align-items-center justify-content-between coinMain rounded-2 ${item?.code === userBalance?.setCurrencyData?.code ? 'active' : ''}`}>
//                                         <div className="d-inline-flex align-items-center gap-2">
//                                           <div className="dropcoinImg d-flex align-items-center justify-content-center overflow-hidden rounded-circle">
//                                             <img src={item?.icon} alt="coin" className="h-100 w-100 img-fluid" />
//                                           </div>
//                                           <div className="coinname">
//                                             {/* {item} */}
//                                             {item?.code}
//                                           </div>
//                                         </div>
//                                         <div className="coinPrice">
//                                           {/* 0.67589 */}
//                                           {userDetails?.fiatStatus ? userDetails?.currency?.symbol : ""} {item?.wallet?.totalBalance ? item?.wallet?.totalBalance : "0"}
//                                         </div>
//                                       </div>
//                                     </li>
//                                   ))
//                                 }

//                               </ul>
//                             </div>
//                             <div className="tab-pane fade" id="mnft-tab-pane" role="tabpanel" aria-labelledby="mnft-tab" tabIndex={0}>...</div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>