import React, { useEffect, useState } from 'react'
import { useAuthModal } from '../../../hooks/useAuthModal'
import { AUTH_MODAL_TABS, MD_SCREEN_WIDTH } from '../../../constants'
import { getAPI } from '../../../service/apiInstance'
import { useMediaQuery } from 'react-responsive'
import authBanner from './../../../assets/img/authBanner.png'

const StaticBanner = () => {
  const { setAuthModalObject, authModalObject } = useAuthModal()
  const isMobile = useMediaQuery({ maxWidth: MD_SCREEN_WIDTH })
  const [bannerData, setBannerData] = useState([])
  const [mobileBanner, setMobileBanner] = useState('')
  const [desktopBanner, setDesktopBanner] = useState('')

  const getBannerImage = async () => {
    try {
      const res = await getAPI('banner/getBannerByFilter?type=signup')
      if (res?.data?.success) {
        setBannerData(res?.data?.data)
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getBannerImage()
  }, [])

  useEffect(() => {
    if (bannerData?.length) {
      const desktopData = bannerData?.filter((item) => item?.viewType === 'desktop')
      setDesktopBanner(desktopData?.[0]?.image)
      const mobileData = bannerData?.filter((item) => item?.viewType === 'mobile')
      setMobileBanner(mobileData?.[0]?.image)

    }
  }, [bannerData])

  return (
    <>
      <div className="leftWrapper" style={{ '--bgImg': `url(${isMobile ? (mobileBanner ? mobileBanner : authBanner) : (desktopBanner ? desktopBanner : authBanner)})` }}>
        {/* <div className="row mx-0 w-100 align-items-center leftWrapperSignUpTxt d-lg-none">
          <div className="col"><div className="sign"> {authModalObject.selectedTab ===AUTH_MODAL_TABS.LOG_IN ? "Sign In" :"Sign Up"} </div></div>
          <div className="col-auto">
              <div className="close" onClick={()=>setAuthModalObject(pre=>({...pre,isAuthOpen:false}))}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                      <path d="M9.23043 1.13304C9.48961 0.873846 9.48961 0.453598 9.23043 0.194402C8.97124 -0.0648007 8.55098 -0.0648007 8.29179 0.194402L4.71242 3.77379L1.13304 0.194402C0.873846 -0.0648007 0.453598 -0.0648007 0.194402 0.194402C-0.0648007 0.453598 -0.0648007 0.873846 0.194402 1.13304L3.77379 4.71242L0.194402 8.29179C-0.0648007 8.55098 -0.0648007 8.97124 0.194402 9.23043C0.453598 9.48961 0.873846 9.48961 1.13304 9.23043L4.71242 5.65105L8.29179 9.23043C8.55098 9.48961 8.97124 9.48961 9.23043 9.23043C9.48961 8.97124 9.48961 8.55098 9.23043 8.29179L5.65105 4.71242L9.23043 1.13304Z" fill="white"/>
                  </svg>
              </div>
          </div>
        </div> */}
        {/* <div className="signupLftImg">
              <img className='' src={modallogo} alt=''/>
          </div>
          <div className="welcome">WELCOME TO <span>{APP_NAME}</span></div>
          <div className="start">START YOUR GAME JOURNEY NOW!</div> */}
        <div className="signImgConatiner">
          {/* <div className="d-flex align-items-start gap-3">
            <div className="signLogo">
              <img className='h-100 w-100' src="assets/img/appLogo.png" alt="" />
            </div>
            <div className="signupImgText">
              A quality gaming experience
            </div>
          </div> */}
          <div className="termsText">
            By accessing this site, I attest that I am at least <span>18 years</span> old and have rerad the <span>Terms & Conditions</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default StaticBanner