import React, { useEffect, useState } from 'react'
import { getAPIAuth } from '../../../service/apiInstance'
import { Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { assetAction, networkAction } from '../../../store/action'
import { useAuth } from '../../../hooks/useAuth'
import { useKado } from '../../../hooks/useKado'
import { useTranslation } from 'react-i18next'

const SelectNetwork = ({ showNetwork, setShowNetwork, network, asset, setNetwork, setAsset }) => {
    const [loader, setLoader] = useState(true)
    const [assetLoader, setAssetsLoader] = useState(true)
    const dispatch = useDispatch()
    const networkData = useSelector(state => state?.networkData)
    const assetData = useSelector(state => state?.assetData)
    const { token } = useAuth()
    const [preNetworkId, setPreNetworkId] = useState('')
    const { kadoWalletObject } = useKado()
    const { t } = useTranslation()
    const kadoMoney = t("kadoMoney", { returnObjects: true })

    const getNetworkData = async () => {
        setLoader(true)
        try {
            const res = await getAPIAuth('crypto/get-blockchain', token)
            if (res?.data?.success) {
                // setCryptoData(res?.data?.data)
                // setFilterData(res?.data?.data)
                dispatch(networkAction(res?.data?.data))
                if (!network?._id) {
                    const filterData = res?.data?.data?.filter(item => item?.network === "ethereum")
                    dispatch(setNetwork(filterData[0] ? filterData[0] : res?.data?.data[0]))
                    // dispatch(setNetwork(res?.data?.data[0]))
                }
            }
        } catch (error) {

        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        if (token && kadoWalletObject.isBuySellOpen) {

            if (!networkData?.length) {
                getNetworkData()
            } else {
                setLoader(false)
                if (!network?._id) {
                    // dispatch(setNetwork(networkData[0]))
                    const filterData = networkData?.filter(item => item?.network === "ethereum")
                    dispatch(setNetwork(filterData[0] ? filterData[0] : networkData[0]))
                    // setPreNetworkId(networkData[0]?._id)
                }
            }
        }

    }, [token, kadoWalletObject.isBuySellOpen])


    const getAssets = async (item) => {
        setAssetsLoader(true)
        try {
            if (network?._id) {
                const res = await getAPIAuth(`crypto/get-network-assets?blockChainId=${item?._id ? item?._id : network?._id}`, token)
                if (res?.data?.success) {
                    dispatch(assetAction(res?.data?.data))
                    if (!asset?.blockChainId?.network || item?._id) {
                        // symbol:"USDC"
                        const filterData = res?.data?.data?.filter(item => item.symbol === "USDT")
                        // dispatch(setAsset(res?.data?.data[0]))
                        dispatch(setAsset(filterData[0] ? filterData[0] : res?.data?.data[0]))
                        // dispatch(swapAssetFromAction(res?.data?.data[0]))
                    }
                    // setAssetsList(res?.data?.data)
                }
            }
        } catch (error) {

        } finally {
            setAssetsLoader(false)
        }

    }

    useEffect(() => {

        if (token) {
            // if (!asset?.blockChainId?.network) {
            getAssets()
            // } else {
            // setAssetsLoader(false)
            // }
        }
    }, [token, network?._id])

    return (
        <>
            {/* <span className="currencyOffcanvasBackdrop"></span> */}
            <div className={`currencyOffcanvas row mx-0 w-100 h-100 position-absolute top-0 bottom-0 start-0 end-0 ${showNetwork ? 'show' : ''}`}
            >
                <div className="col-12 offcanvasInner m-auto z-3 position-relative flex-column d-flex">
                    <div className="row h-100 flex-column flex-nowrap overflow-hidden">
                        <div className="col-12 pb-3">
                            <div className="row align-items-center flex-nowrap">
                                <div className="col-auto pe-0 d-grid offcanvaskBtn" onClick={() => setShowNetwork(false)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
                                        <rect x="0.0449219" y="0.505859" width="25.6028" height="25.6028" rx="5" fill="#15191F" />
                                        <path d="M14.4339 16.4975L10.7146 13.666C10.4589 13.4714 10.4589 13.1427 10.7146 12.948L14.4339 10.1165C14.7133 9.90377 15.1719 10.064 15.1719 10.3744L15.1719 16.2396C15.1719 16.55 14.7133 16.7102 14.4339 16.4975Z" fill="white" />
                                    </svg>
                                </div>
                                <div className="col offcanvasTitle">{kadoMoney.Network_and_Asset}</div>
                                {/* <div className="col-auto d-grid offcanvasClose"

                                    onClick={() => {
                                        setKadoWalletObject((prev) => ({
                                            ...prev,
                                            isBuySellOpen: false
                                        }))
                                        setShowNetwork(false)
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                                        <path d="M10.1229 1.48316C10.3998 1.2062 10.3998 0.757149 10.1229 0.480187C9.84591 0.203219 9.39684 0.203219 9.11989 0.480187L5.29518 4.3049L1.47047 0.480187C1.19351 0.203219 0.744454 0.203219 0.467492 0.480187C0.190523 0.757149 0.190523 1.2062 0.467492 1.48316L4.29221 5.30787L0.467492 9.13258C0.190523 9.40953 0.190523 9.8586 0.467492 10.1356C0.744454 10.4125 1.19351 10.4125 1.47047 10.1356L5.29518 6.31084L9.11989 10.1356C9.39684 10.4125 9.84591 10.4125 10.1229 10.1356C10.3998 9.8586 10.3998 9.40953 10.1229 9.13258L6.29814 5.30787L10.1229 1.48316Z" fill="#969CAA" />
                                    </svg>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-12 inputUi d-flex flex-column gap-2 overflow-hidden">
                            <label htmlFor="currencySearch">{kadoMoney.Select_network}</label>
                            <div className="row">
                                <div className="col-12 position-relative">
                                    <div className="row networks">
                                        {
                                            loader ? (
                                                <div className='h-100 w-100 '>
                                                    <Spinner />
                                                </div>
                                            ) : (
                                                !networkData?.length ? (
                                                    <div className='h-100 w-100 '>
                                                        No data available
                                                    </div>
                                                ) : (
                                                    networkData?.map((item) => (
                                                        <div
                                                            key={item?._id}
                                                            onClick={() => {
                                                                // setSelectedCrypto(item)
                                                                dispatch(setNetwork(item))
                                                                getAssets(item)
                                                                // setPreNetworkId(network)
                                                            }}
                                                            className={`col-2 network ${network?._id === item._id ? "active" : ''}`}
                                                        >
                                                            <div className="row networkInner mx-0" data-tooltipp={item.network}>
                                                                <div className="col-12 networkIcon">
                                                                    <img src={item?.image} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 pt-3 pb-2">
                            <div className="row mx-0">
                                <div className="col-12 dividerCol"></div>
                            </div>
                        </div>
                        <div className="col-12 inputUi d-flex flex-column gap-2 flex-fill overflow-hidden">
                            <label htmlFor="selectAsset">{kadoMoney.Select_asset}</label>
                            <div className="row selectAssetGroup mx-0 gap-2 flex-column flex-fill flex-nowrap overflow-auto">
                                {/* may be of future use */}
                                {/* upTrend card */}
                                {/* <div className="col-12 selectAssetItem active">
                                    <div className="row selectAssetItemInner flex-nowrap align-items-center">
                                        <div className="col-1 px-0 selectAssetIcon">
                                            <b>
                                                <img src="assets/img/icon2344234.png" alt="" />
                                            </b>
                                            <span className='w-100 h-100'>
                                                <img src="assets/img/tether-usdt.png" alt="" />
                                            </span>
                                        </div>
                                        <div className="col-2 selectAssetTitle">USD</div>
                                        <div className="col selectAssetSubTitle">United States Dollar</div>
                                        <div className="col-auto selectAssetTreding up">2.85%</div>
                                    </div>
                                </div> */}
                                {/* downTrend card */}
                                {/* <div className="col-12 selectAssetItem">
                                    <div className="row selectAssetItemInner flex-nowrap align-items-center">
                                        <div className="col-1 px-0 selectAssetIcon">
                                            <b>
                                                <img src="assets/img/icon2344234.png" alt="" />
                                            </b>
                                            <span className='w-100 h-100'>
                                                <img src="assets/img/tether-usdt.png" alt="" />
                                            </span>
                                        </div>
                                        <div className="col-2 selectAssetTitle">USD</div>
                                        <div className="col selectAssetSubTitle">United States Dollar</div>
                                        <div className="col-auto selectAssetTreding down">2.85%</div>
                                    </div>
                                </div> */}
                                {
                                    assetLoader ? (
                                        <div className='h-100 w-100 d-flex justify-content-center '>
                                            <Spinner />
                                        </div>
                                    ) : (
                                        !assetData.length ? (
                                            <div className='h-100 w-100  d-flex justify-content-center'>
                                                No data available
                                            </div>
                                        ) : (
                                            assetData?.map((item) => (
                                                <div className={`col-12 selectAssetItem ${item?._id === asset?._id ? "active" : ''}`}
                                                    key={item?._id}
                                                    onClick={() => {
                                                        // setSelectedAsset(item)
                                                        dispatch(setAsset(item))
                                                        setShowNetwork(false)
                                                    }}
                                                >
                                                    <div className="row selectAssetItemInner flex-nowrap align-items-center">
                                                        <div className="col-1 px-0 selectAssetIcon">
                                                            <b>
                                                                <img src={item?.blockChainId?.image} alt="" />
                                                            </b>
                                                            <span className='w-100 h-100'>
                                                                <img src={item?.image} alt="" />
                                                            </span>
                                                        </div>
                                                        <div className="col-2 selectAssetTitle">{item?.symbol}</div>
                                                        <div className="col selectAssetSubTitle">{item?.name}</div>
                                                        <div className="col-auto selectAssetTreding"></div>
                                                    </div>
                                                </div>
                                            ))
                                        )
                                    )
                                }

                            </div>
                        </div>
                        <div className="col-12 pt-4">
                            <div className="row weAcceptGroup align-items-center gap-2 justify-content-center">
                                <div className="col-auto weAccepTitle px-0 d-grid">{kadoMoney.We_accept}</div>
                                <div className="col-auto weAccepDivider px-0 d-grid"></div>
                                <div className="col-auto weAccepIcons px-0 d-grid">
                                <img loading='lazy' src="assets/img/paymentOptionss.png" alt="payment" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default SelectNetwork