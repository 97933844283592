import React from 'react'

const NoData = () => {
  return (
    <>
      <div className="d-flex flex-column noDataBox justify-content-center align-items-center bg-transparent">
        <img src="./assets/img/stacking/noData.svg" alt="" />
        <div className="noDataTxt mt-2">OOPS! NO DATA FOUND</div>
      </div>
    </>
  )
}

export default NoData