import { useContext } from "react"
import { AuthBtContext } from "../context/authBtContext"


export const useBtAuth = () => {
    const { btToken, setBtToken } = useContext(AuthBtContext)
    return {
        btToken,
        setBtToken
    }
}