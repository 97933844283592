import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import './style.scss';
import StaticBanner from './components/StaticBanner';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import { useAuthModal } from '../../hooks/useAuthModal'
import { AUTH_MODAL_TABS, CLICK_ID, NEW_CLICK_ID, OPEN_CAGE_API_KEY, SPIN_STRING } from '../../constants';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import ipAddressFunc from '../../utils/ipAddressFunc';
import { useDispatch, useSelector } from 'react-redux';
import { countryAction, ipAddressAction } from '../../store/action';
import axios from 'axios';

const UpdatedAuthModal = () => {
    // const { authModalObject, setAuthModalObject } = useAuthModal()
    const { setAuthModalObject, authModalObject } = useAuthModal()
    const [query, setQuery] = useSearchParams()
    const [browserName, setBrowserName] = useState()
    const [isForgotPassword, setIsForgotPassword] = useState(query.get('action') === 'forgotPassword')
    const [checks, setChecks] = useState({
        terms: false,
        promotions: false
    })

    const click_id = sessionStorage.getItem(CLICK_ID) === 'null' ? null : sessionStorage.getItem(CLICK_ID)
    const new_click_id = localStorage.getItem(NEW_CLICK_ID) === 'null' ? null : localStorage.getItem(NEW_CLICK_ID) // setup in appHeader 
    // setup in appHeader 
    const [bonus, setBonus] = useState({ bonus: false })
    const { token } = useAuth()
    const { t } = useTranslation()
    const authLabels = t("AuthLabels", { returnObjects: true }) //string has to be same as language json key 
    const dispatch = useDispatch()

    const ipAddress = useSelector(state => state.ipAddress)
    const country = useSelector(state => state.country)

    //ip Address
    const fetchIpAddress = async () => {
        const res = await ipAddressFunc()
        if (res) {
            dispatch(ipAddressAction(res))
        }
    }
    //get country name
    const countryNameFunc = async () => {
        try {
            navigator.geolocation?.getCurrentPosition(async (data) => {
                axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${data?.coords?.latitude}+${data?.coords?.longitude}&key=${OPEN_CAGE_API_KEY}`)
                    .then((res) => {
                        dispatch(countryAction(res?.data?.results[0]?.components?.country))
                    })
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        if (authModalObject.isAuthOpen) {
            console.log('ipAddress', ipAddress)
            if (!ipAddress) fetchIpAddress()
            if (!country) countryNameFunc()
        }
    }, [authModalObject.isAuthOpen])


    useEffect(() => {
        if (token) {
            if (query.get('action') === 'forgotPassword' && authModalObject?.selectedTab == AUTH_MODAL_TABS.LOG_IN) {
                setIsForgotPassword(true)
                console.log("hello--->>>");
            }
        }
    }, [query.get('action'), token])

    useEffect(() => {
        // const encodedParam = searchParams.get("bonus")
        const encodedParam = localStorage.getItem(SPIN_STRING)
        if (!!encodedParam) {
            const decodedParam = JSON.parse(atob(encodedParam));
            setBonus(decodedParam)
        }
    }, [authModalObject.isAuthOpen])




    return (
        <>
            <Modal
                show={authModalObject.isAuthOpen}
                // show= {true}
                className='modalDialog overflow-x-hidden signUpModalNew'
                scrollable
                // centered
                fullscreen={'sm-down'}
                onHide={() => {
                    setAuthModalObject(pre => ({ ...pre, isAuthOpen: false }))
                    setQuery({ action: "" })
                }}
            >
                <Modal.Body className='modalBody overflow-x-hidden overflow-y-auto'>
                    <div className="row w-100 mx-0 flex-column flex-lg-row flex-nowrap flex-lg-wrap">
                        <div className="col-12 px-1 d-lg-none">
                            <div className="row mx-0 w-100 align-items-center leftWrapperSignUpTxt pt-3">
                                {/* <div className="col"><div className="sign"> {authModalObject.selectedTab === AUTH_MODAL_TABS.LOG_IN ? (isForgotPassword ? 'Forget Password' : "Sign In") : "Sign Up"} </div></div> */}
                                <div className="col"><div className="sign"> {authModalObject.selectedTab === AUTH_MODAL_TABS.LOG_IN ? (isForgotPassword ? authLabels.Forget_Password : authLabels.Sign_In) : authLabels.Sign_Up} </div></div>
                                <div className="col-auto">
                                    <div className="close" onClick={() => {
                                        setAuthModalObject(pre => ({ ...pre, isAuthOpen: false }))
                                        setQuery({ action: "" })
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                            <path d="M9.23043 1.13304C9.48961 0.873846 9.48961 0.453598 9.23043 0.194402C8.97124 -0.0648007 8.55098 -0.0648007 8.29179 0.194402L4.71242 3.77379L1.13304 0.194402C0.873846 -0.0648007 0.453598 -0.0648007 0.194402 0.194402C-0.0648007 0.453598 -0.0648007 0.873846 0.194402 1.13304L3.77379 4.71242L0.194402 8.29179C-0.0648007 8.55098 -0.0648007 8.97124 0.194402 9.23043C0.453598 9.48961 0.873846 9.48961 1.13304 9.23043L4.71242 5.65105L8.29179 9.23043C8.55098 9.48961 8.97124 9.48961 9.23043 9.23043C9.48961 8.97124 9.48961 8.55098 9.23043 8.29179L5.65105 4.71242L9.23043 1.13304Z" fill="white" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 px-0 col order-2 order-lg-1">
                            {
                                authModalObject.selectedTab === AUTH_MODAL_TABS.SIGN_UP ? (
                                    <SignUp
                                        checks={checks}
                                        // setActiveTab={setActiveTab}
                                        browserName={browserName}
                                        click_id={click_id}
                                        new_click_id={new_click_id}
                                        setChecks={setChecks}
                                        bonus={bonus}
                                    />
                                ) : (<></>)
                            }
                            {
                                authModalObject.selectedTab === AUTH_MODAL_TABS.LOG_IN ? (
                                    <SignIn
                                        browserName={browserName}
                                        isForgotPassword={isForgotPassword}
                                        setIsForgotPassword={setIsForgotPassword}
                                    />
                                ) : (<></>)
                            }

                        </div>
                        <div className="col-lg-6 px-0 order-1 order-lg-2">
                            <StaticBanner />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UpdatedAuthModal