import { useContext } from "react"
import { BonusContext } from "../context/bonusContext"


export const useBonus = ()=> {
  const {bonusObject, setBonusObject} = useContext(BonusContext)
  return {
    bonusObject, 
    setBonusObject
  }
}