import React, { createContext, useState } from 'react'

export const ClaimBonusContext = createContext(null)

const ClaimBonusContextProvider = ({children}) => {
  const [claimBonusObject, setClaimBonusObject] = useState({
    isClaimBonusOpen : false,
    isClaimHistoryOpen: false,
    selectedTab : ''
  })

  return (
    <ClaimBonusContext.Provider value={{claimBonusObject, setClaimBonusObject}}>{children}</ClaimBonusContext.Provider>
  )
}

export default ClaimBonusContextProvider