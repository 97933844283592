import { Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from "yup";
import { errorToaster, succesToaster } from '../../../../utils/toaster';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../../config/firebase';
import { postAPI } from '../../../../service/apiInstance';
import { useAuth } from '../../../../hooks/useAuth';
import { useSelector } from 'react-redux';
import { USER_LANG } from '../../../../constants';
import { useAuthModal } from '../../../../hooks/useAuthModal';
import ErrorMessage from '../../../common/ErrorMessage/ErrorMessage';
import { Spinner } from 'react-bootstrap';
import { useSpinModal } from '../../../../hooks/useSpinModal';
import { useReferralModal } from '../../../../hooks/useReferralModal';
import { useTranslation } from 'react-i18next';
import { getCustomAuthError } from '../../../../utils/customFirebaseError';

const validationSchema = Yup.object({
    password: Yup.string().required("This field is required"),
    email: Yup.string()
        .required("Email is required")
        .matches(
            /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
            "Invalid email"
        ),
});

const SignUpEmail = ({ checks, browserName, setChecks, bonus, click_id, referral, new_click_id }) => {
    const [isPasswordHidden, setIsPasswordHidden] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const { setToken } = useAuth()
    const country = useSelector(state => state.country)
    const { authModalObject, setAuthModalObject } = useAuthModal()
    const { setSpinModalObject } = useSpinModal()
    const ipAddress = useSelector(state => state.ipAddress)
    const { isReferral, setIsReferral, isReferralInput, setIsReferralInput } = useReferralModal()
    const { t } = useTranslation()
    const authLabels = t("AuthLabels", { returnObjects: true }) //string has to be same as language json key 



    async function createUserWithEmailAndPasswordF(values) {
        if (!checks.terms) {
            errorToaster("User agreement is required");
            setIsLoading(false)
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                values.email,
                values.password,
            );
            const user = userCredential.user;
            // const lang = JSON.parse(localStorage.getItem(USER_LANG))
            const lang = localStorage.getItem(USER_LANG)
            const body = {
                email: userCredential._tokenResponse.email,
                idToken: userCredential._tokenResponse.idToken,
                localId: userCredential._tokenResponse.localId,
                refreshToken: userCredential._tokenResponse.refreshToken,
                password: values.password,
                terms: checks.terms,
                promotion: checks.promotions,
                // username: username,
                ipAddress: ipAddress ? ipAddress : "",
                country: country ? country : "",
                // language: lang?.symbol ? lang?.symbol : localStorage.getItem(USER_LANG),
                language: lang,
                refercode: values.referral ? values.referral : "",
                browser: browserName,
                bonus: !!bonus ? bonus.bonus : false,
                trackingToken: !!click_id ? click_id : null,
                refercode: values.referral ? values.referral : "",
                isLoginWithEmail : true,
                clickid : !!new_click_id ? new_click_id : null
            };

            const res = await postAPI("user/register-user", body);
            if (res.data.success) {
                succesToaster(res.data.message);
                setToken(res?.data.data?.auth_token);
                setAuthModalObject(pre => ({ ...pre, isAuthOpen: false }))
                setSpinModalObject(prev => ({
                    ...prev,
                    isModalOpen: true
                }))
                localStorage.removeItem('clickid')
                // resetForm()
                // setIsReferral(true)
                window?.dataLayer?.push({
                    'event': 'GA4_event',
                    'event_name': 'game_sign_up'
                });
            } else {
                errorToaster(res?.data?.message);
            }
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message.substr(9, 30);
            if (error.message === 'Firebase: Error (auth/email-already-in-use).') {
                errorToaster('Email already exists');
            } else {
                // errorToaster(errorMessage);
                errorToaster(getCustomAuthError(error))
            }
        } finally {
            setIsLoading(false)
        }
    }
    return (
        <Formik
            initialValues={{
                email: "",
                password: "",
                referral: referral
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                setIsLoading(true)
                createUserWithEmailAndPasswordF(values)
            }}
        >
            {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
            }) => (
                <form autoComplete='off'>
                    <div className="col-12">
                    <div>
                        <label className='inputLabelNew'> Email </label>
                    </div>
                        <input
                            className='inp'
                            type="email"
                            autoComplete='off'
                            placeholder='Email'
                            name="email"
                            id="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </div>
                    <ErrorMessage>
                        {errors.email && touched.email && errors.email}
                    </ErrorMessage>
                    <div className="col-12 mt-2">
                        <div className="inpWrapper position-relative">
                        <div>
                            <label className='inputLabelNew'>Password*</label>
                        </div>
                            <input
                                className='inp'
                                autoComplete='off'
                                type={`${isPasswordHidden ? "password" : "text"}`}
                                // placeholder='Login Password'
                                placeholder={authLabels.Password}
                                name="password"
                                id="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {
                                isPasswordHidden ? (
                                    <span
                                        onClick={() => setIsPasswordHidden(false)}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.34444 4.41401C4.34444 2.6747 5.75918 1.25937 7.50037 1.25937C9.23972 1.25937 10.6544 2.67466 10.6544 4.41401C10.6544 6.15372 9.23972 7.56843 7.50037 7.56843C5.75915 7.56843 4.34444 6.15372 4.34444 4.41401ZM0.507401 4.11304C0.398575 4.30988 0.398575 4.51813 0.507401 4.71515C1.92027 7.25228 4.5996 8.82838 7.50037 8.82838C10.3992 8.82838 13.0786 7.25231 14.4915 4.71515C14.6022 4.51813 14.6022 4.30988 14.4915 4.11304C13.0786 1.57592 10.3993 0 7.50037 0C4.5996 0 1.92027 1.57592 0.507401 4.11304ZM7.50037 2.29322C6.32957 2.29322 5.37826 3.2445 5.37826 4.41401C5.37826 5.58349 6.32954 6.53479 7.50037 6.53479C8.6693 6.53479 9.62058 5.58352 9.62058 4.41401C9.62058 3.2445 8.6693 2.29322 7.50037 2.29322Z" fill="#B2B6C5" />
                                        </svg>
                                    </span>
                                ) : (
                                    <span
                                        onClick={() => setIsPasswordHidden(true)}
                                    >
                                        <svg stroke="currentColor" fill="#B2B6C5" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M432 448a15.92 15.92 0 0 1-11.31-4.69l-352-352a16 16 0 0 1 22.62-22.62l352 352A16 16 0 0 1 432 448zM248 315.85l-51.79-51.79a2 2 0 0 0-3.39 1.69 64.11 64.11 0 0 0 53.49 53.49 2 2 0 0 0 1.69-3.39zm16-119.7L315.87 248a2 2 0 0 0 3.4-1.69 64.13 64.13 0 0 0-53.55-53.55 2 2 0 0 0-1.72 3.39z"></path><path d="M491 273.36a32.2 32.2 0 0 0-.1-34.76c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.68 96a226.54 226.54 0 0 0-71.82 11.79 4 4 0 0 0-1.56 6.63l47.24 47.24a4 4 0 0 0 3.82 1.05 96 96 0 0 1 116 116 4 4 0 0 0 1.05 3.81l67.95 68a4 4 0 0 0 5.4.24 343.81 343.81 0 0 0 67.24-77.4zM256 352a96 96 0 0 1-93.3-118.63 4 4 0 0 0-1.05-3.81l-66.84-66.87a4 4 0 0 0-5.41-.23c-24.39 20.81-47 46.13-67.67 75.72a31.92 31.92 0 0 0-.64 35.54c26.41 41.33 60.39 76.14 98.28 100.65C162.06 402 207.92 416 255.68 416a238.22 238.22 0 0 0 72.64-11.55 4 4 0 0 0 1.61-6.64l-47.47-47.46a4 4 0 0 0-3.81-1.05A96 96 0 0 1 256 352z"></path></svg>
                                    </span>
                                )
                            }
                        </div>
                        <ErrorMessage>
                            {errors.password && touched.password && errors.password}
                        </ErrorMessage>
                    </div>
                    <div className="col-12">
                        <button
                            className="refferalBtn collepsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseExample"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                        >
                            {/* Enter Referral/Promo Code */}
                            {authLabels.Enter_Referral}/{authLabels.Promo_Code}
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                <path d="M5.02282 5.73994C4.92844 5.74049 4.83488 5.7224 4.74751 5.68671C4.66014 5.65102 4.58067 5.59844 4.51366 5.53198L0.210901 1.22922C0.0758633 1.09418 0 0.911032 0 0.72006C0 0.529089 0.0758633 0.345938 0.210901 0.210901C0.345938 0.0758633 0.529089 0 0.72006 0C0.911032 0 1.09418 0.0758633 1.22922 0.210901L5.02282 4.01167L8.81641 0.218072C8.9536 0.100588 9.13007 0.0391974 9.31055 0.0461687C9.49104 0.05314 9.66224 0.12796 9.78996 0.255676C9.91768 0.383392 9.99249 0.554598 9.99946 0.735082C10.0064 0.915565 9.94505 1.09203 9.82756 1.22922L5.52481 5.53198C5.39123 5.66446 5.21095 5.73915 5.02282 5.73994Z" fill="#B2B6C5" />
                            </svg>
                        </button>
                        <div className="collapse" id="collapseExample">
                            <div className="card card-body collapseBody">
                      
                                <input
                                autoComplete='off'
                                    className='inp'
                                    type="text"
                                    // placeholder='Enter Referral/Promo Code (optional)'
                                    placeholder={`${authLabels.Enter_Referral}/${authLabels.Promo_Code}`}
                                    name="referral"
                                    id="referral"
                                    value={values.referral}
                                    onChange={handleChange}
                                />

                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-check formCheck">
                            <input
                                className="form-check-input formInp"
                                type="checkbox"
                                value=""
                                autoComplete='off'
                                id="flexCheckDefault"
                                checked={checks.terms}
                                onChange={(e) => {
                                    setChecks(pre => ({
                                        ...pre,
                                        terms: e.target.checked,
                                    }))
                                }}
                            />
                            <label className="form-check-label formLbl" for="flexCheckDefault">
                                {/* I agree to the <span>User Agreement</span> & confirm I am at least 18 years old */}
                                {authLabels.I_agree_to_the} <span>{authLabels.User_Agreement}</span> {authLabels.confirm_I_am}
                            </label>
                        </div>

                        <div className="form-check formCheck mt-0">
                            <input
                                className="form-check-input formInp"
                                type="checkbox"
                                autoComplete='off'
                                id="flexCheckDefault1"
                                checked={checks.promotions}
                                onChange={(e) => {
                                    setChecks(pre => ({
                                        ...pre,
                                        promotions: e.target.checked,
                                    }))
                                }}
                            />
                            <label className="form-check-label formLbl" htmlFor="flexCheckDefault1">
                                {/* I agree to receive marketing promotions from */}
                                {authLabels.I_agree_to_receive_marketing}
                            </label>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <button
                            className='signUpBtnNew'
                            type='button'
                            onClick={(e) => {
                                e.preventDefault()
                                handleSubmit(values)
                            }}
                        >
                            {/* {isLoading ? <Spinner size="sm" color="dark" /> : 'Sign Up'} */}
                            {isLoading ? <Spinner size="sm" color="dark" /> : <span>Sign Up</span>}
                        </button>
                    </div>
                </form>
            )}
        </Formik>
    )
}

export default SignUpEmail