import React from 'react'
import { useSelector } from 'react-redux'
import { PROFILE_OPTIONS } from '../../../constants'
import { useProfile } from '../../../hooks/useProfile'

const AccountInfo = () => {
  const userDetails = useSelector(state => state?.userDetails)
  const {setProfileObject} = useProfile()
  return (
    <>
      <div className="row">
        <div className="col-12 pb-2 d-none d-md-block">
          <div className="d-flex align-items-center accountCardHeading">
            <div className="cardImg">
              <img
                src="assets/img/sidebar/profile.png"
                alt="affiliate"
                className="h-100 w-100 img-fluid object-fit-contain"
                loading='lazy'
              />
            </div>
            Account Info
          </div>
        </div>
        <div className="col-12 mt-2 profileBox">
          <div className="row mx-0 gx-0 align-items-center">
            <div className="col">
              <div className="d-flex align-items-center">
                <div className="userImg me-2">
                  <div className="userImgInner">
                    <img src={userDetails?.image} className='h-100 w-100 object-fit-contain' alt="" />
                  </div>
                </div>
                <div className="a">
                  <div className="userName text-capitalize">{userDetails?.username}</div>
                  <div className="userRank">{userDetails?.currentlevel}</div>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <button className="newCommonBtn"
                onClick={()=> {
                  setProfileObject(prev => ({
                    ...prev,
                    isProfileModalOpen : true,
                    activeTab : PROFILE_OPTIONS?.EDIT_PROFILE
                  }))
                }}
              >Edit</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AccountInfo