import React, { useEffect } from 'react'
import { useWalletModal } from '../../hooks/useWallet'
import Deposit from './components/Deposit'
import Withdraw from './components/Withdraw'
import BuyCrypto from './components/BuyCrypto'
import { KADO_WALLET_TABS, WALLET_MODAL_TABS } from '../../constants'
import { Link, useSearchParams } from 'react-router-dom'
import { ROUTES_CONST } from '../../constants/routeConstant'
import { useTranslation } from 'react-i18next'
import { useKado } from '../../hooks/useKado'
import RublePay from './components/RublePay'
import Fiat from './components/Fiat'
import Lottery from '../../pages/dgnToken/Lottery'

const NewWallet = () => {
  const { walletModalObject, setWalletModalObject } = useWalletModal()
  const { t } = useTranslation()
  const walletLabels = t("wallet", { returnObjects: true })
  const { setKadoWalletObject } = useKado()
  const [query, setQuery] = useSearchParams()

  useEffect(() => {
    window?.dataLayer?.push({
      'event': 'GA4_event',
      'event_name': 'game_click_dep'
    });
  }, [])
  return (
    <>
      <div  className={`modal fade bankModal ${walletModalObject?.isWalletOpen ? 'show d-block' : ''}`} id="bankModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog bankdialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-md-down"
      
        >
          <div className="modal-content bankContent"
            style={{
              height:"90vh"
            }}
          >
            {
              walletModalObject?.selectedTab !== WALLET_MODAL_TABS?.BUY_CRYPTO || walletModalObject?.selectedTab !== WALLET_MODAL_TABS?.BUY_RAMPER ?
                <>
                  <div className="modal-header bankHeader border-0 flex-wrap">
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="title">
                        {/* {WALLET_MODAL_TABS.FIAT === walletModalObject?.selectedTab ? "Fiat" :"Crypto" } */}
                        Deposit
                      </div>
                      <div className="ps-3">
                        <button type="button"
                          onClick={() => {
                            setWalletModalObject((prev) => ({
                              isWalletOpen: false,
                            }))
                            setQuery({ action: "" })
                          }
                          }
                          className="btn-close p-0 shadow-none border-0 d-flex align-items-center justify-content-center m-0">
                          <img src="assets/img/sidebar/close.png" alt="close" className="h-100 w-100 img-fluid" />
                        </button>
                      </div>
                    </div>
                    <nav className="overflow-x-auto py-1 w-100 mt-2">
                      <div className="nav nav-tabs border-0 flex-nowrap text-nowrap bankTab">
                        <button
                          onClick={() => setWalletModalObject((prev) => ({
                            ...prev,
                            selectedTab: WALLET_MODAL_TABS?.DEPOSIT
                          }))}
                          className={`nav-link bankBtn ${walletModalObject?.selectedTab === WALLET_MODAL_TABS?.DEPOSIT ? 'active' : ''}`}
                        >
                          {/* {walletLabels.Deposit} */}
                          Crypto
                          </button>

                        <button
                          onClick={() => setWalletModalObject((prev) => ({
                            ...prev,
                            selectedTab: WALLET_MODAL_TABS?.FIAT
                          }))}
                          className={`nav-link bankBtn ${walletModalObject?.selectedTab === WALLET_MODAL_TABS?.FIAT ? 'active' : ''}`}
                        >Fiat</button>


                        {/* <button
                          onClick={() => setWalletModalObject((prev) => ({
                            ...prev,
                            selectedTab: WALLET_MODAL_TABS?.WITHDRAW
                          }))}
                          className={`nav-link bankBtn ${walletModalObject?.selectedTab === WALLET_MODAL_TABS?.WITHDRAW ? 'active' : ''}`}
                        >{walletLabels.Withdraw}</button> */}

                        {/* 
                        <div
                          // onClick={()=> setWalletModalObject((prev)=> ({
                          //   ...prev,
                          //   selectedTab: WALLET_MODAL_TABS?.BUY_CRYPTO
                          // }))}
                          onClick={() => {
                            setWalletModalObject((prev) => ({
                              isWalletOpen: false,
                            }))
                            setKadoWalletObject((prev) => ({
                              isBuySellOpen: true,
                              selectedTab: KADO_WALLET_TABS.BUY
                            }))
                          }}
                          className={`nav-link bankBtn cursor-pointer shadow-none ${walletModalObject?.selectedTab === WALLET_MODAL_TABS?.BUY_CRYPTO ? 'active' : ''}`}
                        >{walletLabels.Buy_Crypto}</div> */}
                        {/* <button
                          onClick={() => setWalletModalObject((prev) => ({
                            ...prev,
                            selectedTab: WALLET_MODAL_TABS?.BUY_RAMPER
                          }))}
                          className={`nav-link bankBtn ${walletModalObject?.selectedTab === WALLET_MODAL_TABS?.BUY_RAMPER ? 'active' : ''}`}
                        >Buy Crypto</button> */}

                        {/* <button
                          onClick={() => setWalletModalObject((prev) => ({
                            ...prev,
                            selectedTab: WALLET_MODAL_TABS?.RUBLE_PAY
                          }))}
                          className={`nav-link bankBtn ${walletModalObject?.selectedTab === WALLET_MODAL_TABS?.RUBLE_PAY ? 'active' : ''}`}
                        >Ruble Pay</button> */}

                      </div>
                    </nav>
                  </div>
                </>
                : ''
            }
            <div className={`modal-body bankbody pb-4 ${walletModalObject?.selectedTab === WALLET_MODAL_TABS?.BUY_CRYPTO ? 'showBuyCrypto' : ''} ${walletModalObject?.selectedTab === WALLET_MODAL_TABS?.RUBLE_PAY ? 'rubleOpen' : ''}`}>
              <div className={`tab-content banktabContent ${walletModalObject?.selectedTab === WALLET_MODAL_TABS?.BUY_RAMPER ? 'h-100' : ''}`}>
                {walletModalObject?.selectedTab === WALLET_MODAL_TABS?.DEPOSIT ?
                  <Deposit /> :
                  walletModalObject?.selectedTab === WALLET_MODAL_TABS?.WITHDRAW ?
                    <Withdraw /> :
                    walletModalObject?.selectedTab === WALLET_MODAL_TABS?.RUBLE_PAY ?
                      <RublePay />
                      // <div className='text-white h1'>hello ji yha ruble pay aayga </div>
                      :
                      walletModalObject?.selectedTab === WALLET_MODAL_TABS?.FIAT ?
                        <Fiat /> : 
                      walletModalObject?.selectedTab === WALLET_MODAL_TABS?.BUY_RAMPER ? <>
                        <div className="d-flex align-items-center justify-content-center h-100">
                        <div className="comingSoon" style={{color: '#EFB90B', fontSize: '24px'}}>Comming Soon</div>
                        </div>
                      </> : ''
                  // <BuyCrypto />
                }
              </div>
            </div>
            {/* <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Save changes</button>
                </div>  */}
          </div>
        </div>
      </div>
    </>
  )
}

export default NewWallet