import { useContext } from "react";
import { GameContext } from "../context/gameContext";

export const useGames = ()=> {
  const {gameObject, setGameObject} = useContext(GameContext)

  return {
    gameObject,
    setGameObject
  }
}