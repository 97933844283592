import React, { useState } from 'react'
import { errorToaster } from '../../../utils/toaster'
import { postAPIAuth } from '../../../service/apiInstance'

const Flat = () => {
    const [amount, setAmount] = useState('')

    const plan = async (value) => {
      //validation
      if (!!!amount) {
        errorToaster("Amount can't be empty")
        return
      }
      if (isNaN(Number(amount))) {
        errorToaster('Please add valid amount')
        return
      }
  
      // amount in paisa
      // const paisaAmount = amount.trim() * 100
      try {
        const body = {
          // amount: paisaAmount
          amount: amount
        }
        // get orderId from backend
        const res = await postAPIAuth(`generate-orderid`, body)
        if (res?.data?.status) {
          // desired options
          const options = {
            key: "rzp_test_du8jJUxQb7hup4",
            // order_id: `RAZOR${Math.floor(Math.random()*20000)}`,
            order_id: res?.data?.orderId,
            handler: function (response) {
  
            },
            // amount: paisaAmount,
            amount: amount,
  
          };
          // create an instance for razorPay
          const paymentObject = new window.Razorpay(options);
          // open the pop up of razorPay 
          paymentObject.open();
        } else {
          // handle exceptions
          errorToaster(res?.data?.message)
        }
      } catch (error) {
        //handle errors
        errorToaster(error.message)
      }
    }
  return (
    
    <div className="tab-pane fade show active pb-4">
    <div className="row">
        <div className="col-12 ">
            <div className="d-flex align-items-center justify-content-between">
                <div className="cryptoHeading">Deposit currency</div>
                <div className="dropdown eurdropDown">
                    <button className="btn eurBtn position-relative d-flex align-items-center dropdown-toggle">
                        <span className="d-flex align-items-center justify-content-center rounded-circle eurIcon"> 
                            <img src="assets/img/sidebar/eur.png" alt="eur" className="h-100 w-100 img-fluid"/>
                        </span>
                    </button>
                    <ul className="dropdown-menu eurMenu">
                        <li>
                            <a className="dropdown-item eurBtnInner d-flex align-items-center " href="javascript:;">
                                <span className="eurIcon d-flex align-items-center justify-content-center overflow-hidden rounded-circle">
                                    <img src="assets/img/sidebar/eur.png" alt="eur" className="h-100 w-100 img-fluid"/>
                                </span>
                                EUR
                            </a>
                        </li>
                        <li>
                            <a className="dropdown-item eurBtnInner d-flex align-items-center " href="javascript:;">
                                <span className="eurIcon d-flex align-items-center justify-content-center overflow-hidden rounded-circle">
                                    <img src="assets/img/sidebar/eur.png" alt="eur" className="h-100 w-100 img-fluid"/>
                                </span>
                                EUR
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="col-12 bankmodalsubheading pt-md-4 pt-3 pb-md-4 pb-sm-3 pb-2"> 
            Choose a Payment Method
        </div>
        <div className="col-12 ">
            <div className="dropdown pt-2 btcdropDown w-100">
                <button className="btn py-3 fw-bold network dropdown-toggle dropDownBtn position-relative d-flex align-items-center w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    AstroPay
                </button>
                <ul className="dropdown-menu btcdropmenu w-100">
                    <li>
                        <a className="dropdown-item btcInner d-flex align-items-center" href="javascript:;">
                            AstroPay
                        </a>
                    </li>
                    <li>
                        <a className="dropdown-item btcInner d-flex align-items-center" href="javascript:;">
                            AstroPay
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div className="col-12 bankmodalsubheading pt-2 pb-3 fw-normal"> 
            1 - 1000 AUD
        </div>
        <div className="col-12">
            <div className="eurExtra d-flex align-items-center justify-content-between">
                <div className="eurtitle">EUR 100</div>
                <div className="eurtitletwo eurtitle">+EUR 180.0 Extra!</div>
            </div>
        </div>
        <div className="col-12 py-3">
            <div className="bonusTitle d-flex align-items-center pt-0">
                Get extra <span className="fw-bold"> 180% Bonus</span> on minimum of 0.002 BTC deposit 
                <a href="javascript:;" className="d-flex align-items-center justify-content-center ms-3 rounded-circle infoImg">
                    <img src="assets/img/sidebar/info.png" alt="info" className="h-100 w-100 img-fluid"/>
                </a>
            </div>
        </div>
        <div className="col-12 pt-md-0 pt-sm-2 pt-4">
            <div className="row">
                <div className="col-4">
                    <div className="w-100">
                        <a href="javascript:;" className="eurCard position-relative w-100a d-flex align-items-center justify-content-center text-center text-decoration-none">
                            EUR 1
                            {/* <div className="eurCardTitle position-absolute end-0 top-0">
                                +180%
                            </div> */}
                        </a>
                    </div>
                </div>
                <div className="col-4">
                    <div className="w-100">
                        <a href="javascript:;" className="eurCard active position-relative w-100a d-flex align-items-center justify-content-center text-center text-decoration-none">
                            EUR 100
                            <div className="eurCardTitle position-absolute end-0 top-0">
                                +180%
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col-4">
                    <div className="w-100">
                        <a href="javascript:;" className="eurCard position-relative w-100a d-flex align-items-center justify-content-center text-center text-decoration-none">
                            EUR 1000
                            <div className="eurCardTitle position-absolute end-0 top-0">
                                +180%
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12 bankmodalsubheading pt-3 pb-2 fw-normal"> 
            Bank Country
        </div>
        <div className="col-12 ">
            <div className="dropdown pt-2 btcdropDown w-100">
                <button className="btn p3 fw-bold network dropdown-toggle dropDownBtn position-relative d-flex align-items-center w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Netherlands 
                </button>
                <ul className="dropdown-menu btcdropmenu w-100">
                    <li>
                        <a className="dropdown-item btcInner d-flex align-items-center" href="javascript:;">
                            Netherlands 
                        </a>
                    </li>
                    <li>
                        <a className="dropdown-item btcInner d-flex align-items-center" href="javascript:;">
                            Netherlands 
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div className="col-md-4 col-sm-5 col-8 mx-auto pt-4">
            <a href="javascript:;" className="playBtn playbtntwo position-relative d-flex align-items-center justify-content-center">
                <div className="playBtnInner d-flex align-items-center justify-content-center w-100">
                    Deposit Via astropay
                </div>
            </a>
        </div>
    </div>
</div>
  )
}

export default Flat