import React from 'react'
import { useNavigate } from 'react-router-dom'

const GameErrorPage = () => {
  const navigate = useNavigate()

  const handleClick = ()=> {
    window.parent.postMessage('navigate-home', '*');
  }
  return (
    <>
      <div className="vh-100 d-flex align-items-center justify-content-center p-3 gameErrorPage">
        <div className="text-white text-center gameErrorHeadingTxt d-flex flex-column align-items-center justify-content-center">
        <img src="assets/img/appLogo.png"  className='w-100 mb-2 object-fit-contain'  alt=""  />
        <div className='mt-3'>
          Game is not available in your country, try disabling VPN
        </div>
        <button onClick={handleClick} className='returnBtn mt-4'>Return to Home Page</button>
          </div>
      </div>
    </>
  )
}

export default GameErrorPage