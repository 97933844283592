import React, { useEffect, useRef, useState } from "react";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { Formik } from "formik";
import * as Yup from "yup";
import { isEmail } from "../../../utils/isEmail";
import {
  auth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "../../../config/firebase";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import { GoogleLogin } from "../../socialMedia/GoogleLogin";
import MetaMaskModal from "../../socialMedia/MetamaskModal";
import { useAuthModal } from "../../../hooks/useAuthModal";
import TelegramComp from "../../socialMedia/TelegramLoginButton";
import TelegramInputModal from "../../socialMedia/TelegramInputModal";
import { useSelector } from "react-redux";
import { getAPI, getAPIAuth } from "../../../service/apiInstance";
import { Spinner } from "react-bootstrap";
import { getFriendlyErrorMessage } from '../../../utils/firebaseError'

const validationSchema = Yup.object({
  emailOrNumber: Yup.string()
    .required("Email/Phone Number is required")
    .matches(
      /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
      "Invalid email or mobile number"
    ),
});

const LoginEmailOrNo = ({
  setEmailPassword,
  setLogOn,
  setPhoneOtp,
  setEmailOrNumber,
  setForgetpass,
  logOn,
  select,
  setSelect
}) => {
  const [isMetamask, setIsMetamask] = useState(false);
  const { authModalObject } = useAuthModal();
  const [isTelegram, setIsTelegram] = useState(false);
  const [userObj, setUserObj] = useState({});
  const [preWidgetId, setPreWidgetId] = useState(null);
  const [matchedItem, setmatchedItem] = useState([]);
  const [countrycodedata, setCountryCode] = useState([]);
  const countryName = useSelector((state) => state.country);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false)

  const onSubmit = (values) => {
    if (isEmail(values.emailOrNumber)) {
      setEmailPassword(true);
      setLogOn(false);
      setEmailOrNumber(values.emailOrNumber);
      setIsLoading(false);
    } else {
      // if (select == 0) {
      //   errorToaster("Choose a country code");
      //   setIsLoading(false);

      //   return;
      // }
      handleSend(values);
      setEmailOrNumber(values.emailOrNumber);
    }
  };
  const generateRecaptcha = (random) => {
    const recaptchaParent = document.getElementById("recaptcha-parent");
    if (recaptchaParent) {
      recaptchaParent.innerHTML = `<span id=${random} />`
    }
    window.recaptchaVerifier = new RecaptchaVerifier(
      `${random}`,
      {
        size: "invisible",
        callback: (response) => { },
      },
      auth
    );
    window.recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
  };

  const handleSend = (values) => {
    const random = Math.random() + 1000000
    generateRecaptcha(random);
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(
      auth,
      `${select?.phoneCode ? select.phoneCode : "+91"} ${values.emailOrNumber}`,
      appVerifier
    )
      .then((confirmationResult) => {
        succesToaster("OTP SENT!");
        window.confirmationResult = confirmationResult;
        setLogOn(false);
        setPhoneOtp(true);
      })
      .catch((error) => {
        errorToaster(error.message.split('(')[1].split(')')[0].split('/')[1]);
        const recaptchaContainer = document.getElementById(`${random}`)
        if (recaptchaContainer) { recaptchaContainer.remove(); }

        // errorToaster(getFriendlyErrorMessage(error.code))
        // errorToaster(error.message)

      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClick = () => {
    setForgetpass(true);
    setLogOn(false);
    setEmailPassword(false);
  };
  const CountryCodeFun = async () => {
    const res = await getAPI("get-countries");
    setCountryCode(res.data.data);
    setmatchedItem(res.data.data)
  };
  useEffect(() => {
    const codedata =
      countrycodedata.filter((item) => item.country == countryName).length == 0
        ? countrycodedata
        : countrycodedata.filter((item) => item.country === countryName);
    setmatchedItem(countrycodedata);
    setSelect(countryName && codedata[0]);
    localStorage.setItem("country_Id", codedata[0]?._id);
  }, [countryName, countrycodedata]);

  useEffect(() => {
    CountryCodeFun();
  }, []);
  useEffect(() => {
    if (!logOn) {
      setIsLoading(false);
    }
  }, [logOn]);
  const handlesearch = (val) => {
    const valdata = val.trim();
    if (valdata.length > 0) {
      const filterdata = countrycodedata?.filter(
        (item) => item.country.toLowerCase().includes(val.toLowerCase()) || item.phoneCode.toLowerCase().includes(val.toLowerCase())
      )
      setmatchedItem(filterdata)
    } else {
      setmatchedItem(countrycodedata)
    }
  }
  return (
    <>
      <Formik
        initialValues={{
          emailOrNumber: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setIsLoading(true);
          onSubmit(values);
        }}
      >
        {({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          errors,
          touched,
        }) => (
          <form>
            <div className="row gap-3 gx-0">
              <div className="col-12 inputdata">
                {/* email / phone */}
                <label htmlFor="email12" className="inputLabel">
                  {/* SELECT */}
                  EMAIL/PHONE NO
                  <span>*</span>
                </label>

                <div className="inputlogin d-flex align-items-center position-relative  rounded-2">
                  {/* dropdown country code */}
                  <div className={`dropdown countycodedata position-static ${isOpen ? "show" : ""}`}>
                    <span
                      className="btn bg-transparent code rounded-0 py-1 border-0 dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="outside"
                      onClick={() => setIsOpen(true)}
                    >
                      {select?.phoneCode ? select?.phoneCode : "+91"}
                    </span>
                    <div className={`dropdown-menu countrydata ${isOpen ? "show" : ""}`}>
                      <div className="input-group mb-2 rounded-2 searchinput flex-nowrap">
                        <span
                          className="input-group-text py-0 pe-0 shadow-none border-0 bg-transparent searchbtn"
                          id="basic-addon2"
                        >
                          <img src="assets/img/search.png" alt="search" />
                        </span>
                        <input
                          type="text"
                          className="form-control py-0 selectInput inputlogin m-0 border-0 bg-transparent shadow-none"
                          placeholder="Search"
                          onChange={(e) => handlesearch(e.target.value)}
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                        />
                      </div>
                      <ul className="list-unstyled m-0 d-flex flex-column gap-1 innerdata overflow-y-auto">
                        {matchedItem.map((item) => (
                          <li key={item?._id}>
                            <div className=" text-decoration-none innerlink  rounded-2 d-flex align-items-center gap-3"
                              onClick={() => {
                                setSelect(item);
                                localStorage.setItem("country_Id", item?._id)
                                setIsOpen(false)
                              }}
                              >
                              <div
                                className="countrycode"

                              >
                                {item.phoneCode}
                              </div>
                              <div className="countryname">{item.country}</div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <input
                    type="text"
                    id="email12"
                    className="form-control selectInput inputlogin mt-0 bg-transparent border-0 shadow-none text-white"
                    placeholder="Email/Phone Number"
                    value={values.emailOrNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="emailOrNumber"
                  />
                </div>
                <ErrorMessage>
                  {errors.emailOrNumber &&
                    touched.emailOrNumber &&
                    errors.emailOrNumber}
                </ErrorMessage>
              </div>
              <div className="col-12 inputdata passwordInput pt-0">
                <div className="inputdata forgotmain">
                  <span
                    className="forgotPassword text-decoration-none cursor-pointer"
                    onClick={handleClick}
                  >
                    Forgot password?
                  </span>
                </div>
              </div>

            </div>
            <div id="recaptcha-parent"> </div>
            <div className="row mt-4 gap-4">
              <div className="col-12 mx-auto">
                <span className="playBtn position-relative d-flex align-items-center justify-content-center cursor-pointer">
                  <button
                    type="submit"
                    className="playBtnInner border-0 d-flex align-items-center justify-content-center w-100"

                    onClick={handleSubmit}
                  >
                    {isLoading ? (
                      <Spinner size="sm" color="dark" />
                    ) : (
                      "Continue"
                    )}
                  </button>
                </span>
              </div>
              {/* divider */}
              <div className="col-12 mx-auto ">
                <div className="loginbutton text-center">
                  <span className="directLogin text-decoration-none ">
                    Log in directly with
                  </span>
                </div>
              </div>
              {/* social links */}
              <div className="col-12 mx-auto">
                <div className="row align-items-center justify-content-center g-2">
                  <div className="col-auto">
                    {/* google */}

                    <span className="text-decoration-none">
                      <GoogleLogin parent={"signIn"}>
                        <div className="link">
                          <img src="assets/img/newIcon/google.svg" alt="" />
                        </div>
                      </GoogleLogin>
                    </span>
                  </div>
                  {/* metamask */}
                  {/* <div className="col-auto">
                    <span
                      className="text-decoration-none cursor-pointer"
                      onClick={() => {
                        // setIsMetamask(true)
                        if (!!!window.ethereum) {
                          errorToaster("Metamask browser extension not found")
                        } else {
                          setIsMetamask(true)
                        }

                      }}
                    >
                      <div className="link">
                        <img
                          src="assets/img/newIcon/metamask-grayscale-logo.svg"
                          alt=""
                        />
                      </div>
                    </span>
                  </div> */}
                  <MetaMaskModal
                    parent={"signIn"}
                  />

                  {/* telegram */}

                  <TelegramComp
                    isOpen={isTelegram}
                    setIsOpen={setIsTelegram}
                    userObj={userObj}
                    setUserObj={setUserObj}
                    // setIsReferral={setIsReferral}
                    authModalObject={authModalObject}
                  />
                  {/* <div className="col-auto cursor-pointer">
                    <span className="text-decoration-none position-relative">
                      <div className="link"><img src="assets/img/newIcon/telegram.svg" alt="" /></div>
                      {authModalObject.isAuthOpen ? (
                        <div className="col-auto d-flex justify-content-center position-absolute opacity-0 top-0 bottom-0 start-0 end-0">
                          <TelegramComp
                            isOpen={isTelegram}
                            setIsOpen={setIsTelegram}
                            userObj={userObj}
                            setUserObj={setUserObj}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </span>
                  </div> */}
                  {/* {authModalObject.isAuthOpen ? (
                    <div className="col-auto d-flex h-25 justify-content-center my-3">
                      <TelegramComp
                        setIsOpen={setIsTelegram}
                        userObj={userObj}
                        setUserObj={setUserObj}
                      />
                    </div>
                  ) : (
                    <></>
                  )} */}
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
      {/* <MetaMaskModal
        isOpen={isMetamask}
        setIsOpen={setIsMetamask}
        parent={"signIn"}
      /> */}
      {/* <TelegramInputModal
        isOpen={isTelegram}
        setIsOpen={setIsTelegram}
        userObj={userObj}
        setUserObj={setUserObj}
      /> */}
    </>
  );
};

export default LoginEmailOrNo;
