import React, { useState ,useEffect } from 'react'
import { AUTH_MODAL_TABS, TOKEN_NAME } from '../../constants'
import { ROUTES_CONST } from '../../constants/routeConstant'
import { useNavigate } from 'react-router-dom'
import { useAuthModal } from '../../hooks/useAuthModal'
import { useAuth } from '../../hooks/useAuth'


const Protected = (props) => {
  const {Component} = props
  const [isLoggedIn, setLoggedIn] = useState(false)
  const { token } = useAuth();
  const { setAuthModalObject } = useAuthModal();
  const navigate = useNavigate()

  useEffect(()=> {
    if(!token) {
      navigate(ROUTES_CONST.INDEX)
      setAuthModalObject((pre) => ({
        selectedTab: AUTH_MODAL_TABS.LOG_IN,
        isAuthOpen: true,
        }))
    }else {
      
      setAuthModalObject((pre) => ({
        isAuthOpen: false,
        }))
      setLoggedIn(true)
    }

  },[token])
  return (
    isLoggedIn && <Component/>
  )
}

export default Protected