import React, { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { errorToaster, succesToaster } from "../../utils/toaster";
import { postAPI } from "../../service/apiInstance";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorMessage from "../common/ErrorMessage/ErrorMessage";
import { useAuthModal } from "../../hooks/useAuthModal";
import { useSelector } from "react-redux";
import { USER_LANG } from "../../constants";
import { useReferralModal } from "../../hooks/useReferralModal";

const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i

const validationSchema = Yup.object({
    email: Yup.string().required("Email is Required").matches(regex, "Invalid email")
})

const TelegramInputModal = ({ isOpen, setIsOpen, userObj, setUserObj, }) => {
    const { setToken } = useAuth()
    const { setAuthModalObject } = useAuthModal()
    const ipAddress = useSelector(state => state.ipAddress)
    const country = useSelector(state => state.country)
    const [isLoading, setIsLoading] = useState(false)
    const { isReferral, setIsReferral,isReferralInput, setIsReferralInput } = useReferralModal()
    
    const telegramHandler = async (values) => {
        try {
            // const lang = JSON.parse(localStorage.getItem(USER_LANG))
            const lang = localStorage.getItem(USER_LANG)
            const body = {
                fullname: `${userObj?.firstName} ${userObj?.lastName}`,
                image: userObj?.photoUrl,
                username: userObj?.username,
                userid: userObj?.id,
                email: values?.email,
                loginMethod: "telegram",
                ipAddress: ipAddress ? ipAddress : "",
                country: country ? country : "",
                // language: lang?.symbol
                language: lang
            }

            const res = await postAPI("user/social-authentication", body)
            if (res.data.success) {
                succesToaster(res.data.message);
                setToken(res?.data.data?.token);
                setIsOpen(false)
                setAuthModalObject(pre => ({ ...pre, isAuthOpen: false }))
                // setIsReferral(true)
                // setIsReferralInput(true)
            } else {
                errorToaster("Something went wrong")
            }
        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    }
    return (
        <div
            className={`modal fade loginModal ${isOpen ? "show" : ""}`}
            id="metamask"
            // tabIndex={-1}
            // aria-labelledby="exampleModalLabel"
            // aria-hidden="true"
            style={{
                display: isOpen ? "block" : "none"
            }}
        >
            <div className="modal-dialog logindialog  modal-dialog-centered modal-dialog-scrollable ">
                <div className="modal-content loginContent h100 overflow-hidden border-0">
                    <div className="modal-header header border-0 d-lg-none d-flex bg-transparent">
                        <div className="modal-title logoimg" id="exampleModalLabel">
                            <img src="assets/img/newIcon/logo2.svg" className="h-100" alt="" /></div>
                        <button
                            onClick={() => {
                                setIsOpen(false)
                            }}
                            type="button"
                            className="btn-close shadow-none border-0 btnClose smbtnClose  d-flex align-items-center justify-content-center"
                            aria-label="Close"
                        >
                            <img src="assets/img/sidebar/close.png" alt="close" className="h-100 w-100 img-fluid" />
                        </button>
                    </div>
                    <div className="modal-body p-0 position-relative">

                        <button
                            type="button"
                            className="btn-close shadow-none border-0 btnClose position-absolute d-lg-flex d-none align-items-center justify-content-center"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                setIsOpen(false)
                            }}
                        >
                            <img src="assets/img/sidebar/close.png" alt="close" className="h-100 w-100 img-fluid" />
                        </button>
                        <div className="row h-100 mx-0">
                            <div className="col-lg-12 loginformMain order-2 order-lg-1">
                                <div className="logindata p-0 px-2  row">

                                    <div className="tacontent col-12 tabdata" id="myTabContent">
                                        <div
                                            className="tapane"
                                        // role="tabpanel"
                                        // aria-labelledby="login-tab"
                                        // tabIndex={0}
                                        >
                                            <Formik
                                                initialValues={{
                                                    email: "",
                                                }}
                                                validationSchema={validationSchema}
                                                onSubmit={(values) => {
                                                    setIsLoading(true)
                                                    telegramHandler(values)
                                                }}
                                            >
                                                {
                                                    ({
                                                        values,
                                                        errors,
                                                        touched,
                                                        handleSubmit,
                                                        handleChange,
                                                        handleBlur,
                                                    }) => (
                                                        <form>
                                                            <div className="row gap-3 gx-0">
                                                                <div className="col-12 inputdata">
                                                                    <label
                                                                        htmlFor="TeleEmail"
                                                                        className="inputLabel"
                                                                    >
                                                                        EMAIL
                                                                        <span>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="TeleEmail"
                                                                        className="form-control inputlogin border-0 shadow-none"
                                                                        placeholder="Email"
                                                                        name="email"
                                                                        value={values.email}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                    />

                                                                    <ErrorMessage>
                                                                        {errors.email && touched.email && errors.email}
                                                                    </ErrorMessage>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4 gap-4 mb-5">
                                                                <div className="col-10 mx-auto">
                                                                    <span
                                                                        className="playBtn position-relative d-flex align-items-center justify-content-center">
                                                                        <button
                                                                            type="submit"
                                                                            onClick={handleSubmit}
                                                                            className="playBtnInner border-0 d-flex align-items-center justify-content-center w-100"
                                                                        >
                                                                            Play now!
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    )
                                                }
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
};

export default TelegramInputModal;
