import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { getAPIAuth } from '../../service/apiInstance';
import { useSelector } from 'react-redux';
import { useAuth } from '../../hooks/useAuth';

const FiatErrorModal = () => {
  // const [show, setShow] = useState(true);
  const { token } = useAuth();
  const userDetails = useSelector((state) => state.userDetails);

  // const handleClose = () => setShow(false);

  const handleViewFiat = async () => {
    try {
      const res = await getAPIAuth(`user/setFiatForUser?fiatStatus=false&userId=${userDetails.id}`, token);
      if (res?.data?.success) {
        // window.location.reload(); 
      }
    } catch (error) {
    }
  }

  useEffect(()=> {
    const timeOut = setTimeout(() => {
      handleViewFiat()
    }, 2000);
    return ()=> {
      clearTimeout(timeOut)
    }
  }, [])

  return (
    <>
      {/* <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
      </Modal> */}
      <button onClick={handleViewFiat} className='refBtn mt-2'>refresh</button>
    </>
  )
}

export default FiatErrorModal