import React from "react";
import { useTranslation } from "react-i18next";

const InGameModal = ({ game }) => {
    const { t } = useTranslation()
    const PlaySlotLabels = t("PlaySlotLabels", { returnObjects: true })
    const commonText = t("commonText", { returnObjects: true })

    return (
        <>
            {
                !game && (
                    <div className="playwithBalance mx-auto rounded-3 p-md-4 p-sm-3 p-2 position-absolute ">
                        <div className="row">
                            <div className="col-12 d-flex flex-column justify-content-center">
                                <div className="oops mx-auto ">
                                    <img src="assets/img/affiliateDashboard/oops.png" style={{ height: 100 }} alt="" />
                                </div>
                                <div className="oppsTxt text-light text-center mt-5">{PlaySlotLabels.Something_went_wrong}</div>
                            </div>
                        </div>
                    </div>
                )


                // can use in future

                // <div className="row">
                //     <div className="col-sm-10 col-12 mx-auto">
                //         <div className="d-flex align-items-center justify-content-center gap-3 py-sm-3 py-2">
                //             <div className="playwithHeading">
                //                 Play with Balance
                //             </div>
                //             <div className="dropdown coindropdown dropdowncenter ">
                //                 <a className="btn btn-secondary dropdowntoggle coinbtn border-0 d-flex align-items-center gap-2" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                //                     <span className="coinImgs d-flex align-items-center justify-content-center rounded-pill overflow-hidden">
                //                         <img src="assets/img/slots/idr" alt="coin" className="h-100 w-100" />
                //                     </span>
                //                     IDR
                //                 </a>
                //                 <div className="dropdown-menu coinMenu p-4 rounded-1 dropdown-menu-end">
                //                     <div className="row">
                //                         <div className="col-12 pb-1">
                //                             <div className="input-group coinsearch rounded-2 align-items-center ps-2">
                //                                 <button className="input-group-text searchBtns ms-1 bg-transparent p-0 border-0" id="basic-addon1">
                //                                     <img src="assets/img/search.png" alt="search" className="h-100" />
                //                                 </button>
                //                                 <input type="search" className="form-control searchipt shadow-none bg-transparent border-0" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" />
                //                             </div>
                //                         </div>
                //                         <div className="col-12 pt-2">
                //                             <ul className="nav nav-tabs cointabs border-0 rounded-2" id="myTab" role="tablist">
                //                                 <li className="nav-item w-50" role="presentation">
                //                                     <button className="nav-link d-flex align-items-center justify-content-center w-100 tablink border-0 rounded-1 active" id="currancy-tab" data-bs-toggle="tab" data-bs-target="#currancy-tab-pane" type="button" role="tab" aria-controls="currancy-tab-pane" aria-selected="true">Currancy</button>
                //                                 </li>
                //                                 <li className="nav-item w-50" role="presentation">
                //                                     <button className="nav-link d-flex align-items-center justify-content-center w-100 tablink border-0 rounded-1" id="mnft-tab" data-bs-toggle="tab" data-bs-target="#mnft-tab-pane" type="button" role="tab" aria-controls="mnft-tab-pane" aria-selected="false">mNFt</button>
                //                                 </li>
                //                             </ul>
                //                         </div>
                //                         <div className="col-12 pt-3">
                //                             <div className="tab-content" id="myTabContent">
                //                                 <div className="tab-pane fade show active" id="currancy-tab-pane" role="tabpanel" aria-labelledby="currancy-tab" tabIndex={0}>
                //                                     <ul className="list-unstyled gap-1 d-flex flex-column">
                //                                         <li>
                //                                             <div className="d-flex align-items-center justify-content-between coinMain rounded-2 active">
                //                                                 <div className="d-inline-flex align-items-center gap-2">
                //                                                     <div className="dropcoinImg d-flex align-items-center justify-content-center overflow-hidden rounded-circle">
                //                                                         <img src="assets/img/slots/idr" alt="coin" className="h-100 w-100 img-fluid" />
                //                                                     </div>
                //                                                     <div className="coinname">IDR</div>
                //                                                 </div>
                //                                                 <div className="coinPrice">
                //                                                     0.67589
                //                                                 </div>
                //                                             </div>
                //                                         </li>
                //                                         <li>
                //                                             <div className="d-flex align-items-center justify-content-between coinMain rounded-2">
                //                                                 <div className="d-inline-flex align-items-center gap-2">
                //                                                     <div className="dropcoinImg d-flex align-items-center justify-content-center overflow-hidden rounded-circle">
                //                                                         <img src="assets/img/slots/idr" alt="coin" className="h-100 w-100 img-fluid" />
                //                                                     </div>
                //                                                     <div className="coinname">IDR</div>
                //                                                 </div>
                //                                                 <div className="coinPrice">
                //                                                     0.67589
                //                                                 </div>
                //                                             </div>
                //                                         </li>
                //                                     </ul>
                //                                 </div>
                //                                 <div className="tab-pane fade" id="mnft-tab-pane" role="tabpanel" aria-labelledby="mnft-tab" tabIndex={0}>...</div>
                //                             </div>
                //                         </div>
                //                     </div>
                //                 </div>
                //             </div>
                //         </div>
                //     </div>
                //     <div className="col-12 playwithdetail text-center pt-3">
                //         The selected currency will be displayed in <span>USD</span>, and if you change currency while playing, the game will refresh and restart.
                //     </div>
                //     <div className="col-12 pt-4 px-0">
                //         <div className="d-flex align-items-center justify-content-center gap-3 py-sm-3 py-2">
                //             <a href="javascript:;" className="realPlayBtn d-inline-flex rounded-2 text-decoration-none align-items-center">
                //                 <span className="realPlayIcon d-flex align-items-center justify-content-center">
                //                     <img src="assets/img/slots/play.png" alt="play" className="h-100 w-100 img-fluid" />
                //                 </span>
                //                 Real Play
                //             </a>
                //             <a href="javascript:;" className="realPlayBtn freePlay d-inline-flex rounded-2 text-decoration-none align-items-center">
                //                 <span className="realPlayIcon d-flex align-items-center justify-content-center">
                //                     <img src="assets/img/slots/play.png" alt="play" className="h-100 w-100 img-fluid" />
                //                 </span>
                //                 Free Play
                //             </a>
                //         </div>
                //     </div>
                // </div> 
            }
        </>

    )
}

export default InGameModal;
