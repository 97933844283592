// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import "firebase/auth";
import {
  getAuth,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  RecaptchaVerifier, signInWithPhoneNumber
} from "firebase/auth";

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyAn1NljGMbGo7bCvVy6eh2ZOou_Icbafbc",
//   authDomain: "degenwin-38841.firebaseapp.com",
//   projectId: "degenwin-38841",
//   storageBucket: "degenwin-38841.appspot.com",
//   messagingSenderId: "103335453238",
//   appId: "1:103335453238:web:dbb5aa24de5111e8fb6e97"
// };

const firebaseConfig = {
  apiKey: "AIzaSyAGATRn7xSQHjZpqsclGJwFdpOiTQjEj1A",
  authDomain: "memestop-8b223.firebaseapp.com",
  projectId: "memestop-8b223",
  storageBucket: "memestop-8b223.appspot.com",
  messagingSenderId: "829914094029",
  appId: "1:829914094029:web:546f93ac27f0f3777acc84",
  measurementId: "G-J91JDKEVC9"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
const provider = new GoogleAuthProvider();

export {
  auth,
  provider,
  firebase,
  createUserWithEmailAndPassword,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  sendPasswordResetEmail
};
