import React, { useEffect } from "react";
import AppFooter from "../../components/AppFooter/AppFooter";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AccountInfo from "./components/AccountInfo";
import './style.scss'
import Security from "./components/Security";


function AccountSetting() {
  const [tabopen, setTabopen] = useState("Account_Info");
  const { t } = useTranslation()
  const accountPageLabels = t("accountPageLabels", { returnObjects: true })


  const accountOption = [
    { name: 'Account Info', value: 'Account_Info', img: "profile", element : <AccountInfo /> },
    { name: 'Security', value: 'Security', img: "setting", element : <Security  tabopen={'Security'} openaccordian={'Security'} /> },
    // { name: 'Preferences', value: 'Preferences', img: "setting" },
  ];


  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage">
            <div className="container-flui accountTab">
              <div className="row d-md-flex d-none">
                <div className="col-md-3 col-lg-3 col-xxl-3">
                  <nav>
                    <div
                      className="nav nav-tabs tabMain flex-column border-0"
                      id="nav-tab"
                      role="tablist"
                    >
                      {accountOption.map((item) => (
                        <button
                          key={`${item?.name}-${Math.random()}`}
                          className={`nav-link accountBtn d-flex align-items-center ${tabopen == `${item?.value}` ? "active" : ""
                            }`}
                          onClick={() => setTabopen(item?.value)}
                        >
                          <span className="accounttabIcon d-flex align-items-center justify-content-center">
                            <img
                              src={`assets/img/sidebar/${item?.img}.png`}
                              alt="profile"
                              className="h-100 w-100 img-fluid object-fit-contain"
                            />
                          </span>
                          {item?.name}
                        </button>
                      ))}
                    </div>
                  </nav>
                </div>
                <div className="col-md-9 col-lg-9 col-xxl-9">
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className={`tab-pane fade show active`}>
                      <div className="accountTabcard">
                        {
                          tabopen === "Account_Info" ? <AccountInfo /> : 
                          tabopen === "Security" ? <Security  tabopen={'Security'} openaccordian={'Security'} /> : ""
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              

              <div className="row d-md-none d-flex tabMain">
              {
                  accountOption?.map(item => (
                    <>
                      <div className="col-12">
                          <div className="row accordion-item border-0 tabMain">
                            <div className="col-12 accordion-header">
                              <button
                                className={`accordion-button accountBtn rounded-2 d-flex align-items-center ${tabopen === item?.value ? "" : "collapsed"
                                  }`}
                                type="button "
                                onClick={() => {
                                  if (tabopen === item?.value) {
                                    setTabopen('')
                                  } else {
                                    setTabopen(item?.value)
                                  }
                                }}
                              >
                                <span className="accounttabIcon d-flex align-items-center justify-content-center">
                                  <img
                                    src={`assets/img/sidebar/${item?.img}.png`}
                                    alt="profile"
                                    className="h-100 w-100 img-fluid"
                                  />
                                </span>
                                {item?.name}
                              </button>
                            </div>
                            <div
                              id="Profile"
                              className={`accordion-collapse col-12 ${tabopen === item?.value ? "collapse show" : "collapse"
                                }`}
                            >
                              <div className="accordion-body">
                                <div className="accountTabcard">
                                  {item?.element}
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                    </>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
        <AppFooter />
      </main >
    </>
  );
}

export default AccountSetting;
