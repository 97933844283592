import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const PromotionCard = ({ data, disabled }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const promotionLabels = t("promotionLabels", { returnObjects: true })

  return (
    <div className="col-lg-4 col-md-6">
      <div className={`promotionCard ${disabled ? 'disabled' : ''}`}
        onClick={() => navigate(`${ROUTES_CONST?.PROMOTIONS}/${data?._id}`)}>
        <div className="promotionImg">
          <img src={data.image} className='w-100 h-100 object-fit-cover' alt="" />
        </div>
        <div className="row promotionTxt mx-0 align-items-center">
          <div className="col ps-0 pe-1">
            <div className='dateTxt'>{promotionLabels.EndsAt} {moment(data?.endDate).format('D/MM/YYYY HH:mm:ss')}</div>
            {/* <div className='dateTxt'>Ends at {moment(data?.endDate ,'Do MMMM, YYYY').format('D/MM/YYYY HH:mm:ss')}</div> */}
            {/* <div className='dateTxt'>Ends at {moment('Sun Nov 27 2016 05:30:00 GMT+0530 (India Standard Time)').format('3/12/2014 5:30:0')}</div> */}
            <div className='dscTxt'>{data?.text}</div>
          </div>
          <div className="col-auto px-0">
            <button className='progressBtn'> {data?.status}</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PromotionCard