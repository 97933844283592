import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { SM_SCREEN_WIDTH } from '../../../constants';
import { getAPI } from '../../../service/apiInstance';
import { useMediaQuery } from 'react-responsive';
import SkeletonLoader from '../../../components/skeletonLoader/SkeletonLoader';
import { useAuth } from '../../../hooks/useAuth';
import MetaMaskModal from '../../../components/socialMedia/MetamaskModal';
import { GoogleLogin } from '../../../components/socialMedia/GoogleLogin';

const HeroBanner = () => {
    const [bannerData, setBannerData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const isMobile = useMediaQuery({ maxWidth: SM_SCREEN_WIDTH })
    const {token} = useAuth()
    const heroBanner= useRef(null)
    const [banner, setBanner] = useState({})

    useEffect(()=> {
        setBanner(heroBanner?.current?.offsetHeight)
    }, [heroBanner])


    console.log('banner', banner)

    const getBannerImage = async () => {
        setIsLoading(true)
        try {
            const res = await getAPI(`banner/getBannerByFilter?type=homepage&viewType=${isMobile ? 'mobile' : 'desktop'}`)
            if (res?.data?.success) {
                setBannerData(res?.data?.data)
            }
        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getBannerImage()
    }, [])

    return (
        <>
            <div className="row">
                <div className="col-12 mt-3 mt-md-0 heroSwiperBanner position-relative" ref={heroBanner}>
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 7000,
                            disableOnInteraction: false,
                        }}
                        // pagination={{
                        //     clickable: true,
                        // }}
                        navigation={{
                            prevEl: '.heroBannerPrev',
                            nextEl: '.heroBannerNext',
                        }}
                        modules={[Autoplay,
                            // Pagination, 
                            Navigation]}
                        className="mySwiper"
                    >
                        {
                            !isLoading ? bannerData?.length && bannerData?.map(item => (
                                <SwiperSlide key={item?._id}>
                                    <div className="sliderImg w-100" onClick={() => item?.link ? window.open(item?.link, '_blank') : ""}>
                                        <img src={item?.image} height={403} width={998} loading='lazy' alt={`hero baner images${item?._id}`} />
                                    </div>
                                </SwiperSlide>
                            )) : <>
                                <SkeletonLoader type={'heroBanner'} />
                            </>
                        }
                    </Swiper>
                    {
                        !token ? 
                        
                                <div className="heroBannerDetailTxt d-none d-lg-flex position-absolute" style={{'--height' : `${banner}px`}}>
                                    {/* <img src="assets/img/appLogo.png" className='bannerLogo invisible' alt="" /> */}
                                    {/* <div className="bannerTxt invisible">Experience the thrill of <br /> quality gambling at your finger tips</div> */}
                                    <button className='exprBtn'><span>Experience now</span> </button>
                                    {/* <div className="orJoinWith invisible">Or join with </div> */}
                                    <div className="bannerSocialBtns d-flex align-items-center gap-2">

                                        {/* <div className="bannSocialBtn">
                                            <img src="assets/img/header01.png" className='socIcons' alt="" />
                                        </div> */}
                                        <MetaMaskModal
                                            heroBanner={true}
                                        />
                                        {/* <div className="bannSocialBtn">
                                            <img src="assets/img/header02.png" className='socIcons' alt="" />
                                        </div> */}
                                        <GoogleLogin
                                            parent="signin"
                                        >
                                        <div className="bannSocialBtn">
                                            <img src="assets/img/header03.png" className='socIcons' alt="" />
                                        </div>
                                        </GoogleLogin>
                                        <div className="bannSocialBtn">
                                            {/* <img src="assets/img/header04.png" className='socIcons' alt="" /> */}
                                            <img src="assets/img/walletConnect.png" className='socIcons' alt="" />
                                        </div>
                                    </div>
                                </div>
                        
                        :  <div className="heroBannerDetailTxt d-none d-lg-flex position-absolute">
                        {/* <img src="assets/img/appLogo.png" className='bannerLogo invisible' alt="" /> */}
                        {/* <div className="bannerTxt invisible">Experience the thrill of <br /> quality gambling at your finger tips</div> */}
                        {/* <button className='exprBtn'><span>Experience now</span> </button> */}
                        {/* <div className="orJoinWith">Or join with </div> */}
                        <div className="bannerSocialBtns d-flex align-items-center gap-2 invisible">
                            <div className="bannSocialBtn">
                                <img src="assets/img/header01.png" className='socIcons' alt="" />
                            </div>
                            <div className="bannSocialBtn">
                                <img src="assets/img/header02.png" className='socIcons' alt="" />
                            </div>
                            <div className="bannSocialBtn">
                                <img src="assets/img/header03.png" className='socIcons' alt="" />
                            </div>
                            <div className="bannSocialBtn">
                                <img src="assets/img/header04.png" className='socIcons' alt="" />
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </>
    );
}

export default HeroBanner