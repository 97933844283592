import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useSpinModal } from '../../hooks/useSpinModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGames } from '../../hooks/useGame';
import { GAME_OPTION_TABS } from '../../constants';
import { ROUTES_CONST } from '../../constants/routeConstant';
import { useSelector } from 'react-redux';
import { getAPIAuth } from '../../service/apiInstance';
import { useAuth } from '../../hooks/useAuth';

const FreeSpinMsgModal = () => {
  const {spinModalObject, setSpinModalObject} = useSpinModal()
  const {setGameObject} = useGames()
  const {token} = useAuth()
  const userDetails = useSelector((state) => state.userDetails);
  const navigate = useNavigate()
  const [query, setQuery] = useSearchParams()


  const handleClose = ()=> {
    setSpinModalObject(prev=>({
      ...prev,
      isModalOpen: false
    }))
  }

  const handlePlayGames = ()=> {
    handleClose()
    setGameObject((prev)=> ({
      selectedTab : GAME_OPTION_TABS?.FREE_SPIN
    }))
    // navigate(ROUTES_CONST.GAME_PAGE)
    setQuery({ q: "freeSpin" })
    navigate(`${ROUTES_CONST.GAME_PAGE}?q=${encodeURIComponent('freeSpin')}`)
  }


  const changeFreeSpinStatus = async ()=> {
    if(spinModalObject?.isModalOpen) {
      try {
        const res = await getAPIAuth('user/updateFreeSpinStatus?flag=true', token)
      } catch (error) {
        
      }
    }
  }

  useEffect(()=> {
    changeFreeSpinStatus()
  }, [spinModalObject?.isModalOpen])


  useEffect(()=> {
    if(userDetails?.freeSpinStatus === false) {
      setSpinModalObject(prev=>({
        ...prev,
        isModalOpen: true
      }))
    }

  }, [userDetails, token])


  return (
    <>
      <Modal className='spinWinnerModal' onHide={handleClose} show={spinModalObject?.isModalOpen} centered>
        <Modal.Body className='d-flex align-items-center justify-content-center'>
            <div className="winnerInnerBox">
              <div className="row">
                <div className="col-12 congTxt">Congratulations!</div>
                <div className="col-12 congMsg">You’ve got 100 free spins!</div>
                <div className="col-12 congPrz">100 SPINS</div>
                <div className="col-12">
                  <button className='claimBtn' onClick={handlePlayGames}>Play Games</button>
                </div>
              </div>
              <img className='coinGroupImage' src="assets/img/coinGroup1.png" alt="" />
              <img className='coinGroupImage2' src="assets/img/coinGroup2.png" alt="" />
            </div>
            <div className="winnerBackImg">
              <img src="assets/img/spinBackgroundImage.png" className='w-100 h-100 object-fit-contain' alt="" />
            </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default FreeSpinMsgModal