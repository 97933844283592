import React, { useEffect, useState } from 'react'
import './../../stacking.scss'
import AppFooter from '../../components/AppFooter/AppFooter'
import CryptoHero from './components/CryptoHero'
import StackingEarning from './components/StackingEarning'
import PopularOfferings from './components/PopularOfferings'
import StackingTypes from './components/StackingTypes'
import ProductTable from './components/productTable/ProductTable'
import CalculateEarning from './components/calculateEarning/CalculateEarning'
import Faq from './components/Faq'
import ModalCryptoEarning from './components/ModalCryptoEarning'
import ShareViaSocialModal from './components/ShareViaSocialModal'
import TokenTable from './components/TokenTable'
import CalculateCryptoEarningModal from './components/modals/CalculateCryptoEarningModal'
import { getAPIAuth } from '../../service/apiInstance'
import { useAuth } from '../../hooks/useAuth'
import { useSubscribeModal } from '../../hooks/useSubscribeModal'
import SubscribeBTCModal from './components/SubscribeBTCModal'
import AboutStackingTypesModal from './components/modals/AboutStackingTypesModal'

const CryptoStacking = () => {
    const { token } = useAuth();
    const [ cryptoList,setCryptoList ] = useState([]);
    const [ typeModalObject, setTypeModalObject] = useState({
      show: false,
      type: ''
    })
    
    const {
      selectedBoostedTerm,
      setSelectedBoostedTerm,
      showSubscribeModal, 
      setShowSubscribeModal,
      selectedItem, 
      setSelectedItem,
      selectedFixedTerm, 
      setSelectedFixedTerm,
      selectedPoolType, 
      setSelectedPoolType
  } = useSubscribeModal();

  const getCryptoList =async ()=>{
    try {
      const res =await getAPIAuth("get-crytoStaking-currency",token);
      setCryptoList(res.data.data);
    } catch (error) {
      console.log("error",error);
    }
  }

  useEffect(()=>{
    getCryptoList();
  },[])//eslint-disable-line

  return (
    <>
      <main className="main vh-100 stackingMain" id="main">
            <div className="mainContaint stackingBg">
                <div className="homepage stackingPage">
                  <CryptoHero data={cryptoList} setModalObject={setTypeModalObject}/>
                  {
                    token ? 
                      <StackingEarning/>
                    : ''
                  }
                  <PopularOfferings/>
                  <StackingTypes  setModalObject={setTypeModalObject}/>
                  <ProductTable/>
                  <CalculateEarning data={cryptoList} />
                  <TokenTable/>
                  <Faq/>
                  {/* modal */}
                  <CalculateCryptoEarningModal/>
                </div>
            </div>
            {/*  **** Footer section *** */}
            <AppFooter />
            {/* <ModalCryptoEarning/> */}
            {/* <ShareViaSocialModal/> */}
        </main>
        {
          showSubscribeModal && (
            <SubscribeBTCModal 
            show ={showSubscribeModal} 
            setShow={setShowSubscribeModal} 
            selectedItem={selectedItem} 
            selectedBoostedTerm={selectedBoostedTerm}
            setSelectedBoostedTerm={setSelectedBoostedTerm}
            selectedFixedTerm={selectedFixedTerm}
            setSelectedFixedTerm={setSelectedFixedTerm}
            selectedPoolType={selectedPoolType}
            setSelectedPoolType={setSelectedPoolType}
          />
          )
        }
      <AboutStackingTypesModal modalObject={typeModalObject} setModalObject={setTypeModalObject}/>
    </>
  )
}

export default CryptoStacking