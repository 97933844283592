import React, { useState } from 'react'
import { useAuthModal } from '../../hooks/useAuthModal'
import { useAuth } from '../../hooks/useAuth'

const RealAndFunModal = ({ setShowOptionModal, lgLoaderHandler }) => {
    const [loading, setLoading] = useState(false)
    const { setAuthModalObject } = useAuthModal()
    const { token } = useAuth()


    // const handlePlayDemo = () => {
    //     setDemo(true)
    //     setLoading(true)
    //     setTimeout(() => {
    //         setShowOptionModal(false)
    //         setLoading(false)
    //         lgLoaderHandler(3000)
    //     }, 2000);
    // }

    return (
        <>
            <div className="row mx-0 w-100 justify-content-center align-items-center modeSelectDialogWrapper bg-transparent">
                <div className="col-12 d-flex justify-content-center bg-transparent">
                    <div className="modeSelectDialog w-100 rounded p-4 bg-transparent">
                        <div className="row py-2 g-3 bg-transparent d-flex justify-content-center">
                            {/* <div className="col-12 dialogTxt">
                                Please select the mode you want to play
                            </div> */}
                            <div className="col-sm-6 bg-transparent ">
                                <button className='dialogBtn ' onClick={() => {
                                    if (!token) {
                                        setAuthModalObject(pre => ({
                                            ...pre,
                                            isAuthOpen: true
                                        }))
                                        return
                                    }
                                    // setDemo(false)
                                    setShowOptionModal(false)
                                    if(lgLoaderHandler){
                                        lgLoaderHandler(5000)
                                    }
                                }}>Real Play</button>
                            </div>
                            {/* <div className="col-sm-6">
                                <button className='dialogBtn freePlay' onClick={handlePlayDemo}>
                                    {
                                        loading ? 'loading...' : 'For Fun'
                                    }
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RealAndFunModal