import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES_CONST } from "../../../constants/routeConstant";

const PlaySlotCard = ({ item }) => {
    const navigate = useNavigate()
    return (
        <div className="games-card h-100"
            onClick={() => navigate(`${ROUTES_CONST.SLOT_PAGE}/${item?.uuid}`)}
        >
            <div className="games-img rounded-2 position-relative bigImg">
                <img src={item?.image} alt="card" className="h-100 w-100 img-fluid rounded-2" />
                <div className="overlay">
                    <div className="row mx-0">
                        <div className="col-12"><div className="overlayTxt text-uppercase">{item.name}</div></div>
                        {/* <div className="col-12"><div className="overlayTxt1">$450.30</div></div> */}
                        <div className="col-12"><div className="overlayTxt">EFFECTIVE RTP</div></div>
                        <div className="col-12"><div className="overlayTxt1 overlayTxt2">75.20%</div></div>
                    </div>
                </div>
                {/* <div className="d-flex justify-content-end">
                  <button className="overlayBtn d-flex align-items-center justify-content-center">$75.293 <img src="assets/img/index/infinite.png" alt="" />
                  </button>
              </div> */}
            </div>
        </div>
    );
};

export default PlaySlotCard;
