import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import * as Yup from 'yup'
import { sendPasswordResetEmail } from "firebase/auth";
import { useAuthModal } from "../../../hooks/useAuthModal";
import { auth } from "../../../config/firebase";
import { succesToaster } from "../../../utils/toaster";
import { Spinner } from "react-bootstrap";
import { GoogleLogin } from "../../socialMedia/GoogleLogin";
import TelegramComp from "../../socialMedia/TelegramLoginButton";
import MetaMaskModal from "../../socialMedia/MetamaskModal";
import TelegramInputModal from "../../socialMedia/TelegramInputModal";

const validationSchema = Yup.object({
  email: Yup.string()
    .required("This field is required")
    .matches(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      "Invalid email"
    ),
});

const LoginForgotPassword = ({ setForgetpass, setLogOn, forgetpass }) => {
  const { authModalObject } = useAuthModal()
  const [isLoading, setIsLoading] = useState(false)
  const [isMetamask, setIsMetamask] = useState(false);
  const [isTelegram, setIsTelegram] = useState(false);
  const [userObj, setUserObj] = useState({});

  useEffect(() => {
    if (!authModalObject.isAuthOpen) {
      setForgetpass(false)
      setLogOn(true)
    }
  }, [authModalObject.isAuthOpen])

  const handle = async (values) => {
    try {
      const res = await sendPasswordResetEmail(auth, values.email);
      succesToaster('Link sent Succesfully!!')
    } catch (error) {
      console.error("Error occurred during password reset:", error);
    } finally {
      setIsLoading(false)
    }
  };
  const handleLogin = () => {
    setLogOn(true);
    setForgetpass(false);
  };
  useEffect(() => {
    if (!forgetpass) {
      setIsLoading(false)
    }
  }, [forgetpass])
  return (
    <>
      <Formik
        initialValues={{
          email: ""
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setIsLoading(true)
          handle(values)
        }}
      >
        {
          ({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched,
          }) => (
            <form>
              <div className="row gap-3 gx-0">
                <div className="col-12 inputdata">
                  {/* email */}
                  <label
                    htmlFor="text"
                    className="inputLabel"
                  >
                    Your Email
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    id="text"
                    className="form-control inputlogin border-0 shadow-none"
                    placeholder="Enter your email"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="email"
                  />
                  <ErrorMessage>
                    {errors.email && touched.email && errors.email}
                  </ErrorMessage>
                </div>

              </div>
              <div className="row mt-4 gap-4">
                <div className="col-12 mx-auto">
                  <span
                    className="playBtn position-relative d-flex align-items-center justify-content-center"
                  >
                    <button
                      type="submit"
                      className="playBtnInner border-0 d-flex align-items-center justify-content-center w-100 cursor-pointer"
                      onClick={handleSubmit}
                    >
                      {
                        isLoading ? <Spinner size="sm" color="dark" /> : " Send Link"
                      }
                    </button>
                  </span>
                </div>
                {/* divider */}
                <div className="col-12 mx-auto ">
                  <div className="loginbutton text-center">
                    <span className="directLogin text-decoration-none ">
                      Log in directly with
                    </span>
                  </div>
                </div>
                {/* social links */}
                <div className="col-12 mx-auto">
                  <div className="row align-items-center justify-content-center g-2">
                    <div className="col-auto">
                      {/* google */}

                      <span className="text-decoration-none">
                        <GoogleLogin parent={"signIn"}>
                          <div className="link">
                            <img src="assets/img/newIcon/google.svg" alt="" />
                          </div>
                        </GoogleLogin>
                      </span>
                    </div>
                    {/* metamask */}
                    {/* <div className="col-auto">
                      <span
                        className="text-decoration-none cursor-pointer"
                        onClick={() => setIsMetamask(true)}
                      >
                        <div className="link">
                          <img
                            src="assets/img/newIcon/metamask-grayscale-logo.svg"
                            alt=""
                          />
                        </div>
                      </span>
                    </div> */}
                    <MetaMaskModal
                      isOpen={isMetamask}
                      setIsOpen={setIsMetamask}
                      parent={"signIn"}
                    />
                    {/* telegram */}
                    <TelegramComp
                      isOpen={isTelegram}
                      setIsOpen={setIsTelegram}
                      userObj={userObj}
                      setUserObj={setUserObj}
                      // setIsReferral={setIsReferral}
                      authModalObject={authModalObject}
                    />
                    {/* <div className="col-auto cursor-pointer">
                      <span className="text-decoration-none position-relative">
                        <div className="link"><img src="assets/img/newIcon/telegram.svg" alt="" /></div>
                        {authModalObject.isAuthOpen ? (
                          <div className="col-auto d-flex justify-content-center position-absolute opacity-0 top-0 bottom-0 start-0 end-0">
                            <TelegramComp
                              isOpen={isTelegram}
                              setIsOpen={setIsTelegram}
                              userObj={userObj}
                              setUserObj={setUserObj}
                            // setIsReferral={setIsReferral}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </span>
                    </div> */}
                    {/* {authModalObject.isAuthOpen ? (
                    <div className="col-auto d-flex h-25 justify-content-center my-3">
                      <TelegramComp
                        setIsOpen={setIsTelegram}
                        userObj={userObj}
                        setUserObj={setUserObj}
                      />
                    </div>
                  ) : (
                    <></>
                  )} */}
                  </div>
                </div>
              </div>
            </form>
          )
        }
      </Formik>
      {/* <MetaMaskModal
        isOpen={isMetamask}
        setIsOpen={setIsMetamask}
        parent={"signIn"}
      /> */}
      {/* <TelegramInputModal
        isOpen={isTelegram}
        setIsOpen={setIsTelegram}
        userObj={userObj}
        setUserObj={setUserObj}
      /> */}
    </>
  );
};

export default LoginForgotPassword;
