import React, { useEffect, useState } from "react";
import AppFooter from "../../components/AppFooter/AppFooter";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { getAPIAuth } from "../../service/apiInstance";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const AboutPartnerPage = () => {
  const { id } = useParams();
  const [partner, setPartner] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation()
  const aboutPartnerLabels = t("aboutPartnerLabels", { returnObjects: true })
  const commonText = t("commonText", { returnObjects: true })
  const singleDataApi = async () => {
    try {
      const res = await getAPIAuth(`get-Single-PartnersData?id=${id}`);
      setPartner(res.data?.data);
    } catch (error) {
      
    }finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    singleDataApi();
  }, [id]);
  return (
    <main className="main vh-100" id="main">
      <div className="mainContaint">
        <div className="homepage aboutJonathan">
          {/* ****** [About Jonathan Section Start ] ****** */}
          <div className="container-fluid aboutJonathanSection">
            {isLoading ? (
              <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="light" />
              </div>
            ) : Object.keys(partner).length === 0 ? (
              <div className="w-100 h-100">
                <h6 className="text-light">{commonText.NoDataAvailable}</h6>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="row mx-0 gap-md-4 gap-3">
                    <div className="col-12 px-0">
                      <div className="sectionHeading">{aboutPartnerLabels.Our_Partner}  </div>
                    </div>
                    <div className="col-12 px-0">
                      <div className="heroImg">
                        {partner?.image ? (
                          <img
                            src={partner?.image}
                            alt="heroImg"
                            // className="img-fluid w-100"
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row mx-0 aboutContent">
                    <div className="col-12 px-0">
                      <div className="row mx-0 gap-lg-3 gap-md-3 gap-2">
                        <div className="col-12 px-0">
                          <div className="subHeadingAbout d-flex align-items-center">
                            {aboutPartnerLabels.About} {partner?.title}
                            {/* <a
                              className="subHeadingImg d-inline-flex ms-md-3 ms-2"
                              target="_blank"
                              href={partner?.instagram}
                            >
                              <img
                                src="assets/img/aboutJonathan/instagram.png"
                                alt="instagram"
                                className="img-fluid w-100 h-100"
                              />
                            </a> */}
                          </div>
                        </div>
                        <div className="col-12 px-0">
                          {/* <div className="pera">{partner?.description}</div> */}
                          {partner.description ? (
                            <div className="pera pt-1">
                              {parse(partner.description)}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/*  **** Footer section *** */}
      <AppFooter />
    </main>
  );
};

export default AboutPartnerPage;
