import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Mousewheel, Keyboard } from 'swiper/modules';
import { getAPI, getAPIAuth } from '../../../service/apiInstance';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ROUTES_CONST } from '../../../constants/routeConstant';
import { useAuth } from '../../../hooks/useAuth';
import { useSelector } from 'react-redux';
import GamesCard from '../../../components/common/GamesCard/GamesCard';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_SCREEN_WIDTH } from '../../../constants';
import { useTranslation } from 'react-i18next';
import SearchInput from './SearchInput';
import SortingComponent from './SortingComponent';
import ProviderSortComp from './ProviderSortComp';



const SelectedGame = ({selectedCategory}) => {
    const [allCats, setAllCats] = useState([])
    const [gamesLoader, setGamesLoader] = useState(true)
    const [isLoadMore, setIsLoadMore] = useState(false)
    const navigate = useNavigate()
    const { token } = useAuth()
    const userDetails = useSelector(state => state.userDetails)
    const isMobile = useMediaQuery({ maxWidth: MOBILE_SCREEN_WIDTH })
    const { t } = useTranslation()
    const casinoLabels = t("casinoLabels", { returnObjects: true })
    const commonText = t("commonText", { returnObjects: true })
    const [sortBy, setSortBy] = useState('')
    const [selectedProvider, setSelectedProvider] = useState('')
    const [pageNo, setPageNo] = useState(1)
    const [totalPages, setTotalPages] = useState(0)

    const getGames = async () => {
        setGamesLoader(true)
        try {
            if (token && userDetails?.id) {
                const res = await getAPIAuth(`games/get-games-by-cat?is_mobile=${isMobile ? "true" : "false"}&categoryId=${selectedCategory}&providerId=${selectedProvider}&gameNameSort=${sortBy}&gameLimit=14&page=1`)
                if (res?.data?.success) {
                    setAllCats(res?.data?.data?.[0]?.data)
                    setTotalPages(res?.data?.data?.[0]?.totalGame)
                } else {
                    setAllCats([])
                    setTotalPages(0)
                }
            } else {
                const res = await getAPI(`games/get-games-by-cat?is_mobile=${isMobile ? "true" : "false"}&categoryId=${selectedCategory}&providerId=${selectedProvider}&gameNameSort=${sortBy}&gameLimit=14&page=1`)
                console.log('ressss', res)

                if (res?.data?.success) {
                    setAllCats(res?.data?.data?.[0]?.data)
                    setTotalPages(res?.data?.data?.[0]?.totalGame)
                }

            }
        } catch (error) {
            setAllCats([])
            setTotalPages(0)
        } finally {
            setGamesLoader(false)
        }
    }

    useEffect(() => {
        if(selectedCategory) {

            getGames()
        }
    }, [token, userDetails?.id, selectedCategory, selectedProvider, sortBy])


    const loadMore = async () => {
        setIsLoadMore(true)
        try {
            if (token && userDetails?.id) {
                const res = await getAPIAuth(`games/get-games-by-cat?is_mobile=${isMobile ? "true" : "false"}&categoryId=${selectedCategory}&providerId=${selectedProvider}&gameNameSort=${sortBy}&gameLimit=14&page=${pageNo + 1}`)
                if (res?.data?.success) {
                    setAllCats([...allCats, ...res?.data?.data?.[0]?.data])
                    setTotalPages(res?.data?.data?.[0]?.totalGame)
                } else {
                    setAllCats([])
                    setTotalPages(0)
                }
            } else {
                const res = await getAPI(`games/get-games-by-cat?is_mobile=${isMobile ? "true" : "false"}&categoryId=${selectedCategory}&providerId=${selectedProvider}&gameNameSort=${sortBy}&gameLimit=14&page=${pageNo + 1}`)
                if (res?.data?.success) {
                    setAllCats([...allCats, ...res?.data?.data?.[0]?.data])
                    setTotalPages(res?.data?.data?.[0]?.totalGame)
                }

            }
        } catch (error) {
            setAllCats([])
            setTotalPages(0)
        } finally {
            setIsLoadMore(false)
        }
    }
    // blank selected provider whenever selecct category changes
    useEffect(()=>{
        setSelectedProvider("")
        setPageNo(1)
    },[selectedCategory])

      // blank sort by whenever selecct category changes
    useEffect(()=>{
        setSortBy("")
        setPageNo(1)
    },[selectedCategory])


    console.log('allCats', allCats)

    return (
        <>
                    <div className="container-fluid position-relative px-0" 
                    >
                        <div className="topratedgames-tab-main position-relative mt-3 mt-lg-4">
                            <div className="topratedgames-tab-content">
                                <div className="row align-items-center justify-content-between g-3">
                                    <div className="col-12 col-md">
                                        <SearchInput/>
                                    </div>
                                    <div className="col-6 col-md-auto">
                                        <ProviderSortComp selectedProvider={selectedProvider} setSelectedProvider={setSelectedProvider} selectedCategory={selectedCategory}/>
                                    </div>
                                    <div className="col-6 col-md-auto">
                                        <SortingComponent sortBy={sortBy} setSortBy={setSortBy} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-content">
                            <div className="tab-pane fade show active">
                                <div className="container-fluid toprated-games-main resultpage p-0">
                                    <div className="toprated-games">
                                        <div className="row">
                                            <div className="col-12 games-card-main px-">
                                                <div className="mySwiper cardSwiper casinoCard1">
                                                    <div
                                                        className="row mx-0 mySwiper searchGameCardWrapper w-100 g-2 row-cols-8  casinoCard1 "
                                                    >
                                                        {!gamesLoader ?
                                                            allCats?.length > 0 ?
                                                                allCats?.map((item) => (
                                                                    <div
                                                                        key={item?._id}
                                                                        className="swiper-slide"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            navigate(`${ROUTES_CONST.SLOT_PAGE}/${item?.uuid}`)
                                                                        }}
                                                                    >
                                                                        <GamesCard
                                                                            RTP={item?.rtp}
                                                                            image={item?.image}
                                                                            name={item?.name}
                                                                            isFav={item?.isFav}
                                                                            id={item?._id}
                                                                            favButton={true}
                                                                            uuid={item?.uuid}
                                                                        />
                                                                    </div>
                                                                ))
                                                                : <div className="w-100 h-100">
                                                                    <h6 className="text-light">
                                                                        {/* No data available */}
                                                                        {commonText.NoDataAvailable}
                                                                    </h6>
                                                                </div>
                                                            :
                                                            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                                                <Spinner animation="border" variant="light" size="sm" />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                totalPages > pageNo && (

                                                    <div className="col-12 d-flex align-items-center justify-content-center mt-3">
                                                        <button onClick={()=>{
                                                            setPageNo(pageNo + 1)
                                                            loadMore()
                                                        }} className='gamePageLoadMoreBtn d-flex align-items-center justify-content-center border-0'>
                                                            {
                                                                isLoadMore ? <Spinner animation="border" variant="light" size="sm"/> : 'Load More'
                                                            }
                                                            
                                                        </button>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
        </>
    )
}

export default SelectedGame