import React, { useState } from 'react'
import { useAuth } from '../../../hooks/useAuth'
import { useAuthModal } from '../../../hooks/useAuthModal';
import { AUTH_MODAL_TABS, CLICK_ID } from '../../../constants';
import { useSearchParams } from 'react-router-dom';
import { GoogleLogin } from '../../../components/socialMedia/GoogleLogin';
import TelegramComp from '../../../components/socialMedia/TelegramLoginButton';
import MetaMaskModal from '../../../components/socialMedia/MetamaskModal';
import CalculateCryptoEarningModal from './modals/CalculateCryptoEarningModal';

const CryptoHero = ({ data, setModalObject }) => {
  const {token} = useAuth()
  const [showCalculator, setShowCalculator] = useState(false);
  const {authModalObject, setAuthModalObject } = useAuthModal();
  const [query, setQuery] = useSearchParams()
  const [checks, setChecks] = useState({
    terms: true,
    promotions: true
})
const [bonus, setBonus] = useState({ bonus: false })
const click_id = sessionStorage.getItem(CLICK_ID) === 'null' ? null : sessionStorage.getItem(CLICK_ID) // setup in appHeader 


  return (
    <>
      <div className="row cryptoHeroSection">
        <div className="col-12 d-none d-md-block">
          <div className="d-flex align-items-center justify-content-end w-100 gap-2 mb-4">
            <button className='commonLightBtn' onClick={()=>setModalObject(prev=>({
              ...prev,
              show: true,
              type: 'flexible'
            }))}>About staking type</button>
            <button className='commonBlueBtn' onClick={()=>setShowCalculator(true)} >
              <img className='btnIcon' src="./assets/img/stacking/calculator.png" alt="" />
              Calculate
            </button>
          </div>
        </div>
        <div className="col-md-6">
          {
            !token ? <>
              <div className="subTitle d-none d-md-block">Sign Up and Earn</div>
            </> : ''
          }
          <div className="title text-center text-md-start">CRYPTO STAKING <br className='d-none d-md-block' /> APR UP TO 130%</div>
          {
            !token ?
              (<div className="d-flex align-items-center mt-3 flex-column flex-md-row mb-3 mb-md-0">
                <button
                  onClick={() => {
                    setAuthModalObject(pre => ({
                       // ...pre,
                      selectedTab: AUTH_MODAL_TABS.LOG_IN,
                      isAuthOpen: true,
                    }))
                    setQuery({ action: "login" })
                  }}
                  className='commonBlueBtn px-5 px-md-4 px-lg-5'>Sign in & Earn</button>
                <div className="orTxt px-2 py-2 py-md-0">Or</div>
                <div className="d-flex align-items-center">
                  <GoogleLogin
                        parent={'signin'}
                        checks={checks}
                        click_id={click_id}
                        bonus={bonus}
                    >
                      <button className='stackingSocialBtn'>
                        <img className='google' src="./assets/img/stacking/google.png" alt="" />
                      </button>
                    </GoogleLogin>
                  <button className='stackingSocialBtn'>
                    <TelegramComp
                        checks={checks}
                        authModalObject={authModalObject}
                        click_id={click_id}
                        bonus={bonus}
                        parent={'signin'}
                        isFromCrypto={true}
                    />
                    {/* <img src="./assets/img/stacking/telegramWhite.png" alt="" /> */}
                  </button>
                  <button className='stackingSocialBtn'>
                    {/* <img src="./assets/img/stacking/metamask.png" alt="" /> */}
                    <MetaMaskModal
                        parent={'signin'}
                        checks={checks}
                        click_id={click_id}
                        bonus={bonus}
                        isFromCrypto={true}
                    />
                  </button>
                </div>
              </div>):("")
          }
        </div>
        <div className="col-md-6 d-flex justify-content-center">
          <div className="heroCoinImg">
            <img className='h-100 w-100 object-fit-contain' src="./assets/img/stacking/cryptoHeroCoins.png" alt="" />
          </div>
        </div>
      </div>
      <CalculateCryptoEarningModal show={showCalculator} setShow={setShowCalculator} data={data} />
    </>
  )
}

export default CryptoHero