import React, { useEffect, useState } from 'react'
import { getAPI } from '../../../service/apiInstance'
import NoData from './NoData'
import { useSubscribeModal } from '../../../hooks/useSubscribeModal'
import { useAuth } from '../../../hooks/useAuth'
import { useAuthModal } from '../../../hooks/useAuthModal'
import { AUTH_MODAL_TABS } from '../../../constants'
import { useSearchParams } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

const PopularOfferings = () => {
  const [data, setData] = useState([])
  const { token } = useAuth()
  const { setAuthModalObject } = useAuthModal();
  const [setQuery] = useSearchParams()
  const [activeTable, setActiveTable] = useState()

  const {
    selectedBoostedTerm,
    setSelectedBoostedTerm,
    showSubscribeModal,
    setShowSubscribeModal,
    selectedItem,
    setSelectedItem,
    selectedFixedTerm,
    setSelectedFixedTerm,
    selectedPoolType,
    setSelectedPoolType
  } = useSubscribeModal();

  useEffect(() => {
    if (data?.length) {
      const boostedFixedTerm = data?.[activeTable]?.term?.filter(item => item?.poolType === "Boosted Fixed-Term")?.[0]?.tab
      const fixedTerm = data?.[activeTable]?.term?.filter(item => item?.poolType === "Fixed-Term")?.[0]?.tab
      if (boostedFixedTerm?.length) {
        const term = boostedFixedTerm[boostedFixedTerm?.length - 1]
        setSelectedBoostedTerm(term)
      }
      if (fixedTerm?.length) {
        const term = fixedTerm[fixedTerm?.length - 1]
        setSelectedFixedTerm(term)
      }
    }
  }, [data, activeTable])

  const getAllProductData = async () => {
    try {
      const res = await getAPI('get-crytoStaking-currency')
      if (res?.data?.success) {
        setData(res?.data?.data)
      }
      console.log('res', res)
    } catch (error) {

    }
  }


  console.log('dataaaaaaaaaaaa', data)

  useEffect(() => {
    getAllProductData()
  }, [])

  useEffect(() => {
    if (data?.length) {
      const boostedFixedTerm = data?.[activeTable]?.term?.filter(item => item?.poolType === "Boosted Fixed-Term")?.[0]?.tab
      if (boostedFixedTerm?.length) {
        const term = boostedFixedTerm[boostedFixedTerm?.length - 1]
        setSelectedBoostedTerm(term)
      }
    }
  }, [data, activeTable])

  return (
    <>
      <div className="my-4">
        <div className="row align-items-center">
          <div className="col">
            <div className="CommonTitle">Popular offerings</div>
          </div>
          <div className="col-auto d-flex align-items-center">
            <div className=" offeringPrev swiper-button-prev-unique gamesPrevBtn livePrev p-0 d-inline-flex align-items-center justify-content-center cursor-pointer casinoPrev me-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="#B6FD9B">
                <path d="M12.1505 0.370117C18.6778 0.370117 23.9692 5.6871 23.9692 12.246C23.9692 18.8047 18.6778 24.1218 12.1505 24.1218C5.62313 24.1218 0.331718 18.8047 0.331718 12.246C0.331718 5.6871 5.62313 0.370117 12.1505 0.370117ZM8.54907 13.0503C8.60666 13.1082 8.66985 13.1571 8.73565 13.1996L13.1712 17.6566C13.3866 17.8732 13.669 17.9815 13.9514 17.9815C14.2338 17.9815 14.516 17.8732 14.7316 17.6566C15.1626 17.2235 15.1626 16.5213 14.7316 16.0884L10.9095 12.2472L14.7124 8.42598C15.1434 7.99267 15.1434 7.29065 14.7124 6.85774C14.2814 6.42467 13.5827 6.4249 13.1517 6.8575L8.73572 11.2946C8.66993 11.3371 8.60674 11.3859 8.54938 11.4439C8.32908 11.6652 8.22279 11.9568 8.22775 12.2472C8.22255 12.5373 8.32861 12.8288 8.54907 13.0503Z" fill="#B6FD9B" />
              </svg>
            </div>
            <div className="offeringsNext swiper-button-next-unique gamesNextBtn liveNext p-0 d-inline-flex align-items-center justify-content-center cursor-pointer casi">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="#B6FD9B">
                <path d="M12.1061 0.370117C5.57877 0.370117 0.287354 5.6871 0.287354 12.246C0.287354 18.8047 5.57877 24.1218 12.1061 24.1218C18.6335 24.1218 23.9249 18.8047 23.9249 12.246C23.9249 5.6871 18.6335 0.370117 12.1061 0.370117ZM15.7075 13.0503C15.6499 13.1082 15.5867 13.1571 15.5209 13.1996L11.0854 17.6566C10.8699 17.8732 10.5876 17.9815 10.3052 17.9815C10.0228 17.9815 9.74055 17.8732 9.52497 17.6566C9.09398 17.2235 9.09398 16.5213 9.52497 16.0884L13.3471 12.2472L9.5442 8.42598C9.11321 7.99267 9.11321 7.29065 9.5442 6.85774C9.97519 6.42467 10.6739 6.4249 11.1049 6.8575L15.5209 11.2946C15.5867 11.3371 15.6498 11.3859 15.7072 11.4439C15.9275 11.6652 16.0338 11.9568 16.0288 12.2472C16.034 12.5373 15.928 12.8288 15.7075 13.0503Z" fill="#B6FD9B" />
              </svg>
            </div>
          </div>
        </div>
        <div className="">
          {
            data?.length ?
              <>
                <Swiper
                  navigation={{
                    nextEl: ".offeringsNext",
                    prevEl: ".offeringPrev",
                  }}
                  modules={[Navigation, Autoplay]}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    576: {
                      slidesPerView: 2,
                      spaceBetween: 15,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 15,
                    },
                    900: {
                      slidesPerView: 3,
                      spaceBetween: 15,
                    },
                    992: {
                      slidesPerView: 3,
                      spaceBetween: 15,
                    },
                    1200: {
                      slidesPerView: 4,
                      spaceBetween: 15,
                    },
                  }}
                  className="mySwiper">
                  {
                    data?.map((item, index) => (
                      <SwiperSlide key={item?._id}>
                        <div className="offeringCard cursor-pointer" onClick={() => {
                          if (token) {
                            setActiveTable(index)
                            setSelectedItem(item)
                            setShowSubscribeModal(true)
                            setSelectedPoolType("Fixed-Term")
                          } else {
                            setAuthModalObject(pre => ({
                              // ...pre,
                              selectedTab: AUTH_MODAL_TABS.LOG_IN,
                              isAuthOpen: true,
                            }))
                            setQuery({ action: "login" })
                          }
                        }}>
                          <div className="offeringCardTop">
                            <div className="row">
                              <div className="col pe-0 overflow-hidden">
                                <div className="d-flex align-items-center">
                                  <div className="currencyIcon me-2 rounded-circle overflow-hidden">
                                    <img src={item?.currency?.icon} alt="" />
                                  </div>
                                  <div>
                                    <div className="sText text-capitalize">{item?.currency?.codeName}</div>
                                    <div className="sTextBold text-uppercase">{item?.currency?.code}</div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-auto">
                                <div className="sText">Est.APR</div>
                                <div className="sTextBold text-end">{item?.estimated_Apr_Min}%</div>
                              </div>
                            </div>
                          </div>
                          <div className="offeringCardBottom">
                            <div className="row">
                              <div className="col sText pe-0">Duration</div>
                              <div className="col-auto sText green">365 Days</div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))
                  }
                </Swiper>
              </>
              :
              <div className="d-flex w-100 justify-content-center">
                <NoData />
              </div>
          }
        </div>
      </div>
    </>
  )
}

export default PopularOfferings