import React from 'react'
import MetaMaskModal from '../../socialMedia/MetamaskModal'
import { useAuthModal } from '../../../hooks/useAuthModal'
import TelegramComp from '../../socialMedia/TelegramLoginButton'
import { GoogleLogin } from '../../socialMedia/GoogleLogin'

const SocialLogin = ({ parent, checks, click_id, new_click_id, bonus }) => {
    const { authModalObject } = useAuthModal()
    return (
        <>
            <div className="col-12 my-3 mt-lg-4">
                <div className="row mx-0 align-items-center">
                    {/* <div className="col ps-0"><div className="divider"></div></div> */}
                    <div className="col-12 px-0"><div className="dividerTxt ">Or continue with</div></div>
                    {/* <div className="col pe-0"><div className="divider"></div></div> */}
                </div>
            </div>
            <div className="col-12">
                <div className="row gx-2 justify-content-center flex-nowrap row-cols-2">
                    <MetaMaskModal
                        parent={parent}
                        checks={checks}
                        click_id={click_id}
                        new_click_id={new_click_id}
                        bonus={bonus}
                    />
                    {/* <TelegramComp
                        checks={checks}
                        authModalObject={authModalObject}
                        click_id={click_id}
                        new_click_id={new_click_id}
                        bonus={bonus}
                        parent={parent}
                    /> */}

                    <GoogleLogin
                        parent={parent}
                        checks={checks}
                        click_id={click_id}
                        new_click_id={new_click_id}
                        bonus={bonus}
                    >
                        <div
                            className="linkImg">
                                <span>
                                    <img
                                        src='assets/img/auth/google.png'
                                        alt=''
                                    />
                                </span>
                                <span className='d-md-flex d-flex'>
                                    Google

                                </span>
                        </div>
                    </GoogleLogin>
                </div>
            </div>
        </>
    )
}

export default SocialLogin