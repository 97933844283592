import { useLocation, useNavigate } from "react-router-dom";
import AppHeader from "./components/AppHeader/AppHeader";
import RoutesComp from "./routes/Routes";
import AppSideBar from "./components/AppSideBar/AppSideBar";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import i18next from "i18next";
import { AUTH_MODAL_TABS, USER_LANG, SESSION_TOKEN, isDev } from "./constants";
import { DEFAULT_LANG } from "./constants/languageConstant";
import { socket } from "./service/socket";
import { useAuth } from "./hooks/useAuth";
import { postAPIAuth } from "./service/apiInstance";
import ChatWrapper from "./components/common/ChatBoat/ChatWrapper";
import { useAuthModal } from "./hooks/useAuthModal";
import { ROUTES_CONST } from "./constants/routeConstant";
import Chatbot from "./components/common/ChatBoat/chatbaot";
import axios from "axios";
import { btRendererAction } from "./store/action";
import MasterInput from "./components/MasterInput";
import moment from 'moment'
// import ipapi from 'ipapi';



function App() {
  const [referraldata, setReferral] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useAuth()
  const userDetails = useSelector(state => state.userDetails)
  const { setAuthModalObject } = useAuthModal()
  const dispatch = useDispatch()
  const referralFun = () => {
    setReferral(location.pathname);
  };
  const splitBySlash = referraldata.split("/");
  const lastPart = splitBySlash[splitBySlash.length - 1];
  const extractedStart = lastPart.startsWith("i-") ? "i-" : "";
  const extractedEnd = lastPart.endsWith("-n") ? "-n" : "";
  const middlePart = lastPart.substring(2, lastPart.length - 2);
  const modifiedLink = referraldata.replace(
    "/" + extractedStart + middlePart + extractedEnd,
    ""
  );

  const [isAuth, setIsAuth] = useState(() => sessionStorage.getItem(SESSION_TOKEN, SESSION_TOKEN) ? true : false)

  ////------------------------------------------ for translation ------------------------------------------>>>>>
  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       autoDisplay: false
  //     },
  //     "google_translate_element"
  //   );
  // };
  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);


  // useEffect(() => {
  //   const unixTimestamp = userDetails?.createdAt ? Math.floor(new Date(userDetails?.createdAt).getTime() / 1000) : "";
  //   const payload = {
  //     api_base: "https://api-iam.intercom.io",
  //     app_id: "to12zr63",
  //     // user_hash: "a5ed72f1b71dbf5307eb71340bf8ca0818da3327c399bc8edce12c173a85fa48" // an Identity Verification user hash for your user
  //   }
  //   if (userDetails?.username) payload.name = userDetails?.username
  //   if (userDetails?.email) payload.email = userDetails?.email
  //   if (userDetails?.createdAt) payload.created_at = unixTimestamp
  //   if (userDetails?.id) payload.user_id = userDetails?.id
  //   if (userDetails?.userHash) payload.user_hash = userDetails?.userHash
  //   window?.Intercom("boot", payload);
  // }, [userDetails?.id])

  // useEffect(() => {
  //   console.log("update");
  //   window?.Intercom("update");
  // }, [location.pathname])

  
  // open register section with referal code.
  useEffect(() => {
    if (extractedStart && extractedEnd) {
      localStorage.setItem("middlePart", middlePart);
      setAuthModalObject(pre => ({
        isAuthOpen: true,
        selectedTab: AUTH_MODAL_TABS.SIGN_UP
      }))
      // navigate("/games", { state: { extractedStart: extractedStart } });
      navigate(ROUTES_CONST.INDEX, { state: { extractedStart: extractedStart } });
    } else {
      localStorage.setItem("middlePart", "");
    }
    referralFun();
  }, [extractedStart, extractedEnd]);

//   useEffect(() => {
//     const handleContextMenu = (event) => {
//         event.preventDefault();
//     };

//     document.addEventListener('contextmenu', handleContextMenu);

//     // Cleanup function to remove the event listener
//     return () => {
//         document.removeEventListener('contextmenu', handleContextMenu);
//     };
// }, []);

// useEffect(() => {
//   const handleKeyDown = (event) => {
//       if (
//           (event.ctrlKey && event.shiftKey && event.key === 'I') ||
//           (event.ctrlKey && event.shiftKey && event.key === 'J') ||
//           (event.ctrlKey && event.key === 'U') ||
//           (event.key === 'F12')
//       ) {
//           event.preventDefault();
//       }
//   };

//   document.addEventListener('keydown', handleKeyDown);

//   // Cleanup function to remove the event listener
//   return () => {
//       document.removeEventListener('keydown', handleKeyDown);
//   };
// }, []);

  // for language
  const languageHandler = async () => {
    const lang = JSON.parse(localStorage.getItem(USER_LANG))
    if (!userDetails?.language) {
      // if user don't have a lang.
      if (token && lang && !userDetails?.language) {
        // api call to set userDetails.language
        if (userDetails?.id) {
          try {
            const body = {
              language: lang
            }
            const res = await postAPIAuth(`user/change-user-lang?userId=${userDetails?.id}`, body)
          } catch (error) {

          }
        }
        // localStorage.setItem(USER_LANG, JSON.stringify(item))

      }
      if (!token && lang) {
        // using the cached language
        i18next.changeLanguage(lang)
      } else if (!token && !lang) {
        // setting default language as en
        i18next.changeLanguage(DEFAULT_LANG.symbol)
        localStorage.setItem(USER_LANG, JSON.stringify(DEFAULT_LANG.symbol))
      }
    } else if (userDetails?.language) {
      // if user do have a lang.
      i18next.changeLanguage(userDetails?.language)
      localStorage.setItem(USER_LANG, JSON.stringify(userDetails?.language))
    }
  }

  const newLanguageHandler = async () => {
    const lang = localStorage.getItem(USER_LANG)
    if (userDetails?.id) {
      // setting users language from local storage
      try {
        const body = {
          language: lang
        }
        const res = await postAPIAuth(`user/change-user-lang?userId=${userDetails?.id}`, body, token)
        if (res.data.success) {
          i18next.changeLanguage(lang)
          localStorage.setItem(USER_LANG, lang)
        }
      } catch (error) {
        // error
      }
    } else {
      if (!token && lang) {
        // using the cached language
        i18next.changeLanguage(lang)
      } else if (!token && !lang) {
        // setting default language as en
        i18next.changeLanguage(DEFAULT_LANG.symbol)
        localStorage.setItem(USER_LANG, DEFAULT_LANG.symbol)
      }
    }

  }

  useEffect(() => {
    // language
    newLanguageHandler()
  }, [userDetails?.id, token])

  // useEffect(() => {
  //   const rendererInstance1 = new window.BTRenderer()
  //   dispatch(btRendererAction(rendererInstance1))
  // }, [])
  // will be of future use

  // const chatBox = async () => {
  //   const path = window.location.pathname
  //   if (!path.startsWith('/sports')) {
  //     // alert(path, 'here')
  //     var $zoho = {};
  //     $zoho.salesiq = {
  //       widgetcode: "siq2a2bff036a476b4b4b76050979792b22930f2581c782d83b9ac7b6f4d435c9dd",
  //       values: {},
  //       ready: function () { }
  //     };
  //     var d = document;
  //     var s = d.createElement("script");
  //     s.type = "text/javascript";
  //     s.id = "zsiqscript";
  //     s.defer = true;
  //     s.src = "https://salesiq.zohopublic.com/widget";
  //     var t = d.getElementsByTagName("script")[0];
  //     t.parentNode.insertBefore(s, t);
  //   }
  // }

  // scroll to top everytime url changes
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [location.pathname])


  if (isDev && !isAuth) {
    return (
      <MasterInput isAuth={isAuth} setIsAuth={setIsAuth} />
    )
  }

  return (
    <>
      {
        location.pathname === "/aml-policy" ||
          location.pathname === "/responsible-gamble" ||
          location.pathname === "/game-error" ||
          location.pathname === "/terms-conditions" ? (
          <></>
        ) : (
          <>
            <AppHeader />
          </>
        )}
      {
        location.pathname === "/affiliate-dashboard" ||
          location.pathname === "/aml-policy" ||
          location.pathname === "/responsible-gamble" ||
          location.pathname === "/game-error" ||
          location.pathname === "/sports" ||
          location.pathname === "/terms-conditions" ? (
          <></>
        ) : (
          <>
            <AppSideBar />
          </>
        )}
      <RoutesComp socket={socket} />
    </>
  );
}

export default App;