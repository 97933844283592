import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { useAuth } from "../../../hooks/useAuth";
import { useAuthModal } from "../../../hooks/useAuthModal";
import * as Yup from "yup";
import { signInWithEmailAndPassword } from "firebase/auth";
import { postAPI } from "../../../service/apiInstance";
import { auth } from "../../../config/firebase";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import { useSelector } from "react-redux";
import { USER_LANG } from "../../../constants";
import { Spinner } from "react-bootstrap";
import { GoogleLogin } from "../../socialMedia/GoogleLogin";
import TelegramComp from "../../socialMedia/TelegramLoginButton";
import MetaMaskModal from "../../socialMedia/MetamaskModal";
import TelegramInputModal from "../../socialMedia/TelegramInputModal";

const validationSchema = Yup.object({
  password: Yup.string().required("This field is required"),
});

const LoginPassword = ({
  setEmailPassword,
  setLogOn,
  emailOrNumber,
  setForgetpass,
  emailPassword,
  browserName
}) => {
  const { setToken } = useAuth();
  const { authModalObject, setAuthModalObject } = useAuthModal();
  const [isHidden, setIsHidden] = useState(true);
  const ipAddress = useSelector((state) => state.ipAddress);
  const country = useSelector((state) => state.country);
  const [isLoading, setIsLoading] = useState(false);
  const [isMetamask, setIsMetamask] = useState(false);
  const [isTelegram, setIsTelegram] = useState(false);
  const [userObj, setUserObj] = useState({});

  useEffect(() => {
    if (!authModalObject.isAuthOpen) {
      setEmailPassword(false);
      setLogOn(true);
    }
  }, [authModalObject.isAuthOpen]);

  async function signInWEAP(values) {
    try {
      if (emailOrNumber) {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          emailOrNumber,
          values.password
        );

        // Signed in
        // const lang = JSON.parse(localStorage.getItem(USER_LANG))
        const lang = localStorage.getItem(USER_LANG);
        const body = {
          email: emailOrNumber,
          password: values.password,
          ipAddress: ipAddress ? ipAddress : "",
          country: country ? country : "",
          // language: lang?.symbol
          language: lang,
          browser: browserName
        };
        const user = userCredential.user.auth.email;
        const res = await postAPI("user/login-user", body);
        if (res?.data?.success) {
          setToken(res?.data?.data?.auth_token);
          succesToaster("Login successful");
          setEmailPassword(false);
          setLogOn(true);
          setAuthModalObject((pre) => ({ ...pre, isAuthOpen: false }));
        } else {
          errorToaster(res?.data?.message);
        }
      }
    } catch (error) {
      errorToaster(error.message);
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    if (!emailPassword) {
      setIsLoading(false);
    }
  }, [emailPassword]);

  return (
    <>
      <Formik
        initialValues={{
          emailOrNumber: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setIsLoading(true);
          signInWEAP(values);
        }}
      >
        {({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          errors,
          touched,
        }) => (
          <form>
            <div className="row gap-3 gx-0">
              <div className="col-12 inputdata passwordInput pt-0">
                <label htmlFor="password1" className="inputLabel">
                  PASSWORD
                  <span>*</span>
                </label>
                <div className="position-relative passwordGroup">
                  <input
                    type={`${isHidden ? "password" : "text"}`}
                    id="password1"
                    className="form-control inputlogin border-0 shadow-none"
                    placeholder="Enter your password"
                    name="password"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {isHidden ? (
                    <img
                      onClick={() => setIsHidden(false)}
                      src="assets/img/newIcon/eyeclose.svg"
                      className="eye active  open cursor-pointer"
                      alt=""
                    />
                  ) : (
                    <img
                      onClick={() => setIsHidden(true)}
                      src="assets/img/newIcon/eyeopen.svg"
                      className="eye active close cursor-pointer"
                      alt=""
                    />
                  )}
                </div>
                <ErrorMessage>
                  {errors.password && touched.password && errors.password}
                </ErrorMessage>
              </div>
            </div>
            <div className="row mt-4 gap-4">
              <div className="col-12 mx-auto">
                <span className="playBtn position-relative d-flex align-items-center justify-content-center cursor-pointer">
                  <button
                    type="submit"
                    className="playBtnInner border-0 d-flex align-items-center justify-content-center w-100"
                    onClick={handleSubmit}
                  >
                    {isLoading ? (
                      <Spinner size="sm" color="dark" />
                    ) : (
                      "Play now!"
                    )}
                  </button>
                </span>
              </div>
              {/* divider */}
              <div className="col-12 mx-auto ">
                <div className="loginbutton text-center">
                  <span className="directLogin text-decoration-none ">
                    Log in directly with
                  </span>
                </div>
              </div>
              {/* social links */}
              <div className="col-12 mx-auto">
                <div className="row align-items-center justify-content-center g-2">
                  <div className="col-auto">
                    {/* google */}

                    <span className="text-decoration-none">
                      <GoogleLogin parent={"signIn"}>
                        <div className="link">
                          <img src="assets/img/newIcon/google.svg" alt="" />
                        </div>
                      </GoogleLogin>
                    </span>
                  </div>
                  {/* metamask */}
                  {/* <div className="col-auto">
                    <span
                      className="text-decoration-none cursor-pointer"
                      onClick={() => setIsMetamask(true)}
                    >
                      <div className="link">
                        <img
                          src="assets/img/newIcon/metamask-grayscale-logo.svg"
                          alt=""
                        />
                      </div>
                    </span>
                  </div> */}
                  <MetaMaskModal
                    isOpen={isMetamask}
                    setIsOpen={setIsMetamask}
                    parent={"signIn"}
                  />

                  {/* telegram */}
                  <TelegramComp
                    isOpen={isTelegram}
                    setIsOpen={setIsTelegram}
                    userObj={userObj}
                    setUserObj={setUserObj}
                    // setIsReferral={setIsReferral}
                    authModalObject={authModalObject}
                  />
                  {/* <div className="col-auto cursor-pointer">
                    <span className="text-decoration-none position-relative">
                      <div className="link"><img src="assets/img/newIcon/telegram.svg" alt="" /></div>
                      {authModalObject.isAuthOpen ? (
                        <div className="col-auto d-flex justify-content-center position-absolute opacity-0 top-0 bottom-0 start-0 end-0">
                          <TelegramComp
                            isOpen={isTelegram}
                            setIsOpen={setIsTelegram}
                            userObj={userObj}
                            setUserObj={setUserObj}
                          // setIsReferral={setIsReferral}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </span>
                  </div> */}
                  {/* {authModalObject.isAuthOpen ? (
                  <div className="col-auto d-flex h-25 justify-content-center my-3">
                    <TelegramComp
                      setIsOpen={setIsTelegram}
                      userObj={userObj}
                      setUserObj={setUserObj}
                    />
                  </div>
                ) : (
                  <></>
                )} */}
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
      {/* <MetaMaskModal
        isOpen={isMetamask}
        setIsOpen={setIsMetamask}
        parent={"signIn"}
      /> */}
      {/* <TelegramInputModal
        isOpen={isTelegram}
        setIsOpen={setIsTelegram}
        userObj={userObj}
        setUserObj={setUserObj}
      /> */}
    </>
  );
};

export default LoginPassword;
