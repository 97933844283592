import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const ToasterContainer = () => <ToastContainer />;

let currentToast = 0
export const succesToaster = (msg) => {

    // toast.dismiss()
    if (currentToast) {
        toast.dismiss(currentToast)
    }
    currentToast = toast(msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        // theme: "colored",
        theme: "dark",
        type: "success",
    });
}
export const errorToaster = (msg) => {
    if (currentToast) {
        toast.dismiss(currentToast)
    }
    currentToast = toast(msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        // theme: "colored",
        theme: "dark",
        type: "error",
    });
}