import { t } from 'i18next'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { getAPIAuth } from '../../../../service/apiInstance'

const DepositTable = ({activeTab, activeCrypto}) => {
  const [loader, setLoader] = useState(false)
  const [deposits, setDeposits] = useState([])
  const accountPageLabels = t("accountPageLabels", { returnObjects: true })
  const [pagination, setPagination] = useState({
    btnOne: 1,
    btnTwo: 2,
    btnThree: 3,
    btnFour: "...",
    btnFive: 0
  })
  const [pageNo, setPageNo] = useState(1)
  const [isLoadMore, setIsLoadMore] = useState(false)
  const [totalPages, setTotalPage] = useState()
  const userDetails = useSelector((state) => state.userDetails);
  const userId = userDetails?.id;


  const paginationHandler = (count) => {
    if (pagination.btnOne != pageNo) {
      if (pageNo - 1 == pagination.btnThree) {
        setPagination(
          prev => ({
            ...prev,
            btnOne: pagination.btnOne + 1,
            btnTwo: pagination.btnTwo + 1,
            btnThree: pagination.btnThree + 1
          })
        )
      } else if (pageNo + 1 == pagination.btnOne) {
        setPagination(
          prev => ({ ...prev, btnOne: pagination.btnOne - 1, btnTwo: pagination.btnTwo - 1, btnThree: pagination.btnThree - 1 })
        )
      } else if (pageNo == totalPages) {
        setPagination(
          prev => ({ ...prev, btnOne: totalPages - 2, btnTwo: totalPages - 1, btnThree: totalPages })
        )
      }
    }
  }


  const loadMore = async () => {
    try {
      const res = await getAPIAuth(`user/deposit-transaction-details?userId=${userId}&chain=${activeCrypto == 'ALL' ? "" : activeCrypto}&page=${pageNo}`)

      // const res = await getAPIAuth(`user/userTransactions/${"64be2d4fcb1b31b159cc6378"}`)
      if (res.data.success) {
        // setTransactionData(res.data.data)
        setDeposits(res?.data?.data)
        setTotalPage(res.data.pagination?.[0]?.totalPages)
      }
    } catch (error) {
      //error handling
    }

  }

  useEffect(() => {
    if (userDetails?.id && activeTab === 'deposit') {
      if (pageNo >= 1 && isLoadMore) {
        paginationHandler()
        loadMore()
        setIsLoadMore(false)
      } else if (pageNo >= 1) {
        paginationHandler()
        loadMore()
      }
    }
  }, [pageNo, userDetails?.id, activeTab])


  const newDeposit = async () => {
    setLoader(true)

    try {
      if (userId) {
        const res = await getAPIAuth(`user/deposit-transaction-details?userId=${userId}&chain=${activeCrypto == 'ALL' ? "" : activeCrypto}&page=${pageNo}`)
        if (res?.data?.success) {
          setDeposits(res?.data?.data)
          setTotalPage(res.data.pagination?.[0]?.totalPages)
          setPagination({
            btnOne: 1,
            btnTwo: 2,
            btnThree: 3,
            btnFour: "...",
            btnFive: res.data.pagination?.[0]?.totalPages
          })
        } else {
          setDeposits([])
        }
      }
    } catch (error) {

    } finally {
      setLoader(false)
    }
  }
  useEffect(() => {
    console.log('actttttttttttttttttt', activeCrypto, activeTab)
    if (activeTab === "deposit") {
      if (activeCrypto) {
        newDeposit()
      }
    }
  }, [userId, activeCrypto, activeTab])


  return (
    <>
          {!loader ?
              deposits?.length > 0 ?
                <>
                  <div className="col-12 pt-3">
                    <div className="table-responsive">
                      <table className="table affiliateTable mb-2 align-middle">
                        <thead>
                          <tr className="tableHead text-nowrap">
                            <th scope="col" className="border-0 rounded-start-2 px-4">
                              COIN
                            </th>
                            <th scope="col" className="border-0 px-4">
                              {accountPageLabels.AMOUNT}
                            </th>
                            <th scope="col" className="border-0 px-4 texter">
                              {accountPageLabels.DATE_TIME}
                            </th>
                            <th
                              scope="col"
                              className="border-0 px-4 text-er rounded-end-2"
                            >
                              {/* {accountPageLabels.TRANSACTION} */}
                              {accountPageLabels.CREDIT_AMOUNT}
                              {/* COIN */}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="tablebody">
                          {deposits?.map((item) => (
                            <tr className="text-nowrap" key={item?._id}>
                              <td className="border-0  rounded-start-2 px-4 text-uppercase">
                                {/* {item?.amount ? Number(item?.amountInUsdt).toFixed(4) : '0.00'} */}
                                {/* {item?.amount ? Math.floor(item?.amount) : '0'} */}
                                {item?.coin ? item?.coin : item?.currency ? item?.currency : "----"}
                              </td>
                              <td className="border-0 px-4">
                                {item?.amount ? Number(item.amount).toFixed(2) : '0.00'}
                                {/* {userBalance ? Math.floor(userBalance) : '0'} */}
                              </td>
                              <td className="border-0 px-4">{moment(item.createdAt).format("MMM DD YYYY , HH:mm")}</td>
                              {/* <td className="border-0 px-4">{item?.order_id}</td> */}
                              <td className="border-0 px-4 text-decoration-underlin cursor-pointer">
                                {/* {item?.coin ? item?.coin : item?.currency ? item?.currency : ""} */}
                                {/* {item?.amount ? Number(item?.amountInUsdt).toFixed(4) : '0.00'} */}
                                {item?.amountInUsdt ? Number(item?.amountInUsdt).toFixed(4) : item?.amount ? Number(item?.amount).toFixed(4) : '0.00'}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-12 pt-3">
                    <div className="row gap-4 mt-4">
                      {/* pagination */}
                      <div className="col-12">
                        <ul className="pageNavigationSection mb-0 d-flex gap-2 list-unstyled align-items-center justify-content-center">
                          <li
                            className=" cursor-pointer bg-dark p-2"
                            onClick={() => {
                              if (pageNo < 2) return
                              setPageNo(pre => pre - 1)
                            }}
                          >
                            <span
                              className="text-decoration-none d-flex align-items-center justify-content-center pageNavgation"
                            >
                              <img
                                src="assets/img/newsApp/leftarro.svg"
                                alt="leftarro"
                                className="img-fluid w-100 h-100"
                              />
                            </span>
                          </li>
                          {
                            pagination.btnOne > 1 ? <li className="bg-dark p-2 text-light cursor-pointer" onClick={() => {
                              setIsLoadMore(true)
                              setPageNo(1)
                            }}>
                              <span className={`text-decoration-none d-flex align-items-center justify-content-center pageNavgation`}>
                                1
                              </span>
                            </li> : ''
                          }
                          {
                            pagination.btnOne > 2 ?
                              <li className=" cursor-pointer bg-dark p-2 text-light">
                                <span className="text-decoration-none d-flex align-items-center justify-content-center pageNavgation ">
                                  <img src="assets/img/newsApp/Menu.svg" alt="menu" className="img-fluid w-100 h-100" />
                                </span>
                              </li> : ''
                          }
                          <li className=" cursor-pointer bg-dark p-2 text-light" onClick={() => setPageNo(pagination.btnOne)} >
                            <span className={`text-decoration-none ${pageNo == pagination.btnOne ? "active" : ''}  d-flex align-items-center justify-content-center pageNavgation`}>
                              {pagination.btnOne}
                            </span>
                          </li>
                          {
                            totalPages <= pagination.btnTwo - 1 ? '' : <li onClick={() => setPageNo(pagination.btnTwo)} className=" bg-dark p-2 text-light cursor-pointer">
                              <span className={`text-decoration-none ${pageNo == pagination.btnTwo ? "active" : ''}  d-flex align-items-center justify-content-center pageNavgation`}>
                                {pagination.btnTwo}
                              </span>
                            </li>
                          }
                          {
                            totalPages <= pagination.btnThree - 1 ? '' : <li onClick={() => setPageNo(pagination.btnThree)} className="bg-dark p-2 text-light cursor-pointer ">
                              <span className={`text-decoration-none ${pageNo == pagination.btnThree ? "active" : ''}  d-flex align-items-center justify-content-center pageNavgation`}>
                                {pagination.btnThree}
                              </span>
                            </li>
                          }
                          {
                            totalPages >= pagination.btnThree + 2 ? <li className="bg-dark p-2 text-light cursor-pointer">
                              <span className="text-decoration-none d-flex align-items-center justify-content-center pageNavgation bg-transparent">
                                <img src="assets/img/newsApp/Menu.svg" alt="menu" className="img-fluid w-100 h-100" />
                              </span>
                            </li> : ''
                          }
                          {
                            pagination.btnThree == totalPages || pagination.btnTwo == totalPages || pagination.btnOne == totalPages ? '' :
                              <li className=" bg-dark p-2 text-light cursor-pointer" onClick={() => setPageNo(pagination.btnFive)}>
                                <span className={`text-decoration-none text-light ${pageNo == pagination.btnFive ? "active" : ''}  d-flex align-items-center justify-content-center pageNavgation`}>
                                  {pagination.btnFive}
                                </span>
                              </li>
                          }
                          <li
                            className="bg-dark p-2 text-light cursor-pointer bg-dark p-2"
                            onClick={() => {
                              if (pageNo > totalPages - 1) return
                              setPageNo(pre => pre + 1)
                            }}
                          >
                            <span
                              className="text-decoration-none d-flex align-items-center justify-content-center pageNavgation">
                              <img src="assets/img/newsApp/rightarro.svg" alt="leftarro" className="img-fluid w-100 h-100" />
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
                :                 <>
                <div className="py-5 text-white text-center">
                  No Data Available
                </div>
              </>
              : (<div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="light" size="lg" />
              </div>)
            }
    </>
  )
}

export default DepositTable