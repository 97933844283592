import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES_CONST } from "../../constants/routeConstant";
import { useWalletModal } from "../../hooks/useWallet";
import { useSelector } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { APP_NAME, AUTH_MODAL_TABS, WALLET_MODAL_TABS } from "../../constants";
import { useAuthModal } from "../../hooks/useAuthModal";
import { useDownloadModal } from "../../hooks/useDownload";
import { socket } from "../../service/socket";
import { useTranslation } from "react-i18next";
import { useResultModal } from "../../hooks/useResult";

const AppFooter = () => {
  const { setWalletModalObject } = useWalletModal()
  const { token } = useAuth()
  const { setAuthModalObject } = useAuthModal();
  const navigate = useNavigate()
  const { t } = useTranslation()
  const footerLabel = t("appFooter", { returnObjects: true })
  const { setResultObject } = useResultModal()
  const [query, setQuery] = useSearchParams()

  const sideAppHandler = () => {
    const exists = document.body.classList

    if (exists["value"] === "asideBar") {
      document.body.classList.remove("asideBar")
    } else {
      document.body.classList.add("asideBar")
    }
  }

  const handleLiveSupport = () => {
    const btn = document.getElementById('zsiq_float')
    if (btn) {
      btn.click()
    }
  }

  const footerPaymentImages = [
    { id: 'fi1', image: 'assets/img/sidebar/visa.png' },
    { id: 'fi2', image: 'assets/img/sidebar/dotspay.png' },
    { id: 'fi3', image: 'assets/img/sidebar/gpay.png' },
    { id: 'fi4', image: 'assets/img/sidebar/applepay.png' },
    { id: 'fi5', image: 'assets/img/sidebar/paypal.png' }
  ]

  const socialLinks = [
    {
      url: '',
      icon: 'ft1'
    },
    {
      url: '',
      icon: 'ft2'
    },
    // {
    //   url: '',
    //   icon: 'f3'
    // },
    {
      url: '',
      icon: 'ft3'
    },
    // {
    //   url: '',
    //   icon: 'f5'
    // },
    // {
    //   url: '',
    //   icon: 'f6'
    // },
  ]


  useEffect(() => {
    function xorEncrypt(message, secret) {
      let encrypted = "";
      for (let i = 0; i < message.length; i++) {
        encrypted += String.fromCharCode(message.charCodeAt(i) ^ secret.charCodeAt(i % secret.length));
      }
      const data = btoa(encrypted); // Base64 encode the encrypted message
      console.log('Encoded Message', data)
    }
    const secret = 'shindakimfalme_secret';
    const message = '6679468b38026ed2c55edd53';
    xorEncrypt(message, secret)
  }, [])





  return (
    <>
      <div className="footerSection newFooter">
        <div className="container-fluid py-5 newFooter px-4 px-lg-5">
          <div className="containerC py-2">
            <div className="row gy-4">
              <div className="col-12 col-sm-6 col-lg-4">
                <div className="brandSide">
                  <div className="logoImage">
                    <Link>
                      <img class="image h-100 w-100" src="assets/img/appLogo.png" alt="logo" style={{marginLeft: '-15px'}}></img>
                    </Link>
                  </div>
                  <div className="brandDescription">
                  Memestop is a brand name of Dream igaming Tech N.V. Company Address:  Zuikertuintjeweg Z/N (Zuikertuin Tower), Curaçao. Memestop is authorised to operate by the Curacao Gaming Control Board under its valid Certificate of Operation until the process of the application has concluded.                  </div>
                  <div className="d-flex align-items-center gap-3">
                    <div className="warningImg">
                      <img class="h-100 w-100" src="assets/img/eighteenPlus.png" alt=""></img>
                    </div>
                    <div className="warningText">
                      Responsible  Gambling
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 col-lg-2">
                <div className="UsefulLinks">
                  <div className="head">
                    Useful Link
                  </div>
                  <ul className="menuLinks">
                    <li>
                      <Link to={ROUTES_CONST.REFER_TO_EARN} className="footerAnc">
                        Affiliate  Program
                      </Link>
                    </li>
                    <li>
                      <Link to={'/responsible-gambling'} className="footerAnc">
                        Responsible Gaming
                      </Link>
                    </li>
                    <li>
                      <Link className="footerAnc">
                        Support
                      </Link>
                    </li>
                    <li>
                      <Link to={'/sports-policy'}  className="footerAnc">
                        Sports Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="col-12 col-sm-6 col-md-3 col-lg-2">
                <div className="UsefulLinks">
                  <div className="head">
                    Support
                  </div>
                  <ul className="supportList">
                    <li>
                      <div className="heading-text">
                        For Support
                      </div>
                      <Link className="footerAnc">
                        support@memestop.win
                      </Link>
                    </li>
                    <li>
                      <div className="heading-text">
                        For Sponsorships
                      </div>
                      <Link className="footerAnc">
                        pr@memestop.win
                      </Link>
                    </li>
                    <li>
                      <div className="heading-text">
                        For REFER TO EARNs
                      </div>
                      <Link className="footerAnc">
                        REFER TO EARN@memestop.win
                      </Link>
                    </li>
                  </ul>
                </div>
              </div> */}
              <div className="col-12 col-sm-6 col-md-12 col-lg-6">
                <div className="footerCoinsWrapper">
                  <div className="footerCoins">
                    <img className="h-100 w-100" src="assets/img/ReFooter/1.png" alt="" />
                  </div>
                  <div className="footerCoins">
                    <img className="h-100 w-100" src="assets/img/ReFooter/2.png" alt="" />
                  </div>
                  <div className="footerCoins">
                    <img className="h-100 w-100" src="assets/img/ReFooter/3.png" alt="" />
                  </div>
                  <div className="footerCoins">
                    <img className="h-100 w-100" src="assets/img/ReFooter/4.png" alt="" />
                  </div>
                  <div className="footerCoins">
                    <img className="h-100 w-100" src="assets/img/ReFooter/5.png" alt="" />
                  </div>
                  <div className="footerCoins">
                    <img className="h-100 w-100" src="assets/img/ReFooter/6.png" alt="" />
                  </div>
                  <div className="footerCoins">
                    <img className="h-100 w-100" src="assets/img/ReFooter/7.png" alt="" />
                  </div>
                  <div className="footerCoins">
                    <img className="h-100 w-100" src="assets/img/ReFooter/8.png" alt="" />
                  </div>
                  <div className="footerCoins">
                    <img className="h-100 w-100" src="assets/img/ReFooter/9.png" alt="" />
                  </div>
                  <div className="footerCoins">
                    <img className="h-100 w-100" src="assets/img/ReFooter/10.png" alt="" />
                  </div>
                  <div className="footerCoins">
                    <img className="h-100 w-100" src="assets/img/ReFooter/11.png" alt="" />
                  </div>
                  <div className="footerCoins">
                    <img className="h-100 w-100" src="assets/img/ReFooter/12.png" alt="" />
                  </div>
                  <div className="footerCoins">
                    <img className="h-100 w-100" src="assets/img/ReFooter/13.png" alt="" />
                  </div>
                  <div className="footerCoins">
                    <img className="h-100 w-100" src="assets/img/ReFooter/14.png" alt="" />
                  </div>
                  <div className="footerCoins">
                    <img className="h-100 w-100" src="assets/img/ReFooter/15.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="containerC">
            <div className="row justify-content-between align-items-center gy-4">
              <div className="col-auto">
                <ul className="socilaLinkswrapper">
                  {
                    socialLinks?.map((item, index)=> (
                      <>
                        <li>
                          <Link className="socialIcon">
                            <img className="h-100 w-100" src={`assets/img/${item?.icon}.png`} alt="" />
                          </Link>
                        </li>
                      </>
                    ))
                  }
                </ul>
              </div>
              {/* <div className="col-auto">
                <div className="bitExchnage">
                  <div className="bitCoin">
                    <img className="h-100 w-100" src="assets/img/ReFooter/bitCoin.png" alt="" />
                  </div>
                  <div className="bitValue">
                    1 BTC=$59,248.00
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <div className="paymentWrapper">
                  <div className="paymentGateways">
                    <img className="h-100 w-100 object-fit-contain" src="assets/img/ReFooter/visa.png" alt="" />
                  </div>
                  <div className="paymentGateways">
                    <img className="h-100 w-100 object-fit-contain" src="assets/img/ReFooter/gpay.png" alt="" />
                  </div>
                  <div className="paymentGateways">
                    <img className="h-100 w-100 object-fit-contain" src="assets/img/ReFooter/mastercard.png" alt="" />
                  </div>
                  <div className="paymentGateways">
                    <img className="h-100 w-100 object-fit-contain" src="assets/img/ReFooter/applePay.png" alt="" />
                  </div>
                  <div className="paymentGateways">
                    <img className="h-100 w-100 object-fit-contain" src="assets/img/ReFooter/paypal.png" alt="" />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="borderDiv"></div>
          <div className="containerC">
            <div className="row justify-content-center justify-content-md-between gy-2 gy-lg-4">
              <div className="col-auto">
                <div className="copyrightText">
                  Copyright © 2024 MemeStop. All rights reserved.
                </div>
              </div>
              <div className="col-auto">
                <div className="row gap-2">
                  <div className="col-auto">
                    <Link to={'/terms-and-conditions'} className="copyrightText">
                      Terms of Service
                    </Link>
                  </div>
                  <div className="col-auto">
                    <Link className="copyrightText">
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="borderDiv"></div>
        </div>
      </div>
            {/* mobile bottom bar start  */}
      <div className="container-fluid bottomBarFluid d-xl-none d-block">
        <div className="row">
          <div className="col-12 px-0">
            <div className="bottomBar">
              <div className=" row justify-content-around align-items-center mx-0 gx-0 h-100">
                <div className="col-auto"
                  onClick={sideAppHandler}
                >
                  <span className="bottomAnchor toggleButton">
                    <div className="iconParent">
                      <img
                        src="assets/img/givewayImg/allGamesMenu.png"
                        alt="menu" className="h-100 w-100" />
                    </div>
                    Menu
                  </span>
                </div>
                {
                  token ? (
                    <>
                      {/* <div className="col-auto" onClick={() => navigate(ROUTES_CONST.ACCOUNT)}>
                        <span className="bottomAnchor">
                          <div className="iconParent">
                            <img
                              src="assets/img/header/user.svg"
                              alt="" className="w-100 h-100" /></div>
                        </span>
                      </div> */}
                      <div className="col-auto">
                        <span className="bottomAnchor"
                          onClick={() => {
                            setWalletModalObject((prev) => ({
                              isWalletOpen: true,
                              selectedTab: WALLET_MODAL_TABS.DEPOSIT
                            }))
                            setQuery({ action: "wallet" })
                          }}>
                          <div className="iconParent">
                            <img
                              src="assets/img/givewayImg/walletVector.png"
                              alt="" className="w-100 h-100" /></div>
                              Wallet
                        </span>
                      </div>
                      {/* <div className="col-auto" onClick={() => {
                        setResultObject(pre => ({
                          ...pre,
                          isResultOpen: true
                        }))

                      }}>
                        <span
                          className="bottomAnchor">
                          <div className="iconParent">
                            <img
                              src="assets/img/header/search.svg"
                              alt=""
                              className="img-fluid w-100 h-100" />
                          </div>
                        </span>
                      </div> */}
                    </>
                  ) : (
                    // <div className="col-auto" onClick={() => {
                    //   navigate(ROUTES_CONST.SLOT_PAGE)
                    // }}>
                    //   <span
                    //     className="bottomAnchor">
                    //     <div className="iconParent">
                    //       <img
                    //         src="assets/img/app/promotions.svg"
                    //         style={{filter: 'brightness(100)'}}
                    //         alt=""
                    //         className="img-fluid w-100 h-100" />
                    //     </div>
                    //     Promotion
                    //   </span>
                    // </div>
                          <div className="col-auto" onClick={() => {
                        setResultObject(pre => ({
                          ...pre,
                          isResultOpen: true
                        }))

                      }}>
                        <span
                          className="bottomAnchor">
                          <div className="iconParent">
                            <img
                              src="assets/img/header/search.svg"
                              alt=""
                              className="img-fluid w-100 h-100" />
                          </div>
                          Search
                        </span>
                      </div> 
                  )
                }
                  <div className="col-auto" onClick={() => {
                      navigate(ROUTES_CONST.GAME_PAGE)
                    }}>
                      <span
                        className="bottomAnchor">
                        <div className="iconParent">
                          <img
                            src="assets/img/gameCasino.png"
                            alt=""
                            className="img-fluid w-100 h-100" />
                        </div>
                        Casino
                      </span>
                    </div>
                    <div className="col-auto" onClick={() => {
                      navigate(ROUTES_CONST.SPORTS)
                    }}>
                      <span
                        className="bottomAnchor">
                        <div className="iconParent">
                          <img
                            src="assets/img/gameSports.png"
                            alt=""
                            className="img-fluid w-100 h-100" />
                        </div>
                        Sports
                      </span>
                    </div>


                <div className="col-auto">
                  <span
                    className="bottomAnchor"
                    data-bs-toggle="offcanvas"
                    href="#message"
                    role="button"
                    aria-controls="message"
                    onClick={() => {
                      socket.emit('joinRoom', { msg: null })
                      document.body.classList.toggle('showMsgOffcanvas')
                    }}
                  >
                    <div className="iconParent">
                      <img
                        src="assets/img/header/chat.svg"
                        alt=""
                        className="img-fluid w-100 h-100" />
                    </div>
                    Chat
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppFooter;
