import axios from "axios"
import { getAPIAuth } from "./apiInstance"
import { TOKEN_NAME, baseURL } from "../constants"
import { signOut } from "firebase/auth";
import { auth } from "../config/firebase";

export const fetchUserDetails = async (tokenInit) => {
    try {
        const res = await axios.get(`${baseURL}/user/full-details`, {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Accept: "application/json",
                Authorization: `Bearer ${tokenInit}`,
            }
        })
        if (res.data.success) {
            return res
        }

    } catch (error) {
           // return  error
    if (error?.response?.data?.msg === "Invalid token") {
        localStorage.removeItem(TOKEN_NAME);
        signOut(auth)
          .then(() => {
            // succesToaster("Logged Out")
          })
          .catch((error) => {
            // An error happened.
          });
        window.location.reload(true);
      }
      throw new Error(error);
        // throw new Error(error)
        // throw new Error(error)
    }
}
