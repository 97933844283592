import React, { useEffect, useState } from 'react'
import { Offcanvas } from 'react-bootstrap';
import './style.scss';
import System from './component/System';
import Activities from './component/Activities';
import { useNotification } from '../../hooks/useNotification';
import { useAuth } from '../../hooks/useAuth'
import { getAPIAuth, postAPIAuth } from '../../service/apiInstance'
import { useTranslation } from 'react-i18next';

const NotificationOffcanvas = () => {
    const { isNotificationOpen, setIsNotificationOpen } = useNotification()
    const handleClose = () => setIsNotificationOpen(false);
    const [activeTab, setActiveTab] = useState('system')
    const { token } = useAuth()
    const [notification, setNotification] = useState([])
    const [perPage, setPerPage] = useState(2)
    const [pageNo, setPageNo] = useState(1)
    const { t } = useTranslation()
    const notificationModal = t("notificationModal", { returnObjects: true })

    // const getNotification = async () => {
    //     try {
    //         const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws"
    //         const res = await getAPIAuth(`user/get-global-notification?page=${pageNo}&perPage=${perPage}&notificationType=${activeTab === 'activities' ? 'activity' : 'system'}`, token)
    //         if (res?.data?.success) {
    //             setNotification(res.data?.data)
    //         }
    //     } catch (error) {
    //     }
    // }

    // const loadMore = async () => {
    //     try {
    //         const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws"
    //         const res = await getAPIAuth(`user/get-global-notification?page=${pageNo}&perPage=${perPage}&notificationType=${activeTab === 'activities' ? 'activity' : 'system'}`, token)
    //         if (res?.data?.success) {
    //             setNotification(...notification, ...res.data?.data)
    //             setPageNo(pageNo + 1)
    //         }
    //     } catch (error) {
    //     }
    // }

    // useEffect(() => {
    //     if (token && isNotificationOpen) {
    //         getNotification()
    //     }
    // }, [token, activeTab, isNotificationOpen])

    const notificationRead = async () => {
        try {
            const payload = {
                status: true
            }
            // const res = await postAPIAuth('user/markRead', payload, token)
            // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws"
            const res = await getAPIAuth('user/markRead', token)
        } catch (error) {

        }
    }
    useEffect(() => {
        if (isNotificationOpen) {
            notificationRead()
        }
    }, [isNotificationOpen])
    return (
        <>
            <Offcanvas
                className='offcanvasWrapper'
                show={isNotificationOpen}
                placement={'end'}
                onHide={handleClose}
            >
                <Offcanvas.Header className='offcanvasHeader'>
                    <Offcanvas.Title className='offcanvasTitle'>
                        {/* Notification */}
                          {notificationModal.Notification}
                    </Offcanvas.Title>
                    <div
                        className="offcanvasclose cursor-pointer"
                        onClick={handleClose}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                            <path d="M9.81006 1.70419C10.0855 1.42872 10.0855 0.982082 9.81006 0.70661C9.5346 0.43113 9.08794 0.43113 8.81248 0.70661L5.00834 4.51076L1.20419 0.70661C0.928719 0.43113 0.482082 0.43113 0.20661 0.70661C-0.0688699 0.982082 -0.0688699 1.42872 0.20661 1.70419L4.01076 5.50834L0.20661 9.31248C-0.0688699 9.58794 -0.0688699 10.0346 0.20661 10.3101C0.482082 10.5855 0.928719 10.5855 1.20419 10.3101L5.00834 6.50591L8.81248 10.3101C9.08794 10.5855 9.5346 10.5855 9.81006 10.3101C10.0855 10.0346 10.0855 9.58794 9.81006 9.31248L6.00591 5.50834L9.81006 1.70419Z" fill="white" />
                        </svg>
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body className='offcanvasBody'>
                    <div className="row flex-column h-100 flex-nowrap">
                        <div className="col-12 mb-3">
                            <div className="row mx-0 offcanvasmain">
                                <div className="col-6 px-0">
                                    <div className={`offcanvasBtns ${activeTab === 'system' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('system')}>{notificationModal.System_Notice}</div>
                                </div>
                                <div className="col-6 pe-0">
                                    <div className={`offcanvasBtns ${activeTab === 'activities' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('activities')}>{notificationModal.Activities}</div>
                                </div>
                            </div>
                        </div>
                        {
                            activeTab === 'system' ? <System /> :
                            activeTab === 'activities' ? <Activities/> : ''
                        }
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default NotificationOffcanvas