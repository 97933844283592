export const calculateDays = (date)=> {
  const targetDate = new Date(date);
  const now = new Date();
  const difference = targetDate - now;
  const days = Math.abs(Math.floor(difference / (1000 * 60 * 60 * 24)));
  const hours = Math.abs(Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
  const minutes = Math.abs(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)));
  const seconds = Math.abs(Math.floor((difference % (1000 * 60)) / 1000));
  // const timeDiffString = `${days} D, ${hours} H, ${minutes} M, ${seconds} S`;
  const timeDiffString = `${seconds} S`;
  return timeDiffString
}