import React, { useEffect, useState } from 'react'
import { getAPIAuth, postAPIAuth } from '../../../service/apiInstance'
import { Spinner } from 'react-bootstrap'
import { useWalletModal } from '../../../hooks/useWallet'
import { useSelector } from 'react-redux'
import { errorToaster } from '../../../utils/toaster'

const RublePay = () => {
  const [amount, setAmount] = useState('')
  const [coin, setCoin] = useState('usdt')
  const [error, setError] = useState({
    amount: ''
  })
  const [data, setData] = useState([])
  const [showIframe, setShowIframe] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [cryptoOpen, setCryptoOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState()
  const [currencyList, setCurrencyList] = useState([])
  const { walletModalObject, setWalletModalObject } = useWalletModal()
  const rubleAmount = useSelector(state => state?.rubleAmount)
  console.log({ rubleAmount })


  useEffect(() => {
    if (rubleAmount) setAmount(`${rubleAmount}`)
  }, [rubleAmount])

  const handleClick = async () => {
    if (amount?.length) {
      setIsLoading(true)
      setError(prev => ({
        ...prev,
        amount: ''
      }))
      const body = {
        fiat: selectedCurrency?.name,
        fiat_amount: amount,
        redirect_url: "url will come here"
      }
      try {
        const res = await postAPIAuth('/depositByPlay2pay', body)
        if (res?.data?.status) {
          setData(res?.data?.data)
          // window.open(res?.data?.data?.payment_url)
          // window.location.href = res?.data?.data?.payment_url
          setWalletModalObject(pre => ({
            ...pre,
            isWalletOpen: false
          }))
          // setShowIframe(true)
        }else{
          errorToaster(res.data?.message)
        }
      } catch (error) {
        console.log('ruble error', error)
        errorToaster("Something went wrong")
      } finally {
        setIsLoading(false)
      }
    } else {
      setError(prev => ({
        ...prev,
        amount: 'Please Enter Amount'
      }))
    }
  }

  const getCurrencyList = async () => {
    try {
      const res = await getAPIAuth('fetchCurrenciesForPay2play')
      if (res?.data?.status) {
        setCurrencyList(res?.data?.data)
        setSelectedCurrency(res?.data?.data?.[0])
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    getCurrencyList()
  }, [])

  console.log('currencyList', currencyList)

  return (
    <>
      {
        !showIframe ? <>
          <div className="rublePayBoxWrapper">
            <div className="cryptoHeading">Select Currency</div>
            <div className="dropdown btcdropDown pt-1 w-100 mb-3">
              {/* selected crypto */}
              <button
                onClick={() =>
                  setCryptoOpen(!cryptoOpen)
                }
                className="btn dropdown-toggle dropDownBtn position-relative d-flex align-items-center w-100 pe-4">
                {/* <span className="bitcoinIcon me-3 d-flex align-items-center justify-content-center rounded-circle overflow-hidden">
                <img src={selectedCurrency?.icon} alt="Bitcoin" className="h-100 w-100 img-fluid" />
              </span> */}
                <div className="d-flex justify-content-between w-100 pe-2">
                  <span>{selectedCurrency?.name}</span>

                  <span>
                    {/* {selCryptoNetwork?.crypto?.chain} */}
                  </span>
                </div>
              </button>
              <ul className={`dropdown-menu btcdropmenu w-100 ${cryptoOpen ? 'show' : ''}`}>
                {/* crypto  search box */}
                {/* <li className="">
                <div className="position-relative dropSearch">
                  <img className="searchIcon" src="assets/img/aboutJonathan/search.png" alt="search Icon" />
                  <input type="text" className="form-control bg-transparent shadow-none searchInput"
                    value={search.crypto}
                    ref={cryptoRef}
                    onChange={(e) =>
                      setSearch((prev) => ({
                        ...prev,
                        ["crypto"]: e.target.value,
                      }))} 
                    />
                </div>
              </li> */}
                {currencyList?.map(
                  (elem) => (
                    <>
                      <li
                        key={elem?.icon}
                        className='my-1'
                        onClick={() => {
                          setSelectedCurrency(elem)
                          setCryptoOpen(false)
                        }}
                      >
                        <div className={`dropdown-item btcInner d-flex align-items-center ${selectedCurrency?.icon === elem?.icon ? "active" : ""
                          }`}>
                          {/* <span className="selectIcon me-3 d-flex align-items-center justify-content-center rounded-circle overflow-hidden">
                          <img src={elem?.icon} alt={elem?.code} className="h-100 w-100 img-fluid" />
                        </span> */}
                          <div className="d-flex justify-content-between w-100">
                            <span>{elem?.name}</span>
                          </div>
                        </div>
                      </li>
                    </>
                  )
                )}
              </ul>
            </div>
            <div className="rublePayBox">
              <div className="title">Amount</div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="rubleInput">
                  <input
                    className='form-control shadow-none'
                    placeholder='Enter Amount'
                    type="text"
                    value={amount}
                    onChange={e => {
                      if (isNaN(Number(e.target.value))) return
                      setAmount(e.target.value)
                    }}
                  />
                </div>
                <div className="rubleDropdown">
                  <div className="dropdownHeader">
                    <img src="https://cdn.onramper.com/icons/crypto/usdt_tron.png" alt="" />
                    usdt
                  </div>
                </div>
              </div>
            </div>
            {
              error?.amount ?
                <div className='rubleError'>{error?.amount}</div>
                : ''
            }
            <button onClick={handleClick} className="rublePayBtn">
              {
                isLoading ? <Spinner size="sm" color="dark" />
                  : 'Pay Now'
              }
            </button>
          </div>
        </> : <>

          <div className="rubleIframeWrapper">
            <iframe
              id="myIframe"
              className='w-100 h-100'
              src={data?.payment_url}
              title='ruble pay'
              frameborder="0"></iframe>
          </div>
        </>
      }
    </>
  )
}

export default RublePay