import React from 'react'
import Modal from 'react-bootstrap/Modal';

const OnekRace = ({
    isOpen,
    setIsOpen,
    isRaceStats,
    setIsRaceStats
}) => {
    return (
        <>
            <Modal className='modalSection' centered show={isOpen} onHide={() => setIsOpen(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        DBL Rakeback
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='dblrackback'>
                                <div className='watchImg mx-auto'>
                                    <img src="/assets/img/bouns/watch.png" alt="" className='w-100 h-100' />
                                </div>
                                <div className='timerSection'>
                                    <div className='timerBox'>
                                        <div className='timerValue'>0</div>
                                        <div className='timerName'>Day</div>
                                    </div>
                                    <div className='timerBox'>
                                        <div className='timerValue'>3</div>
                                        <div className='timerName'>Hour</div>
                                    </div>
                                    <div className='timerBox'>
                                        <div className='timerValue'>44</div>
                                        <div className='timerName'>Min</div>
                                    </div>
                                    <div className='timerBox'>
                                        <div className='timerValue'>46</div>
                                        <div className='timerName'>Sec</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='positionCard'>
                                <div className='row'>
                                    <div className='col-4 borderRight'>
                                        <div className='positionHeading'>Your Position</div>
                                        <div className='positionValue'>-</div>
                                    </div>
                                    <div className='col-4 borderRight'>
                                        <div className='positionHeading'>Your Current Prize</div>
                                        <div className='positionValue'>$0.00</div>
                                    </div>
                                    <div className='col-4 '>
                                        <div className='positionHeading'>Your Wagered </div>
                                        <div className='positionValue'>$0.00</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='heaidngoneRace py-4'>Join Memestop 24-hour $1,000 daily race! For every bet placed, sports or casino, you’ll climb passed other racers in the top 10 positions increasing your prize the higher you go! Upon completion of Stake races all prizes will be instantly paid out to your balance in the equivalent BTC value!</div>
                        </div>
                        <div className='col-12'>
                            <button className='bounesHistory' onClick={() => setIsRaceStats(true)}>View Race Stats</button>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OnekRace