import React, { useEffect, useRef, useState } from "react";

const TimerComp = ({ endDate ,text }) => {
    const [timeLeft, setTimeLeft] = useState('-- ---- ---- --:--:--')
    const timerRef = useRef(null)

    const getRemainingDays = (date) => {
        const targetDate = new Date(date)
        const now = new Date();
        const difference = targetDate - now;
        const days = Math.abs(Math.floor(difference / (1000 * 60 * 60 * 24)));
        const hours = Math.abs(Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        const minutes = Math.abs(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)));
        const seconds = Math.abs(Math.floor((difference % (1000 * 60)) / 1000));
        const timeDiffString = `${days}d:${hours}h:${minutes}m:${seconds}s`;
        setTimeLeft(timeDiffString)
    }

    useEffect(() => {
        if (!!endDate) {
            if (timerRef.current) {
                clearInterval(timerRef.current)
            }
            timerRef.current = setInterval(() => {
                getRemainingDays(endDate)

            }, 500)
        }
        return () => {
            clearInterval(timerRef.current)
        }
    }, [endDate])

    return <div className="tymLft">{text} : <span> {timeLeft}</span></div>;
};

export default TimerComp;
