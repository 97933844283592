import React, { useEffect, useState } from "react";
import { getAPI } from "../../../service/apiInstance";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const ProviderSelect = ({ handleButtonClick, selectedProvider, setSelectedProvider }) => {
    const [providerList, setProviderList] = useState([]);
    const { t } = useTranslation()
    const allGamesLabels = t("allGamesLabels", { returnObjects: true })
    // Function for getting provider data
    const location = useLocation();
    const providerid = location ? location.state?.provider_id : "";
    const [search, setSearch] = useState("")
    const [filterData, setFilterData] = useState([])
    const resultModal = t("resultModal", { returnObjects: true })
    const getProvider = async () => {
        try {
            const result = await getAPI("crypto/get-provider");
            if (result?.data?.success) {
                setProviderList(result.data.data);
                setFilterData(result.data.data)
            } else {
                //
            }
        } catch (error) { }
    };

    // Function for adding Providers
    const changeHandler = (e, data) => {
        if (e.target.checked) {
            setSelectedProvider((pre) => [...pre, e.target.id]);
        } else {
            const filterData = selectedProvider.filter(
                (item) => item !== e.target.id
            );
            setSelectedProvider(filterData);
        }
    };
    useEffect(() => {
        getProvider();
    }, [providerid]);

    useEffect(() => {
        const providerfilterdata = providerList.filter((item) =>
            item._id?.toLowerCase().includes(providerid?.toLowerCase())
        );
        if (providerfilterdata) {
            setSelectedProvider(providerid ? [providerfilterdata[0]?._id] : []);
        }
    }, [providerid, providerList]);

    useEffect(() => {
        // search
        if (search) {
            const filter_data = providerList.filter(item => item._id?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase()))
            setFilterData(filter_data)
        }else{
            setFilterData(providerList)
        }
    }, [search])

    return (
        <div className="dropDown dropdown">
            <button 
            // onClick={handleButtonClick} 
            className=" dropBtn dropdown-toggle" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                {/* Provider : <span>{selectedProvider?.length}</span> */}
                {resultModal.Provider} : <span>{selectedProvider?.length}</span>
            </button>
            <div className="dropMenu dropdown-menu">
                <div className="row">
                    <div className="col-12">
                        <div className="dropHeader position-relative">
                            <input
                                className='dropSearch'
                                type="search"
                                // placeholder='Search here'
                                placeholder={resultModal?.Search_here}
                                name=""
                                id=""
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <div className="serachImg">
                                <img src="assets/img/heroBanner/searchh.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-2 bodyWrapper">
                        <div className="dropBody d-flex align-items-center"
                            onClick={() => setSelectedProvider([])}
                        >
                            <div className="form-check formCheck">
                                <div className="dropdownLabel d-flex align-items-center" >
                                    {/* <div className='dropdownTxt'>CLEAR ALL</div> */}
                                    <div className='dropdownTxt'>{resultModal.CLEAR_ALL}</div>
                                    <hr className="divider bg-light" />
                                </div>
                            </div>
                        </div>
                        {
                            filterData.map((item) => (
                                <div
                                    key={item._id}
                                    className="dropBody mt-2 d-flex align-items-center"
                                >
                                    <div className="form-check formCheck">
                                        <input
                                            className="dropdownInp  d-none"
                                            type="checkbox"
                                            name={`${item._id}`}
                                            id={`${item._id}`}
                                            onChange={(e) => {
                                                changeHandler(e, item);
                                            }}
                                            checked={selectedProvider.includes(item._id)}
                                        />
                                        <label className="dropdownLabel d-flex align-items-center"
                                            htmlFor={`${item._id}`}
                                        >
                                            <div className='dropdownCheckbox'></div>
                                            <div className='dropdownTxt'>{item._id}</div>
                                            <div className='dropdownValue ms-auto'>{item.count}</div>
                                        </label>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="col-12">
                        <div className="dropFooter"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ProviderSelect;

// <div className="col-6 d-block d-md-none ps-0">
//         <div className="dropDown">
//         <button onClick={handleButtonClick} className=" dropBtn" type="button">
//             Provider : <span>ALL</span>
//         </button>
//         <div className="dropMenu">
//             <div className="row">
//                 <div className="col-12">
//                     <div className="dropHeader position-relative">
//                         <input className='dropSearch' type="search" name="" id="" />
//                         <div className="serachImg">
//                             <img src="assets/img/heroBanner/searchh.png" alt="" />
//                         </div>
//                     </div>
//                 </div>
//                 <div className="col-12 mt-2 bodyWrapper">
//                     <div className="dropBody d-flex align-items-center">
//                         <div className="form-check formCheck">
//                             <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1" />
//                             <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault1">
//                                 <div className='dropdownCheckbox'></div>
//                                 <div className='dropdownTxt'>Provider</div>
//                                 <div className='dropdownValue ms-auto'>100</div>
//                             </label>
//                         </div>
//                     </div>
//                     <div className="dropBody mt-2 d-flex align-items-center">
//                         <div className="form-check formCheck">
//                             <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault11" id="flexRadioDefault11" />
//                             <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault11">
//                                 <div className='dropdownCheckbox'></div>
//                                 <div className='dropdownTxt'>Provider</div>
//                                 <div className='dropdownValue ms-auto'>100</div>
//                             </label>
//                         </div>
//                     </div>
//                     <div className="dropBody mt-2 d-flex align-items-center">
//                         <div className="form-check formCheck">
//                             <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault12" id="flexRadioDefault12" />
//                             <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault12">
//                                 <div className='dropdownCheckbox'></div>
//                                 <div className='dropdownTxt'>Provider</div>
//                                 <div className='dropdownValue ms-auto'>100</div>
//                             </label>
//                         </div>
//                     </div>
//                     <div className="dropBody mt-2 d-flex align-items-center">
//                         <div className="form-check formCheck">
//                             <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault12" id="flexRadioDefault12" />
//                             <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault12">
//                                 <div className='dropdownCheckbox'></div>
//                                 <div className='dropdownTxt'>Provider</div>
//                                 <div className='dropdownValue ms-auto'>100</div>
//                             </label>
//                         </div>
//                     </div>
//                     <div className="dropBody mt-2 d-flex align-items-center">
//                         <div className="form-check formCheck">
//                             <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault12" id="flexRadioDefault12" />
//                             <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault12">
//                                 <div className='dropdownCheckbox'></div>
//                                 <div className='dropdownTxt'>Provider</div>
//                                 <div className='dropdownValue ms-auto'>100</div>
//                             </label>
//                         </div>
//                     </div>
//                     <div className="dropBody mt-2 d-flex align-items-center">
//                         <div className="form-check formCheck">
//                             <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault12" id="flexRadioDefault12" />
//                             <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault12">
//                                 <div className='dropdownCheckbox'></div>
//                                 <div className='dropdownTxt'>Provider</div>
//                                 <div className='dropdownValue ms-auto'>100</div>
//                             </label>
//                         </div>
//                     </div>
//                     <div className="dropBody mt-2 d-flex align-items-center">
//                         <div className="form-check formCheck">
//                             <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault12" id="flexRadioDefault12" />
//                             <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault12">
//                                 <div className='dropdownCheckbox'></div>
//                                 <div className='dropdownTxt'>Provider</div>
//                                 <div className='dropdownValue ms-auto'>100</div>
//                             </label>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="col-12">
//                     <div className="dropFooter"></div>
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>
