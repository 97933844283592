import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import GamesCard from '../../common/GamesCard/GamesCard';
import { getAPIAuth, postAPIAuth } from '../../../service/apiInstance';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_SCREEN_WIDTH, PROFILE_OPTIONS } from '../../../constants';
import { useProfile } from '../../../hooks/useProfile';

const ViewProfile = () => {
  const userDetails = useSelector((state) => state.userDetails);
  const isMobile = useMediaQuery({ maxWidth: MOBILE_SCREEN_WIDTH })
  const [isLoading, setIsLoading] = useState(true)
  const [favGames, setFavGames] = useState([])
  const [allrankdata, setALLRankData] = useState([]);
  const {profileObject, setProfileObject} = useProfile()



  const FavGame = async () => {
    if (userDetails?.id) {
      setIsLoading(true);
      try {
        const res = await getAPIAuth(
          `crypto/get-fav-games?userId=${userDetails?.id}&is_mobile=${isMobile ? "true" : "false"}`
        );
        setFavGames(res.data.data);
      } catch (error) {
        //   errorToaster(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getAllRanks = async () => {
    try {
      const res = await getAPIAuth("user/get-ranking-list");
      setALLRankData(res?.data?.data);
    } catch (error) {

    }
  };


  useEffect(()=> {
    getAllRanks()
  }, [])

  useEffect(()=> {
    FavGame()
  }, [userDetails])

  const updateFavGame = async () => {
    try {
      const res = await getAPIAuth(
        `crypto/get-fav-games?userId=${userDetails?.id}&is_mobile=${isMobile ? "true" : "false"}`
      );
      setFavGames(res.data.data);
    } catch (error) {
      //   errorToaster(error.message);
    }
  };

  return (
    <>
      <div className="row flex-col position-relative mt-1">
        <div className="col-12 text-center">
          <div className="userImg mx-auto mb-2">
            <img className='h-100 w-100 object-fit-cover' src={userDetails?.image} alt="" />
          </div>
        </div>
        <div className="col-12 text-center userName">
          {userDetails?.username}
        </div>
        <div className="col-12 position-absolute w-100 top-0">
          <div className="row mx-0 g-0">
            <div className="col"></div>
            <div className="col-auto">
              <div className="editProfileBtn text-white cursor-pointer"
                onClick={()=> {
                  setProfileObject(prev =>({
                    ...prev,
                    activeTab: PROFILE_OPTIONS?.EDIT_PROFILE
                  }))
                }}
              >
                <svg style={{height: 18}} fill='white' viewBox="0 0 32 32"><path d="M20.6084 8.27708C21.3744 8.37616 22.0993 8.74507 22.7856 9.3814C23.4977 10.0419 23.8779 10.7757 23.927 11.582C23.9608 12.141 23.7522 12.6863 23.3535 13.0802L11.268 25.0416L5.94215 26.8813C5.60466 26.9981 5.23655 26.8193 5.11976 26.4818C5.07304 26.3449 5.07304 26.1967 5.11976 26.0589L6.95948 20.7331L18.9958 8.82079C19.4211 8.40113 20.0164 8.19976 20.6084 8.27708ZM24.6938 5.21061C25.6435 5.50381 26.399 6.22391 26.7389 7.15182L26.7913 7.30809C27.0966 8.29561 26.8316 9.37174 26.101 10.1039L25.9963 10.2086L25.8054 10.4003L25.5702 10.6355L25.4453 10.7588C25.3164 10.886 25.2415 10.9585 25.2206 10.9763C25.1167 11.0649 24.9516 11.0117 24.9443 10.8997C24.8767 9.82764 24.49 8.94403 23.7812 8.2497C23.0901 7.57229 22.2242 7.18002 21.1827 7.0745C21.0442 7.06 20.8162 7.00442 21.0506 6.75553C21.1287 6.67256 21.3374 6.46153 21.6773 6.12161L21.8972 5.90252C22.6285 5.17195 23.7047 4.90614 24.693 5.21142L24.6938 5.21061Z"></path></svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="staticsCard mt-3">
        <div className="row g-2">
          <div className="col-12 mainTitle py-1">Medals</div>
          <div className="col-12 hasBorder"></div>
          <div className="row gx-3 flex-nowrap overflow-x-auto ranksScrollar mt-2">
            {
              allrankdata?.length ? 
              allrankdata?.map(item =>(
                <div className={`col-auto rankImgCol ${item?.level == (userDetails?.nextlevel?.level - 1)  ? 'activeLevel' : ''}`} key={item?._id}>
                  <img className={`object-fit-contain rankImg`} style={{height: 60, width: 60}} src={item?.image} alt="" />
                </div>
              ))
              : ''
            }
          </div>
        </div>
      </div>
      <div className="staticsCard mt-3">
        <div className="row g-2">
          <div className="col-8 mainTitle py-1">Statistics</div>
          {/* <div className="col-4 text-end py-1">
            <div className="profileDetailBtn d-inline-flex">Details</div>
          </div> */}
          <div className="col-12 hasBorder"></div>
          <div className="col-6">
            <div className="staticCard">
              <div className="cardTitle">Total Wins</div>
              <div className="cardValue">{userDetails?.totalwon}</div>
            </div>
          </div>
          <div className="col-6">
          <div className="staticCard">
              <div className="cardTitle">Total Bets</div>
              <div className="cardValue">{userDetails?.totalPlayedGames}</div>
            </div>
          </div>
          <div className="col-12">
          <div className="staticCard">
              <div className="cardTitle">Total Wagered</div>
              <div className="cardValue">{userDetails?.wagerAmount}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="staticsCard mt-3">
        <div className="row g-2">
          <div className="col-12 mainTitle py-1">Favourite Games</div>
          <div className="col-12 hasBorder"></div>
          {
            favGames?.length ? 
              favGames?.slice(0, 4)?.map(item=> (
                <div className="col-3" key={item?.gameData?._id}>
                  <div className="toprated-games-main">
                    <div className="toprated-games">
                      <div className="games-card-main">
                        <div className="mySwiper">

                            <GamesCard
                                        key={item?.gameData?._id}
                                        RTP={item?.rtp}
                                        image={item?.gameData?.image}
                                        name={item?.gameData?.name}
                                        isFav={item?.gameData?.is_Fav}
                                        id={item?.gameData?._id}
                                        favButton={true}
                                        uuid={item?.gameData?.uuid}
                                        FavGame={updateFavGame}
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : ''
          }
        </div>
      </div>
    </>
  )
}

export default ViewProfile