import React, { useEffect } from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import TimerComponent from '../../newWallet/components/TimerComponent';
import { useSelector } from 'react-redux';
import { BONUS_DROPDOWN_OPTIONS, WALLET_MODAL_TABS } from '../../../constants'
import { useBonus } from '../../../hooks/useBonus';
import { useWalletModal } from '../../../hooks/useWallet';
import { getAPIAuth } from '../../../service/apiInstance';
import { useAuth } from '../../../hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES_CONST } from '../../../constants/routeConstant';

const ClaimBonusModal = () => {
  const userBonusDetail = useSelector(state=> state.userBonusDetail)
  const [show, setShow] = useState(true);
  const {setBonusObject} = useBonus();
  const {setWalletModalObject} = useWalletModal()
  const {token} = useAuth()
  const location = useLocation()
  const navigate = useNavigate()


  const handleClose = () => {
    setShow(false)
    if(location.search?.includes('freeSpinGame')) {
      updateFreeSpinPopupStatus()
    }
  } ;


  const updateFreeSpinPopupStatus = async ()=> {
      try {
        const res = getAPIAuth('user/updateFreeSpinStatus?type=freeSpinPopup', token);

      } catch (error) {
        
      }
  }




  return (
    <>
      <Modal className='claimBonusModal' centered show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="freeSpinTxt">FREE SPINS FOR REGISTRATION</div>
          <div className="claimBonusModalInner">
            <div className="row align-items-center mx-0">
              <div className="col-auto">
                <div className="spinAmount">100 Free Spins</div>
              </div>
              <div className="col bonusDetailTxt" onClick={()=> {
                        // navigate(ROUTES_CONST.VIP_CLUB)
                        setBonusObject(prev => ({
                          isBonusOpen: true,
                          selectedTab: BONUS_DROPDOWN_OPTIONS.BONUS_DETAIL
                      }))
              }}>Bonus Details</div>
              <div className="col-12 urBonusTxt">
                Your bonus
              </div>
              <div className="col-12 urBonusVal">
                <img src="assets/img/dollerCoin.png" alt="" />
                {userBonusDetail?.bonusData?.freeSpinAmount ? Number(userBonusDetail?.bonusData?.freeSpinAmount)?.toFixed(2)  : 0.00}
                <span> USD</span>
              </div>
              <div className="col-12 detailTxt">Make a deposit from $40 and start wagering to unlock the free spins $ {userBonusDetail?.bonusData?.freeSpinAmount ? Number(userBonusDetail?.bonusData?.freeSpinAmount)?.toFixed(2)  : 0.00} you won before it expires!</div>
              <div className="col-12">
                <div className="timeLeftBox">
                <TimerComponent isClaimBonusModal={true} targetTime={userBonusDetail?.bonusData?.spinTimmer}/>
                </div>
              </div>
              <div className="col-12">
                <button className='dpNowBtn'  
                    onClick={() => {
                      handleClose();
                      setWalletModalObject((prev) => ({
                        isWalletOpen: true,
                        selectedTab: WALLET_MODAL_TABS.DEPOSIT
                      }))
                    }}>Deposit now </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ClaimBonusModal