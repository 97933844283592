import { useContext } from "react"
import { SpinModalContext } from "../context/freeSpinModalContext"


export const useSpinModal = () => {
    const { spinModalObject, setSpinModalObject } = useContext(SpinModalContext)
    return {
      spinModalObject, 
      setSpinModalObject
    }
}
