import React, { useCallback, useEffect, useRef, useState } from "react";
import "./styles.scss"
import { useSpin } from "../../hooks/useSpin";
import { getAPI } from "../../service/apiInstance";
import spinPinImg from './../../assets/img/spinPin.webp'
import spinBtnImg from './../../assets/img/spinBtnImg.webp'
import leftCoinImg from './../../assets/img/coinLeft.webp'
import rightCoinImg from './../../assets/img/coinRight.webp'
import tapSpinImg from './../../assets/img/tapSpin.webp'
import spinPointerImg from './../../assets/img/spinPointer.png'
import spinAudio from './../../assets/audio/spinAudio.mpeg'
import { SPIN_MODAL_OPTIONS, SPIN_STRING, SPIN_TOKEN } from "../../constants";
import { useSearchParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import SkeletonLoader from "../skeletonLoader/SkeletonLoader";





const colorGenerator = () => {
    for (let i = 0; i < 10; i++) {
        const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
        // colors.push(randomColor);
        return randomColor
    }
}

const SpinWheelModal = () => {
    const { spinObject, setSpinObject } = useSpin()
    const [winner, setWinner] = useState()
    const [spinSection, setSpinSection] = useState([])
    const [noOfSection, setNoOfSection] = useState(0)
    const spinAudioTag = useRef()
    const [audio] = useState(new Audio(spinAudio))
    const [playing, setPlaying] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true)



    useEffect(() => {
        setNoOfSection(360 / (spinSection?.length))
    }, [spinSection])

    const getSection = async () => {
        setLoading(true)
        try {
            const res = await getAPI('get-damble-wheel')
            if (res.data.success) {
                setSpinSection(res?.data?.data)
                setWinner(res?.data?.index)
            }
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if(spinObject?.isSpinOpen) {
            getSection()
        }
    }, [spinObject?.isSpinOpen])

    useEffect(() => {
        if (spinSection?.length) {
            const prizes = spinSection

            const wheel = document.querySelector(".deal-wheel");
            const spinner = wheel.querySelector(".spinner");
            const trigger = wheel.querySelector(".btn-spin");
            const ticker = wheel.querySelector(".ticker");
            // const reaper = wheel.querySelector(".grim-reaper");
            const prizeSlice = 360 / prizes.length;
            const prizeOffset = Math.floor(180 / prizes.length);
            const spinClass = "is-spinning";
            const selectedClass = "selected";
            const spinnerStyles = window.getComputedStyle(spinner);
            let tickerAnim;
            let rotation = 0;
            let currentSlice = 0;
            let prizeNodes;
            const createPrizeNodes = () => {
                prizes.forEach(({ amount, background, image }, i) => {
                    const rotation = ((prizeSlice * i) * -1) - prizeOffset;
                    const leftPos = Math.abs(((prizeSlice * 0) * -1) - prizeOffset);
                    spinner.insertAdjacentHTML(
                        "beforeend",
                        `<li class="prize"  style="--rotate: ${rotation}deg">
                            <img src=${image} alt=""/>
                            <span class="text">${amount}</span>
                            <span class="dotSpan" style="--val: ${leftPos}deg; --clr: ${background}"></span>
                        </li>`
                    );
                });
            };

            const createConicGradient = () => {
                spinner.setAttribute(
                    "style",
                    `background: conic-gradient(
                        from -90deg,
                        ${prizes
                        .map(({ background }, i) => `${background} 0 ${(100 / prizes.length) * (prizes.length - i)}%`)
                        .reverse()
                    }
                    );`
                );
            };


            const setupWheel = () => {
                createConicGradient();
                createPrizeNodes();
                prizeNodes = wheel.querySelectorAll(".prize");
            };

            const spinertia = (min, max) => {

                min = Math.ceil(min);
                max = Math.floor(max);
                return (min + max) / 2
                // return 28
                // return Math.floor(Math.random() * (max - min + 1)) + min;
            };

            const runTickerAnimation = () => {
                // https://css-tricks.com/get-value-of-css-rotation-through-javascript/

                const values = spinnerStyles?.transform?.split("(")[1]?.split(")")[0]?.split(",");
                const a = values[0];
                const b = values[1];
                let rad = Math.atan2(b, a);

                if (rad < 0) rad += (2 * Math.PI);

                const angle = Math.round(rad * (180 / Math.PI));
                const slice = Math.floor(angle / prizeSlice);

                if (currentSlice !== slice) {
                    ticker.style.animation = "none";
                    setTimeout(() => ticker.style.animation = null, 10);
                    currentSlice = slice;
                }

                tickerAnim = requestAnimationFrame(runTickerAnimation);
            };

            const selectPrize = () => {
                const selected = Math.floor(rotation / prizeSlice);

                prizeNodes[selected].classList.add(selectedClass);
                // reaper.dataset.reaction = prizeNodes[selected].dataset.reaction;
            };

            trigger?.addEventListener("click", () => {
                // if (reaper.dataset.reaction !== "resting") {
                //   reaper.dataset.reaction = "resting";
                // }
                // spinAudioTag.current.play();
                audio.play()
                localStorage.setItem(SPIN_TOKEN, 'true')
                trigger.disabled = true;
                // rotation = Math.floor(Math.random() * 360 + spinertia(2000, 5000));
                // let spins = spinertia(2000, 5000) /// to add random rotations in spinner
                // let spins = spinertia(1, 39) /// to add random rotations in spinner
                // rotation = Math.floor(Math.random() * 360 + spinertia(2000, 5000));
                // rotation = Math.floor(Math.random() * 360 + spins);
                // rotation = 100 * 5
                const totalSections = prizes.length;
                const winnerIndex = winner + 1;
                const sliceSize = 360 / totalSections
                const desiredWinnerAngle = sliceSize * winnerIndex;
                /// if three section, 120 / section, 0-119 in 0 , 120-139  in 1, 140 - 359 in 3
                // const desiredWinnerAngle = (360 / totalSections) * (winnerIndex + 1);

                // rotation = desiredWinnerAngle + spinertia(2000, 5000);
                let spins = spinertia(sliceSize * winnerIndex - sliceSize, sliceSize * winnerIndex - 1) /// to add random rotations in spinner
                // let spins = 20

                const generateRandom = () => Math.round(Math.random() * 10)
                rotation = spins + (360 * 6) + (totalSections / 2)
                // rotation = 360
                // rotation = 239


                prizeNodes.forEach((prize) => prize.classList.remove(selectedClass));
                wheel.classList.add(spinClass);
                spinner.style.setProperty("--rotate", rotation);
                ticker.style.animation = "none";
                runTickerAnimation();
            });

            spinner.addEventListener("transitionend", () => {
                cancelAnimationFrame(tickerAnim);
                trigger.disabled = false;
                trigger.focus();
                rotation %= 360; //to keep the angel between 0 <--> 359
                selectPrize();
                wheel.classList.remove(spinClass);
                spinner.style.setProperty("--rotate", rotation);
                setSpinObject((prev=>({
                    ...prev,
                    selectedModal: SPIN_MODAL_OPTIONS.WINNER_MODAL,
                })))
            });

            setupWheel();
        }

    }, [spinSection])

    const claimBonus = () => {
        const isTrue = localStorage.getItem(SPIN_TOKEN)
        const encodedSN = btoa(JSON.stringify({ bonus: true }))

        if (!!isTrue) {
            setSearchParams({ bonus: encodedSN })
            localStorage.setItem(SPIN_STRING, encodedSN)
        }
    }
// sidebar m y search krke spinWheelModalOpen useeffect ko uncomment krna h

    return (
        <div
            className={`modal fade spinWheelModalOuter bankModal ${spinObject.isSpinOpen ? 'sho d-bloc' : ''}`}
            id="bankModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            {/* modal-fullscreen-sm-down */}
            <div className="modal-dialog spinDialog modal-dialog-centered modal-dialog-scrollable 
            
            ">
                <div className="modal-content">
                    <div className={`modal-body spinModalBody bankbody pb-4 ${"walletModalObject?.selectedTab === WALLET_MODAL_TABS?.BUY_CRYPTO " ? 'showBuyCrypto' : ''}`}>
                        <div className="tab-content banktabContent pt-3">
                            <div className="tab-pane fade show active">
                                <div className="spinHead mb-3 position-relative">
                                    <button type="button"
                                        onClick={() => setSpinObject((prev=>({
                                            ...prev,
                                            isSpinOpen: false,
                                        })))}
                                        className="p-0 shadow-none border-0 d-flex align-items-center justify-content-center closeSpinBtn">
                                        <img src="assets/img/sidebar/close.png" alt="close" className="h-100 w-100 img-fluid" />
                                    </button>
                                    <div className="spinWinTxt">
                                        SPIN TO WIN
                                        <img loading='lazy' src={leftCoinImg} className="leftImg" alt="" />
                                        <img loading='lazy' src={rightCoinImg} className="rightImg" alt="" />
                                    </div>
                                    <div className="spinBtcTxt">1 BTC</div>
                                </div>
                                <div className="overflow-hidden py-4">
                                    {
                                        spinSection?.length ? 
                                        <div className="deal-wheel">
                                            <div className="dealWheelInner1">
                                                <div className="dealWheelInner2">
                                                    <div className="dealWheelInner3">
                                                        <div className="dealWheelInner4">
                                                            <div className="dealWheelInner5">
                                                                <div className="dealWheelInner6">
                                                                    <div className="dealWheelInner7">
                                                                        <div className="dealWheelInner8">
                                                                            <ul className="spinner"></ul>
                                                                            <div className="ticker">
                                                                                <img src={spinPinImg} alt="" />
                                                                            </div>
                                                                            <button className="btn-spin cap">
                                                                                <img
                                                                                    style={{
                                                                                        objectFit: 'contain',
                                                                                        width: "100%",
                                                                                        height: "100%"
                                                                                    }}
                                                                                    className="spinBtnImg"
                                                                                    src={spinBtnImg}
                                                                                    alt=""
                                                                                />
                                                                                <img src={tapSpinImg} className="tapSpinImg" alt="" />
                                                                                {/* <img src={spinPointerImg} className="spinPointerImg" alt="" /> */}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="winnerSection" style={{ '--sizeeee': noOfSection + 'px' }}></div>
                                            <div className="a">
                                                <audio ref={spinAudioTag} src={spinAudio}></audio>
                                            </div>
                                        </div>
                                        : 
                                        <div className="d-flex align-items-center justify-content-center">
                                            <SkeletonLoader type={'spinWheel'}/>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer spinModalFooter">
                        <div className="row w-100 align-items-end">
                            <div className="col-12 d-flex align-items-center justify-content-center">
                                <div className="bonusTotal">SPIN BONUS TOTAL: </div>
                                <div className="bounsTtlPrz ms-3">32,223,242,34 $</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default SpinWheelModal;
