import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { postAPIAuth, postAPIAuthFormData } from '../../../service/apiInstance';
import { fetchUserDetails } from '../../../service/fetchUserDetails';
import { useAuth } from '../../../hooks/useAuth';
import { reducerConst } from '../../../constants/storeConstants';
import { t } from 'i18next';
import { errorToaster, succesToaster } from '../../../utils/toaster';



const EditProfile = () => {
  const userDetails = useSelector((state) => state.userDetails);
  const accountPageLabels = t("accountPageLabels", { returnObjects: true })
  const {token} = useAuth()
  const dispatch = useDispatch()
  const [userName, setUserName] = useState('')
  const [error, setError] = useState(false)
  const userId = userDetails?.id;
  const [image, setImage] = useState('')
  const [imagePreview, setImagePreview] = useState(null);
  const MAX_FILE_SIZE = 2 * 1024 * 1024;


  const handleProfilePic = async (e)=> {
    const fileObj = e.target.files && e.target.files[0]

    if(!fileObj) {
      return
    } else {
      if (fileObj.size > MAX_FILE_SIZE) {
        errorToaster("File size should be less than 2 MB");
        setImagePreview(null);
        return;
      }
      setImage(fileObj)
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Set the image preview data
      };
      reader.readAsDataURL(fileObj);
    }

    // const formData = new FormData()
    // formData.append('typename', 'profilePic')
    // formData.append('image', fileObj)

    // const res = await postAPIAuthFormData('user/update-profile-pic', formData)
    // if(res?.data?.success) {
    //   updateUserDetails();
    // }
  }

  useEffect(() => {
    setUserName(userDetails?.username)
  }, [userDetails])

  // const handleChangeUser = async () => {
  //   if (userName?.length >= 3) {
  //     setError(false)
  //     try {
  //       const res = await postAPIAuth('user/update-username', {
  //         userId,
  //         userName
  //       })
  //       if (res?.data?.success) {
  //         succesToaster('Name Changed Successfully')
  //         updateUserDetails()
          
  //       } else {
  //         errorToaster(res?.data?.message)
  //       }
  //     } catch (error) {
  //       errorToaster(error?.message)
  //     }
  //   } else {
  //     setError(true)
  //   }
  // }

  const handleChangeUser = async () => {
    if (userName?.length >= 3) {
      setError(false)
          const formData = new FormData()
          formData.append('typename', 'profilePic')
          formData.append('image', image)
          formData.append('userName', userName)

      try {
        const res = await postAPIAuthFormData('user/update-profile-pic', formData)
        if (res?.data?.success) {
          succesToaster('Profile Updated Successfully')
          updateUserDetails()
          
        } else {
          errorToaster(res?.data?.message)
        }
      } catch (error) {
        errorToaster(error?.message)
      }
    } else {
      setError(true)
    }
  }

  const updateUserDetails = async () => {
    const res = await fetchUserDetails(token);
    dispatch({
      type: reducerConst.USER_DETAILS,
      payload: res?.data?.data ? res.data.data : {},
    });
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-auto">
          <div className="m-auto position-relative userProfilePicWrapper">
                <div className="user">
                  {
                    imagePreview ?
                    <img src={imagePreview} alt="" /> 
                    :
                  userDetails?.image ? 
                    <img src={userDetails?.image} alt="" />
                  : <img src='assets/img/userProfile.webp' alt="" />
                  }
                  
                </div>
                <input type="file" className="d-none" onChange={handleProfilePic} id="userProfileImage" />
                <label htmlFor="userProfileImage" className="position-absolute userProfileLabelbtn cursor-pointer">
                  Edit Your Avatar
                </label>
              </div>
        </div>
        <div className="col-12">
          <div className="accountInput">
            <label htmlFor="username" className="accountLabel">
              Username
            </label>
            <div className="inputmain d-flex align-items-center flex-column">
              <input
                type="text"
                className="userNameInput form-control shadow-none border-0"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
                {error ? <div className="text-danger text-start w-100 mt-1 errorTxt">{accountPageLabels.Invalid_name}</div> : ''}
              <button
                className="handleSaveBtn d-flex border-0 align-items-center justify-content-center "
                onClick={handleChangeUser}
              >
                SAVE
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditProfile