import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const SortingComponent = ({sortBy, setSortBy}) => {
  const { t } = useTranslation()
  const allGamesLabels = t("allGamesLabels", { returnObjects: true })
  // const [showDropdown, setShowDropdown] = useState(false)

  return (
    <>
      
      <div className="roundedDropdown dropdown position-relative"
      //  onClick={()=>setShowDropdown(!showDropdown)}
       >
          <div className="roundedDropBtn text-capitalize" data-bs-toggle="dropdown" aria-expanded="false">
              {allGamesLabels.SORT_BY.toLowerCase()} : <span>{sortBy === '+1' ? 'A-Z' : sortBy === '-1' ? 'Z-A' : allGamesLabels.Select}</span>
              <img src="assets/img/options/downArrow.png" className='downArrow' alt="" />
          </div>
          {/* {
            showDropdown ? <> */}
            <div class="roundedDropMenu roundedDropMenu2 dropdown-menu">
                <div className="row">
                    <div className="col-12 mt-2 bodyWrapper">
                            <div onClick={() => setSortBy('+1')} className="dropBody mb-2 d-flex align-items-center">
                              <div class="form-check formCheck">
                                  <div class={`dropdownLabel d-flex align-items-center ${sortBy === '+1' ? 'active' : ''}`}>
                                      <div className='dropdownCheckbox'></div>
                                      <div className='dropdownTxt'>A-Z</div>
                                  </div>
                              </div>
                            </div>
                            <div onClick={() => setSortBy('-1')} className="dropBody mb-2 d-flex align-items-center">
                              <div class="form-check formCheck">
                                  <div class={`dropdownLabel d-flex align-items-center ${sortBy === '-1' ? 'active' : ''}`}>
                                      <div className='dropdownCheckbox'></div>
                                      <div className='dropdownTxt'>Z-A</div>
                                  </div>
                              </div>
                            </div>
                    </div>
                </div>
            </div>
            {/* </> : '' */}
          {/* } */}
      </div>
      
    </>
  )
}

export default SortingComponent