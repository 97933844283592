import React, { useEffect, useRef, useState, useTransition } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { getAPIAuth, postAPIAuth } from '../../../service/apiInstance';
import { useWalletModal } from '../../../hooks/useWallet';
import { WALLET_MODAL_TABS } from '../../../constants';
import { getCryptoListAction, selectCrypto } from '../../../store/action';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { errorToaster, succesToaster } from '../../../utils/toaster';
import { useTranslation } from 'react-i18next';

const Crypto = () => {
  const { token } = useAuth();
  const { walletModalObject } = useWalletModal()
  const cryptoList = useSelector(state => state.cryptoList)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [selectedCrypto, setSelectedCrypto] = useState({})
  const [address, setAddress] = useState("")
  const [amount, setAmount] = useState("")
  const userDetails = useSelector(state => state.userDetails)
  const userBonusDetail = useSelector(state => state.userBonusDetail)
  const [withdrawLoading, setWithdrawLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [availCrypto, setAvailCrypto] = useState([])
  const [filterData, setFilterData] = useState([])
  const [convertedPrice, setConvertedPrice] = useState(0.00)
  const timeoutRef = useRef()
  const { t } = useTranslation()
  const walletLabels = t("wallet", { returnObjects: true })
  const [allNetwork, setAllNewtWork] = useState([])
  const [selectedNetwork, setSelectedNetwork] = useState('')
  const [networkOpen, setNetworkOpen] = useState(false)
  const [networkFiltered, setNetworkFiltered] = useState([]);
  const [searchNetwork, setSearchNetwork] = useState('')
  const networkRef = useRef();
  const [userCurrencyBalance, setUserCurrencyBalance] = useState(0)

  // const [withdrawLoading, setWithdrawLoading] = useState(false)
  // const cryt
  const crypto = useSelector(state => state?.selectedCurrency?.crypto)
  console.log("userCurrencyBalance", userCurrencyBalance );

  console.log('crypto', crypto)
  useEffect(() => {
    if (crypto?._id) {
      setSelectedCrypto(crypto)
      setSelectedNetwork(crypto?.allNetworks?.[0])
    }
  }, [crypto?._id])

  // const userDetails = useSelector(state => state.userDetails)
  const getAvailableCrypto = async () => {
    try {
      const res = await getAPIAuth("crypto/get-crypt-favorite-by-admin")
      if (res.data.success) {
        // setAvailCrypto(res.data.data)
        const data = res?.data?.data?.filter(item => item?.code !== 'eth')
        setAvailCrypto(data)
        // setSelCryptoNetwork(res.data.data)
      }
    } catch (error) {
    }
  }

  //   function for filter network data
  const filterNetwork = () => {
    if (searchNetwork) {
      let filterData = allNetwork.filter((el) =>
        el.name
          .toUpperCase()
          .includes(searchNetwork?.toUpperCase())
      );
      setNetworkFiltered(filterData);
    } else {
      setNetworkFiltered(allNetwork);
    }
  };
  const getCrypto = async () => {
    if (!!search) {
      let filterDataArr = cryptoList.filter((el) => {
        return el.code.toUpperCase().includes(search.toUpperCase())
      });
      setFilterData(filterDataArr)
    } else {
      if (

        cryptoList.length === 0
      ) {
        try {
          const resp = await getAPIAuth("crypto/get-crypto", token);
          // setCrypto(resp?.data.data)
          const data = resp?.data?.data?.filter(item => item?.code !== 'eth')
          if (!crypto?._id) {
            setSelectedCrypto(data[0])
            setSelectedNetwork(data?.[0]?.allNetworks[0])
          } else {
            const cryptooo = data?.filter((item)=> item?.code === crypto?.code)
            console.log('cryptooo', cryptooo)
            setAllNewtWork(cryptooo?.[0]?.allNetworks)
            setNetworkFiltered(cryptooo?.[0]?.allNetworks)
          }
          setFilterData(data)

        } catch (error) {

        }
      } else if (

        cryptoList.length !== 0
      ) {
        const data = structuredClone(cryptoList)?.filter(item => item?.code !== 'eth')
        if (!crypto?._id) {
          setSelectedCrypto(data?.[0])
          setSelectedNetwork(data?.[0]?.allNetworks[0])
        }else {
          const cryptooo = data?.filter((item)=> item?.code === crypto?.code)
          setAllNewtWork(cryptooo?.[0]?.allNetworks)
          setNetworkFiltered(cryptooo?.[0]?.allNetworks)
        }
        // setAllNewtWork(data?.[0]?.allNetworks)
        // setNetworkFiltered(data?.[0]?.allNetworks)
        setFilterData(data)
      }
      setIsLoading(false)
    }
  }

  const onWithdraw = async () => {
    console.log('clicked')
    setWithdrawLoading(true)
    if (!address) {
      errorToaster("Address can't be empty!")
      setWithdrawLoading(false)
      return
    }
    if (!!!amount || amount === '') {
      errorToaster("Add a valid amount to withdraw!")
      setWithdrawLoading(false)
      return
    }
    // if (convertedPrice === 0 || convertedPrice < 0) {
    //     errorToaster("Converted Amount isn't Valid")
    //     setWithdrawLoading(false)
    //     return
    // }
    try {
      const body = {
        userId: userDetails.id,
        address: address,
        withdraw_amount: amount,
        coinId: selectedCrypto._id,
        user_currency: userDetails?.usercurrency,
        chain: 'eth',
        type: "withdraw",
        // convertedCurrency: convertedPrice,
        network: selectedNetwork?.name
      }

      // const res = await postAPIAuth("user/save-request-withdraw", body)
      const res = await postAPIAuth("coin/withdrawRequest", body)

      if (res.data.success) {
        succesToaster(res.data.message)
      } else {
        errorToaster(res.data.message)
      }
    } catch (error) {
      errorToaster("Something went wrong")
    } finally {
      setTimeout(() => {
        setWithdrawLoading(false)
      }, 500)
      setAddress('')
      setAmount("")
    }
  }
  useEffect(() => {
    getCrypto()
  }, [search])

  useEffect(() => {
    getAvailableCrypto()
  }, [])

  const priceConverter = async () => {

    const body = {
      // source: "eth",
      // target: "usdt",
      source: selectedCrypto.code,
      target: "usdt",
      amount: amount
    }
    try {
      const res = await postAPIAuth("convertPrice", body)
      if (res.data.success) {
        setConvertedPrice(res.data.data.amount)
      } else {
        setConvertedPrice(0.00)
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if (walletModalObject.isWalletOpen &&
      walletModalObject.selectedTab === WALLET_MODAL_TABS.WITHDRAW) {
      // debouncing
      if (!!amount) {
        setWithdrawLoading(true)
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = setTimeout(() => {
        if (!!amount) {
          // priceConverter()
        } else {
          if (!amount) setConvertedPrice(0.00)
        }
        setWithdrawLoading(false)
      }, 400)
    }

    return () => clearTimeout(timeoutRef.current)
  }, [amount, walletModalObject.selectedTab, walletModalObject.isWalletOpen, selectedCrypto])

  useEffect(() => {
    if (!!selectedCrypto?.allNetworks) {
      setAllNewtWork(selectedCrypto?.allNetworks)
      setNetworkFiltered(selectedCrypto?.allNetworks)
      setSelectedNetwork(selectedCrypto?.allNetworks[0])
    }
  }, [selectedCrypto])
  
  useEffect(() => {
    filterNetwork()
  }, [searchNetwork])

  const getUserCurrencyAmount = async ()=> {
    try {
        const res = await getAPIAuth(`crypto/crypto-wallet?cryptoId=${selectedCrypto?._id}`)
        // console.log('crypto', res)
        if(res?.data?.success) {
            setUserCurrencyBalance(res?.data?.data)
        }
    } catch (error) {
        
    }
}

useEffect(()=> {
  getUserCurrencyAmount()
}, [selectedCrypto?._id])
  
  return (
    <>
      <div className="withdrawBody">
        <div className="row gx-2">
          <div className="col-12 withTitle text-white">{walletLabels.Withdraw_currency}</div>
          <div className="col-12">
            <div class="dropdown btcdropDown pt-1 w-100">
              <button class="btn dropdown-toggle dropDownBtn position-relative d-flex align-items-center w-100 pe-4" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="bitcoinIcon me-3 d-flex align-items-center justify-content-center rounded-circle overflow-hidden">
                  <img alt="Bitcoin" class="h-100 w-100 img-fluid" src={selectedCrypto.icon} />
                </span>
                <div class="d-flex justify-content-between w-100 pe-2">
                  <span>{selectedCrypto.code}</span>
                  <span></span>
                </div>
              </button>
              <ul class="dropdown-menu btcdropmenu w-100">
                <li class="">
                  <div class="position-relative dropSearch">
                    <img class="searchIcon" src="assets/img/aboutJonathan/search.png" alt="search Icon" />
                    <input type="text" class="form-control bg-transparent shadow-none searchInput"
                      value={search}
                      onChange={(e) =>
                        setSearch(e.target.value)
                      }
                    />
                  </div>
                </li>
                {
                  isLoading ? (
                    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                      <Spinner animation="border" variant="light" size="sm" />
                    </div>
                  ) : (
                    filterData?.length === 0 ? (
                      <div className="w-100 h-100">
                        <h6 className="text-light"> No data available</h6>
                      </div>
                    ) : (filterData?.map((item) => (
                      <li class="my-1"
                        onClick={() => {
                          setSelectedCrypto(item)
                          dispatch(selectCrypto(item))
                        }}
                        key={item?._id}
                      >
                        <div class={`dropdown-item btcInner d-flex align-items-center ${selectedCrypto?._id == item?._id ? "active" : ""}`} >
                          <span class="selectIcon me-3 d-flex align-items-center justify-content-center rounded-circle overflow-hidden">
                            <img src={item?.icon} alt="CBANK" class="h-100 w-100 img-fluid" />
                          </span>
                          <div class="d-flex justify-content-between w-100">
                            <span>{item?.code}</span>
                          </div>
                        </div>
                      </li>
                    )))
                  )
                }
              </ul>
            </div>
          </div>
          <div className="col-12 withTitle text-white">{walletLabels.Choose_Network}</div>
          <div className="col-12">
            <div class="dropdown btcdropDown pt-1 w-100">
              <button onClick={() => setNetworkOpen(!networkOpen)} class="btn dropdown-toggle dropDownBtn position-relative d-flex align-items-center w-100 pe-4" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                
                <div class="d-flex justify-content-between w-100 pe-2">
                  <span> {selectedNetwork?.name}</span>
                  <span></span>
                </div>
              </button>
              <ul className={`dropdown-menu btcdropmenu w-100 ${networkOpen ? 'show' : ''}`}>
                <li class="">
                  <div class="position-relative dropSearch">
                    <img class="searchIcon" src="assets/img/aboutJonathan/search.png" alt="search Icon" />
                    <input type="text" class="form-control bg-transparent shadow-none searchInput"
                      ref={networkRef}
                      value={searchNetwork}
                      onChange={(e) => setSearchNetwork(e.target.value)}
                    />
                  </div>
                </li>
                {
                  networkFiltered?.map((item) => (
                    <li class="my-1"
                      key={item?._id}

                      onClick={() => {
                        setSelectedNetwork(item)
                        setNetworkOpen(!networkOpen);
                      }}
                    >
                      <div class="dropdown-item btcInner d-flex align-items-center ">
                      
                        <div class={`d-flex justify-content-between w-100 ${selectedNetwork?._id == item?._id ? "active" : ""}`}>
                          <span>{item?.name}</span>
                        </div>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
          <div className="col-12 withTitle mt-3 text-white"> {walletLabels.Please_enter} {selectedCrypto?.code} {walletLabels.Wallet_Address}</div>

          <div className="col-12 withTitle mt-3 text-white">
            {walletLabels.RECEIVING} {selectedCrypto?.code?.toUpperCase()} {walletLabels.ADDRESS} <span className="receivingStar">*</span>
          </div>
          <div className="col-12">
            <input type="text" className='form-control receivingInput shadow-none border-0' placeholder='Fill in carefully according to the specific currency'
              value={address}
              onChange={(e) => {
                setAddress(e.target.value)
              }}
            />
          </div>
          <div className="col-12 withTitle mt-3 text-white">{walletLabels.WITHDRAWAL_AMOUNT} <span className="withdrawalStar">*</span></div>
          <div className="col-12">
            <input type="text" className='form-control receivingInput shadow-none border-0' placeholder='Enter Amount'
              value={amount}
              onChange={e => {
                if (isNaN(Number(e.target.value))) return
                setAmount(e.target.value)
                // priceConverter(e)
              }}
            />
          </div>
          <div className="col-12 mt-1">
            <div className="row">
              <div className="col">
                <div className="withTitle">Available:  <span>
                  {
                    userCurrencyBalance?.totalBalance ? Number(userCurrencyBalance?.totalBalance)?.toFixed(2) : 
                    '0.00'
                  }
                </span></div>
              </div>
              <div className="col-auto">
                <div className="withTitle">Locked funds:  <span> $ {''}
                    {userBonusDetail?.bonusData?.lockedBonus ? Number(userBonusDetail?.bonusData?.lockedBonus)?.toFixed(2) : '0.00' }
                  </span></div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-3">
            <div className="row mx-0 amountDetailBox gx-0">
              <div className="col-12 d-flex align-items-center justify-content-between">
                <div className="withTitle">Withdraw Amount</div>
                <div className="withTitle"><span>{amount ? Number(amount)?.toFixed(2)  : "0.00"} {selectedCrypto.code?.toUpperCase()}</span></div>
              </div>
              <div className="col-12 d-flex align-items-center justify-content-between py-1">
                <div className="withTitle">
                  Fee
                  <img className="infoImg" src="./assets/img/infoGreen.png" alt="" />
                </div>
                <div className="withTitle"><span>0.00 {selectedCrypto.code?.toUpperCase()}</span></div>
              </div>
              <div className="col-12 d-flex align-items-center justify-content-between">
                <div className="withTitle mb-0">Total withdrawal amount</div>
                <div className="withTitle mb-0"><span className='green'>{amount ? Number(amount)?.toFixed(2) : "0.00"} {selectedCrypto.code?.toUpperCase()}</span></div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div class="securityMsg">
              <div class="msg"> {walletLabels.The_Value_Subtracted}</div>
            </div>
          </div>
          <div className="col-12">
            <button className='shadow-none withdrawBtn'
              type='button'
              onClick={onWithdraw}
            >

              {
                withdrawLoading ? (
                  <Spinner animation="border" variant="dark" size="sm" />
                ) : (
                  "Withdraw"
                )
              }
            </button>
          </div>
        </div>
      </div>
    </>

  )
}

export default Crypto;