import React, { useEffect, useState } from "react"
import CasionGames from "./components/CasionGames";
import DambleTable from "./components/DambleTable";
import { useAuth } from "../../hooks/useAuth";
import DepositBanner from "./components/DepositBanner";
import HeroBanner from "./components/HeroBanner";
import LiveWins from "./components/LiveWins";
import DambleUniverse from "./components/DambleUniverse";
import SportsCards from "./components/SportsCards";
import AppFooter from "../../components/AppFooter/AppFooter";
import CategoriesGamesComp from "./components/CategoriesGamesComp";
import OptionPills from "./components/OptionPills";
import ProviderSlider from "../../components/providerSlider/ProviderSlider";
import CasinoDetail from "./components/CasinoDetail";

const Casino = ({ socket }) => {
  const { token } = useAuth();

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <main className="main vh-100" id="main">
        <div className="mainContaint">
          <div className="homepage">
            {/* <LiveWins socket={socket} /> */}
            <HeroBanner />

            <CasinoDetail />

            <OptionPills />
{/* 
    <h1 className="text-white">
    Device width: {width}px
    </h1> */}

            {/* <DambleUniverse /> */}

            <CasionGames />

            <CategoriesGamesComp />

            <SportsCards />

            {/* <ProviderSlider /> */}

            <DambleTable
              socket={socket}
            // design={true} 
            />
          </div>
        </div>
        <AppFooter />
      </main>
    </>
  );
};

export default Casino;
