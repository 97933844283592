import React, { useEffect, useRef, useState, useTransition } from 'react'
import { useAuth } from '../../../hooks/useAuth'
import { getAPIAuth, postAPIAuth } from '../../../service/apiInstance'
import { useWalletModal } from '../../../hooks/useWallet'
import { WALLET_MODAL_TABS } from '../../../constants'
import { getCryptoListAction } from '../../../store/action'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import { errorToaster, succesToaster } from '../../../utils/toaster'
import { useTranslation } from 'react-i18next'

const Withdraw = () => {
    const { token } = useAuth()
    const { walletModalObject } = useWalletModal()
    const cryptoList = useSelector(state => state.cryptoList)
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(true)
    const [selectedCrypto, setSelectedCrypto] = useState({})
    const [address, setAddress] = useState("")
    const [amount, setAmount] = useState("")
    const userDetails = useSelector(state => state.userDetails)
    const [withdrawLoading, setWithdrawLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [availCrypto, setAvailCrypto] = useState([])
    const [filterData, setFilterData] = useState([])
    const [convertedPrice, setConvertedPrice] = useState(0.00)
    const timeoutRef = useRef()
    const { t } = useTranslation()
    const walletLabels = t("wallet", { returnObjects: true })
    const [allNetwork, setAllNewtWork] = useState([])
    const [selectedNetwork, setSelectedNetwork] = useState('')
    const [networkOpen, setNetworkOpen] = useState(false)
    const [networkFiltered, setNetworkFiltered] = useState([]);
    const [searchNetwork, setSearchNetwork] = useState('')
    const networkRef = useRef();
    // const userDetails = useSelector(state => state.userDetails)
    const getAvailableCrypto = async () => {
        try {
            const res = await getAPIAuth("crypto/get-crypt-favorite-by-admin")
            if (res.data.success) {
                // setAvailCrypto(res.data.data)
                const data = res?.data?.data?.filter(item => item?.code !== 'eth')
                setAvailCrypto(data)
                // setSelCryptoNetwork(res.data.data)
            }
        } catch (error) {
        }
    }

    //   function for filter network data
    const filterNetwork = () => {
        if (searchNetwork) {
            let filterData = allNetwork.filter((el) =>
                el.name
                    .toUpperCase()
                    .includes(searchNetwork?.toUpperCase())
            );
            setNetworkFiltered(filterData);
        } else {
            setNetworkFiltered(allNetwork);
        }
    };
    const getCrypto = async () => {
        if (!!search) {
            let filterDataArr = cryptoList.filter((el) => {
                return el.code.toUpperCase().includes(search.toUpperCase())
            });
            setFilterData(filterDataArr)
        } else {
            if (
                walletModalObject.isWalletOpen &&
                walletModalObject.selectedTab === WALLET_MODAL_TABS.WITHDRAW &&
                cryptoList.length === 0
            ) {
                try {
                    const resp = await getAPIAuth("crypto/get-crypto", token);
                    // setCrypto(resp?.data.data)
                    const data = resp?.data?.data?.filter(item => item?.code !== 'eth')

                    // dispatch(getCryptoListAction(resp?.data.data))


                    // setSelectedCrypto(resp?.data?.data?.filter(item => item.code === 'eth')[0])
                    // setFilterData(resp?.data.data)
                    // setAllNewtWork(resp?.data?.data?.filter(item => item.code === 'eth')[0].allNetworks)
                    // setNetworkFiltered(resp?.data?.data?.filter(item => item.code === 'eth')[0].allNetworks)
                    // setSelectedNetwork(resp?.data?.data?.filter(item => item.code === 'eth')[0].allNetworks[0])

                    // dispatch(getCryptoListAction(data))


                    setSelectedCrypto(data[0])
                    setFilterData(data)
                    setAllNewtWork(data?.[0]?.allNetworks)
                    setNetworkFiltered(data?.[0]?.allNetworks)
                    setSelectedNetwork(data?.[0]?.allNetworks[0])

                } catch (error) {

                }
            } else if (
                walletModalObject.isWalletOpen &&
                walletModalObject.selectedTab === WALLET_MODAL_TABS.WITHDRAW &&
                cryptoList.length !== 0
            ) {
                // setSelectedCrypto(cryptoList.filter(item => item.code === 'eth')[0])
                // setFilterData(cryptoList)
                // setAllNewtWork(cryptoList.filter(item => item.code === 'eth')[0].allNetworks)
                // setNetworkFiltered(cryptoList.filter(item => item.code === 'eth')[0].allNetworks)
                // setSelectedNetwork(cryptoList.filter(item => item.code === 'eth')[0].allNetworks[0])
                // .filter(item => item?.code !== 'eth')
                const data = structuredClone(cryptoList)?.filter(item => item?.code !== 'eth')

                setSelectedCrypto(data?.[0])
                setFilterData(data)
                setAllNewtWork(data?.[0]?.allNetworks)
                setNetworkFiltered(data?.[0]?.allNetworks)
                setSelectedNetwork(data?.[0]?.allNetworks[0])
            }
            setIsLoading(false)
        }
    }

    const onWithdraw = async () => {
        if (!address) {
            errorToaster("Address can't be empty!")
            setWithdrawLoading(false)
            return
        }
        if (!!!amount || amount === '') {
            errorToaster("Add a valid amount to withdraw!")
            setWithdrawLoading(false)
            return
        }
        if (convertedPrice === 0 || convertedPrice < 0) {
            errorToaster("Converted Amount isn't Valid")
            setWithdrawLoading(false)
            return
        }
        try {
            const body = {
                userId: userDetails.id,
                address: address,
                withdraw_amount: amount,
                coinId: selectedCrypto._id,
                user_currency: userDetails?.usercurrency,
                chain: 'eth',
                type: "withdraw",
                convertedCurrency: convertedPrice,
                network: selectedNetwork?.name
            }

            // const res = await postAPIAuth("user/save-request-withdraw", body)
            const res = await postAPIAuth("coin/withdrawRequest", body)

            if (res.data.success) {
                succesToaster(res.data.message)
            } else {
                errorToaster(res.data.message)
            }
        } catch (error) {
            errorToaster("Something went wrong")
        } finally {
            setWithdrawLoading(false)
            setAddress('')
            setAmount("")
        }
    }
    useEffect(() => {
        getCrypto()
    }, [walletModalObject.selectedTab, walletModalObject.isWalletOpen, search])

    useEffect(() => {
        if (walletModalObject.isWalletOpen &&
            walletModalObject.selectedTab === WALLET_MODAL_TABS.WITHDRAW) {
            getAvailableCrypto()
        }
    }, [walletModalObject.selectedTab, walletModalObject.isWalletOpen])

    const priceConverter = async () => {

        const body = {
            // source: "eth",
            // target: "usdt",
            source: selectedCrypto.code,
            target: "usdt",
            amount: amount
        }
        try {
            const res = await postAPIAuth("convertPrice", body)
            if (res.data.success) {
                setConvertedPrice(res.data.data.amount)
            } else {
                setConvertedPrice(0.00)
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        if (walletModalObject.isWalletOpen &&
            walletModalObject.selectedTab === WALLET_MODAL_TABS.WITHDRAW) {
            // debouncing
            if (!!amount) {
                setWithdrawLoading(true)
            }
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
            timeoutRef.current = setTimeout(() => {
                if (!!amount) {
                    priceConverter()
                } else {
                    if (!amount) setConvertedPrice(0.00)
                }
                setWithdrawLoading(false)
            }, 400)
        }

        return () => clearTimeout(timeoutRef.current)
    }, [amount, walletModalObject.selectedTab, walletModalObject.isWalletOpen, selectedCrypto])

    useEffect(() => {
        if (!!selectedCrypto?.allNetworks) {
            setAllNewtWork(selectedCrypto?.allNetworks)
            setNetworkFiltered(selectedCrypto?.allNetworks)
            setSelectedNetwork(selectedCrypto?.allNetworks[0])
        }
    }, [selectedCrypto])
    useEffect(() => {
        filterNetwork()
    }, [searchNetwork])
    return (
        <div className="tab-pane fade show active">
            <div className="row">
                <div className="col-12 cryptoHeading">
                    {walletLabels.Withdraw_currency}
                </div>
                {/* available chains */}
                <div className="col-12">
                    <div className="d-flex align-items-center gap-2">
                        {
                            availCrypto.map(item => (
                                <span
                                    key={item?._id}
                                    className="usdtBtn d-flex align-items-center justify-content-center text-decoration-none cursor-pointer"
                                    onClick={() => setSelectedCrypto(item)}
                                >
                                    <span className="usdtIcon d-flex align-items-center justify-content-center">
                                        <img src={item?.icon} alt="usdt" className="h-100 w-100 img-fluid" />
                                    </span>

                                    {item?.code}
                                </span>
                            ))
                        }
                    </div>
                </div>
                <div className="col-12 py-2">
                    <div
                        className="dropdown btcdropDown pt-1 w-100"
                    >
                        {/* selected crypto */}
                        <button
                            className="btn dropdown-toggle dropDownBtn position-relative d-flex align-items-center w-100"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <span
                                className="bitcoinIcon me-3 d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                            >
                                <img
                                    src={selectedCrypto?.icon}
                                    alt="Bitcoin"
                                    className="h-100 w-100 img-fluid"
                                />
                            </span>

                            {/* {selectedCrypto?.code} */}
                            <div className="d-flex justify-content-between w-100 pe-4">
                                <span>{selectedCrypto?.code}</span>
                                {/* <span>{selectedCrypto?.chain}</span> */}
                            </div>
                        </button>
                        {/* dropdown-menu */}
                        <ul className="dropdown-menu btcdropmenu w-100">
                            {/* search box */}
                            <li className="px-3">
                                <div className="position-relative dropSearch">
                                    <img className="searchIcon" src="assets/img/aboutJonathan/search.png" alt="search Icon" />
                                    <input type="text" className="form-control bg-transparent shadow-none searchInput"
                                        value={search}
                                        onChange={(e) =>
                                            setSearch(e.target.value)
                                        }
                                    />
                                </div>
                            </li>
                            {/* list of crypto */}
                            {
                                isLoading ? (
                                    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                        <Spinner animation="border" variant="light" size="sm" />
                                    </div>
                                ) : (
                                    filterData?.length === 0 ? (
                                        <div className="w-100 h-100">
                                            <h6 className="text-light"> No data available</h6>
                                        </div>
                                    ) : (
                                        filterData?.map((item) => (
                                            <li
                                                onClick={() => setSelectedCrypto(item)}
                                                key={item?._id}
                                                className='my-1'
                                            >
                                                <span
                                                    className={`dropdown-item btcInner d-flex align-items-center  ${selectedCrypto?._id == item?._id ? "active" : ""}`}
                                                >
                                                    <span
                                                        className="selectIcon me-3 d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                                                    >
                                                        <img
                                                            src={item?.icon}
                                                            alt="Bitcoin"
                                                            className="h-100 w-100 img-fluid"
                                                        />
                                                    </span>
                                                    {/* {item?.code} */}
                                                    <div className="d-flex justify-content-between w-100">
                                                        <span>{item?.code}</span>
                                                        {/* <span>{item?.chain}</span> */}
                                                    </div>
                                                </span>
                                            </li>
                                        ))
                                    )
                                )
                            }
                        </ul>
                    </div>
                </div>
                {/* selected network */}
                <div className="col-12 pt-4 cryptoHeading">
                    {walletLabels.Choose_Network}
                </div>
                {/* network search box and network list */}
                <div className="col-12 ">
                    <div className="dropdown btcdropDown w-100">
                        <button
                            onClick={() => setNetworkOpen(!networkOpen)}
                            className="btn fw-bold network dropdown-toggle dropDownBtn position-relative d-flex align-items-center w-100"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            {selectedNetwork?.name}
                        </button>
                        <ul className={`dropdown-menu btcdropmenu w-100 ${networkOpen ? 'show' : ''}`}>
                            <li className="px-3">
                                <div className="position-relative dropSearch">
                                    <img className="searchIcon" src="assets/img/aboutJonathan/search.png" alt="search Icon" />
                                    <input type="text" className="form-control bg-transparent shadow-none searchInput"
                                        ref={networkRef}
                                        value={searchNetwork}
                                        onChange={(e) => setSearchNetwork(e.target.value)}
                                    />
                                </div>
                            </li>
                            {
                                networkFiltered?.map(
                                    (item) => (
                                        <li
                                            key={item?._id}

                                            onClick={() => {
                                                setSelectedNetwork(item)
                                                setNetworkOpen(!networkOpen);
                                            }}
                                        >
                                            <div
                                                className={`dropdown-item btcInner d-flex align-items-center ${selectedNetwork?._id == item?._id ? "active" : ""}`}
                                            >
                                                {item?.name}
                                            </div>
                                        </li>
                                    )
                                )}
                        </ul>
                    </div>
                </div>
                <div className="col-12 pb-4 pt-sm-4 pt-2 bankmodalsubheading fw-semibold">
                    {walletLabels.Please_enter} {selectedCrypto?.code} {walletLabels.Wallet_Address}
                </div>
                {/* wallet address */}
                <div className="col-12">
                    <label htmlFor="receiving" className="receivingLabel pt-0">
                        {walletLabels.RECEIVING} {selectedCrypto?.code?.toUpperCase()} {walletLabels.ADDRESS} <span className="receivingStar">*</span>
                    </label>
                    <input
                        type="text"
                        className="form-control receivingInput shadow-none border-0 mt-2"
                        placeholder="Paste your Wallet Address here"
                        value={address}
                        onChange={(e) => {
                            setAddress(e.target.value)
                        }}
                    />
                </div>
                <div className="col-12 pb-2">
                    <div className="receivingLabel ">
                        {walletLabels.WITHDRAWAL_AMOUNT} <span className="withdrawalStar">*</span>
                    </div>
                    <div className="row gx-1 align-items-center gap-1 pt-3">
                        {/* user currency */}
                        <div className="col">
                            <div className="input-group btcValue ">
                                <div className="input-group-text btcLabel bg-transparent border-0" id="basic-addon1">
                                    <span className="overflow-hidden me-2 rounded-circle btcvalueImg d-flex align-items-center justify-content-center">
                                        <img src={userDetails?.currencyimage} alt="coin" className="h-100 w-100 img-fluid" />
                                    </span>
                                    {/* {userDetails?.usercurrency} */}
                                    $
                                </div>
                                <input
                                    type="text"
                                    className="form-control btcInput shadow-none border-0 bg-transparent ps-0"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    value={amount}
                                    onChange={e => {
                                        if (isNaN(Number(e.target.value))) return
                                        setAmount(e.target.value)
                                        // priceConverter(e)
                                    }}
                                />
                            </div>
                        </div>
                        <span className="Equal col-auto">=</span>
                        {/* converted crypto currency */}
                        <div className="col">
                            <div className="input-group btcValue ">
                                <div className="input-group-text pe-0 btcLabel bg-transparent border-0" id="basic-addon1">
                                    <span className="overflow-hidden me-2 rounded-circle btcvalueImg d-flex align-items-center justify-content-center">
                                        <img
                                            src={selectedCrypto?.icon}
                                            alt="bitcoin"
                                            className="h-100 w-100 img-fluid" />
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="form-control btcInput shadow-none border-0 bg-transparent ps-0"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    value={convertedPrice}
                                    readOnly
                                />
                            </div>
                        </div>
                        {/* REQUEST WITHDRAWAL button */}
                        <div className="col-auto d-sm-block d-none ms-3 cursor-pointer">
                            <span
                                onClick={() => {
                                    if (amount > userDetails.totalbalance) {
                                        errorToaster("Insufficient balance")
                                        return
                                    }
                                    setWithdrawLoading(true)
                                    onWithdraw()
                                }}
                                className="playBtn playbtntwo position-relative d-flex align-items-center justify-content-center"
                                style={{ width: '190px' }}
                            >
                                <div className="playBtnInner d-flex align-items-center justify-content-center w-100">
                                    {
                                        withdrawLoading ? (
                                            <Spinner animation="border" variant="dark" size="sm" />
                                        ) : (
                                            walletLabels.REQUEST_WITHDRAWAL
                                        )
                                    }
                                </div>
                            </span>
                        </div>
                        <div className="networkFee col-12 d-md-none d-block">{walletLabels.NetworkFee} : $0.61</div>
                        {/* REQUEST WITHDRAWAL button */}
                        <div className="col-auto d-sm-none d-block mx-auto pt-4 pb-2 ms3 cursor-pointer">
                            <span
                                onClick={() => {
                                    setWithdrawLoading(true)
                                    onWithdraw()
                                }}
                                className="playBtn playbtntwo withDrBtn position-relative d-flex align-items-center justify-content-center">
                                <div className="playBtnInner d-flex align-items-center justify-content-center w-100">
                                    {
                                        withdrawLoading ? (
                                            <Spinner animation="border" variant="dark" size="sm" />
                                        ) : (
                                            "REQUEST WITHDRAWAL"
                                        )
                                    }
                                </div>
                            </span>
                        </div>
                    </div>
                    <div className="networkFee pt-2 d-md-block d-none">{walletLabels.NetworkFee} : $0.61</div>
                </div>
                <div className="col-12 pt-3 btnvaluetitle">
                    {/* *You will receive the specified {selectedCrypto?.code} amount to your withdrawal address */}
                    {walletLabels.You_Will} {selectedCrypto?.code} {walletLabels.Amount_to}
                </div>
                <div className="col-12 pt-1 btnvaluetitle">
                    {/* *The value subtracted from your balance may vary between now and the time we process your withdrawal */}
                    {walletLabels.The_Value_Subtracted}
                </div>
            </div>
        </div>
    )
}

export default Withdraw