


import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import AppFooter from "../../components/AppFooter/AppFooter";
import { useAuth } from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import i18n from 'i18next';
import { USER_LANG } from "../../constants";
import { getAPI, getAPIAuth } from "../../service/apiInstance";
import { useSearchParams } from "react-router-dom";
import FiatErrorModal from "../../components/fiatErrorModal/FiatErrorModal";

const Sports = () => {
    const { token } = useAuth()
    const userDetails = useSelector(state => state.userDetails)
    const lang = localStorage.getItem(USER_LANG)
    const [language, setLanguage] = useState(lang)
    const [sports, setSports] = useState([])
    const [gameUrl, setGameUrl] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [sessionId, setSessionId] = useState('')
    const userBalance = useSelector(state => state.userBalance)
    const [query, setQuery] = useSearchParams()
    const [errorMsg, setErrorMsg] = useState({
        show: false,
        msg: ''
    })
    const [showIframe, setShowIframe] = useState(false)

    useEffect(() => {

        if (token) {
            if (userDetails?.id && userBalance.setCurrencyData?.code) {
                // for logged in users
                console.log("running", { token, language, userDetails: userDetails?.id, userBalance: userBalance.setCurrencyData?.code, query: query.get("setIframePath") })
                getSports()
            }
        } else {
            // for logged out user--->>
            const betSDKHandler = async () => {
                console.log("logged out");
                try {
                    const bettingFrame = await window.BetSdk.init({
                        mainFrameUrl: "https://memestop.win/sports", 
                        // host: 'https://stage.sportbook.work/',
                        host : 'https://gameosys-mu83s8.turbostars.gg',
                        cid: "gameosys",
                        lang: 'en',
                        token: "",
                        containerId: 'iFrame-container',
                        width: "100%",
                        height: "100%",
                        parent: 'https://memestop.win',
                        customStyles: false,
                        sub_partner_id: false,
                        allowParentUrlUpdate: true,
                    })
                } catch (error) {
                }
            }
            betSDKHandler()
        }
    }, [token, language, userDetails?.id, userBalance.setCurrencyData?.code, query.get("setIframePath")])

    const selfValidateResult = async (task_id) => {
        try {
            const res = await getAPIAuth(`sport/selfValidateResult?task_id=${task_id}`)
            if (res.data.success) {

            }
        } catch (error) {
        }
    }


    const selfValidate = async (sessionId) => {

        try {
            const res = await getAPIAuth(`sport/selfValidate?session_id=${sessionId}`)
            if (res.data.status) {
                console.log("taskId", res.data?.data?.task_id);

                selfValidateResult(res.data?.data?.task_id)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getSports = async () => {

        try {
            // get uuid to generate url--->>>>
            const res = await getAPI('sport/getSportbooks')
            if (res.data.status) {
                setSports(res.data.data)
                //generate url --------------------------------->>
                const response = await getAPIAuth(`sport/urlGenerate?uuid=${res.data.data?.[0]?.uuid}`, token, "fromsports")

                if (response.data.status) {
                    setShowIframe(true)
                    setErrorMsg(prev => ({
                        ...prev,
                        show: false,
                        msg: ''
                    }))
                    setSessionId(response.data.data?.session_id)
                    setGameUrl(response.data.data?.data)
                    // selfValidate(response.data.data?.session_id)

                    // try {
                    // call url to get cid and token --------------------------------->>
                    // const resp = await axios.get(response.data.data?.data?.url);
                    // const resp = await getAPIAuth()

                    // ----extract token value 
                    const tokenRegex = /token:\s*'([^']+)'/;
                    const tokenMatch = tokenRegex.exec(response.data?.data?.gethtml);
                    const tokenValue = tokenMatch && tokenMatch[1]; // Extracted token value
                    // ----extract cid value 
                    const cidRegex = /cid:\s*'([^']+)'/;
                    const cidMatch = cidRegex.exec(response.data?.data?.gethtml);
                    const cidValue = cidMatch && cidMatch[1]; // Extracted cid value
                    console.log("tokenMatch", { cidValue, tokenValue });

                    // -------------initialize betSDK-------------->>>
                    try {
                        console.log('bettttttttttttttttttttttttttttttttttttttttttttttttttttttttttttt')
                        const bettingFrame = await window.BetSdk.init({
                            mainFrameUrl: "https://memestop.win/sports", 
                        host : 'https://gameosys-mu83s8.turbostars.gg',
                            // host: 'https://stage.sportbook.work/',
                            cid: cidValue,
                            lang: 'en',
                            token: tokenValue,
                            containerId: 'iFrame-container',
                            width: "100%",
                            height: "100%",
                            parent: 'https://memestop.win', 
                            customStyles: false,
                            sub_partner_id: false,
                            allowParentUrlUpdate: true,
                        })
                    } catch (error) {
                        console.error('Error initializing betSDK', error);
                    }
                    // } catch (error) {
                    // console.error('Error in getting token and cid:', error);
                    // }
                } else {
                    setShowIframe(false)
                    setErrorMsg(prev => ({
                        ...prev,
                        show: true,
                        msg: response?.data?.message
                    }))

                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(()=> {
        if(token) {
            getSports()
        }
    }, [token])
    useEffect(() => {
        const handleLanguageChange = () => {
            // Handle language change here
            const currentLanguage = i18n.language;
            setLanguage(currentLanguage)
        };

        // Add an event listener for language changes
        i18n.on('languageChanged', handleLanguageChange);

        // Clean up the event listener on component unmount
        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []); // Empty dependency array, so this effect runs only once on mount

    return (
        <main className="main vh-100" id="main">

            <div className="mainContaint sportsPageMainContent" style={{marginLeft: 0}}>
                <div
                    className="homepage pt-0 sportsPageMainInner"
                    style={{ height: "calc(100dvh - 58px)" }}
                >
                    {
                        token ? (
                            showIframe ? (
                                <>
                                    {/* <div
                                        id="iFrame-container"
                                        className="align-items-center mt-0 sports-div h-100 w-100"
                                    >
                                        {
                                            errorMsg?.show ? 
                                                <div className="text-white">{errorMsg?.msg}</div>
                                            :
                                                <div className="loader-div aaa w-100 h-100  d-flex justify-content-center align-items-center">
                                                    <Spinner animation="border" variant="light" />
                                                </div>
                                        }
                                    </div> */}
                                    {RenderIframe(errorMsg)}
                                    {/* <div className="text-white">iframe</div> */}
                                </>
                            ) : (

                                <>
                                    <div
                                        className="align-items-center mt-0 sports-div h-100 w-100"
                                    >
                                        {
                                            errorMsg?.show ?
                                                <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
                                                    <div className="text-white h5 text-center">{errorMsg?.msg}</div>
                                                    <FiatErrorModal/>
                                                </div>
                                                :
                                                <div className="loader-div aaa w-100 h-100  d-flex justify-content-center align-items-center">
                                                    <Spinner animation="border" variant="light" />
                                                </div>
                                        }
                                    </div>
                                </>
                            )
                        ) : (
                            <>
                                <div
                                    id="iFrame-container"
                                    className="align-items-center mt-0 sports-div h-100 w-100"
                                >
                                    <div className="loader-div w-100 h-100  d-flex justify-content-center align-items-center">
                                        <Spinner animation="border" variant="light" />
                                    </div>
                                </div>
                            </>
                        )
                    }

                    {/* <div className="loader-div w-100 d-flex justify-content-center align-items-center">
                            <Spinner animation="border" variant="light" size="sm" />
                        </div> */}
                    {/* </div> */}
                </div>
            </div>
            <div className="d-none d-lg-block">
                {/* <AppFooter /> */}
            </div>
        </main>
    );
};

export default Sports;


const RenderIframe = ({ errorMsg }) => {
    return (
        <>
            <div
                id="iFrame-container"
                className="align-items-center mt-0 sports-div h-100 w-100"
            >
                {
                    errorMsg?.show ?
                    <>
                        <div className="d-flex flex-column">
                            <div className="text-white">{errorMsg?.msg}</div>
                            <FiatErrorModal/>
                        </div>
                    </>
                        : 
                        <div className="loader-div w-100 h-100  d-flex justify-content-center align-items-center">
                            <Spinner animation="border" variant="light" />
                        </div>
                }
            </div>
        </>
    )
}
