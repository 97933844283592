import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useClaimBonus } from '../../hooks/useClaimBonus'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useWalletModal } from '../../hooks/useWallet'
import { useGames } from '../../hooks/useGame'
import { errorToaster } from '../../utils/toaster'
import { CLAIM_BONUS_OPTIONS, GAME_OPTION_TABS, ROUTE_CONST, WALLET_MODAL_TABS } from '../../constants'
import TimerComponent from '../../components/newWallet/components/TimerComponent'
import BonusDetails from './modalsection/bonusDetails'
import DepositBonusRules from './modalsection/depositeBounsRole'
import Hunthub from './modalsection/huntHub'
import OnekRace from './modalsection/onekRace'
import RaceStats from './modalsection/raceStats'
import DBLRackBack from './modalsection/dblRackBack'
import DBLBonusHistory from './modalsection/dblBounsHistory'




const Bouns = () => {


    const [isBonusDetails, setIsBonusDetails] = useState(false)
    const [isBonusRules, setIsBonusRules] = useState(false)
    const [isHuntsHub, setIsHuntsHub] = useState(false)
    const [isOneRake, setIsOneRake] = useState(false)
    const [isRaceStats, setIsRaceStats] = useState(false)
    const [isDBKRaceBack, setIsDBKRaceBack] = useState(false)
    const [isDBLBonusHistory, setIsDBLBonusHistory] = useState(false)
    const userDetails = useSelector(state => state?.userDetails)
    const userBonusDetail = useSelector(state => state?.userBonusDetail)
    const { setClaimBonusObject } = useClaimBonus()
    const [query, setQuery] = useSearchParams()
    const { setWalletModalObject } = useWalletModal()
    const { setGameObject } = useGames()
    const navigate = useNavigate()



    return (
        <>

            <main className="main vh-100" id="main">
                <div className="mainContaint">
                    <div className="homepage">
                        <div>
                            <div className='bonus-page'>
                                <div className="row justify-content-between align-items-center py-3 py-md-4 gy-3">
                                    <div className="col-12 col-sm-4 col-md-5">
                                        <div className="heading">
                                            BONUS SECTION
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-8 col-md-7">
                                        <div className="redeem-form">
                                            <div class="input-group">
                                                <input type="text" class="form-control formControl shadow-none" placeholder="Enter Bonus Code Here" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                                <button onClick={() => {
                                                    errorToaster('Coming soon')
                                                }} class="btn redeem-btn border-0 shadow-none" type="button" id="button-addon2">Redeem Bonus Code</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row bonus-details px-2 py-3 my-3 justify-content-center justify-content-xl-between align-items-center gy-4 gy-xl-0">
                                    <div className="col-6 col-xl-3">
                                        <div className="row align-items-center total-bonus-wrapper">
                                            <div className="description d-flex align-items-center gap-2 gap-xl-3">
                                                <div className="col-auto">
                                                    <div className="dollar-img">
                                                        <img src='./assets/img/bouns/dollarBonus.png' alt="" />
                                                    </div>
                                                </div>
                                                <div className="col px-0">
                                                    <div className="head">
                                                        Total Bonus Claimed
                                                    </div>
                                                    <div className="sub-para">
                                                        $ {userDetails?.totalbonus ? userDetails?.totalbonus?.toFixed(2) : '0.00'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-xl-auto">
                                        <div className="description">
                                            <div className="head">
                                                Total VIP Bonus
                                            </div>
                                            <div className="sub-head">
                                                $ 0.00
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-xl-auto border-div">
                                        <div className="description">
                                            <div className="head">
                                                Total Special Bonus
                                            </div>
                                            <div className="sub-head">
                                                $ 0.00
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-xl-auto">
                                        <div className="description">
                                            <div className="head">
                                                Total General Bonus
                                            </div>
                                            <div className="sub-head">
                                                $ 0.00
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto col-md-3 col-xl-2">
                                        <button className='details-btn' type='button'
                                            onClick={() => setIsBonusDetails(true)}
                                        >
                                            Details
                                        </button>
                                    </div>
                                </div>
                                <div className="row py-3 general-bonus-container">
                                    <div className="heading py-4">
                                        General Bonus
                                    </div>
                                    <div className="col-12">
                                        <div className="row g-3">
                                            <div className="col-xl-4 col-md-6 col-12 ">
                                                <div className='depositBounsModal deposit-bonus-card'>
                                                    <div className='row gap-1 h-100'>
                                                        <div className='col-12'>
                                                            <div className='row'>
                                                                <div className='col'>
                                                                    <div className="depositeheading">Deposit Bonus </div>
                                                                </div>
                                                                <div className='col-auto'>
                                                                    <div>
                                                                        <button
                                                                            onClick={() => setIsBonusRules(true)}
                                                                            className='moreDetails'>View details <span className='arrow'><img src="/assets/img/bouns/rightArrowgreen.png" alt="" /></span></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 mt-3'>
                                                            <div className="depositCard active">
                                                                <div className='row'>
                                                                    <div className='col'>
                                                                        <div className='bonusHeading'>1st Bonus</div>
                                                                    </div>
                                                                    <div className='col-auto'>
                                                                        <div className='bonusValue'>180%</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12'>
                                                            <div className="depositCard ">
                                                                <div className='row'>
                                                                    <div className='col'>
                                                                        <div className='bonusHeading'>2nd Bonus</div>
                                                                    </div>
                                                                    <div className='col-auto'>
                                                                        <div className='bonusValue'>240%</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12'>
                                                            <div className="depositCard ">
                                                                <div className='row'>
                                                                    <div className='col'>
                                                                        <div className='bonusHeading'>3rd Bonus</div>
                                                                    </div>
                                                                    <div className='col-auto'>
                                                                        <div className='bonusValue'>300%</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12'>
                                                            <div className="depositCard ">
                                                                <div className='row'>
                                                                    <div className='col'>
                                                                        <div className='bonusHeading'>4th Bonus</div>
                                                                    </div>
                                                                    <div className='col-auto'>
                                                                        <div className='bonusValue'>360%</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 text-center mt-auto'>
                                                            <button className='registernowBtn mx-auto w-100' onClick={() => {
                                                                setWalletModalObject((prev) => ({
                                                                    isWalletOpen: true,
                                                                    selectedTab: WALLET_MODAL_TABS.DEPOSIT
                                                                }))
                                                                setQuery({ action: "wallet" })
                                                            }}>
                                                                Deposit
                                                                {/* coming soon */}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-md-6 col-12">
                                                <div className='depositBounsModal deposit-bonus-card'>
                                                    <div className='row gap-3 h-100'>
                                                        <div className='col-12'>
                                                            <div className='row'>
                                                                <div className='col'>
                                                                    <div className="depositeheading">Bonus Hunts <span className='quetionImg '>
                                                                        <img src="/assets/img/bouns/Question.png" alt="" />
                                                                        <div class="tooltip">Keep completing hunts and earning more rewards.</div>
                                                                    </span> </div>
                                                                </div>
                                                                {/* <div className='col-auto'>
                                            <div>
                                                <button className='moreDetails'>View details <span className='arrow'><img src="/assets/img/bouns/rightArrowgreen.png" alt="" /></span></button>
                                            </div>
                                        </div> */}
                                                            </div>
                                                        </div>
                                                        <div className='col-12 '>
                                                            <div className='bonusheadingHunt'>Complete daily and weekly hunts</div>

                                                        </div>
                                                        <div className='col-12'>
                                                            <div className='row align-items-center'>
                                                                <div className='col'>
                                                                    <div className='row gap-3'>

                                                                        <div className='col-12'>
                                                                            <div className='row'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Daily huntss</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>0 / 3</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='row'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Weekly hunts</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>0 / 1</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-auto'>
                                                                    <div className='huntImg'>
                                                                        <img src="/assets/img/bouns/bonushunt.png" alt="" className='h-100 w-100' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 text-center mt-auto">
                                                            <button
                                                                // onClick={() => setIsHuntsHub(true)}
                                                                // className='bounesHistory w-auto px-4'
                                                                className='registernowBtn disabled mx-auto'
                                                            >
                                                                {/* Complete Hunts */}
                                                                coming soon
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-md-6 col-12">
                                                <div className='depositBounsModal deposit-bonus-card'>
                                                    <div className='row g-3 h-100 flex-column flex-nowrap'>
                                                        <div className='col-12'>
                                                            <div className='row'>
                                                                <div className='col'>
                                                                    <div className="depositeheading">Free Spins Bonus <span className='quetionImg '>
                                                                        <img src="/assets/img/bouns/Question.png" alt="" />
                                                                        <div class="tooltip">
                                                                            <div className='toltipContent'>There are two ways to get the lucky spin:</div>
                                                                            <ul className='ps-3'>
                                                                                <li className='toltipContent'>You will receive 1 free spin every day once you reach a specific wager amount.</li>
                                                                                <li className='toltipContent'>Starting from VIP level 8, you will receive one free spin each time you level up, and there is no limit to the number of spins you can earn.</li>
                                                                            </ul>
                                                                        </div>
                                                                    </span> </div>
                                                                </div>
                                                                {/* <div className='col-auto'>
                                            <div>
                                                <button className='moreDetails'>View details <span className='arrow'><img src="/assets/img/bouns/rightArrowgreen.png" alt="" /></span></button>
                                            </div>
                                        </div> */}
                                                            </div>
                                                        </div>
                                                        <div className='col-12 flex-fill'>
                                                            <div className='row align-items-center'>
                                                                <div className='col'>
                                                                    <div className='row g-2'>

                                                                        <div className='col-12'>
                                                                            <div className='row'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Spins available</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>
                                                                                        {userBonusDetail?.bonusData?.freeSpinLeft} / 100
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='row'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>
                                                                                        Selected Game:
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>
                                                                                        {userDetails?.freeSpinGameData?.name ? userDetails?.freeSpinGameData?.name : 'Not selected'}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className='' style={{
                                                                                height: 1,
                                                                                backgroundColor: '#B2B6C5',
                                                                                opacity: 0.5
                                                                            }}></div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='row align-items-center'>
                                                                                <div className='col'>

                                                                                    <div className='bonusheadingHunt'>Min. Deposit to unlock winnings</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>$40</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='row'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Wager required to unlock winnings</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>x40</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='row'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Bonus available to claim</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>
                                                                                        $ {userBonusDetail?.bonusData?.freeSpinAmount ? Number(userBonusDetail?.bonusData?.freeSpinAmount)?.toFixed(2) : "0.00"}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className='col-auto'>
                                                    <div className='spinwheel '>
                                                        <img src="/assets/img/bouns/spinWheel.png" alt="" className='h-100 w-100' />
                                                    </div>
                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="row mt-3">
                                                                <div className="col-12 text-center">
                                                                    <button
                                                                        className={`registernowBtn  w-100 ${userBonusDetail?.lockedStatus?.freeSpinStatus === "disabled" ? 'disabled' : ''}`}
                                                                        onClick={() => {
                                                                            if (userBonusDetail?.lockedStatus?.freeSpinStatus === 'play') {
                                                                                if (userDetails?.freeSpinGameData?.uuid) {
                                                                                    navigate(`/games/${userDetails?.freeSpinGameData?.uuid}?freeSpinGame`)
                                                                                } else navigate('/games?q=freeSpin');
                                                                            } else if (userBonusDetail?.lockedStatus?.freeSpinStatus === 'claim') {
                                                                                setWalletModalObject((prev) => ({
                                                                                    isWalletOpen: true,
                                                                                    selectedTab: WALLET_MODAL_TABS.DEPOSIT
                                                                                }))
                                                                                setQuery({ action: "wallet" })
                                                                            } else return;
                                                                        }}
                                                                    // className='bounesHistory d-flex align-items-center justify-content-center mx-auto w-auto'
                                                                    >
                                                                        {/* <span className='icon me-2'><img src={clock} alt="" /></span> Daily Wager Expires in 18h:13m:36s */}
                                                                        {
                                                                            userBonusDetail?.lockedStatus?.freeSpinStatus === 'play' ? 'Play' :
                                                                                userBonusDetail?.lockedStatus?.freeSpinStatus === 'claim' ? <>
                                                                                    Claim before : {' '}
                                                                                    <TimerComponent targetTime={userBonusDetail?.bonusData?.spinTimmer} isBonusDropdown={true} />
                                                                                </> :
                                                                                    userBonusDetail?.lockedStatus?.freeSpinStatus === 'disabled' ? 'Not Available' : ''
                                                                        }
                                                                        {/* {userBonusDetail?.lockedStatus?.freeSpinStatus} */}
                                                                    </button>
                                                                </div>
                                                                {/* <div className="col-6 text-center">
                                                    <button 
                                                    className='registernowBtn w-100'
                                                    onClick={() => {
                                                        setWalletModalObject((prev) => ({
                                                          isWalletOpen: true,
                                                          selectedTab: WALLET_MODAL_TABS.DEPOSIT
                                                        }))
                                                        setQuery({ action: "wallet" })
                                                      }}
                                                    // className='bounesHistory d-flex align-items-center justify-content-center mx-auto w-auto'
                                                    > 
                                                        <span className='icon me-2'><img src={clock} alt="" /></span> Daily Wager Expires in 18h:13m:36s
                                                        Deposit
                                                    </button>
                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-xl-4 col-md-6 col-12'>
                                                <div className='depositBounsModal deposit-bonus-card'>
                                                    <div className='row gap-3 h-100'>
                                                        <div className='col-12'>
                                                            <div className='row'>
                                                                <div className='col'>
                                                                    <div className="depositeheading">$1k Daily Race <span className='quetionImg '>
                                                                        <img src="/assets/img/bouns/Question.png" alt="" />
                                                                    </span> </div>
                                                                </div>
                                                                <div className='col-auto'
                                                                >
                                                                    <div>
                                                                        <button
                                                                            onClick={() => setIsOneRake(true)}
                                                                            className='moreDetails'>View details <span className='arrow'><img src="/assets/img/bouns/rightArrowgreen.png" alt="" /></span></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 '>
                                                            <div className='bonusheadingHunt'>Climb in Memestop’s $1k Daily Race! </div>
                                                        </div>
                                                        <div className='col-12 '>
                                                            <div className='row align-items-center'>
                                                                <div className='col-md-8'>
                                                                    <div className='row gap-3'>
                                                                        <div className='col-12'>
                                                                            <div className='row'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Your Position</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>0 / 10 </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='row align-items-center'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Your Current Price</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>$0.00</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='row'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Your Wagered</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>${userDetails?.wagerAmount ? Number(userDetails?.wagerAmount)?.toFixed(2) : '0.00'}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className='col-auto'>
                                            <div className='spinwheel '>
                                            <img src="/assets/img/bouns/spinWheel.png" alt="" className='h-100 w-100' />
                                            </div>
                                        </div> */}
                                                            </div>
                                                        </div>
                                                        <div className='col-12 text-center mt-auto'>
                                                            <button className='registernowBtn disabled mx-auto'>
                                                                {/* Start Racing! */}
                                                                coming soon
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-xl-4 col-md-6 col-12'>
                                                <div className='depositBounsModal deposit-bonus-card'>
                                                    <div className='row gap-3 h-100'>
                                                        <div className='col-12'>
                                                            <div className='row'>
                                                                <div className='col'>
                                                                    <div className="depositeheading">DBL Rakeback <span className='quetionImg '>
                                                                        <img src="/assets/img/bouns/Question.png" alt="" />
                                                                    </span> </div>
                                                                </div>
                                                                {/* <div className='col-auto'>
                                            <div>
                                                <button className='moreDetails'>View details <span className='arrow'><img src="/assets/img/bouns/rightArrowgreen.png" alt="" /></span></button>
                                            </div>
                                        </div> */}
                                                            </div>
                                                        </div>
                                                        <div className='col-12 '>
                                                            <div className='bonusheadingHunt'>Wager using DBL Token and increase your rakeback</div>
                                                        </div>
                                                        <div className='col-12 '>
                                                            <div className='row align-items-center'>
                                                                <div className='col-md-8'>
                                                                    <div className='row gap-3'>
                                                                        <div className='col-12'>
                                                                            <div className='row'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Min to claim</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>5 <span>DBL</span> </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='row align-items-center'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Available for claim</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>
                                                                                        {
                                                                                            !isNaN(Number(userBonusDetail?.bonusData?.rakeBack)) ? (Number(userBonusDetail?.bonusData?.rakeBack).toFixed(2)) : '0.00'
                                                                                        }
                                                                                        <span> DBL</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className='col-auto'>
                                            <div className='spinwheel '>
                                            <img src="/assets/img/bouns/spinWheel.png" alt="" className='h-100 w-100' />
                                            </div>
                                        </div> */}
                                                            </div>
                                                        </div>
                                                        <div className='col-12 text-center mt-auto'>
                                                            <button
                                                                onClick={() => {
                                                                    // setshowModal(true);
                                                                    // setBonusType('deposit')
                                                                    setClaimBonusObject(prev => ({
                                                                        ...prev,
                                                                        isClaimBonusOpen: true,
                                                                        selectedTab: CLAIM_BONUS_OPTIONS?.RACKNACK_BONUS
                                                                    }))
                                                                }}
                                                                // onClick={() => setIsDBKRaceBack(true)}
                                                                className='bounesHistory unlockDbl mx-auto px-4'>Unlock DBL</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4 col-md-6 col-12">
                                                <div className='depositBounsModal deposit-bonus-card'>
                                                    <div className='row gap-3 h-100'>
                                                        <div className='col-12'>
                                                            <div className='row'>
                                                                <div className='col'>
                                                                    <div className="depositeheading">Spin Wheel <span className='quetionImg '>
                                                                        <img src="/assets/img/bouns/Question.png" alt="" />
                                                                        <div class="tooltip">
                                                                            <div className='toltipContent'>There are two ways to get the lucky spin:</div>
                                                                            <ul className='ps-3'>
                                                                                <li className='toltipContent'>You will receive 1 free spin every day once you reach a specific wager amount.</li>
                                                                                <li className='toltipContent'>Starting from VIP level 8, you will receive one free spin each time you level up, and there is no limit to the number of spins you can earn.</li>
                                                                            </ul>
                                                                        </div>
                                                                    </span> </div>
                                                                </div>
                                                                {/* <div className='col-auto'>
                                            <div>
                                                <button className='moreDetails'>View details <span className='arrow'><img src="/assets/img/bouns/rightArrowgreen.png" alt="" /></span></button>
                                            </div>
                                        </div> */}
                                                            </div>
                                                        </div>
                                                        <div className='col-12 '>
                                                            <div className='row align-items-center'>
                                                                <div className='col'>
                                                                    <div className='row gap-3'>

                                                                        <div className='col-12'>
                                                                            <div className='row'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Spins available</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>
                                                                                        {userBonusDetail?.bonusData?.freeSpinLeft}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='row align-items-center'>
                                                                                <div className='col'>

                                                                                    <div className='bonusheadingHunt'>Daily Spin</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusSmall'>Wager Req.</div>
                                                                                    <div className='bonusheadingHunt'>$0.00/$733.79</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='row'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>VIP Spin</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>Reach VIP 8</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-auto'>
                                                                    <div className='spinwheel '>
                                                                        <img src="/assets/img/bouns/spinWheel.png" alt="" className='h-100 w-100' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 text-center mt-auto">
                                                            <button
                                                                className='registernowBtn disabled mx-auto'
                                                            // className='bounesHistory d-flex align-items-center justify-content-center mx-auto w-auto'
                                                            >
                                                                {/* <span className='icon me-2'><img src={clock} alt="" /></span> Daily Wager Expires in 18h:13m:36s */}
                                                                coming soon
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row general-bonus-container depositBounsModal pt-md-5 pt-3 pb-3'>
                                    <div className='col-12'>
                                        <div className='row align-items-center'>
                                            <div className='col-auto'>
                                                <div className="heading ">
                                                    <span>VIP</span>  Bonus
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className='commingSoon'>
                                                    coming soon
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 mt-4'>
                                        <div className='row g-3'>
                                            <div className='col-xl-4 col-md-6 col-12'>
                                                <div className=' deposit-bonus-card'>
                                                    <div className='row h-100 gap-3'>
                                                        <div className='col-12'>
                                                            <div className='row align-items-center'>
                                                                <div className='col'>
                                                                    <div className="depositeheading">Level Up Rewards </div>

                                                                    <div className="subTitleVip">Level up to get exclusive rewards.</div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <div className='upimg'><img src="/assets/img/bouns/upVip.png" alt="" /></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 '>
                                                            <div className='row align-items-center'>
                                                                <div className='col-9'>
                                                                    <div className='row gap-2'>
                                                                        <div className='col-12'>
                                                                            <div className='row'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Current VIP</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>VIP0 </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='row align-items-center'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>XP to Next Level</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>0 / 1</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='row align-items-center'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Weekly Hunts</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>-</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className='col-auto'>
                                            <div className='spinwheel '>
                                            <img src={spinwheel} alt="" className='h-100 w-100' />
                                            </div>
                                        </div> */}
                                                            </div>
                                                        </div>
                                                        <div className='col-12 text-center mt-auto'>
                                                            <button className='bounesHistory unlockDbl mx-auto px-4'>COMING SOON</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-xl-4 col-md-6 col-12'>
                                                <div className=' deposit-bonus-card'>
                                                    <div className='row h-100 gap-3'>
                                                        <div className='col-12'>
                                                            <div className='row align-items-center'>
                                                                <div className='col'>
                                                                    <div className="depositeheading">Supercharge <span className='quetionImg '> <img src="/assets/img/bouns/Question.png" alt="" /></span> </div>

                                                                    {/* <div className="subTitleVip">Level up to get exclusive rewards.</div> */}
                                                                </div>
                                                                <div className="col-auto">
                                                                    <div className='arowplanImg'><img src="/assets/img/bouns/arrowplan.png" alt="" /></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 '>
                                                            <div className='row align-items-center'>
                                                                <div className='col-9'>
                                                                    <div className='row gap-2'>
                                                                        <div className='col-12'>
                                                                            <div className='row'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Wager required</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>$ 1,000 </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='row align-items-center'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Current Tier</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>-</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='row align-items-center'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Supercharge Rate</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>-</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='row align-items-center'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Ready to claim</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>-</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className='col-auto'>
                                            <div className='spinwheel '>
                                            <img src={spinwheel} alt="" className='h-100 w-100' />
                                            </div>
                                        </div> */}
                                                            </div>
                                                        </div>
                                                        <div className='col-12 text-center mt-auto'>
                                                            <button className='bounesHistory unlockDbl mx-auto px-4'>COMING SOON</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-xl-4 col-md-6 col-12'>
                                                <div className=' deposit-bonus-card'>
                                                    <div className='row h-100 gap-3'>
                                                        <div className='col-12'>
                                                            <div className='row align-items-center'>
                                                                <div className='col'>
                                                                    <div className="depositeheading">Weekly Cashback <span className='quetionImg '> <img src="/assets/img/bouns/Question.png" alt="" /></span> </div>
                                                                    <div className="subTitleVip">Receive every Friday</div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <div className='arowplanImg'><img src="/assets/img/bouns/vipcalender.png" alt="" /></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 '>
                                                            <div className='row align-items-center'>
                                                                <div className='col-9'>
                                                                    <div className='row gap-2'>
                                                                        <div className='col-12'>
                                                                            <div className='row'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Wager required</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>$ 1,000 </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='row align-items-center'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Current Tier</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>-</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='row align-items-center'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Supercharge Rate</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>-</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='row align-items-center'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Ready to claim</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>-</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className='col-auto'>
                                            <div className='spinwheel '>
                                            <img src={spinwheel} alt="" className='h-100 w-100' />
                                            </div>
                                        </div> */}
                                                            </div>
                                                        </div>
                                                        <div className='col-12 text-center mt-auto'>
                                                            <button className='bounesHistory unlockDbl mx-auto px-4'>COMING SOON</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-xl-4 col-md-6 col-12'>
                                                <div className=' deposit-bonus-card'>
                                                    <div className='row h-100 gap-3'>
                                                        <div className='col-12'>
                                                            <div className='row align-items-center'>
                                                                <div className='col'>
                                                                    <div className="depositeheading">Monthly Cashback <span className='quetionImg '> <img src="/assets/img/bouns/Question.png" alt="" /></span> </div>
                                                                    <div className="subTitleVip">Receive every 15th of the month</div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <div className='arowplanImg'><img src="/assets/img/bouns/greencalendervip.png" alt="" /></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 '>
                                                            <div className='row align-items-center'>
                                                                <div className='col-9'>
                                                                    <div className='row gap-2'>
                                                                        <div className='col-12'>
                                                                            <div className='row'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Wager required</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>$ 1,000 </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='row align-items-center'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>The last monthly bonus</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>-</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className='col-auto'>
                                            <div className='spinwheel '>
                                            <img src={spinwheel} alt="" className='h-100 w-100' />
                                            </div>
                                        </div> */}
                                                            </div>
                                                        </div>
                                                        <div className='col-12 text-center mt-auto'>
                                                            <button className='bounesHistory unlockDbl mx-auto px-4'>COMING SOON</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-xl-4 col-md-6 col-12'>
                                                <div className=' deposit-bonus-card'>
                                                    <div className='row h-100 gap-3'>
                                                        <div className='col-12'>
                                                            <div className='row align-items-center'>
                                                                <div className='col'>
                                                                    <div className="depositeheading">Sports Weekly Bonus <span className='quetionImg '> <img src="/assets/img/bouns/Question.png" alt="" /></span> </div>
                                                                    <div className="subTitleVip">Receive every Saturday</div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <div className='arowplanImg'><img src="/assets/img/bouns/ball.png" alt="" /></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 '>
                                                            <div className='row align-items-center'>
                                                                <div className='col-9'>
                                                                    <div className='row gap-2'>
                                                                        <div className='col-12'>
                                                                            <div className='row'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>Wager required</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>$ 500</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <div className='row align-items-center'>
                                                                                <div className='col'>
                                                                                    <div className='bonusheadingHunt'>The last weekly bonus</div>
                                                                                </div>
                                                                                <div className='col-auto'>
                                                                                    <div className='bonusheadingHunt'>-</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className='col-auto'>
                                            <div className='spinwheel '>
                                            <img src={spinwheel} alt="" className='h-100 w-100' />
                                            </div>
                                        </div> */}
                                                            </div>
                                                        </div>
                                                        <div className='col-12 text-center mt-auto'>
                                                            <button className='bounesHistory unlockDbl mx-auto px-4'>COMING SOON</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row general-bonus-container depositBounsModal pb-md-5 pb-3">
                                    <div className='col-12'>
                                        <div className='row align-items-center'>
                                            <div className='col-auto'>
                                                <div className="heading ">Special Bonus</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 mt-4'>
                                        <div className='deposit-bonus-card spicalBounsCard'>
                                            <div className='imgSpicalBouns mx-auto'>
                                                <img src="/assets/img/bouns/Isolation_Mode.png" alt="" />
                                            </div>
                                            <div className='spicalbounseHeading'>Oops! There is no data yet!</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
            <BonusDetails
                isOpen={isBonusDetails}
                setIsOpen={setIsBonusDetails}
            />
            <DepositBonusRules
                isOpen={isBonusRules}
                setIsOpen={setIsBonusRules}
            />
            <Hunthub
                isOpen={isHuntsHub}
                setIsOpen={setIsHuntsHub}
            />

            <OnekRace
                isOpen={isOneRake}
                setIsOpen={setIsOneRake}
                isRaceStats={isRaceStats}
                setIsRaceStats={setIsRaceStats}
            />

            <RaceStats
                isOpen={isRaceStats}
                setIsOpen={setIsRaceStats}

            />

            <DBLRackBack
                // isOpen={isDBKRaceBack}
                // setIsOpen={setIsDBKRaceBack}
                // isDBLBonusHistory={isDBLBonusHistory}
                setIsDBLBonusHistory={setIsDBLBonusHistory}

            />

            <DBLBonusHistory
                isOpen={isDBLBonusHistory}
                setIsOpen={setIsDBLBonusHistory}

            />



        </>
    )
}

export default Bouns