import { useContext } from "react"
import { WalletModalContext } from "../context/walletModalContext"
import { SpinContext } from "../context/spinWheelContext"


export const useSpin = () => {
    const { spinObject, setSpinObject } = useContext(SpinContext)
    return {
        spinObject, 
        setSpinObject
    }
}
