import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import { useState } from "react";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTES_CONST } from "../../constants/routeConstant";
import { getAPIAuth } from "../../service/apiInstance";
import SkeletonLoader from "../skeletonLoader/SkeletonLoader";

const ProviderSlider = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [providers, setProviders] = useState([]);
  const navigate = useNavigate()
  const { t } = useTranslation()
  const PlaySlotLabels = t("PlaySlotLabels", { returnObjects: true })
  const commonText = t("commonText", { returnObjects: true })
  const casinoLabels = t("casinoLabels", { returnObjects: true })
  
  const getData = async () => {
    try {
      const res = await getAPIAuth("games/get-all-providers?limit=10");
      if (res.data.success) {
        setProviders(res.data.data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="gamesPage">
      <div className="container-fluid position-relative providerSection mt-0 toprated-games-main toprated-games-mainLive mb-4 mb-lg-5">
        <div className="row toprated-games position-relative ">
          <div className="col-12 px-0">
            <div className="d-flex align-items-center justify-content-between">
              <div className="gamesHeading d-flex align-items-center gap-2">
                {/* <span className="gamesHeadingIcon">
                  <img
                    src="assets/img/sidebar/provider.png"
                    alt="related games"
                    className="h-100 w-100  img-fluid"
                  />
                </span> */}
                {PlaySlotLabels.PROVIDERS}
              </div>
                        <div className="col-auto">
                            <div className="d-flex align-items-center gap-2">
                            <div className='commonNavBtn newGamesPrevBtn providerPrev d-flex'>
                                <img src="assets/img/prevImg.png" alt="" />
                            </div>
                            <div className='commonNavBtn newGamesNextBtn  providerNext d-flex'>
                                <img src="assets/img/nextImg.png" alt="" />
                            </div>
                            </div>
                        </div>
            </div>
          </div>
          <div className="col-12 games-card-main pt-3 px-0">
            <Swiper
              className="mySwiper sportsSwiper"
              navigation={{
                nextEl: ".providerNext",
                prevEl: ".providerPrev",
              }}
              modules={[Navigation,]}
              // breakpoints={{
              //   0: {
              //     slidesPerView: 3,
              //     // centeredSlides: true,
              //     spaceBetween: 8,
              //   },
              //   576: {
              //     slidesPerView: 4,
              //     spaceBetween: 10,
              //   },
              //   768: {
              //     slidesPerView: 5,
              //     spaceBetween: 10,
              //   },
              //   992: {
              //     slidesPerView: 5,
              //     spaceBetween: 10,
              //   },
              //   1200: {
              //     slidesPerView: 6,
              //     spaceBetween: 10,
              //   },
              //   1400: {
              //     slidesPerView: 7,
              //     spaceBetween: 10,
              //   },
              // }}
              slidesPerView={'auto'}
              spaceBetween={11}
            >
              <div className="swiper-wrapper">
                {isLoading ? (
                              [1,2,3,4,5,6,7,8,9,10]?.map((item) => (
                                <SwiperSlide
                                  key={`provider${item}`}
                                  className="swiper-slide cursor-pointer providerSliderCard"
                                  // style={{width: 150}}
                                >
                                  <SkeletonLoader type={'provider'}/>
                                </SwiperSlide>
                              ))
                ) : providers?.length === 0 ? (
                  <div className="w-100 h-100">
                    <h6 className="text-light">{commonText.NoDataAvailable}</h6>
                  </div>
                ) : (
                  providers?.map((item) => (
                    <SwiperSlide
                      key={item?._id}
                      className="swiper-slide cursor-pointer providerSliderCard"
                      // onClick={() => navigate("/all-games", { state: { provider_id: item?.provider } })}
                      onClick={() => navigate(`${ROUTES_CONST.PROVIDERS}/${item?.provider}`)}
                    >
                      <div className="providerCard d-flex align-items-center flex-column">
                        <div className="providerImg">
                          <img
                            src={item.image}
                            alt="provider"
                            className="w-100 h-100 object-fit-contain"
                            loading='lazy'
                            // height={40}
                            // width={117}
                          />
                        </div>
                        {/* <div className="text-white mt-1" style={{fontSize: 12}}>
                          {item?.gameCount} Games
                        </div> */}
                      </div>
                    </SwiperSlide>
                  ))
                )}
              </div>
            </Swiper>
            <span
              className="swiper-notification"
              aria-live="assertive"
              aria-atomic="true"
            />
            <span
              className="swiper-notification"
              aria-live="assertive"
              aria-atomic="true"
            />
          </div>
        </div >
      </div >
    </div>
  );
};

export default ProviderSlider;
