import { createContext, useContext, useEffect, useState } from "react";
import { BT_TOKEN_NAME } from "../constants";



export const AuthBtContext = createContext(null)

const AuthBtContextProvider = ({ children }) => {
    // const [btToken, setBtToken] = useContext(AuthContext)
    const [btToken, setBtToken] = useState(() => {
        const storeToken = localStorage.getItem(BT_TOKEN_NAME)
        return storeToken
    })
    useEffect(() => {
        if (btToken) {
            localStorage.setItem(BT_TOKEN_NAME, btToken);
        } else {
            //remove btToken
            // localStorage.removeItem(BT_TOKEN_NAME);
        }
    }, [btToken])

    return (
        <AuthBtContext.Provider value={{ btToken, setBtToken }}>
            {children}
        </AuthBtContext.Provider>
    )

}

export default AuthBtContextProvider
