import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../hooks/useAuth'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useClaimBonus } from '../../../hooks/useClaimBonus'
import { useGames } from '../../../hooks/useGame'
import { getAPIAuth } from '../../../service/apiInstance'
import { succesToaster } from '../../../utils/toaster'
import { getUserBonusDetails } from '../../../store/action'
import { Modal } from 'react-bootstrap'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import { GAME_OPTION_TABS } from '../../../constants'




const DBLRackBack = ({ setIsDBLBonusHistory }) => {
    const [data, setData] = useState({})
    const { token } = useAuth()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { claimBonusObject, setClaimBonusObject } = useClaimBonus()
    const [query, setQuery] = useSearchParams()
    const { gameObject, setGameObject } = useGames()



    const handleClose = () => {
        setClaimBonusObject(prev => ({
            ...prev,
            isClaimBonusOpen: false,
            // isClaimHistoryOpen: false
        }))
    }

    const getCashbackDetails = async () => {
        // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws'
        try {
            const res = await getAPIAuth(`user/get-claim-data?type=${claimBonusObject?.selectedTab === 'deposit' || claimBonusObject?.selectedTab === 'daily' ? 'lockedBonus' : claimBonusObject?.selectedTab}`, token)
            if (res?.data?.status) {
                setData(res?.data?.data)
            } else {
                setData({})
            }
        } catch (error) {
            setData({})
        }
    }

    useEffect(() => {
        if (claimBonusObject?.isClaimBonusOpen) {
            getCashbackDetails()
        }
    }, [claimBonusObject?.isClaimBonusOpen, claimBonusObject?.selectedTab])

    // const checkStatus = () => {
    //     if (data?.userId) {
    //         if (data?.isExpired) {
    //             return 'disabled'
    //         } else if (data?.isClaimed) {
    //             return 'disabled'
    //         } else {
    //             return ''
    //         }
    //     } else {
    //         return 'disabled'
    //     }
    // }

    const handleBonusClaim = async () => {
        // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws'
        try {
            let res = ''
            res = await getAPIAuth(`user/claimBonus?type=${claimBonusObject?.selectedTab === 'deposit' || claimBonusObject?.selectedTab === 'daily' ? 'lockedBonus' : claimBonusObject?.selectedTab}`, token)
            if (res?.data?.status) {
                if (claimBonusObject?.selectedTab === 'deposit' || claimBonusObject?.selectedTab === 'daily') {
                    handleClose()
                    getCashbackDetails()

                } else {
                    getCashbackData()
                    handleClose()
                }
                succesToaster('Bonus Claimed Sussessfully')
                // setBonusData(res?.data?.data)
            } else {
                // setBonusData({})
            }
        } catch (error) {
            getCashbackDetails({})
        }
    }


    const getCashbackData = async () => {
        // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws'
        try {
            const res = await getAPIAuth(`user/get-userbonus-stats`, token)
            if (res?.data?.success) {
                dispatch(getUserBonusDetails({
                    bonusData: res?.data?.data?.[0],
                    lockedStatus: res.data?.lockStatus
                }))
                // setLockStatus(res.data?.lockStatus)
                // setBonusData(res?.data?.data?.[0])
            }
        } catch (error) {

        }
    }
    return (
        <>
            <Modal className='modalSection' centered show={claimBonusObject?.isClaimBonusOpen} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>

                        {
                            claimBonusObject?.selectedTab === 'weekly' ? 'Weekly Claim' :
                                claimBonusObject?.selectedTab === 'monthly' ? 'Monthly Claim' :
                                    claimBonusObject?.selectedTab === 'deposit' ? 'Deposit Claim' :
                                        claimBonusObject?.selectedTab === 'rackback' ? 'DBL Rakeback' :
                                            claimBonusObject?.selectedTab === 'daily' ? 'Daily Claim' : 'Cashback Claim'
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='dblrackback'>
                                <div className='dblImage mx-auto'>
                                    <img src="/assets/img/bouns/DBL.png" alt="" className='w-100 h-100' />
                                </div>
                                <div className='dblheaidng'>Unlocked DBL</div>
                                <div className='text-center mt-3'>
                                    <div className='dblValue mx-auto'>
                                        <span className='valueimg'><img src="/assets/img/bouns/DBL.png" alt="dblImg" className='h-100 w-100' /></span>
                                        {
                                            claimBonusObject?.selectedTab === 'deposit' || claimBonusObject?.selectedTab === 'daily' ?
                                                <>
                                                    {data?.unlockAmount ? Number(data?.unlockAmount)?.toFixed(2) : 0}
                                                </>
                                                : <>
                                                    {data?.[0]?.amount ? Number(data?.[0]?.amount)?.toFixed(2) : 0}
                                                </>
                                        }
                                    </div>
                                </div>
                                <div className='dblheaidng'>Minimum to claim: 5 DBL</div>
                                <div className='text-center mt-2'>
                                    {/* <button className='registernowBtn '>Claim</button> */}
                                    {
                                        claimBonusObject?.selectedTab === 'deposit' || claimBonusObject?.selectedTab === 'daily' ?
                                            <>
                                                <button className={`registernowBtn ${data?.claminStatus ? '' : 'disabled'}`}
                                                    onClick={handleBonusClaim}>Claim</button>
                                            </> :
                                            <>
                                                <button className={`registernowBtn`}
                                                    onClick={handleBonusClaim}>Claim</button>
                                                <div className="modalTxt">Claim before: <span>
                                                    {/* <TimerComponen targetTime={data?.[0]?.expireDate}/> */}
                                                </span></div>
                                            </>
                                    }
                                </div>

                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='lockeddbl'>
                                <div className='row align-items-center'>
                                    <div className='col'>
                                        <div className='loackheading'>  <span className='lockImg'><img src="/assets/img/bouns/lock.png" alt="" className='h-100 w-100' /></span> Locked DBL:</div>
                                    </div>
                                    <div className='col-auto'>
                                        <div className='loackcontent'>
                                            <div className='loackValue'><span >
                                                {
                                                    claimBonusObject?.selectedTab === 'deposit' || claimBonusObject?.selectedTab === 'daily' ?
                                                        <>
                                                            {data?.lockedUsd ? Number(data?.lockedUsd)?.toFixed(2) : 0}
                                                        </> :
                                                        <>
                                                            {data?.[0]?.lockedUsd ? Number(data?.[0]?.lockedUsd)?.toFixed(2) : 0}
                                                        </>
                                                }
                                            </span> DBL</div>
                                            <div className='loacksubvalue'>$ 0.00</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='claimHeading py-2'>How To Claim</div>
                        </div>
                        <div className='col-12'>
                            <div className='viewDetails'>
                                <div className='detailsSection'>
                                    <div className='row align-items-end '>
                                        <div className='col-sm'>
                                            <div className='viewDetailsheading'>
                                                Unlock amount = wager amount * 1% * 20%
                                            </div>
                                            <div className='viewDetailsheading mt-2'>You still need to wager about AED 9,179.22 more in order to reach minimum unlockable amount of 5 DBL</div>
                                        </div>
                                        <div className='col-sm-auto'>
                                            <div>
                                                <a href="javascript:;" className='moreDetailsbtn d-flex align-items-center justify-content-end'>View Details</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=''>
                                    <ul className='m-0 mt-2 list-unstyled d-flex align-items-center w-100 gap-2 mainDivBtn '>
                                        <li>
                                            <button
                                                onClick={() => {
                                                    handleClose()
                                                    // navigate(ROUTES_CONST.GAME_PAGE)

                                                    setGameObject((prev) => ({
                                                        selectedTab: GAME_OPTION_TABS?.LOBBY
                                                    }))
                                                    setQuery({ q: "lobby" })
                                                    navigate(`${ROUTES_CONST.CASINO}?q=${encodeURIComponent('lobby')}`)
                                                }}
                                                className='casinoBtn'>Go To Casino</button>
                                        </li>
                                        <li>
                                            <button
                                                onClick={() => {
                                                    handleClose()
                                                    navigate(ROUTES_CONST.SPORTS_PAGE)
                                                }} className='registernowBtn w-100'>Go To Sports</button>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div className='col-12 mt-3'>
                            <button className='bounesHistory'
                                // onClick={() => {
                                //     handleClose()
                                //     setIsDBLBonusHistory(true)
                                // }}
                                onClick={() => {
                                    setClaimBonusObject(prev => ({
                                        ...prev,
                                        isClaimBonusOpen: false,
                                        isClaimHistoryOpen: true
                                    }))
                                }}
                            >DBL Bonus History</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DBLRackBack