import { connect } from 'socket.io-client';

// for production build
export const socket = connect("https://api.memestop.win");
// export const socket = {
//     on:()=>{},
//     emit:()=>{},
//     disconnect:()=>{},
// };


// for development mode
// export const socket = connect("https://dev.api.degenwin.app/");


console.log('socket', socket)


