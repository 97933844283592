import React from 'react'
import { useState } from 'react'
import Crypto from './Crypto'
import Flat from './Flat'

const Deposit = () => {
  const [showCrypto, setShowCrypto] = useState(true)
  return (

    <div className="tab-pane fade show active">
      <div className="row">
        {/* will be of future use  */}
        {/* <nav className="col-12">
            <div className="nav nav-tabs cryptoTab border-0 justify-content-center">
                <button 
                  onClick={()=> setShowCrypto(true)}
                  className={`nav-link cryptotabBtn border-0 ${showCrypto ? 'active' : ''}`}>Crypto</button>
                <button 
                  onClick={()=> setShowCrypto(false)}
                  className={`nav-link cryptotabBtn border-0 ${!showCrypto ? 'active' : ''}`}>Fiat</button>
            </div>
        </nav> */}
        <div className="tab-content col-12 cryptoContent">
          {showCrypto ?
            <Crypto />
            :
            <Flat />
          }
        </div>
      </div>
    </div>
  )
}

export default Deposit