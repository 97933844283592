import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useProfile } from '../../hooks/useProfile';
import './style.scss'
import ViewProfile from './components/ViewProfile';
import { PROFILE_OPTIONS } from '../../constants';
import EditProfile from './components/EditProfile';


const ProfileModal = () => {
  const {profileObject, setProfileObject} = useProfile()

  const handleClose = () => setProfileObject(prev =>({
    ...prev,
    isProfileModalOpen : false
  }));

  return (
    <>
      <Modal 
        centered 
        scrollable
        show={profileObject?.isProfileModalOpen} 
        onHide={handleClose}
        className='profileModal'
        fullscreen={'md-down'}
      >
        <Modal.Header>
          {
            profileObject?.activeTab === PROFILE_OPTIONS.EDIT_PROFILE ? 
            <>
              <div className="row w-100 m-0 g-0">
                <div className="col-auto">
                  <div className="backBtn" onClick={()=> {
                    setProfileObject(prev =>({
                      ...prev,
                      activeTab: PROFILE_OPTIONS?.VIEW_PROFILE
                    }))
                  }}>
                    <svg fill='white' className='' viewBox="0 0 32 32"><path d="M20.1912 6.1001L9.79119 16.5001L20.1912 26.9001L23.2088 23.8825L15.8264 16.5001L23.2088 9.1177L20.1912 6.1001Z"></path></svg>
                  </div>
                </div>
                <div className="col modalTitle" style={{paddingLeft : '2.7rem'}}>Edit Profile</div>
              </div>
            </> : 
            <>
              <div className="row w-100 m-0 g-0">
                <div className="col modalTitle">My Profile</div>
                <div className="col-auto">
                  <img onClick={handleClose} className='closeImg' src="assets/img/heroBanner/closee.png" alt="" />
                </div>
              </div>
            </>
          }
        </Modal.Header>
        <Modal.Body>
          {
            profileObject?.activeTab === PROFILE_OPTIONS.VIEW_PROFILE ? <ViewProfile/> :
            profileObject?.activeTab === PROFILE_OPTIONS.EDIT_PROFILE ? <EditProfile/> :
            ''
          }
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ProfileModal