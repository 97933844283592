import React from 'react'
import AppFooter from '../../components/AppFooter/AppFooter'
import { useTranslation } from 'react-i18next'

const FaqPage = () => {
  const { t } = useTranslation()
  const affiliateLabels = t("affiliateLabels", { returnObjects: true })
  return (
    
    <>
    <main className="main vh-100" id="main">

        <div className="mainContaint">
            <div className="policypage">
            <div className="container-fluid pt-0 faqpage">
                            <div className="policamlHeading policyHeading">
                                {/* {affiliateLabels.FAQ} */}
                                Frequently Asked Questions
                            </div>
                            <div className="faqCard mt-3">
                                <div className="row faqMain">
                                    <div className="col-12">
                                        <div className="faqinner">
                                            <div className="question d-flex align-items-end">
                                                <div className="faqcount">01</div>
                                                <span className='d-inline-block'>{affiliateLabels.What_is_an}</span>
                                            </div>
                                            <div className="answer">
                                                {affiliateLabels.Affiliate_Program_is}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="faqinner">
                                            <div className="question d-flex align-items-end">
                                                <div className="faqcount">02</div>
                                                <span className="d-inline-block">
                                                    {affiliateLabels.How_do_I}</span>
                                            </div>
                                            <div className="answer">
                                                {affiliateLabels.Whenever_a_player}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="faqinner border-0 pb-0">
                                            <div className="question d-flex align-items-end">
                                                <div className="faqcount">03</div>
                                                <span className=" d-inline-block">
                                                    {affiliateLabels.earn_commission}</span>
                                            </div>
                                            <div className="answer">
                                                {affiliateLabels.When_you_refer}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            </div>
        </div>

        <AppFooter />

    </main>
</>
  )
}

export default FaqPage