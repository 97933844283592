// Custom error handling function
export const getCustomAuthError = (error)=> {
  let errorMessage = 'An unknown error occurred';

  switch (error.code) {
    case 'auth/admin-restricted-operation':
      errorMessage = 'This operation is restricted to administrators only.';
      break;
    case 'auth/argument-error':
      errorMessage = 'Invalid argument provided.';
      break;
    case 'auth/app-not-authorized':
      errorMessage = 'This app is not authorized to use Firebase Authentication.';
      break;
    case 'auth/app-not-installed':
      errorMessage = 'The requested app is not installed on this device.';
      break;
    case 'auth/captcha-check-failed':
      errorMessage = 'The reCAPTCHA response token is invalid or has expired.';
      break;
    case 'auth/code-expired':
      errorMessage = 'The SMS code has expired. Please request a new code.';
      break;
    case 'auth/cordova-not-ready':
      errorMessage = 'Cordova framework is not ready.';
      break;
    case 'auth/cors-unsupported':
      errorMessage = 'This browser is not supported.';
      break;
    case 'auth/credential-already-in-use':
      errorMessage = 'This credential is already associated with a different user account.';
      break;
    case 'auth/custom-token-mismatch':
      errorMessage = 'The custom token corresponds to a different audience.';
      break;
    case 'auth/requires-recent-login':
      errorMessage = 'This operation is sensitive and requires recent authentication. Log in again before retrying this request.';
      break;
    case 'auth/dynamic-link-not-activated':
      errorMessage = 'Dynamic links are not activated.';
      break;
    case 'auth/email-already-exists':
      errorMessage = 'The email address is already in use by another account.';
      break;
    case 'auth/email-already-in-use':
      errorMessage = 'The email address is already in use by another account.';
      break;
    case 'auth/expired-action-code':
      errorMessage = 'The action code has expired.';
      break;
    case 'auth/cancelled-popup-request':
      errorMessage = 'This operation was cancelled due to another conflicting popup being opened.';
      break;
    case 'auth/internal-error':
      errorMessage = 'An internal error occurred.';
      break;
    case 'auth/invalid-app-credential':
      errorMessage = 'The phone verification request contains an invalid application verifier.';
      break;
    case 'auth/invalid-app-id':
      errorMessage = 'The mobile app identifier is not registered for the current project.';
      break;
    case 'auth/invalid-user-token':
      errorMessage = 'The user credential is no longer valid. The user must sign in again.';
      break;
    case 'auth/invalid-auth-event':
      errorMessage = 'The authentication event is invalid or malformed.';
      break;
    case 'auth/invalid-verification-code':
      errorMessage = 'Invalid SMS verification code.';
      break;
    case 'auth/invalid-verification-id':
      errorMessage = 'The verification ID used to create the phone auth credential is invalid.';
      break;
    case 'auth/invalid-continue-uri':
      errorMessage = 'The continue URL provided in the request is invalid.';
      break;
    case 'auth/invalid-cordova-configuration':
      errorMessage = 'The provided Cordova configuration is not valid.';
      break;
    case 'auth/invalid-custom-token':
      errorMessage = 'The custom token format is invalid or the token is invalid for some reason.';
      break;
    case 'auth/invalid-dynamic-link-domain':
      errorMessage = 'The provided dynamic link domain is not configured or authorized for the current project.';
      break;
    case 'auth/invalid-email':
      errorMessage = 'The email address is not valid.';
      break;
    case 'auth/invalid-emulator-scheme':
      errorMessage = 'The emulator scheme specified is not valid.';
      break;
    case 'auth/invalid-api-key':
      errorMessage = 'Your API key is invalid, please check you have copied it correctly.';
      break;
    case 'auth/invalid-cert-hash':
      errorMessage = 'The SHA-1 certificate hash provided is invalid.';
      break;
    case 'auth/invalid-credential':
      errorMessage = 'The supplied auth credential is malformed or has expired.';
      break;
    case 'auth/invalid-display-name':
      errorMessage = 'The display name is invalid.';
      break;
    case 'auth/invalid-email-verified':
      errorMessage = 'The email verified status is invalid.';
      break;
    case 'auth/invalid-hash-algorithm':
      errorMessage = 'The hash algorithm must match one of the strings in the list of supported algorithms.';
      break;
    case 'auth/invalid-hash-block-size':
      errorMessage = 'The hash block size must be a valid number.';
      break;
    case 'auth/invalid-hash-derived-key-length':
      errorMessage = 'The derived key length must be a valid number.';
      break;
    case 'auth/invalid-hash-key':
      errorMessage = 'The hash key must be a valid byte buffer.';
      break;
    case 'auth/invalid-hash-memory-cost':
      errorMessage = 'The memory cost must be a valid number.';
      break;
    case 'auth/invalid-hash-parallelization':
      errorMessage = 'The parallelization must be a valid number.';
      break;
    case 'auth/invalid-hash-rounds':
      errorMessage = 'The rounds must be a valid number.';
      break;
    case 'auth/invalid-hash-salt-separator':
      errorMessage = 'The hashing algorithm salt separator field must be a valid byte buffer.';
      break;
    case 'auth/invalid-id-token':
      errorMessage = 'The provided ID token is not valid.';
      break;
    case 'auth/invalid-last-sign-in-time':
      errorMessage = 'The last sign-in time must be a valid UTC date string.';
      break;
    case 'auth/invalid-page-token':
      errorMessage = 'The provided next page token in listUsers() is invalid.';
      break;
    case 'auth/invalid-password':
      errorMessage = 'Invalid Password';
      break;
    case 'auth/invalid-password-hash':
      errorMessage = 'The password hash must be a valid byte buffer.';
      break;
    case 'auth/invalid-password-salt':
      errorMessage = 'The password salt must be a valid byte buffer.';
      break;
    case 'auth/invalid-phone-number':
      errorMessage = 'Invalid Phone Number';
      break;
    case 'auth/invalid-provider-id':
      errorMessage = 'The specified provider ID is invalid.';
      break;
    case 'auth/invalid-recipient-email':
      errorMessage = 'The email corresponding to this action failed to send as the provided recipient email address is invalid.';
      break;
    case 'auth/invalid-sender':
      errorMessage = 'The email template corresponding to this action contains an invalid sender email or name.';
      break;
    case 'auth/invalid-tenant-id':
      errorMessage = 'The Auth instance\'s tenant ID is invalid.';
      break;
    case 'auth/missing-android-pkg-name':
      errorMessage = 'An Android package name must be provided if the Android app is required to be installed.';
      break;
    case 'auth/missing-continue-uri':
      errorMessage = 'A valid continue URL must be provided in the request.';
      break;
    case 'auth/missing-iframe-start':
      errorMessage = 'An internal error occurred.';
      break;
    case 'auth/missing-ios-bundle-id':
      errorMessage = 'An iOS Bundle ID must be provided if an App Store ID is provided.';
      break;
    case 'auth/missing-phone-number':
      errorMessage = 'To send verification codes, provide a phone number for the recipient.';
      break;
    case 'auth/missing-verification-code':
      errorMessage = 'The SMS verification code is missing.';
      break;
    case 'auth/missing-verification-id':
      errorMessage = 'The verification ID is missing.';
      break;
    case 'auth/multi-factor-auth-required':
      errorMessage = 'Multi-factor authentication required.';
      break;
    case 'auth/network-request-failed':
      errorMessage = 'A network error occurred. Please try again.';
      break;
    case 'auth/operation-not-allowed':
      errorMessage = 'The operation is not allowed. Please enable it in the Firebase console.';
      break;
    case 'auth/popup-blocked':
      errorMessage = 'The popup was blocked. Please allow popups and try again.';
      break;
    case 'auth/popup-closed-by-user':
      errorMessage = 'The popup was closed by the user before finalizing the operation.';
      break;
    case 'auth/provider-already-linked':
      errorMessage = 'The user can only be linked to one identity for the given provider.';
      break;
    case 'auth/quota-exceeded':
      errorMessage = 'The quota for this operation has been exceeded. Please try again later.';
      break;
    case 'auth/redirect-cancelled-by-user':
      errorMessage = 'The redirect operation has been cancelled by the user before finalizing.';
      break;
    case 'auth/redirect-operation-pending':
      errorMessage = 'A redirect operation is already pending.';
      break;
    case 'auth/rejected-credential':
      errorMessage = 'The request contains malformed or mismatching credentials.';
      break;
    case 'auth/second-factor-already-in-use':
      errorMessage = 'The second factor is already enrolled for this user.';
      break;
    case 'auth/tenant-id-mismatch':
      errorMessage = 'The provided tenant ID does not match the Auth instance\'s tenant ID.';
      break;
    case 'auth/timeout':
      errorMessage = 'The operation has timed out.';
      break;
    case 'auth/too-many-requests':
      errorMessage = 'Too many requests. Try again later.';
      break;
    case 'auth/unauthorized-domain':
      errorMessage = 'The domain of the continue URL is not whitelisted. Please whitelist the domain in the Firebase console.';
      break;
    case 'auth/unsupported-first-factor':
      errorMessage = 'The first factor is not supported.';
      break;
    case 'auth/unsupported-persistence-type':
      errorMessage = 'The current environment does not support the specified persistence type.';
      break;
    case 'auth/unsupported-tenant-operation':
      errorMessage = 'This operation is not supported in a multi-tenant context.';
      break;
    case 'auth/unverified-email':
      errorMessage = 'The operation requires a verified email.';
      break;
    case 'auth/user-cancelled':
      errorMessage = 'The user did not grant your application the permissions it requested.';
      break;
    case 'auth/user-disabled':
      errorMessage = 'The user account has been disabled by an administrator.';
      break;
    case 'auth/user-mismatch':
      errorMessage = 'The supplied credentials do not correspond to the previously signed in user.';
      break;
    case 'auth/user-not-found':
      errorMessage = 'There is no user record corresponding to this identifier. The user may have been deleted.';
      break;
    case 'auth/user-signed-out':
      errorMessage = 'The user has been signed out.';
      break;
    case 'auth/weak-password':
      errorMessage = 'The password is too weak.';
      break;
    case 'auth/web-storage-unsupported':
      errorMessage = 'This browser is not supported or 3rd party cookies and data may be disabled.';
      break;
    case 'auth/wrong-password':
      errorMessage = 'The password is invalid or the user does not have a password.';
      break;
    case 'auth/unexpected-error':
      errorMessage = 'An unexpected error has occurred.';
      break;
    default:
      errorMessage = error.message; // Return the original error message if the code is not mapped
      break;
  }

  return errorMessage;
}

