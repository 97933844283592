import React from "react";

const Chart = ({ selectedYear }) => {
  return (
      <>
        <div className="chartImg text-center mt-1">
          {
            selectedYear === 1 ? <img className="w-75" src="./assets/img/stacking/year1.png" alt="" /> :
            selectedYear === 2 ? <img className="w-75" src="./assets/img/stacking/year2.png" alt="" /> :
            selectedYear === 3 ? <img className="w-75" src="./assets/img/stacking/year3.png" alt="" /> :
            selectedYear === 5 ? <img className="w-75" src="./assets/img/stacking/year5.png" alt="" /> : ''
          }
        </div>
      </>
  )
};

export default Chart;
