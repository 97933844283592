import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { getAPIAuth, postAPIAuth } from '../../service/apiInstance';
import { useAuth } from '../../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import Fiat from './../newWallet/components/Fiat';
import { getFavCryptoAction, getFavFiatAction } from '../../store/action';

const AddCurrency = ({ showFavoriteModal, setShowFavoriteModal }) => {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const handleClose = () => {
    setShowFavoriteModal(false);
    setActiveTab('1');
  };
  const [cryptoList, setCryptoList] = useState([]);
  const [fiatList, setFiatList] = useState([]);
  const [MyFavList, setMyFavList] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const [search, setSearch] = useState('')
  const [isChecked, setIsChecked] = useState(false);
  const userDetails = useSelector(state => state.userDetails)
  const [isLoading, setLoading] = useState(false);
  const [filteredMyFavList, setFilteredMyFavList] = useState([])

  const getAvailableCrypto = async () => {
    try {
      const res = await getAPIAuth(`crypto/get-crypto?userId=${userDetails?.id}&crypto=${search}`, token);
      if (res?.data?.success) {
        setCryptoList(res.data.data);
      }
    } catch (error) {
    }
  }
  const getFiatCurrency = async () => {
    try {
      const res = await getAPIAuth(`currency/get-currency?userId=${userDetails?.id}&currency=${search}`, token);
      setFiatList(res.data.data);
    } catch (error) {
    }
  }


  const handleChecked = async (e, item) => {
    setIsChecked(e.target.checked);

    if (item?.type === "crypto" && activeTab === '1') {
      const body = {
        crypto_id: item._id,
        userid: userDetails?.id
      }
      const res = await postAPIAuth("crypto/save-crypt-favorite", body)
      getAvailableCrypto();
    }
    else if (item?.type === 'currency' && activeTab === '2') {
      const body = {
        currency_id: item._id,
        userid: userDetails?.id
      }
      const res = await postAPIAuth("currency/save-curr-favorite", body)
      getFiatCurrency();
    }

  }
  const getFav = async () => {
    try {
      const res = await getAPIAuth(`crypto/get-fav-currency-crypto?userId=${userDetails?.id}&favcryptocurrency=${search}`);
      if (res?.data?.success) {
        const crypto = res?.data?.crypto
        const fiat = res?.data?.fiat
        const newArray = [...crypto, ...fiat]
        setMyFavList(newArray);
        setFilteredMyFavList(newArray)
        dispatch(getFavFiatAction(res?.data?.fiat ? res.data.fiat : []))
        dispatch(getFavCryptoAction(res?.data?.crypto ? res.data.crypto : []))
      }
    } catch (error) {

    }
  }

  const filterMyList = () => {
    const filterData = MyFavList.filter(item => item.code.toLowerCase().includes(search.toLowerCase()) || item.name.toLowerCase().includes(search.toLowerCase()))
    console.log("filterData",{ filterData,search })
    setFilteredMyFavList(filterData)
  }
  useEffect(() => {
    if (userDetails.id) {
      getAvailableCrypto();
      getFiatCurrency();
      if (search) {
        filterMyList()
      } else {
        getFav();

      }
    }
  }, [userDetails.id, activeTab, search])
  return (
    <>
      <Modal show={showFavoriteModal} className='viewInFiatModal addCurrencyModal' scrollable centered onHide={handleClose}

      >
        <Modal.Header>
          <div className="row w-100 mx-0 align-items-center">
            <div className="col px-0">
              <div className="searchInp">
                <img src="./assets/img/searchGray.png" alt="" />
                <input type="text" className="form-control shadow-none" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
              </div>
            </div>
            <div className="col-auto pe-0">
              <img className='closeBtn' onClick={handleClose} src="assets/img/sidebar/close.png" alt="" />
            </div>
            <div className="col-12 px-0 mb-1">
              <div className="dropTabs">
                <div className={`tab ${activeTab === '1' ? 'active' : ''}`} onClick={() => setActiveTab('1')}>Crypto</div>
                <div className={`tab ${activeTab === '2' ? 'active' : ''}`} onClick={() => setActiveTab('2')}>Fiat</div>
                <div className={`tab ${activeTab === '3' ? 'active' : ''}`} onClick={() => setActiveTab('3')}>mNFT</div>
                <div className={`tab ${activeTab === '4' ? 'active' : ''}`} onClick={() => setActiveTab('4')}>My Favourite</div>
              </div>
            </div>
            <div className="col px-0 my FavCoin">My Favorite Coins</div>
            <div className="col-auto pe-0">
              <div className="sortBtn">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M348.3 295.6c-5-5.1-13.3-5.1-18.4-.1L269 356.2V124.9c0-7.1-5.8-12.9-13-12.9s-13 5.8-13 12.9v231.3l-60.9-60.8c-5.1-5-13.3-4.9-18.4.1-5 5.1-5 13.2.1 18.3l83 82.4c1.2 1.1 2.5 2 4.1 2.7 1.6.7 3.3 1 5 1 3.4 0 6.6-1.3 9.1-3.7l83-82.4c5.2-4.9 5.3-13.1.3-18.2z"></path></svg>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body
          style={{
            height: "60vh"
          }}
        >
          <div className="row">
            <div className="col-12">
              <ul className='m-0 p-0 list-unstyled usdtImageDropdownInner'>
                {activeTab === '1' ? (
                  <>
                    {
                      cryptoList?.map((item, index) => (
                        <li key={index} className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <img loading='lazy' className="rounded" src={item.icon} alt="" />
                            {item.code}
                          </div>
                          <div className='crName d-flex align-items-center'>
                            {item.name}
                            <div className="checkInp">
                              <input type="checkbox" className='chcck d-none' id={`cta-${item.id}`}
                                checked={item?.isFav}
                                onChange={(e) => handleChecked(e, item)} />
                              <label className='checkLabel' htmlFor={`cta-${item.id}`}></label>
                            </div>
                          </div>
                        </li>
                      ))
                    }
                  </>
                ) : activeTab === '2' ? (
                  <>
                    {fiatList?.map((item, index) => (
                      <li key={index} className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <img loading='lazy' className="rounded" src={item.icon} alt="" />
                          {item.code}
                        </div>
                        <div className='crName d-flex align-items-center'>
                          {item.name}
                          <div className="checkInp">
                            <input type="checkbox" className='chcck d-none' id={`cta-${item.id}`}
                              checked={item.isFav}
                              onChange={(e) => handleChecked(e, item)} />
                            <label className='checkLabel text-white' htmlFor={`cta-${item.id}`}>{item.isFav}</label>
                          </div>
                        </div>
                      </li>
                    ))
                    }
                  </>
                ) : activeTab === '3' ? (
                  <>
                    <div className="innerScrollDiv d-flex justify-content-center align-items-center">
                      <div>
                        <img
                          src="/assets/img/stacking/noData.svg"
                          alt=""
                          style={{
                            width: "150px"
                          }}
                        />
                        <div className="FavCoin mb-1 text-center mt-3">No Data Available</div>
                      </div>

                    </div>
                  </>
                ) : (
                  <>
                    {filteredMyFavList?.map((item, index) => (
                      <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <img loading='lazy' className="rounded" src={item.icon} alt="" />
                          {item.code}
                        </div>
                        <div className='crName d-flex align-items-center'>
                          {item.name}
                          {/* <div className="checkInp">
                           <label className='checkLabel text-white' htmlFor={`cta-${item.id}`}>{item.isFav}</label>
                         </div> */}
                        </div>
                      </li>
                    ))
                    }
                  </>
                )}
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddCurrency