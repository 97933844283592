import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { GoogleLogin } from "../../socialMedia/GoogleLogin";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import * as Yup from "yup";
import { errorToaster } from "../../../utils/toaster";
import MetaMaskModal from "../../socialMedia/MetamaskModal";
import { useAuthModal } from "../../../hooks/useAuthModal";
import TelegramComp from "../../socialMedia/TelegramLoginButton";
import TelegramInputModal from "../../socialMedia/TelegramInputModal";
import { Spinner } from "react-bootstrap";
import { postAPI } from "../../../service/apiInstance";

const validationSchema = Yup.object({
    email: Yup.string()
        .required("Email is required")
        .matches(
            /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
            "Invalid email"
        ),
});

const EmailField = ({
    setPasswordView,
    setActive,
    setUsername,
    setChecks,
    checks,
    Active,
    setEmail,
    referral,
    setReferral,
    setIsReferral
}) => {
    const [isMetamask, setIsMetamask] = useState(false);
    const { authModalObject } = useAuthModal();
    const [isTelegram, setIsTelegram] = useState(false);
    const [userObj, setUserObj] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (values) => {
        if (!checks?.terms) {
            errorToaster("User agreement is required");
            setIsLoading(false);
            return;
        }
        const res = await postAPI("user/check-email", {
            email: values.email,
            refer_code: referral,

        });

        if (res?.data?.status) {
            setEmail(values.email);
            setUsername(values.username);
            setPasswordView(true);
            setActive(false);
            setIsLoading(false);
        } else {
            errorToaster(res?.data?.message)
            setIsLoading(false);
        }

    };

    useEffect(() => {
        if (!Active) {
            setIsLoading(false);
        }
    }, [Active]);

    return (
        <>
            <Formik
                initialValues={{
                    email: "",
                    username: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    setIsLoading(true);
                    onSubmit(values);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                }) => (
                    <form>
                        <div className="row gap-3 gx-0">
                            {/* <div className="col-12 inputdata">
                             
                                <label htmlFor="text" className="inputLabel">
                                    USERNAME
                                    <span>*</span>
                                </label>
                                <input
                                    type="text"
                                    id="text"
                                    className="form-control inputlogin border-0 shadow-none"
                                    placeholder="Username"
                                    value={values.username}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="username"
                                />
                                <ErrorMessage>
                                    {errors.username && touched.username && errors.username}
                                </ErrorMessage>
                            </div> */}
                            <div className="col-12 inputdata">
                                {/* EMAIL */}
                                <label htmlFor="text" className="inputLabel">
                                    EMAIL
                                    <span>*</span>
                                </label>
                                <input
                                    type="text"
                                    id="text"
                                    className="form-control inputlogin border-0 shadow-none"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Email"
                                    value={values.email}
                                    name="email"

                                />
                                <ErrorMessage>
                                    {errors.email &&
                                        touched.email &&
                                        errors.email
                                    }
                                </ErrorMessage>
                            </div>
                            <div className="col-12 inputdata">
                                {/* referral */}
                                <label htmlFor="text" className="inputLabel referralLabel">
                                    Enter Referral/Promo code
                                    <img src="" alt="" />
                                </label>
                                <input
                                    type="text"
                                    id="text"
                                    className="form-control inputlogin border-0 shadow-none"
                                    placeholder="Enter code"
                                    name="referral"
                                    value={referral}
                                    onChange={(e) => setReferral(e.target.value)}
                                />
                            </div>
                            {/* checks */}
                            <div className="col-12 inputdata forgotmain">
                                <div className="form-check ">
                                    <input
                                        className="form-check-input shadow-none"
                                        type="checkbox"
                                        id="flexCheckC"
                                        value={checks.terms}
                                        name="terms"
                                        onChange={(e) =>
                                            setChecks((pre) => ({
                                                ...pre,
                                                [e.target.name]: e.target.checked,
                                            }))
                                        }
                                        checked={checks?.terms}
                                    />

                                    <label
                                        className="form-check-label forgotPassword"
                                        htmlFor="flexCheckC"
                                    >
                                        I agree to the User Agreement & confirm I am at least 18
                                        years old
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* footer section*/}
                        <div className="row mt-4 gap-4">
                            {/* button section*/}
                            <div className="col-12 mx-auto">
                                <span className="playBtn position-relative d-flex align-items-center justify-content-center cursor-pointer">
                                    <button
                                        type="submit"
                                        id="recaptcha2"
                                        className="playBtnInner border-0 d-flex align-items-center justify-content-center w-100"
                                        onClick={handleSubmit}
                                    >
                                        {/* Play now! */}
                                        {isLoading ? (
                                            <Spinner size="sm" color="dark" />
                                        ) : (
                                            "Continue"
                                        )}
                                    </button>
                                </span>
                            </div>

                            {/* divider*/}
                            <div className="col-12 mx-auto ">
                                <div className="loginbutton text-center">
                                    <span className="directLogin text-decoration-none">
                                        Log in directly with
                                    </span>
                                </div>
                            </div>
                            {/* social links*/}
                            <div className="col-12 mx-auto">
                                <div className="row align-items-center justify-content-center g-2">
                                    {/* google */}
                                    <div className="col-auto">
                                        <span className="text-decoration-none">
                                            <div className="link">
                                                <GoogleLogin parent={"signUp"}
                                                    setIsReferral={setIsReferral}
                                                >
                                                    <img src="assets/img/newIcon/google.svg" alt="" />
                                                </GoogleLogin>
                                            </div>
                                        </span>
                                    </div>
                                    {/* <div className="col-auto">
                                            <span className="text-decoration-none">
                                                <div className="link">
                                                    <img src="assets/img/newIcon/telegram.svg" alt="" />
                                                </div>
                                            </span>
                                        </div> */}
                                    {/* metamask */}
                                    {/* <div className="col-auto">
                                        <span className="text-decoration-none cursor-pointer">
                                            <div className="link" onClick={() => {
                                                if (!!!window.ethereum) {
                                                    errorToaster("Metamask browser extension not found")
                                                }else{
                                                    setIsMetamask(true)
                                                }
                                            }}>
                                                <img
                                                    src="assets/img/newIcon/metamask-grayscale-logo.svg"
                                                    alt=""
                                                />
                                            </div>
                                        </span>
                                    </div> */}
                                    <MetaMaskModal
                                        // isOpen={isMetamask}
                                        // setIsOpen={setIsMetamask}
                                        parent={"signUp"}
                                        setIsReferral={setIsReferral}
                                    />
                                    {/* Telegram */}
                                    <TelegramComp
                                        isOpen={isTelegram}
                                        setIsOpen={setIsTelegram}
                                        userObj={userObj}
                                        setUserObj={setUserObj}
                                        setIsReferral={setIsReferral}
                                        authModalObject={authModalObject}

                                    />
                                    {/* <div className="col-auto cursor-pointer">
                                        <span className="text-decoration-none position-relative d-block overflow-hidden">
                                            <div className="link"><img src="assets/img/newIcon/telegram.svg" alt="" /></div>
                                            {authModalObject.isAuthOpen ? (
                                                <div  className="col-auto d-flex justify-content-center position-absolute top-0 bottom-0 start-0 end-0 customBtn1">
                                                    <TelegramComp
                                                        isOpen={isTelegram}
                                                        setIsOpen={setIsTelegram}
                                                        userObj={userObj}
                                                        setUserObj={setUserObj}
                                                        setIsReferral={setIsReferral}
                                                        authModalObject={authModalObject}

                                                    />
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
            {/* <MetaMaskModal
                isOpen={isMetamask}
                setIsOpen={setIsMetamask}
                parent={"signUp"}
                setIsReferral={setIsReferral}
            /> */}
            {/* <TelegramInputModal
                isOpen={isTelegram}
                setIsOpen={setIsTelegram}
                userObj={userObj}
                setUserObj={setUserObj}
                setIsReferral={setIsReferral}
            /> */}
        </>
    );
};

export default EmailField;
