import React, { useEffect, useState } from "react";
import { getAPI, getAPIAuth } from "../../service/apiInstance";
import moment from "moment";
import { useAuth } from "../../hooks/useAuth";
const UserInfoModal = ({ isOpen, setIsOpen, selectedUser }) => {
    const [user, setUser] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const {token} = useAuth()


    const getData = async () => {
        try {
            const res = await getAPIAuth(`user/user-gambling-data?userName=${selectedUser?.userData?.username}`, token)
            if (res.data.success) {
                setUser(res.data.data[0])
            } else {
                setUser({})
            }
        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    }
    useEffect(() => {
        if(isOpen && token) {
            if (selectedUser?.userData?.username) {
                getData()
            }
        }
    }, [selectedUser, token, isOpen])

    return (
        <div
            className={`modal fade coinmodal ${isOpen ? "show" : " "}`}
            id="coinmodal"
            style={{
                display: isOpen ? "block" : "none",
            }}
        >
            <div className="modal-dialog coindialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content modalcontent">
                    <div className="modal-header border-0">
                        <button
                            type="button"
                            style={{top: '20px', right: '20px'}}
                            className="btn-close p0 shadow-none border-0 d-flex align-items-center justify-content-center position-absolute"
                            onClick={() => setIsOpen(false)}
                        >
                            <img src="assets/img/sidebar/close.png" alt="close" className="h-100 w-100 img-fluid" />
                        </button>
                    </div>
                    <div className="modal-body py-0">
                        <div className="row">
                            <div className="col-12">
                                <div className="row align-items-center">
                                    <div className="col-sm-auto">
                                        <div className="d-flex align-items-center gap-3">
                                            <div className="coinIcon d-flex align-items-center justify-content-center">
                                                <img src={user?.currentRankData?.image} alt="coin" className="h-100 w-100 img-fluid" />
                                            </div>
                                            <div className="coinname d-sm-none d-block">
                                                {user?.username ? user?.username : "Guest"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm">
                                        <div className="coinname d-sm-block d-none">
                                            {user?.username ? user?.username : "Guest"}
                                        </div>
                                        <div
                                            className="progress my-3"
                                            role="progressbar"
                                            aria-label="Basic example"
                                            aria-valuenow={75}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        >
                                            <div className="progress-bar " style={{ width: `${user?.wageredAmount / user?.nextRank?.max_amount * 100}%` }} />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between ">
                                            <div className="d-flex align-items-center gap-1">
                                                <div className="rank">
                                                    Rank:
                                                </div>
                                                <div className="rank rankname fw-semibold">
                                                    {user?.currentRankData?.level ? user?.currentRankData?.level : "0"}
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                                <div className="rank">
                                                    Next:
                                                </div>
                                                <div className="rank rankname fw-semibold d-flex align-items-centerv gap-1 ">
                                                    <span
                                                        className="rankcoin d-flex align-items-center justify-content-center">
                                                        <img
                                                            src={user?.nextRank?.image}
                                                            alt="coin"
                                                            className="h-100 w-100 img-fluid"
                                                        />
                                                    </span>
                                                    {user?.nextRank?.level ? user?.nextRank?.level : "0"}
                                                </div>
                                                <div className="rank">
                                                    ${user?.nextRank?.max_amount ? user?.nextRank?.max_amount : "00"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 pt-sm-5 pt-4 pb-3 my-sm-0 my-2">
                                <div className="rankcard rounded-2">
                                    <div className="row rankdata py-1 mx-0">
                                        <div className="col-12">
                                            <div className="row rankinnerdata align-items-center justify-content-between">
                                                <div className="col-sm-6">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <div className="ranktotal">Total Bets:</div>
                                                        <div className="ranktotal text-white">{user?.totalBet ? user?.totalBet : "0"}</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <div className="ranktotal">Wagered:</div>
                                                        <div className="ranktotal text-white">${user?.wageredAmount ? user?.wageredAmount : "0"}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row rankinnerdata align-items-center justify-content-between">
                                                <div className="col-sm-6">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <div className="ranktotal">Rewarded:</div>
                                                        <div className="ranktotal text-white">${user?.bonus ? user?.bonus : "0"}</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <div className="ranktotal">Join On:</div>
                                                        <div className="ranktotal text-white"> {user ? moment(user?.createdAt).format("DD MMM YYYY") : 'No data'}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row rankinnerdata align-items-center justify-content-between">
                                                <div className="col-sm-6">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <div className="ranktotal">Rollback:</div>
                                                        <div className="ranktotal text-white">${user?.rollback ? user?.rollback : "0"}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserInfoModal;
