import React from "react";
import { useTranslation } from "react-i18next";

const SearchInput = ({ search, setSearch }) => {
    const { t } = useTranslation()
    const resultModal = t('resultModal', { returnObjects: true })

    return (
        <div className="col" style={{ maxWidth: 300 }}>
            <input
                className='searchInp'
                type="text"
                name="" id=""
                // placeholder='Search for games'
                placeholder={resultModal.Search_for_games}
                // value={search?.replace(/\s/g, '')}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
        </div>
    );
};

export default SearchInput;
