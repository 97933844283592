import React from 'react'
import AppFooter from '../../components/AppFooter/AppFooter'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTES_CONST } from '../../constants/routeConstant'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { APP_NAME } from '../../constants'

const AffilliatePage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const affiliateLabels = t("affiliateLabels", { returnObjects: true })
    return (
        <>
            <main className="main vh-100" id="main">

                <div className="mainContaint">
                    <div className="affiliatepagenew homepage pt-lg-4">

                        <div className="container-fluid partnershipSection">
                            <div className="affiliateheading">
                                {affiliateLabels.App_partnership}
                            </div>
                            <div className="partnershiptitle">
                                {affiliateLabels.The_most_rewarding}
                            </div>
                            {/*  <div className="row partnershipCardWrapper parnershipCardMain">
                                <div className="col-sm-auto col-12 partnerShipColAuto1">
                                    <div className="row partnershipcolAuto mx-0 h-100">
                                        <div className="col-md-12 col-auto iconCol d-flex justify-content-center align-items-center">
                                            <div className="casinoicon d-flex align-items-center justify-content-center">
                                                <img src="assets/img/affiliatenew/casino.png" alt="casino" className="h-100 w-100" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 col txtCol d-flex flex-column justify-content-md-center align-items-md-center">
                                            <div className="gamename">CASINO</div>
                                            <div className="cardTitle d-md-none">+ 20,000 Games for the Best Casino experience.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm col-12 partnerShipCol">
                                    <div className="row partnershipcolAuto mx-0 align-items-center h-100">
                                        <div className="col-lg col-12">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="cardTitle d-none d-md-block">+ 20,000 Games for the Best Casino experience.</div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="carddetail">We provide users with more than 20.000 games from Slots to live casino games & more. All our Games are from the top providers for the best Casino Experience.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-auto col-12 mt-4 mt-lg-0">
                                            <button
                                                onClick={() => navigate(ROUTES_CONST.INDEX)}
                                                className="gotoBtn">Go to Casino<img src="assets/img/affiliatenew/gotocasino.png" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row partnershipCardWrapper parnershipCardMain">
                                <div className="col-sm-auto col-12 partnerShipColAuto1">
                                    <div className="row partnershipcolAuto mx-0 h-100">
                                        <div className="col-md-12 col-auto iconCol d-flex justify-content-center align-items-center">
                                            <div className="casinoicon d-flex align-items-center justify-content-center">
                                                <img src="assets/img/affiliatenew/sport.png" alt="casino" className="h-100 w-100" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 col txtCol d-flex flex-column justify-content-md-center align-items-md-center">
                                            <div className="gamename">SPORTS</div>
                                            <div className="cardTitle d-md-none">25+ Sports 1000+ Betting Markets 60 000+ events per Months</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm col-12 partnerShipCol">
                                    <div className="row partnershipcolAuto mx-0 align-items-center h-100">
                                        <div className="col-lg col-12">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="cardTitle d-none d-md-block">25+ Sports 1000+ Betting Markets 60 000+ events per Months</div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="carddetail">{APP_NAME} sportsbook offers the widest range of sports events, from live sports to Esports betting, free bets & boosted odds are also available for the greatest betting experience. </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-auto col-12 mt-4 mt-lg-0">
                                            <button
                                                onClick={() => navigate(ROUTES_CONST.SPORTS)}
                                                className="gotoBtn">Go to Sports<img src="assets/img/affiliatenew/gotocasino.png" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row partnershipCardWrapper parnershipCardMain">
                                <div className="col-sm-auto col-12 partnerShipColAuto1">
                                    <div className="row partnershipcolAuto mx-0 h-100">
                                        <div className="col-md-12 col-auto iconCol d-flex justify-content-center align-items-center">
                                            <div className="casinoicon d-flex align-items-center justify-content-center">
                                                <img src="assets/img/affiliatenew/staking.png" alt="casino" className="h-100 w-100" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 col txtCol d-flex flex-column justify-content-md-center align-items-md-center">
                                            white !important;!important;               <div className="gamename">STAKING</div>
                                            <div className="cardTitle d-md-none">Stake your crypto and get up to 50% APR!</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm col-12 partnerShipCol">
                                    <div className="row partnershipcolAuto mx-0 align-items-center h-100">
                                        <div className="col-lg col-12">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="cardTitle d-none d-md-block">Stake your crypto and get up to 50% APR!</div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="carddetail">{APP_NAME} allows you to stake your crypto in the platform where you can earn up to 50% APR! Stake your crypto and start earning! </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-auto col-12 mt-4 mt-lg-0">
                                            <button className="gotoBtn">Coming Soon</button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="row parnershipCardMain g-sm-3 g-2">
                                <div className="col-md-4">
                                    <div className="parnershipCard d-flex justify-content-between flex-column position-relative h-100" onClick={() => navigate(ROUTES_CONST.INDEX)}>
                                        <div>
                                            <div className="cardHeading d-flex align-items-center">
                                                <div className="casinoicon d-flex align-items-center justify-content-center">
                                                    <img src="assets/img/affiliatenew/casino.png" alt="casino" className="h-100 w-100" />
                                                </div>
                                                CASINO
                                            </div>
                                            <div className="cardTitle">
                                                + 20,000 Games for the Best Casino experience.
                                            </div>
                                            <div className="carddetail">
                                                We provide users with more than 20.000 games from Slots to live casino games & more. All our Games are from the top providers for the best Casino Experience.
                                            </div>
                                        </div>
                                        <span className="d-flex justify-content-end align-items-center gotocasino text-decoration-none stretched-link cursor-pointer">
                                            {affiliateLabels.Go_to_Casino}
                                            <span className="d-flex align-items-center justify-content-center gotocasinoicon">
                                                <img src="assets/img/affiliatenew/gotocasino.png" alt="affiliate" className="h-100 w-100 img-fluid" />
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="parnershipCard d-flex justify-content-between flex-column position-relative h-100" onClick={() => navigate(ROUTES_CONST.SPORTS)}>
                                        <div>
                                            <div className="cardHeading d-flex align-items-center ">
                                                <div className="casinoicon d-flex align-items-center justify-content-center">
                                                    <img src="assets/img/affiliatenew/sport.png" alt="SPORTS" className="h-100 w-100" />
                                                </div>
                                                SPORTS
                                            </div>
                                            <div className="cardTitle">
                                                25+ Sports 1000+ Betting Markets 60 000+ events per Months
                                            </div>
                                            <div className="carddetail">
                                                MemeStop sportsbook offers the widest range of sports events, from live sports to Esports betting, free bets & boosted odds are also available for the greatest betting experience.
                                            </div>
                                        </div>
                                        <a href="javascript:;" className="d-flex justify-content-end align-items-center gotocasino text-decoration-none stretched-link">
                                            {affiliateLabels.Go_to_Sports}
                                            <span className="d-flex align-items-center justify-content-center gotocasinoicon">
                                                <img src="assets/img/affiliatenew/gotocasino.png" alt="affiliate" className="h-100 w-100 img-fluid" />
                                            </span>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="parnershipCard d-flex justify-content-between flex-column position-relative h-100">
                                        <div>
                                            <div className="cardHeading d-flex align-items-center">
                                                <div className="casinoicon d-flex align-items-center justify-content-center">
                                                    <img src="assets/img/affiliatenew/vault.png" alt="VAULT" className="h-100 w-100" style={{
                                                        filter: 'grayscale(1) contrast(4.5)'
                                                    }} />
                                                </div>
                                                STAKING
                                            </div>
                                            <div className="cardTitle">
                                                Stake your crypto and get up to 50% APR!
                                            </div>
                                            <div className="carddetail">
                                                MemeStop allows you to stake your crypto in the platform where you can earn up to 50% APR! Stake your crypto and start earning!
                                            </div>
                                        </div>
                                        <div className="gotocasino d-flex justify-content-end align-items-center">
                                            {affiliateLabels.Coming_soon}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid commissinplanSection">
                            <div className="affiliateheading">
                                {affiliateLabels.Сommission_plan}
                            </div>
                            <div className="commissioncard">
                                <div className="row">
                                    <div className="col-12 commissionData">
                                        {affiliateLabels.The_App_Affiliate_program}
                                    </div>
                                    <div className="col-12 commissionTitle">
                                        *{affiliateLabels.Reward_for_Casino}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid becomepartnerSection">
                            <div className="affiliateheading">
                                {affiliateLabels.Why_becoming}
                            </div>
                            <div className="row partnerCardMain p-0" style={{ background: 'transparent', }}>
                                <div className="col-md-6 ">
                                    <div className="partnerCard position-relative h-100 d-flex flex-column justify-content-between">
                                        <div>
                                            <div className="partnerHeading">
                                                {affiliateLabels.International_brand}
                                            </div>
                                            <div className="partnerDetail">
                                                {affiliateLabels.App_is_online}
                                            </div>
                                        </div>
                                        <div className="cardfooter d-flex align-items-center justify-content-between">
                                            <a href="javascript:;" className="d-flex w-100 stretched-link align-items-center text-decoration-none justify-content-end cardlink">
                                                {affiliateLabels.Check_our_Celebrity}
                                                <span className="d-flex align-items-center justify-content-center linkicon">
                                                    <img src="assets/img/affiliatenew/gotocasino.png" alt="check our celebrity list" className="h-100 w-100 img-fluid" />
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4 mt-md-0">
                                    <div className="partnerCard position-relative h-100 d-flex flex-column justify-content-between">
                                        <div>
                                            <div className="partnerHeading">
                                                {affiliateLabels.Professional_Support}
                                            </div>
                                            <div className="partnerDetail">
                                                {affiliateLabels.You_will_have}
                                            </div>
                                        </div>
                                        <div className="cardfooter d-flex align-items-center justify-content-between">
                                            <a href="javascript:;" className="d-flex w-100 stretched-link align-items-center text-decoration-none justify-content-end cardlink">
                                                {affiliateLabels.Contact_us}
                                                <span className="d-flex align-items-center justify-content-center linkicon">
                                                    <img src="assets/img/affiliatenew/gotocasino.png" alt="check our celebrity list" className="h-100 w-100 img-fluid" />
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4 ">
                                    <div className="partnerCard position-relative h-100 d-flex flex-column justify-content-between">
                                        <div>
                                            <div className="partnerHeading">
                                                {affiliateLabels.Favorable_payouts}
                                            </div>
                                            <div className="partnerDetail">
                                                {affiliateLabels.With_our_program}
                                            </div>
                                        </div>
                                        <div className="cardfooter d-flex align-items-center justify-content-between" style={{ margin: '0' }}>
                                            <a href="javascript:;" className="d-flex w-100 stretched-link align-items-center text-decoration-none  cardlink text-start">
                                                {affiliateLabels.Contact_us}
                                                <span className="d-flex align-items-center justify-content-center linkicon">
                                                    <img src="assets/img/affiliatenew/gotocasino.png" alt="check our celebrity list" className="h-100 w-100 img-fluid" />
                                                </span>
                                            </a>
                                            <div className="ngrtitle ngrtitle1">
                                                {affiliateLabels.Net_gaming_revenue}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <div
                                        onClick={() => {
                                            dispatch({
                                                type: "AFFILIATE",
                                                payload: "Affiliate",
                                            })
                                            // navigate(ROUTES_CONST.ACCOUNTE)
                                        }
                                        }
                                        className="partnerCard position-relative h-100 d-flex flex-column justify-content-between">
                                        <div>
                                            <div className="partnerHeading">
                                                {affiliateLabels.Traffic_performance}
                                            </div>
                                            <div className="partnerDetail">
                                                {affiliateLabels.You_will_have_access}
                                            </div>
                                        </div>
                                        <div className="cardfooter d-flex align-items-center justify-content-between">
                                            <Link to={ROUTES_CONST.ACCOUNT} className="d-flex w-100 stretched-link align-items-center text-decoration-none justify-content-end cardlink">
                                                {affiliateLabels.Go_to_Dashboard}
                                                <span className="d-flex align-items-center justify-content-center linkicon">
                                                    <img src="assets/img/affiliatenew/gotocasino.png" alt="check our celebrity list" className="h-100 w-100 img-fluid" />
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid guidelinesSection">
                            <div className="affiliateheading">
                                {affiliateLabels.Guidelines}
                            </div>
                            <div className="row guidelinescardmain g-sm-3 g-2">
                                <div className="col-lg-3 col-6">
                                    <div className="guidelinescard h-100">
                                        <div className="guidelinesimg d-flex align-items-center justify-content-center">
                                            <img src="assets/img/affiliatenew/marketing.png" alt="marketing" className="h-100 img-fluid" />
                                        </div>
                                        <div className="guidelinedata">
                                            {affiliateLabels.Affiliate_must_exclusively}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                    <div className="guidelinescard h-100">
                                        <div className="guidelinesimg d-flex align-items-center justify-content-center">
                                            <img src="assets/img/affiliatenew/review.png" alt="review" className="h-100 img-fluid" />
                                        </div>
                                        <div className="guidelinedata">
                                            {affiliateLabels.Affiliate_must_represent}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                    <div className="guidelinescard h-100">
                                        <div className="guidelinesimg d-flex align-items-center justify-content-center">
                                            <img src="assets/img/affiliatenew/notification.png" alt="notification" className="h-100 img-fluid" />
                                        </div>
                                        <div className="guidelinedata">
                                            {affiliateLabels.Affiliate_must_notify}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                    <div className="guidelinescard h-100">
                                        <div className="guidelinesimg d-flex align-items-center justify-content-center">
                                            <img src="assets/img/affiliatenew/clipboard.png" alt="clipboard" className="h-100 img-fluid" />
                                        </div>
                                        <div className="guidelinedata">
                                            {affiliateLabels.App_reserves_the}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="guidelinescard cardlast d-flex align-items-center justify-content-center">
                                        <div className="guidelinesimg d-flex align-items-center justify-content-center">
                                            <img src="assets/img/affiliatenew/streaming.png" alt="streaming" className="h-100 img-fluid" />
                                        </div>
                                        <div className="guidelinedata mt-0 text-start">
                                            {affiliateLabels.Affiliate_must_actively}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid faqpage">
                            <div className="affiliateheading">
                                {affiliateLabels.FAQ}
                            </div>
                            <div className="faqCard">
                                <div className="row faqMain">
                                    <div className="col-12">
                                        <div className="faqinner">
                                            <div className="question d-flex align-items-end">
                                                <div className="faqcount">01</div>
                                                <span className='d-inline-block'>{affiliateLabels.What_is_an}</span>
                                            </div>
                                            <div className="answer">
                                                {affiliateLabels.Affiliate_Program_is}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="faqinner">
                                            <div className="question d-flex align-items-end">
                                                <div className="faqcount">02</div>
                                                <span className="d-inline-block">
                                                    {affiliateLabels.How_do_I}</span>
                                            </div>
                                            <div className="answer">
                                                {affiliateLabels.Whenever_a_player}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="faqinner border-0 pb-0">
                                            <div className="question d-flex align-items-end">
                                                <div className="faqcount">03</div>
                                                <span className=" d-inline-block">
                                                    {affiliateLabels.earn_commission}</span>
                                            </div>
                                            <div className="answer">
                                                {affiliateLabels.When_you_refer}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid JoinusSection">
                            <div className="affiliateheading">
                                {affiliateLabels.Join_us}
                            </div>
                            <div className="joinuscard">
                                <div className="row g-3">
                                    <div className="col-12 signuptitle">
                                        {affiliateLabels.Sign_Up}
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="row g-3">
                                            <div className="col-md-6 order-1">
                                                <div className="inputmain position-relative">
                                                    <input type="text" id="nameinput" className="form-control shadow-none affiliateInput bg-transparent rounded-0 border-0 h-100" />
                                                    <label for="nameinput" className="affiliateinputLabel position-absolute">{affiliateLabels.Name}</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 order-md-2 order-5">
                                                <div className="socialmedia d-flex align-items-center gap-2 w-100">
                                                    <div className="dropdown instagramdropdown">
                                                        <button className="btn instabtn dropdown-toggle d-flex align-items-center justify-content-center" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                                            Instagram
                                                        </button>
                                                        <ul className="dropdown-menu instamenu w-100">
                                                            <li>
                                                                <a className="dropdown-item socialmedialink bg-transparent active mt-0" href="javascript:;">Instagram</a>
                                                            </li>
                                                            <li>
                                                                <a className="dropdown-item socialmedialink bg-transparent" href="javascript:;">Facebook</a>
                                                            </li>
                                                            <li>
                                                                <a className="dropdown-item socialmedialink bg-transparent" href="javascript:;">Youtube</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="inputmain position-relative w-100">
                                                        <input type="text" id="yourinstagram" className="form-control shadow-none affiliateInput bg-transparent rounded-0 border-0 h-100" />
                                                        <label for="yourinstagram" className="affiliateinputLabel position-absolute">{affiliateLabels.Your} Instagram</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 order-md-3 order-2">
                                                <div className="inputmain position-relative">
                                                    <input type="email" id="emailinput" className="form-control shadow-none affiliateInput bg-transparent rounded-0 border-0 h-100" />
                                                    <label for="emailinput" className="affiliateinputLabel position-absolute">{affiliateLabels.Email}</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 order-md-4 order-last">
                                                <div className="inputmain position-relative">
                                                    <input type="text" id="companyname" className="form-control shadow-none affiliateInput bg-transparent rounded-0 border-0 h-100" />
                                                    <label for="companyname" className="affiliateinputLabel position-absolute">{affiliateLabels.Your_Company_name}</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 order-md-5 order-3">
                                                <div className="inputmain position-relative d-flex align-items-center">
                                                    <input type="text" id="Password" className="form-control shadow-none affiliateInput bg-transparent rounded-0 border-0 h-100" />
                                                    <label for="Password" className="affiliateinputLabel position-absolute">{affiliateLabels.Password}</label>
                                                    <button className="border-0 shadow-none d-flex align-items-center justify-content-center p-0 passwordvisible bg-transparent">
                                                        <img src="assets/img/affiliatenew/passwordvisible.png" alt="password" className="h-100 w-100 img-fluid" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-md-6 order-md-6 order-4">
                                                <div className="inputmain position-relative d-flex align-items-center">
                                                    <input type="text" id="confirmpassword" className="form-control shadow-none affiliateInput bg-transparent rounded-0 border-0 h-100" />
                                                    <label for="confirmpassword" className="affiliateinputLabel position-absolute">{affiliateLabels.Confirm_Password}</label>
                                                    <button className="border-0 shadow-none d-flex align-items-center justify-content-center p-0 passwordvisible bg-transparent">
                                                        <img src="assets/img/affiliatenew/passwordvisible.png" alt="password" className="h-100 w-100 img-fluid" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="h-100 row flex-column align-items-center justify-content-between">
                                            <div className="col-12">
                                                <div className="termsservice d-flex align-items-start justify-content-start">
                                                    <input type="checkbox" id="termsofservice" className="checkinput form-check-input shadow-none" />
                                                    <label for="termsofservice">{affiliateLabels.By_accessing_you} <a href="javascript:;" className="text-decoration-none conditiontitle">Terms of Service</a></label>
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-lg-4 col-sm-5 col-sm-6  mx-auto">
                                                <button className="btn resisterbtn d-flex align-items-center justify-content-center w-100">{affiliateLabels.Register_now}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AppFooter />

            </main>
        </>
    )
}

export default AffilliatePage