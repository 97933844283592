import React from 'react'
import { createContext } from 'react'
import { useState } from 'react'
import { KADO_WALLET_TABS } from '../constants'

export const KadoWalletContext = createContext(null)

const KadoWalletContextProvider = ({children}) => {
  const [kadoWalletObject, setKadoWalletObject] = useState({
    isBuySellOpen : false,
    isSelectWalletOpen : false,
    isSelectCurrencyOpen : false,
    isSelectNetworkOpen : false,
    selectedTab : KADO_WALLET_TABS.BUY
  })
  return (
    <KadoWalletContext.Provider value={{kadoWalletObject, setKadoWalletObject}}> {children}</KadoWalletContext.Provider>
  )
}

export default KadoWalletContextProvider